/** -------------------------------------------------------------------------------------------------------------------
 * VideoAssignmentView
 * A component that renders the subview for assigning a video to a university, a course, or a lecture
 *
 * @examples
 *
 *  ```jsx
 *
 *    <VideoAssignmentView
 *      userInfo={userInfo}
 *      video={video}
 *      i18n={i18n}
 *      context={context}
 *      courses={courses}
 *      lectures={lectures}
 *      showSpinner={false}
 *      onVideoActions={(action) => console.log("On video action: ", action)}
 *    />
 *
 * ```
 *
 *  @component VideoAssignmentView
 *  @import VideoAssignmentView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import BlackAndWhite from "../../../media/black-and-white";

import {
    ADD_COURSE,
    ADD_LECTURE,
    SET_ASSIGNMENT_TYPE,
    SET_ASSIGNED_COURSE_ID,
    SET_ASSIGNED_LECTURE_ID
} from "../../../store/reducers/videos/videos.types";

import Selector from "../../shared/selector";
import { Loader } from "../../shared/spinner/loader";

import "../videos.scss";


export class VideoAssignmentView extends PureComponent {

    constructor(props) {
        super(props);

        this.movieContainer = React.createRef();

        const assignmentOptions = this._generateAssignmentOptions();
        const courseList = this._generateCourseList();
        const lectureList = this._generateLectureList();

        this.state = {
            assignmentOptions,
            courseList,
            lectureList,
            assignmentType: null
        };
    }

    _generateAssignmentOptions() {
        const { getString } = this.props.i18n;

        return [
            { id: "courseIntro", name: "courseIntro", title: getString("videos.assignmentCourseIntro") },
            { id: "courseLecture", name: "courseLecture", title: getString("videos.assignmentCourseLecture") },
            { id: "universityIntro", name: "universityIntro", title: getString("videos.assignmentUniversityIntro") }
        ];
    }

    _generateCourseList() {
        return this.props.courses.map((course) => {
            const displayId = course.courseId || course.id;

            return {
                id: course.id,
                title: `${displayId.toUpperCase()} - ${course.title}`
            };
        });
    }

    _generateLectureList() {
        return this.props.lectures.map((lecture) => ({
            id: lecture.id,
            title: lecture.title
        }));
    }

    _onAssignmentOptionChange = (type, value, isAssignmentType = false) => {
        const { onVideoActions } = this.props;
        const { courseList, lectureList } = this.state;

        if (isAssignmentType) {
            this.setState({
                assignmentType: value
            });

            if (value === "courseIntro") {
                onVideoActions(SET_ASSIGNED_COURSE_ID, (courseList.length > 0) ? courseList[0].id : null);
            } else if (value === "courseLecture") {
                onVideoActions(SET_ASSIGNED_LECTURE_ID, (lectureList.length > 0) ? lectureList[0].id : null);
            }
        }

        onVideoActions(type, value);
    };

    componentDidMount() {
        const {
            video,
            onVideoActions
        } = this.props;
        const {
            assignmentOptions,
            courseList
        } = this.state;

        onVideoActions(SET_ASSIGNMENT_TYPE, assignmentOptions[0].id);
        this.setState({
            assignmentType: assignmentOptions[0].id
        });

        if (courseList.length > 0) {
            onVideoActions(SET_ASSIGNED_COURSE_ID, courseList[0].id);
        }

        const {
            URL,
            poster
        } = video;

        this.mediaPlayer = new BlackAndWhite.MediaPlayer({
            id: "assignment-media-player",
            url: URL,
            poster,
            scaleFactor: 32,
            element: this.movieContainer.current
        });
    }

    componentWillUnmount() {
        if (this.mediaPlayer) {
            this.mediaPlayer.destroy();
            this.mediaPlayer = null;
        }
    }

    render() {
        const {
            userInfo,
            courses,
            lectures,
            i18n: { getString },
            showSpinner
        } = this.props;

        const {
            courseList,
            lectureList,
            assignmentOptions,
            assignmentType
        } = this.state;

        const hasCourses = courses.length > 0;
        const hasLectures = lectures.length > 0;

        return (
            <React.Fragment>
                <div className="view-section-wrapper video-assignment-wrapper">
                    <div className="movie-section">
                        <div className="movie-container" ref={this.movieContainer}></div>
                    </div>
                    <div className="row">
                        <p className="typography-body info">
                            {getString("videos.assignmentMessage")}
                        </p>
                    </div>
                    <div className="row type-section">
                        <div className="column medium-4 small-12 label">
                            <label>{getString("videos.assignmentType")}</label>
                        </div>
                        <div className="column medium-8 small-12">
                            <Selector
                                options={assignmentOptions}
                                onChange={(event) => {
                                    this._onAssignmentOptionChange(SET_ASSIGNMENT_TYPE, event.target.value, true);
                                }}
                            />
                        </div>
                    </div>
                    {(assignmentType === "courseIntro") && <div className="row course-intro-section">
                        <div className="column medium-4 small-12 label">
                            <label>{getString("lecture.course")}</label>
                        </div>
                        <div className="column medium-8 small-12">
                            {hasCourses ? (<Selector
                                options={courseList}
                                onChange={(event) => {
                                    this._onAssignmentOptionChange(SET_ASSIGNED_COURSE_ID, event.target.value);
                                }}
                            />) :
                                (<a
                                    href="javascript:void(0);"
                                    className="icon icon-pluscircle icon-before"
                                    onClick={() => this._onAssignmentOptionChange(ADD_COURSE)}>
                                    {getString("course.addCourse")}
                                </a>)
                            }
                        </div>
                    </div>}
                    {(assignmentType === "courseLecture") && <div className="row course-lecture-section">
                        <div className="column medium-4 small-12 label">
                            <label>{getString("lecture.lecture")}</label>
                        </div>
                        <div className="column medium-8 small-12">
                            {hasLectures ? (<Selector
                                options={lectureList}
                                onChange={(event) => {
                                    this._onAssignmentOptionChange(SET_ASSIGNED_LECTURE_ID, event.target.value);
                                }}
                            />) :
                                (<a
                                    href="javascript:void(0);"
                                    className="icon icon-pluscircle icon-before"
                                    onClick={() => this._onAssignmentOptionChange(ADD_LECTURE)}>
                                    {getString("lecture.scheduleLecture")}
                                </a>)
                            }
                        </div>
                    </div>}
                    {(assignmentType === "universityIntro") && <div className="row university-intro-section">
                        <div className="column medium-4 small-12 label">
                            <label>{getString("generic.university")}</label>
                        </div>
                        <div className="column medium-8 small-12">
                            <p>{userInfo.universityName}</p>
                        </div>
                    </div>}
                    {showSpinner && <div className="loader-wrapper">
                        <Loader />
                    </div>}
                </div>
            </React.Fragment>
        );
    }
}

VideoAssignmentView.propTypes = {
    userInfo: PropTypes.object,
    video: PropTypes.object.isRequired,
    courses: PropTypes.array,
    lectures: PropTypes.array,
    i18n: PropTypes.object,
    auth: PropTypes.object,
    showSpinner: PropTypes.bool,
    onVideoActions: PropTypes.func
};

VideoAssignmentView.defaultProps = {
    courses: [],
    lectures: [],
    onVideoActions: () => {}
};

export default VideoAssignmentView;
