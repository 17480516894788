/** -------------------------------------------------------------------------------------------------------------------
 * VideoPlaybackView
 * A component that renders the subview for playing a video
 *
 * @examples
 *
 *  ```jsx
 *
 *    <VideoPlaybackView
 *      video={video}
 *      onPlaybackActions={(action) => console.log("On playback action: ", action)}
 *    />
 *
 * ```
 *
 *  @component VideoPlaybackView
 *  @import VideoPlaybackView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import BlackAndWhite from "../../../media/black-and-white";

import { noop } from "../../../utils/functions";


export class VideoPlaybackView extends PureComponent {

    constructor(props) {
        super(props);

        this.movieContainer = React.createRef();
    }

    componentDidMount() {
        const { video } = this.props;
        const {
            URL,
            poster
        } = video;

        this.mediaPlayer = new BlackAndWhite.MediaPlayer({
            id: "assignment-media-player",
            url: URL,
            poster,
            scaleFactor: 28,
            element: this.movieContainer.current
        });
    }

    componentWillUnmount() {
        if (this.mediaPlayer) {
            this.mediaPlayer.destroy();
            this.mediaPlayer = null;
        }
    }

    render() {
        return (
            <div className="video-playback-wrapper">
                <div className="movie-section">
                    <div className="movie-container" ref={this.movieContainer}></div>
                </div>
            </div>
        );
    }
}

VideoPlaybackView.propTypes = {
    video: PropTypes.object.isRequired,
    onPlaybackActions: PropTypes.func
};

VideoPlaybackView.defaultProps = {
    onPlaybackActions: noop
};

export default VideoPlaybackView;
