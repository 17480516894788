import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";


export const SiteInfo = ({ i18n }) => (
    <Fragment>
        <Link
            to="/terms-and-conditions"
            title={i18n.getString("privacy.termsAndConditions")}
            target="_blank">
            {i18n.getString("privacy.termsAndConditions")}
        </Link>
        <Link
            to="/privacy-policy"
            title={i18n.getString("privacy.policy")}
            target="_blank">
            {i18n.getString("privacy.policy")}
        </Link>
    </Fragment>
);

SiteInfo.propTypes = {
    i18n: PropTypes.object
};
