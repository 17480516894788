/** -------------------------------------------------------------------------------------------------------------------
 * Courses
 * A top-level component for the Courses page
 *
 * @examples
 *
 *  ```jsx
 *    let CourseComponent = WithRouter(InjectIntl(Courses, initialState.locale));
 *    CourseComponent = StoreEnhancedComponent(CourseComponent, courseReducers);
 *
 *    ReactDOM.render(
 *       <Provider store={store}>
 *          <Courses />
 *       </Provider>
 *       document.getElementById("app-root")
 * );
 * ```
 *
 *  @component Courses
 *  @import Courses
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import LocalNav from "../shared/local-nav";
import PageTemplate from "../shared/pages/template";
import ModalDialog from "../shared/modal";
import ModalSheet from "../shared/modal/modal-sheet";
import EditCourseView from "./views/edit-course-view";
import Course from "./views/course";
import { Loader } from "../shared/spinner/loader";
import languages from "../../store/data/languages";

import {
    fetchUserCourses,
    onCourseDetailUpdated,
    addNewCourse,
    updateCourse,
    resetQuery,
    resetErrors
} from "../../store/reducers/courses/courses.actions";

import {
    fetchUniversityInstructors
} from "../../store/reducers/universities/universities.actions";

import "./courses.scss";
import "../../media/black-and-white/styles/main.scss";


export class Courses extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showingModalDialog: false,
            showingErrorSheet: false,
            validationStates: this._initialValidationStates(),
            isValid: false,
            isEditMode: false
        };
    }

    _initialValidationStates() {
        return {
            id: false,
            title: false,
            startDate: false,
            endDate: false
        };
    }

    _onCourseActions = (action, id) => {
        const course = this.props.courses.courseList.find((course) => course.id === id);

        if (action === "edit") {
            this.setState({
                editingCourse: course,
                isEditMode: true,
                showingModalDialog: true
            });
        } else {
            this.props.history.push(`${this.props.match.url}/${id}`);
        }
    };

    _onCourseDetailsUpdated = (name, value) => {
        value = (name === "startDate" || name === "endDate") ? this.props.i18n.formatDate(value, "%Y-%m-%D") :
            (name === "price") ? parseFloat(value.replace(/[,]+/, ".")) : value;
        this.props.onCourseDetailUpdated(name, value);
        const { validationStates } = this.state;

        if (Object.keys(validationStates).includes(name)) {
            validationStates[name] = true;

            this.setState({
                validationStates
            });
        }
    };

    _onInvalidCourseDetails = (name) => {
        const { validationStates } = this.state;
        validationStates[name] = false;

        this.setState({
            validationStates
        });
    };

    _onColorCodeSelected = (colorCode, selected) => {
        this.props.onCourseDetailUpdated("colorCode", selected ? colorCode : null);
    };

    _onAddNewCourse = () => {
        this.setState({
            showingModalDialog: true
        });
    };

    _onCancel = () => {
        this.props.resetQuery();
        this.setState({
            validationStates: this._initialValidationStates(),
            showingModalDialog: false,
            isEditMode: false
        });
    };

    _onUpdateCourse = () => {
        const { id } = this.props.auth.userInfo;

        if (this.state.isEditMode) {
            this.props.updateCourse(id, this.state.editingCourse.id);
        } else {
            this.props.addNewCourse(id);
        }
    };

    _extractCourseDetails() {
        const {
            i18n,
            courses: { courseList }
        } = this.props;

        const userCourses = courseList.map((course) => {
            course.configuration = (typeof course.configuration === "string") ? JSON.parse(course.configuration) :
                course.configuration;

            const formattedStartDate = course.startDate ? i18n.formatDate(new Date(Date.parse(course.startDate)),
                i18n.languagePack.dateFormat.MEDIUM) : "";
            const formattedEndDate = course.endDate ? i18n.formatDate(new Date(Date.parse(course.endDate)),
                i18n.languagePack.dateFormat.MEDIUM) : "";
            const formattedPrice = (course.price === 0) ? i18n.getString("course.free") : course.formattedPrice;

            course.formattedStartDate = formattedStartDate;
            course.formattedEndDate = formattedEndDate;
            course.formattedPrice = formattedPrice;

            return course;
        });

        return userCourses;
    }

    _onErrorSheetActions = () => {
        this.props.resetErrors();
        this.setState({
            showingErrorSheet: false
        });
    };

    _renderCourses() {
        const courses = this._extractCourseDetails();
        const {
            match,
            auth: { userInfo: { id }}
        } = this.props;

        return courses.map((course) => {
            const courseLanguage = course.deliveryLanguage.split("_")[0];
            const deliveryLanguage = languages.find((language) => language.locale === courseLanguage);
            course.deliveryLanguageName = deliveryLanguage.displayName;
            const canEdit = course.instructor.id === id;
            const url = `${match.url}/${course.id}`;

            return (
                <Course
                    key={course.id}
                    course={course}
                    url={url}
                    className="column"
                    onClick={this._onCourseActions}
                    i18n={this.props.i18n}
                    canEdit={canEdit}
                />
            );
        });
    }

    componentDidMount() {
        this.props.fetchUserCourses(this.props.auth.userInfo.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.courses.isUpdating === true && this.props.courses.isUpdating === false) {
            const { validationStates } = this.state;
            Object.keys(validationStates).forEach((key) => {
                validationStates[key] = false;
            });

            this.setState({
                validationStates,
                showingModalDialog: false,
                showingErrorSheet: this.props.courses.error
            });
        }
    }

    render() {
        const {
            auth: { userInfo: { accountType }},
            i18n: { getString },
            courses: { courseList, isLoading, isUpdating }
        } = this.props;
        const {
            showingModalDialog,
            showingErrorSheet,
            editingCourse,
            isEditMode
        } = this.state;
        const isValidForm = Object.values(this.state.validationStates).every((item) => !!item);
        const hasCourses = (courseList.length > 0);
        const isStudent = accountType.toLowerCase() === "student";

        return (
            <React.Fragment>
                <PageTemplate
                    {...this.props}
                    className="course-wrapper">
                    <LocalNav
                        title={getString("title.courses")}
                        page="courses"
                        className="section-local-nav"
                        showPageInfo={true}
                        showContextualAction={false}
                    />

                    <div className="module-section">
                        <div className={classNames("row course-list-wrapper", {
                            "no-content": (isStudent && !hasCourses)
                        })}>
                            {isLoading && <div className="loader-wrapper"><Loader /></div>}
                            {(!isLoading && !hasCourses && isStudent) &&
                                <div className="no-content">
                                    <p className="typography-body info">
                                        {getString("course.noCoursesFound")}
                                    </p>
                                </div>
                            }
                            {((!isLoading && isStudent && hasCourses) || (!isLoading && !isStudent)) &&
                                <ul className="course-list row">
                                    {this._renderCourses()}
                                    {!isStudent && <li className="column actions">
                                        <a href="javascript:void(0);" onClick={this._onAddNewCourse}>
                                            <i className="icon icon-close" />
                                        </a>
                                    </li>}
                                </ul>
                            }
                        </div>
                    </div>

                </PageTemplate>
                {showingModalDialog &&
                    <ModalDialog
                        mode="compact"
                        title={getString(isEditMode ? "generic.update" : "course.addNewCourse")}
                        okLabel={isEditMode ? getString("generic.update") : getString("course.addCourse")}
                        cancelLabel={getString("generic.cancel")}
                        autoDismiss={false}
                        okButtonDisabled={isUpdating || !isValidForm}
                        onModalConfirm={this._onUpdateCourse}
                        onModalClose={this._onCancel}>
                        <div className="add-course-wrapper">
                            <EditCourseView
                                mode={isEditMode ? "edit" : "create"}
                                course={isEditMode ? editingCourse : null}
                                i18n={this.props.i18n}
                                showSpinner={isUpdating}
                                onChange={this._onCourseDetailsUpdated}
                                onColorCodeSelected={this._onColorCodeSelected}
                                onError={this._onInvalidCourseDetails}
                            />
                        </div>
                    </ModalDialog>}
                {showingErrorSheet &&
                    <ModalSheet
                        title={getString("course.courseCreationError")}
                        type="error"
                        mode="compact"
                        showCancelButton={false}
                        okLabel={getString("generic.OK")}
                        onOK={this._onErrorSheetActions}
                        onModalClose={this._onErrorSheetActions}>
                        <p>{getString(this.props.courses.error)}</p>
                    </ModalSheet>}
            </React.Fragment>
        );
    }
}

Courses.propTypes = {
    title: PropTypes.string,
    auth: PropTypes.object,
    context: PropTypes.object,
    i18n: PropTypes.object,
    profile: PropTypes.object,
    retrieveUserNetwork: PropTypes.func
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth,
    courses: state.courses,
    universities: state.universities,
    profile: state.profile
});

const mapDispatchToProps = {
    fetchUserCourses,
    onCourseDetailUpdated,
    addNewCourse,
    updateCourse,
    resetQuery,
    resetErrors,
    fetchUniversityInstructors
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
