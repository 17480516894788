/** -------------------------------------------------------------------------------------------------------------------
 * CourseDiscussionsView
 * A component that renders the subview for showing the discussions for a given course
 *
 * @examples
 *
 *  ```jsx
 *
 *    <CourseDiscussionsView
 *      courses={courses}
 *      course={course}
 *      discussions={discussions}
 *      i18n={i18n}
 *      onDiscussionActions={(discussion, action) => { console.log("onDiscussionAction"); }}
 *    />
 * );
 * ```
 *
 *  @component CourseDiscussionsView
 *  @import CourseDiscussionsView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import CourseDiscussion from "./course-discussion";
import CommentForm from "./comment-form";
import CourseDiscussionDetailsView from "./course-discussion-details-view";

import { noop } from "../../../utils/functions";

import "../courses.scss";


export class CourseDiscussionsView extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            currentDiscussion: null
        };
    }

    _onDiscussionSelected = (event) => {
        const discussionId = event.currentTarget.getAttribute("data-id");
        const currentDiscussion = this._findDiscussionById(discussionId);

        this.setState({
            currentDiscussion,
            isShowingDiscussionDetails: true
        }, () => {
            this.props.onDiscussionSelected(currentDiscussion);
            this._showCommentsForCurrentDiscussion();
        });
    };

    _findDiscussionById(id) {
        return this.props.discussions.find((discussion) => discussion.id === id);
    }

    _hideDiscussionDetails = () => {
        this.setState({
            isShowingDiscussionDetails: false
        });
    };

    _renderDiscussionList() {
        const {
            userInfo,
            discussions,
            comments,
            likedComments,
            likedDiscussions,
            i18n,
            onDiscussionActions,
            onCommentActions
        } = this.props;
        const { currentDiscussion } = this.state;
        const discussionComments = currentDiscussion ? comments[currentDiscussion.id] : null;
        const isDiscussionLiked = (
            currentDiscussion &&
            Array.isArray(likedDiscussions) &&
            likedDiscussions.includes(currentDiscussion.id)
        );

        return (
            <React.Fragment>
                <div className="sidebar-wrapper">
                    <ul className="row item-list discussion-list">
                        {discussions.map((discussion) => {
                            const className = classNames("discussion", {
                                selected: discussion === currentDiscussion
                            });

                            return (
                                <li
                                    key={discussion.id}
                                    data-id={discussion.id}
                                    className={className}
                                    onClick={this._onDiscussionSelected}
                                >
                                    <CourseDiscussion
                                        discussion={discussion}
                                        i18n={i18n}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="details-wrapper">
                    <CourseDiscussionDetailsView
                        discussion={currentDiscussion}
                        comments={discussionComments}
                        likedComments={likedComments}
                        isLiked={isDiscussionLiked}
                        i18n={i18n}
                        loadingComments={!discussionComments}
                        onDiscussionActions={onDiscussionActions}
                        onCommentActions={onCommentActions}
                    />
                    {currentDiscussion &&
                        <CommentForm
                            userInfo={userInfo}
                            i18n={i18n}
                            onSubmit={this._onCommentPost}
                        />}
                </div>
            </React.Fragment>
        );
    }

    _renderEmptyContentMessage() {
        const {
            i18n: { getString }
        } = this.props;

        return (
            <p className="info-message">
                <span>{getString("discussions.noCourseDiscussions")}</span>
            </p>
        );
    }

    _showCommentsForCurrentDiscussion() {
        const {
            comments,
            fetchDiscussionComments
        } = this.props;
        const { currentDiscussion } = this.state;

        if (!comments[currentDiscussion.id]) {
            fetchDiscussionComments(currentDiscussion);
        }
    }

    _onCommentPost = (comment) => {
        this.props.onCommentPost(this.state.currentDiscussion, comment);
    };

    render() {
        const {
            discussions,
        } = this.props;
        const { isShowingDiscussionDetails } = this.state;

        const discussionsAvailable = (Array.isArray(discussions) && discussions.length > 0);
        const wrapperClassName = classNames("section-content-wrapper", {
            "no-background": !discussionsAvailable,
            "showing-details": isShowingDiscussionDetails
        });

        return (
            <div className={wrapperClassName}>
                {!discussionsAvailable ? this._renderEmptyContentMessage() : this._renderDiscussionList()}
                {isShowingDiscussionDetails && <div className="modal-actions">
                    <button className="modal-close icon icon-close"
                        data-action="close"
                        onClick={this._hideDiscussionDetails}
                    />
                </div>}
            </div>
        );
    }
}

CourseDiscussionsView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    courses: PropTypes.object,
    course: PropTypes.object,
    discussions: PropTypes.array,
    comments: PropTypes.object,
    likedDiscussions: PropTypes.array,
    likedComments: PropTypes.array,
    match: PropTypes.object,
    onDiscussionActions: PropTypes.func,
    onDiscussionSelected: PropTypes.func,
    fetchDiscussionComments: PropTypes.func,
    onCommentPost: PropTypes.func,
    onCommentActions: PropTypes.func
};

CourseDiscussionsView.defaultProps = {
    onDiscussionActions: noop,
    onDiscussionSelected: noop,
    fetchDiscussionComments: noop,
    onCommentPost: noop,
    onCommentActions: noop
};

export default CourseDiscussionsView;
