/** -------------------------------------------------------------------------------------------------------------------
 * PreferredLanguageView
 * A component that renders the view for setting a user's preferred language
 *
 * @examples
 *
 *  ```jsx
 *
 *    <PreferredLanguageView
 *      userInfo={userInfo}
 *      i18n={i18n}
 *      locale={locale}
 *      onLanguageSelected={(locale) => console.log("Language changed: ", locale)}
 *    />
 * );
 * ```
 *
 *  @component PreferredLanguageView
 *  @import PreferredLanguageView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import languages from "../../../store/data/languages";
import Selector from "../../shared/selector";

import { noop } from "../../../utils/functions";


export class PreferredLanguageView extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            currentLanguage: this.props.context.locale
        };
    }

    _generateLanguageList() {
        const {
            currentLanguage
        } = this.state;

        const languageList = languages.map((language) => ({
            id: language.locale,
            title: language.displayName
        }));

        languageList.forEach((language) => {
            if (language.id === currentLanguage) {
                language.selected = true;
            }
        });

        return languageList;
    }

    _onLanguageSelected = (event) => {
        const locale = event.target.value;
        const selectedLanguage = this._findLanguageByLocale(locale);
        this.props.onLanguageSelected(selectedLanguage);
    };

    _findLanguageByLocale(localeId) {
        const language = languages.find((language) => language.locale === localeId);
        return language || languages[0];
    }

    render() {
        const {
            i18n: { getString }
        } = this.props;

        return (
            <div className="profile-section language-wrapper">
                <h3 className="profile-section-header">{getString("profile.preferredLanguage")}</h3>
                <div className="profile-section-wrapper">
                    <div className="row locale-selector">
                        <div className="column medium-6 small-12 label">
                            <label>{getString("profile.currentLanguage")}</label>
                        </div>
                        <div className="column medium-6 small-12">
                            <Selector
                                options={this._generateLanguageList()}
                                onChange={this._onLanguageSelected}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PreferredLanguageView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    context: PropTypes.object,
    languages: PropTypes.array,
    locale: PropTypes.string,
    country: PropTypes.string,
    onLanguageSelected: PropTypes.func
};

PreferredLanguageView.defaultProps = {
    onLanguageSelected: noop
};

export default PreferredLanguageView;
