import * as types from "./notifications.types";

const initialState = {
    isLoading: false,
    unread: [],
    error: null
};

const notifications = (state = initialState, action = {}) => {
    switch (action.type) {

        case types.NOTIFICATIONS_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case types.NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                unread: action.payload
            };

        case types.NOTIFICATIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default notifications;
