/** -------------------------------------------------------------------------------------------------------------------
 * EditCourseView
 * A component that renders the modal dialog for creating or updating a course
 *
 * @examples
 *
 *  ```jsx
 *    <EditCourseView
 *      i18n={i18n}
 *      showSpinner={isUpdating}
 *      onChange={(name, value) => console.log("Field changed: ", name, value)}
 *      onColorCodeSelected={(code) => console.log("Color code selected", code)}
 *      onError={(name) => console.log("Field error: ", name)}
 *     />
 * ```
 *
 *  @component EditCourseView
 *  @import EditCourseView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import InputField from "../../shared/input-field";
import TextArea from "../../shared/text-area";
import ColorSelector from "../../shared/color-selector";
import Selector from "../../shared/selector";
import DatePicker from "../../shared/calendar/date-picker";
import { Loader } from "../../shared/spinner/loader";

const currencies = [
    { id: "USD", title: "USD" },
    { id: "JPY", title: "JPY"},
    { id: "EUR", title: "EUR" },
    { id: "MXN", title: "MXN"}
];

const validIdChars = [
    "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m",
    "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
    "_", "-", " ", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"
];


export class EditCourseView extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            startDate: null,
            endDate: null
        };
    }

    _onDateSelected = (type, date) => {
        const { startDate, endDate } = this.state;
        let isValid = true;

        if (type === "startDate") {
            isValid = (endDate === null) ? true : endDate.getTime() > date.getTime();
        } else {
            isValid = (startDate === null) ? true : (date.getTime() > this.state.startDate.getTime());
        }

        this.setState({
            [type]: date
        });

        if (isValid) {
            this.props.onChange(type, date);
        } else {
            this.props.onError(type);
        }
    };

    _getCourseValidators() {
        const { getString } = this.props.i18n;

        return {
            isRequired: {
                message: getString("course.errorCourseIDRequired")
            },
            isCourseId: {
                test: (value) => value.split("").every((c) => validIdChars.includes(c.toLowerCase())),
                message: getString("course.errorInvalidCourseID")
            }
        };
    }

    componentDidMount() {
        const { mode, course } = this.props;

        if (mode === "edit") {
            this.props.onChange("id", course.id);
            this.props.onChange("title", course.title);
            this.props.onChange("description", course.description);
            this.props.onChange("currency", course.currency || currencies[0].id);
            this.props.onChange("price", (course.price > 0) ? `${course.price}` : "0.00");
            this.props.onChange("colorCode", course.colorCode);

            if (course.startDate) {
                this.props.onChange("startDate", new Date(Date.parse(course.startDate)));
                this.props.onChange("endDate", new Date(Date.parse(course.endDate)));
            }

        } else {
            this.props.onChange("currency", currencies[0].id);
            this.props.onChange("price", "0.00");
        }
    }

    render() {
        const {
            i18n,
            mode,
            course,
            onChange,
            onColorCodeSelected,
            onError,
            showSpinner
        } = this.props;
        const { getString } = i18n;
        const isEditMode = (mode === "edit");
        const startDate = (isEditMode && course.startDate) ? new Date(Date.parse(course.startDate)) : null;
        const endDate = (isEditMode && course.endDate) ? new Date(Date.parse(course.endDate)) : null;
        const currencyList = currencies.map((currency) => {
            currency.selected = (isEditMode && currency.id === course.currency);
            return currency;
        });

        return (
            <div className="edit-course-wrapper">
                <div className="row">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("course.courseID")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <InputField
                            value={isEditMode ? course.courseId || course.id.toUpperCase() : ""}
                            placeholder={getString("course.courseIDHint")}
                            showClearControl={false}
                            validationTypes={["isRequired", "isCourseId"]}
                            validators={this._getCourseValidators()}
                            disabled={isEditMode || showSpinner}
                            onValid={(value) => onChange("id", value)}
                            onError={() => onError("id")}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("course.title")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <InputField
                            value={isEditMode ? course.title : ""}
                            placeholder={getString("course.titleHint")}
                            showClearControl={false}
                            validationTypes={["isRequired"]}
                            validators={this._getCourseValidators()}
                            disabled={showSpinner}
                            onValid={(value) => onChange("title", value)}
                            onError={() => onError("title")}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("course.description")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <TextArea
                            value={isEditMode ? course.description : ""}
                            disabled={showSpinner}
                            placeholder={getString("course.description")}
                            onChange={(value) => onChange("description", value)}
                        />
                    </div>
                </div>
                <div className="row date-section start-date">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("course.startDate")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <DatePicker
                            date={startDate}
                            dateStrings={i18n.languagePack}
                            dateFormatter={i18n}
                            onDateSelected={(date) => this._onDateSelected("startDate", date)}
                        />
                    </div>
                </div>
                <div className="row date-section end-date">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("course.endDate")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <DatePicker
                            date={endDate}
                            dateStrings={i18n.languagePack}
                            dateFormatter={i18n}
                            onDateSelected={(date) => this._onDateSelected("endDate", date)}
                        />
                    </div>
                </div>
                <div className="row color-code-section">
                    <div className="column medium-4 small-12 label course-color-code">
                        <label>{getString("course.colorCode")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <ColorSelector
                            selectedColor={isEditMode ? course.colorCode : null}
                            onColorSelected={onColorCodeSelected}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("course.price")}</label>
                    </div>
                    <div className="column medium-3 small-4">
                        <InputField
                            value={(isEditMode && course.price > 0) ? `${course.price.toFixed(2)}` : ""}
                            className="price"
                            disabled={showSpinner}
                            placeholder={getString("course.priceHint")}
                            showClearControl={false}
                            onChange={(value) => onChange("price", value)}
                        />
                    </div>
                    <div className="column medium-5 small-6 currency-wrapper">
                        <Selector
                            options={currencyList}
                            disabled={showSpinner}
                            onChange={(event) => onChange("currency", event.target.value)}
                        />
                    </div>
                </div>
                <div className="row price-info">
                    <div className="column medium-8 small-12">
                        <p>{getString("course.priceInfo")}</p>
                    </div>
                </div>
                {showSpinner && <div className="loader-wrapper">
                    <Loader />
                </div>}
            </div>
        );
    }
}

EditCourseView.propTypes = {
    i18n: PropTypes.object,
    mode: PropTypes.string,
    course: PropTypes.object,
    showSpinner: PropTypes.bool,
    onChange: PropTypes.func,
    onColorCodeSelected: PropTypes.func,
    onError: PropTypes.func
};

EditCourseView.defaultProps = {
    mode: "create",
    onChange: () => {},
    onColorCodeSelected: () => {},
    onError: () => {}
};

export default EditCourseView;
