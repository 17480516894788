/** -------------------------------------------------------------------------------------------------------------------
 * ContactInfo
 * A component that render the details of a contact
 *
 *  @examples
 *
 *  ```jsx
 *    <ContactInfo
 *      contact={contact}
 *      i18n={i18n}
 *      showsFollowButton={true}
 *      showsFollowingLabel={true}
 *      onContactActions={(contact, action) => { console.log("onContactAction"); }}
 *    />
 * ```
 *
 *  @component ContactInfo
 *  @import ContactInfo
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
    CLASSMATE,
    ACTION_FOLLOW
} from "../../../constants/contacts";

import Button from "../../shared/button";
import { noop } from "../../../utils/functions";

import "../contacts.scss";


class ContactInfo extends PureComponent {

    _onContactActions = (action) => {
        const {
            contact,
            onContactActions
        } = this.props;

        onContactActions(contact, action);
    };

    _generateContextualActions() {
        const {
            showsFollowButton,
            showsFollowingLabel,
            i18n: { getString }
        } = this.props;
        let actions = null;

        if (showsFollowButton) {
            actions = (
                <Button
                    title={getString("contacts.followLabel")}
                    compact={true}
                    onClick={() => this._onContactActions(ACTION_FOLLOW)}
                />
            );
        } else if (showsFollowingLabel) {
            actions = (
                <p className="violator compact">{getString("contacts.followingLabel")}</p>
            );
        }

        return actions;
    }

    _renderContactView() {
        const {
            contact,
        } = this.props;

        return (
            <React.Fragment>
                <div className="presence-indicator">
                    <span className="presence-icon" />
                </div>
                <figure className="avatar">
                    <img src={contact.avatarURL} />
                </figure>
                <div className="user-info">
                    <p className="first-name">{contact.firstName}</p>
                    <p><strong>{contact.lastName}</strong></p>
                </div>
                <div className="actions">
                    {this._generateContextualActions()}
                </div>
            </React.Fragment>
        );
    }

    render() {
        const {
            type
        } = this.props;

        return (
            <div className={classNames("contact-info-wrapper", type)}>
                {this._renderContactView()}
            </div>
        );
    }
}

ContactInfo.propTypes = {
    contact: PropTypes.object.isRequired,
    type: PropTypes.string,
    className: PropTypes.string,
    i18n: PropTypes.object,
    onContactActions: PropTypes.func,
    showsFollowButton: PropTypes.bool,
    showsFollowingLabel: PropTypes.bool
};

ContactInfo.defaultProps = {
    type: CLASSMATE,
    onContactActions: noop
};

export default ContactInfo;
