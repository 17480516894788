/** -------------------------------------------------------------------------------------------------------------------
 * CourseDetails
 * A component that renders the details of a course. It handles the course home page and sub-navigation.
 *
 * @examples
 *
 *  ```jsx
 *
 *    ReactDOM.render(
 *       <CourseDetails>
 *
 *       </CourseDetails>
 * ```
 *
 *  @component CourseDetails
 *  @import CourseDetails
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import LocalNav from "../../shared/local-nav";
import PageTemplate from "../../shared/pages/template";
import courseNav from "../../../store/data/course-nav";

import CourseInfoView from "./course-info-view";

import {
    fetchUserCourses,
    fetchCourseDiscussions,
    fetchDiscussionComments,
    setActiveDiscussion,
    startDiscussion,
    postComment,
    likeDiscussion,
    likeComment,
    fetchLikedDiscussions,
    fetchLikedComments,
    fetchCourseLectures
} from "../../../store/reducers/courses/courses.actions";

import {
    fetchUniversityInstructors
} from "../../../store/reducers/universities/universities.actions";

import "../courses.scss";


export class CourseDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            course: {},
            isEditMode: false
        };
    }

    _generateCourseNav() {
        const {
            i18n,
            match
        } = this.props;

        return courseNav.map((link, index) => {
            const url = (index === 0) ? match.url : `${match.url}/${link.id}`;

            return {
                id: link.id,
                icon: link.icon,
                title: i18n.getString(link.localizedString),
                url
            };
        });
    }

    _getCurrentCourse() {
        const { id } = this.props.match.params;
        const course = this.props.courses.courseList.find((course) => course.id === id);

        return course || {};
    }

    componentWillMount() {
        if (this.props.courses.courseList.length === 0) {
            this.props.fetchUserCourses(this.props.auth.userInfo.id);
        }
    }

    render() {
        const { match } = this.props;
        const course = this._getCurrentCourse();
        const navLinks = this._generateCourseNav();

        return (
            <PageTemplate
                {...this.props}
                className="course-wrapper">
                <LocalNav
                    match={match}
                    title={course.title}
                    page="courses-details"
                    caption={(course.courseId || "").toUpperCase()}
                    className="section-local-nav"
                    showPageInfo={true}
                    showContextualAction={false}
                    showLinks={false}
                    links={navLinks}
                />

                <Route exact path={match.url}
                    render={() => <CourseInfoView {...this.props} />}
                />

            </PageTemplate>
        );
    }
}

CourseDetails.propTypes = {
    id: PropTypes.string,
    auth: PropTypes.object,
    context: PropTypes.object,
    i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth,
    courses: state.courses,
    universities: state.universities
});

const mapDispatchToProps = {
    fetchUserCourses,
    fetchUniversityInstructors,
    fetchCourseDiscussions,
    fetchDiscussionComments,
    setActiveDiscussion,
    startDiscussion,
    postComment,
    likeDiscussion,
    likeComment,
    fetchLikedDiscussions,
    fetchLikedComments,
    fetchCourseLectures
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);

