/** -------------------------------------------------------------------------------------------------------------------
 * LocalTime
 * A component that renders the local date and time at a given time zone
 *
 * @examples
 *
 *  ```jsx
 *    <LocalTime
 *      locale="en"
 *      i18n={i18n}
 *      timezone="America/Los_Angeles"
 *      date="2019-01-31T07:30:00"
 *    />
 * ```
 *
 *  @component LocalTime
 *  @import LocalTime
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./toolbar.scss";


export default class LocalTime extends PureComponent {

    constructor(props) {
        super(props);

        const currentDate = (props.date instanceof Date) ? props.date :
            (typeof props.date === "string") ? new Date(Date.parse(props.date)) : new Date();
        const updateInterval = (/ja|ko/.test(props.locale)) ? 1 : 5;

        this.state = {
            currentDate,
            updateInterval
        };
    }

    _updateDateAndTime = () => {
        const { currentDate, updateInterval } = this.state;
        const instant = new Date(currentDate.getTime() + updateInterval * 1000);

        this.setState({
            currentDate: instant
        });
    };

    componentDidMount() {
        this.timer = setInterval(() => this._updateDateAndTime(), this.state.updateInterval * 1000);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    render() {
        const {
            i18n: {
                languagePack,
                getString,
                formatDate,
                formatTime
            },
            className
        } = this.props;

        const { currentDate } = this.state;
        const date = formatDate(currentDate, languagePack.dateFormat.MEDIUM);
        let time = formatTime(currentDate, languagePack.timeFormat.MEDIUM);
        time = time.replace(/(AM|PM)/, (match, ampm) => {
            const localizedString = getString(`date.${ampm}`);
            return `<em className="ampm">${localizedString}</em>`;
        });

        return (
            <div className={classNames("local-time-wrapper", className)}>
                <p className="date-time">
                    <span>{date}</span>
                    <span className="time" dangerouslySetInnerHTML={{__html: time}} />
                </p>
            </div>
        );
    }
}

LocalTime.propTypes = {
    date: PropTypes.any,
    timezone: PropTypes.string,
    i18n: PropTypes.object.isRequired,
    locale: PropTypes.string,
    className: PropTypes.string
};

LocalTime.defaultProps = {
    date: new Date()
};
