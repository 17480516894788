import * as types from "./universities.types";


export const fetchUniversities = () => (dispatch) => {

    return dispatch({
        types: [
            types.UNIVERSITIES_REQUEST,
            types.UNIVERSITIES_SUCCESS,
            types.UNIVERSITIES_FAILURE
        ],
        request: {
            method: "GET",
            endpoint: "/universities"
        }
    });
};

export const fetchUniversityInstructors = (universityId) => (dispatch) => {
    const endpoint = `/universities/${universityId}/instructors`;

    return dispatch({
        types: [
            types.UNIVERSITY_INSTRUCTORS_REQUEST,
            types.UNIVERSITY_INSTRUCTORS_SUCCESS,
            types.UNIVERSITY_INSTRUCTORS_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};

export const fetchUniversityStudents = (universityId) => (dispatch) => {
    const endpoint = `/universities/${universityId}/students`;

    return dispatch({
        types: [
            types.UNIVERSITY_STUDENTS_REQUEST,
            types.UNIVERSITY_STUDENTS_SUCCESS,
            types.UNIVERSITY_STUDENTS_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};

export const inviteStudents = (instructorId, universityId, data) => (dispatch) => {
    const endpoint = `/universities/${universityId}/invite`;
    const body = {
        instructorId,
        ...data
    };

    return dispatch({
        types: [
            types.INVITE_STUDENTS_REQUEST,
            types.INVITE_STUDENTS_SUCCESS,
            types.INVITE_STUDENTS_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};

export const findInviteByCode = (code) => (dispatch) => {
    const endpoint = `/universities/invites/${code}`;

    return dispatch({
        types: [
            types.INVITE_REQUEST,
            types.INVITE_SUCCESS,
            types.INVITE_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};

export const resetErrors = () => (dispatch) => {
    return dispatch({
        type: types.UNIVERSITY_RESET_ERRORS
    });
};

export const getRecentActivities = (universityId, limit = 100) => (dispatch) => {
    const endpoint = `/universities/${universityId}/recent-activity?limit=${limit}`;

    return dispatch({
        types: [
            types.RECENT_ACTIVITY_REQUEST,
            types.RECENT_ACTIVITY_SUCCESS,
            types.RECENT_ACTIVITY_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};

export const fetchAllUsers = () => (dispatch) => {

    return dispatch({
        types: [
            types.USERS_REQUEST,
            types.USERS_SUCCESS,
            types.USERS_FAILURE
        ],
        request: {
            method: "GET",
            endpoint: "/universities/users"
        }
    });
};
