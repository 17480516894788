/** ------------------------------------------------------------------------------------------------------------
 * Spinner
 * A component that displays a spinner either in a page-wide overlay or in a given context
 *
 * @examples
 *
 *  ```jsx
 *    <Spinner
 *      mode="overlay"
 *      message="Loading..."
 *      theme="lite"
 *    />
 * ```
 *
 *  @component Spinner
 *  @import Spinner
 *  @returns {object} React object
 *
 *-------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./spinner.scss";

export default class Spinner extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            style,
            mode,
            theme,
            message
        } = this.props;
        const isContextual = mode === "contextual";
        const classList = classNames("spinner-wrapper", {
            contextual: isContextual,
            lite: theme === "lite",
            compact: style === "compact"
        });

        const spinner = (
            <p className={classList}>
                <span className={classNames("spinner", style)} />
                {!!message && <span className="message">{message}</span>}
            </p>
        );

        return isContextual ? spinner : (
            <div className="modal-overlay">
                {spinner}
            </div>
        );
    }
}

Spinner.propTypes = {
    mode: PropTypes.string,
    style: PropTypes.string,
    theme: PropTypes.string,
    message: PropTypes.string
};

Spinner.defaultProps = {
    mode: "overlay",
    style: "regular",
    theme: "dark",
    message: ""
};
