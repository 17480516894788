/** -------------------------------------------------------------------------------------------------------------------
 * PasswordForm
 * A component that renders a form for resetting the user's password
 *
 * @examples
 *
 *  ```jsx
 *    <PasswordForm
 *      onCancel={() => console.log("I remember now...")}
 *      onPasswordReset={(username) => console.log("OK, reset my password...")}
 *    />
 * ```
 *
 *  @component PasswordForm
 *  @import PasswordForm
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import InputField from "../../shared/input-field";
import Button from "../../shared/button";


export default class PasswordForm extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            username: ""
        };
    }

    _onUsernameChange = (value) => {
        this.setState({
            username: value
        });
    };

    _onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };

    _onPasswordReset = () => {
        if (this.props.onPasswordReset) {
            this.props.onPasswordReset(this.state.username);
        }
    };

    render() {
        const {
            locale,
            i18n: { getString }
        } = this.props;
        const { username } = this.state;
        const enableButton = !!username.length;

        return (
            <form className={classNames("login-form password-form", locale)}>
                <ul className="password-view rounded">
                    <li className="input-view">
                        <h3>{getString("login.forgotPassword")}</h3>
                        <label className="info">{getString("help.passwordResetInstructions")}</label>
                        <InputField
                            className="username"
                            placeholder={getString("login.usernameHint")}
                            validationTypes={["isRequired"]}
                            showClearControl={true}
                            onChange={this._onUsernameChange}
                        />
                    </li>
                    <li className="message-view" style={{display: "none"}}>
                        <h3>{getString("login.forgotPassword")}</h3>
                        <label className="info">{getString("help.passwordRequestEmailed")}</label>
                    </li>
                    <li className="actions password-reminder">
                        <Button
                            className="password-button"
                            compact={true}
                            secondary={true}
                            title={getString("generic.cancel")}
                            onClick={this._onCancel}
                        />
                        <Button
                            className="password-button"
                            compact={true}
                            disabled={!enableButton}
                            title={getString("help.resetPasswordLabel")}
                            onClick={this._onPasswordReset}
                        />
                    </li>
                </ul>
            </form>
        );
    }
}

PasswordForm.propTypes = {
    locale: PropTypes.string,
    i18n: PropTypes.object,
    onCancel: PropTypes.func,
    onPasswordReset: PropTypes.func
};

PasswordForm.defaultProps = {
    onCancel: () => {},
    onPasswordReset: () => {}
};
