/** -------------------------------------------------------------------------------------------------------------------
 * CourseDiscussion
 * A component that render the summary of a course discussion
 *
 *  @examples
 *
 *  ```jsx
 *    <CourseDiscussion
 *      discussion={discussion}
 *      i18n={i18n}
 *      onDiscussionActions={(discussion, action) => { console.log("onDiscussionAction"); }}
 *    />
 * ```
 *
 *  @component CourseDiscussion
 *  @import CourseDiscussion
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { generateDiscussionStats } from "../../../utils/discussion-utils";
import { noop } from "../../../utils/functions";

import "../courses.scss";


class CourseDiscussion extends PureComponent {

    _onDiscussionActions = (action) => {
        const {
            discussion,
            onDiscussionActions
        } = this.props;

        onDiscussionActions(discussion, action);
    };

    render() {
        const {
            discussion,
            discussion: { author },
            i18n
        } = this.props;
        const stats = generateDiscussionStats(discussion, i18n);

        return (
            <div className="discussion-info-wrapper">
                <figure className="avatar">
                    <img src={author.avatarURL} />
                </figure>
                <div className="discussion-info">
                    <h5>{author.id}</h5>
                    <p>{discussion.body}</p>
                    <dl className="stats">
                        <dt>Time</dt>
                        <dd>{stats.relativeTime}</dd>
                        <dt>Likes</dt>
                        <dd>{stats.totalLikes}</dd>
                        <dt>Comments</dt>
                        <dd>{stats.totalComments}</dd>
                    </dl>
                </div>
            </div>
        );
    }
}

CourseDiscussion.propTypes = {
    discussion: PropTypes.object.isRequired,
    className: PropTypes.string,
    i18n: PropTypes.object,
    onDiscussionActions: PropTypes.func
};

CourseDiscussion.defaultProps = {
    onDiscussionActions: noop
};

export default CourseDiscussion;
