/* eslint-disable max-len, no-trailing-spaces, react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";

import "./terms-and-conditions.scss";


export const TermsAndConditionsText = ({ i18n }) => (
    <React.Fragment>
        <div className="section-header">
            <h2>
                {i18n.getString("privacy.termsAndConditions")}
            </h2>
        </div>
        <div className="content">
            <h3>Welcome to 1 University Avenue</h3>
            <p>
                THIS LEGAL AGREEMENT BETWEEN YOU AND AKWABA SYSTEMS, INC. GOVERNS YOUR USE OF THE &ldquo;1 UNIVERSITY AVENUE&rdquo; 
                PRODUCT, SOFTWARE, SERVICES, AND WEBSITE (COLLECTIVELY REFERRED TO AS THE &ldquo;SERVICE&rdquo;). 
                IT IS IMPORTANT THAT YOU READ AND UNDERSTAND THE FOLLOWING TERMS. BY CLICKING "I AGREE" 
                YOU ARE AGREEING THAT THESE TERMS WILL APPLY IF YOU CHOOSE TO ACCESS OR USE THE SERVICE.
            </p>
            <p>
                Akwaba is the provider of the Service, which allows you to teach and learn through a virtual 
                and online university environment. The service also provides the ability to conduct Chat or Video Chat 
                sessions, to give live lectures to a worldwide audience, to store personal content (such as 
                profile avatar, course notes, documents, calendars, application data and email addresses) and 
                make it available to compatible devices and computers, to collaborate through discussions, 
                and certain location based services, only under the terms and conditions set forth in this Agreement. 
                "Akwaba" as used herein means:
            </p>
            <p className="address">
                Akwaba Systems, Inc., located at 105 N First Street, #90473, San Jose, California 95113
            </p>
            <h4>Requirements for use of the Service</h4>
            <p>
                To use the Service, you cannot be a person barred from receiving the Service under the laws 
                of the United States or other applicable jurisdictions, including the country in which you 
                reside or from where you use the Service. By accepting this Agreement, you represent that 
                you understand and agree to the foregoing.
                Use of the Service requires compatible devices with camera, either embedded into the device 
                or connected through a USB port, a microphone, Internet access, and Adobe Flash on desktop 
                and laptop devices. The latest version of the Flash software is strongly recommended on 
                those devices. High-speed Internet access is strongly recommended to use the Video Chat and 
                live lecture streaming feature of the application. For the version running on Mobile devices 
                (iOS, Android or other platforms), the application may access your camera for Video Chat 
                sessions, or your media library to browse, capture or upload images or videos. You agree that 
                meeting these requirements, which may change from time to time, is your responsibility.
            </p>
            <h4>Changing or upgrading the Service</h4>
            <p>
                Akwaba reserves the right to modify or terminate the Service (or any part thereof), either 
                temporarily or permanently. Akwaba may post on our website and/or will send an email to 
                the primary address associated with your Account to provide notice of any material changes 
                to the Service. It is your responsibility to check your email address and/or primary email 
                address registered with your account for any such notices.
            </p>
            <p>
                You agree that Akwaba shall not be liable to you or any third party for any modification or 
                cessation of the Service. If you have paid to use the Service and we terminate it or materially 
                downgrade its functionality, we will provide you with a pro rata refund of any pre-payment.
            </p>

            <h4>Limitations on Use</h4>
            <p>
                You agree to use the Service only for purposes as permitted by this Agreement and any applicable 
                law, regulation, or generally accepted practice in the applicable jurisdiction.
            </p>
            <p>
                If your use of the Service or other behavior intentionally or unintentionally threatens 
                Akwaba&rsquo;s ability to provide the Service or other systems, Akwaba shall be entitled to 
                take all reasonable steps to protect the Service and Akwaba&rsquo;s systems, which may include 
                suspension of your access to the Service. Repeated violations of the limitations may result in 
                termination of your Account.
            </p>

            <h4>Availability of the Service</h4>
            <p>
                The Service, or any feature or part thereof, are currently available in English, Spanish, French, 
                German, Italian, Simplified Chinese, Japanese, and Korean. Additional translations will be made public when available. Akwaba makes no representation that 
                the Service, or any feature or part thereof, is appropriate or available for use in any particular 
                location. To the extent you choose to access and use the Service, you do so at your own initiative 
                and are responsible for compliance with any applicable laws, including, but not limited to, 
                any applicable local laws.
            </p>

            <h4>Public Beta</h4>
            <p>
                From time to time, Akwaba may choose to offer new and/or updated features of the Service 
                (the “Beta Features”) as part of a Public Beta Program (the “Program”) for the purpose of 
                providing Akwaba with feedback on the quality and usability of the Beta Features. You 
                understand and agree that your participation in the Program is voluntary and does not 
                create a legal partnership between you and Akwaba, and that Akwaba is not obligated to 
                provide you with any Beta Features. Akwaba may make such Beta Features available to Program 
                participants by creating temporary user accounts, and by creating virtual universities for 
                those accounts.
            </p>
            <p>
                You understand and agree that Akwaba may collect and use certain information from your Account, 
                devices and peripherals in order to enroll you in a Program. 
                Your use of the Beta Features and participation in the Program is governed by this Agreement and
                any additional license terms that may separately accompany the Beta Features. 
            </p>
            <p>
                Akwaba may or may not provide you with technical and/or other support for the Beta Features. 
                If support is provided it will be in addition to your normal support coverage for the Service 
                and only available through the Program. You agree to abide by any support rules or policies that
                Akwaba provides to you in order to receive any such support. Akwaba reserves the right to modify 
                the terms, conditions or policies of the Program (including ceasing the Program) at any time 
                with or without notice, and may revoke your participation in the Program at any time. 
                You acknowledge that Akwaba has no obligation to provide a commercial version of the Beta Features,
                and that should such a commercial version be made available, it may have features or functionality 
                different than those contained in the Beta Features. 
            </p>
            <p>
                As part of the Program, Akwaba will provide you with the opportunity to submit comments, 
                suggestions, or other feedback regarding your use of the Beta Features. You agree that in 
                the absence of a separate written agreement to the contrary, Akwaba will be free to use any 
                feedback you provide for any purpose.
            </p>

            <h4>Fees</h4>
            <p>
                The Service accepts credit cards or iTunes for payment, and will automatically charge the 
                payment method associated with your account. You agree that Akwaba may continue to charge 
                your credit card or iTunes account for all fees or charges associated with your subscription on a monthly 
                basis until you change or cancel the subscription. This means that unless you change or 
                cancel your subscription prior to the next monthly billing date, we will charge your credit 
                card the then-current fees on that next monthly billing date.
            </p>

            <h4>Account Information and Billing Inquiries</h4>
            <p>
                You may consult your Account management or profile page for details on your Account 
                information including its current status, the payment method and the billing currency. 
                Akwaba will send an electronic invoice to your email address whenever any Service fees are 
                charged to your Account. If you believe you have been billed in error for the Service please 
                try and notify us within 15 days of the billing date by contacting us at team@akwabasystems.com.
            </p>

            <h4>Cancellations and Refunds</h4>
            <p>
                All fees and charges paid by you in relation to the Service are nonrefundable, except as required 
                by law or as otherwise stated herein. You may contact Akwaba for a full refund within 15 days of 
                an initial subscription or subsequent monthly payment. 
            </p>

            <h4>Changes in Price</h4>
            <p>
                Akwaba may at any time, upon notice required by applicable law, change the price of the Service
                or any part thereof, or institute new charges or fees. Price changes and institution of new 
                charges implemented during your subscription billing monthly will apply to subsequent subscription 
                billing months and to all new subscribers after the effective date of the change. 
                If you do not agree to any such price changes, then you must cancel and stop using the Service.
            </p>

            <h4>No Resale of Service</h4>
            <p>
                You agree that you will not reproduce, copy, duplicate, sell, resell, rent or trade the Service (or any part thereof) for any purpose.
            </p>

            <h4>Akwaba Privacy Policy</h4>
            <p>
                You understand that by using the Service, you consent and agree to the collection and use of certain 
                information about you, your virtual university and your use of the Service in accordance with Akwaba&rsquo;s Privacy Policy. 
                You further consent and agree that Akwaba may collect, use, transmit, process and maintain information 
                related to your Account, and any devices or computers registered thereunder, for purposes of 
                providing the Service, and any features therein, to you. Information collected by Akwaba when you 
                use the Service may also include technical or diagnostic information related to your use that 
                may be used by Akwaba to support, improve and enhance its products and services. 
            </p>

            <h4>Akwaba&rsquo;s Proprietary Rights</h4>
            <p>
                You acknowledge and agree that Akwaba and/or its licensors own all legal right, title and interest 
                in and to the Service, including but not limited to graphics, user interface, the scripts and 
                software used to implement the Service, and any software provided to you as a part of and/or in 
                connection with the Service (the &ldquo;Software&rdquo;), including any and all intellectual property 
                rights that exist therein, whether registered or not, and wherever in the world they may exist. 
                You further agree that the Service (including the Software, or any other part thereof) contains 
                proprietary and confidential information that is protected by applicable intellectual property 
                and other laws, including but not limited to copyright. You agree that you will not use such 
                proprietary information or materials in any way whatsoever except for use of the Service in 
                compliance with this Agreement. No portion of the Service may be reproduced in any form or by 
                any means, except as expressly permitted in these terms.
            </p>

            <h4>Ownership of Media and Content</h4>
            <p>
                All content and digital media that you create as part of your virtual university or 
                universities remain your property, and you may request an electronic copy of the content and 
                media at any time by notifying our Support department. This includes the courses, course notes, 
                course materials, recorded course lectures, lecture notes, uploaded videos from existing 
                lectures, content generated by students attending your university, and other such content 
                created through your university.
            </p>
            <p>
                Akwaba makes periodic backup of the content on its server as part of its maintenance process 
                and also to prevent data loss due to natural disasters. You agree that, in the event of a 
                data loss during the backup process or due to any other cause, Akwaba shall not be held 
                responsible for any loss of your content.
            </p>
            
            <h4>Authenticity of Content</h4>
            <p>
                Akwaba expects the content provided by you to be authentic. In the event that the content 
                provided by you belongs to a third-party entity, Akwaba shall not be held responsible for 
                such plagiarism, and will take all appropriate actions to pull the content from its Service.
            </p>
            
            <h4>Payment Processing</h4>
            <p>
                Payment processing services for Akwaba are provided by Stripe and are subject to 
                the <a href="https://stripe.com/connect/account-terms" target="_blank" rel="noopener noreferrer">
                Stripe Connected Account Agreement</a>, which includes the <a href="https://stripe.com/terms" target="_blank" rel="noopener noreferrer">
                    Stripe Terms of Service</a> (collectively, the “Stripe Services Agreement”). 
                By agreeing to this agreement, you agree 
                to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from 
                time to time. As a condition of Akwaba enabling payment processing services through 
                Stripe, you agree to provide Akwaba accurate and complete information about you and 
                your business, and you authorize Akwaba to share it and any transaction information related to 
                your use of the payment processing services provided by Stripe.
            </p>
            
            <h4>iTunes In-App Purchases</h4>
            <p>
                Two subscription plans are currently available through iTunes In-App Purchase for the iOS 
                version of the Service: a standard plan valued at $39.99, and a premium plan valued at $49.99, 
                both of which give access to advanced features of the Service.
            </p>
            <p>
                The iTunes subscriptions automatically renew every month at their respective rate, and will continue 
                unless automatic renewal is turned off in the Settings app on an iPad at least 24 hours before the end of 
                the current period.
            </p>
            
            <h4>License From Akwaba</h4>
            <p>
                Akwaba grants you a personal, non-exclusive, non-transferable, limited license to use the Software 
                as provided to you by Akwaba as a part of the Service and in accordance with this Agreement; 
                provided that you do not (and do not permit anyone else to) copy, modify, rent, loan, distribute, 
                create a derivative work of, reverse engineer, decompile, or otherwise attempt to discover the 
                source code (unless expressly permitted or required by law), sell, lease, sublicense, assign, 
                grant a security interest in or otherwise transfer any right in the Software; and you shall not 
                exploit the Service in any unauthorized way whatsoever, including, but not limited to, by 
                trespass or burdening network capacity. 
                THE USE OF THE SOFTWARE OR ANY PART OF THE SERVICE, EXCEPT FOR USE OF THE SERVICE AS PERMITTED 
                IN THIS AGREEMENT, IS STRICTLY PROHIBITED AND INFRINGES ON THE INTELLECTUAL PROPERTY RIGHTS OF 
                OTHERS AND MAY SUBJECT YOU TO CIVIL AND CRIMINAL PENALTIES, INCLUDING POSSIBLE MONETARY DAMAGES, 
                FOR COPYRIGHT INFRINGEMENT.
            </p>

            <h4>Termination by You</h4>
            <p>
                You may terminate your Account and/or stop using the Service at any time. 
                Any fees paid by you prior to your termination are nonrefundable (except as expressly permitted 
                otherwise by this Agreement), including any fees paid in advance for the billing month during 
                which you terminate. Termination of your Account shall not relieve you of any obligation to pay 
                any accrued fees or charges.
            </p>

            <h4>Termination by Akwaba</h4>
            <p>
                Akwaba may at any time, under certain circumstances and without prior notice, immediately terminate 
                or suspend all or a portion of your Account and/or access to the Service. Cause for 
                such termination shall include: (a) violations of this Agreement or any other policies or 
                guidelines that are referenced herein and/or posted on the Service; (b) a request by you to 
                cancel or terminate your Account; (c) a request and/or order from law enforcement, a judicial 
                body, or other government agency; (d) where provision of the Service to you is or may become 
                unlawful; (e) unexpected technical or security issues or problems; (f) your participation in 
                fraudulent or illegal activities; or (g) failure to pay any fees owed by you in relation to the 
                Service. Any such termination or suspension shall be made by Akwaba in its sole discretion and 
                Akwaba will not be responsible to you or any third party for any damages that may result or arise 
                out of such termination or suspension of your Account and/or access to the Service. 
            </p>

            <h4>Effects of Termination</h4>
            <p>
                Upon termination of your Account you will lose all access to the Service and any portions thereof, 
                including, but not limited to, your Account and Content.
            </p>

            <h4>Disclaimer of Warranties</h4>
            <p>
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, AS SUCH, TO THE EXTENT SUCH 
                EXCLUSIONS ARE SPECIFICALLY PROHIBITED BY APPLICABLE LAW, SOME OF THE EXCLUSIONS SET FORTH 
                BELOW MAY NOT APPLY TO YOU.
            </p>
            <p>
                AKWABA SHALL USE REASONABLE SKILL AND DUE CARE IN PROVIDING THE SERVICE. THE FOLLOWING DISCLAIMERS 
                ARE SUBJECT TO THIS EXPRESS WARRANTY.
            </p>
            <p>
                AKWABA DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF THE SERVICE WILL BE 
                UNINTERRUPTED OR ERROR-FREE, AND YOU AGREE THAT FROM TIME TO TIME AKWABA MAY REMOVE THE SERVICE 
                FOR INDEFINITE PERIODS OF TIME, OR CANCEL THE SERVICE IN ACCORDANCE WITH THE TERMS OF THIS AGREEMENT.
            </p>
            <p>
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" 
                BASIS. AKWABA AND ITS OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ALL 
                WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED 
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. IN PARTICULAR, AKWABA AND ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO WARRANTY THAT (I) THE SERVICE WILL MEET YOUR REQUIREMENTS; (II) YOUR USE OF THE SERVICE WILL BE TIMELY, UNINTERRUPTED, SECURE OR ERROR-FREE; (III) ANY INFORMATION OBTAINED BY YOU AS A RESULT OF THE SERVICE WILL BE ACCURATE OR RELIABLE; AND (IV) ANY DEFECTS OR ERRORS IN THE SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICE WILL BE CORRECTED.
            </p>
            <p>
                AKWABA DOES NOT REPRESENT OR GUARANTEE THAT THE SERVICE WILL BE FREE FROM LOSS, CORRUPTION, 
                ATTACK, VIRUSES, INTERFERENCE, HACKING, OR OTHER SECURITY INTRUSION, AND AKWABA DISCLAIMS ANY 
                LIABILITY RELATING THERETO.
            </p>

            <h4>Limitation of Liability</h4>
            <p>
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY BY SERVICE PROVIDERS. 
                TO THE EXTENT SUCH EXCLUSIONS OR LIMITATIONS ARE SPECIFICALLY PROHIBITED BY APPLICABLE LAW, 
                SOME OF THE EXCLUSIONS OR LIMITATIONS SET FORTH BELOW MAY NOT APPLY TO YOU.
            </p>
            <p>
                AKWABA SHALL USE REASONABLE SKILL AND DUE CARE IN PROVIDING THE SERVICE. THE FOLLOWING LIMITATIONS 
                DO NOT APPLY IN RESPECT OF LOSS RESULTING FROM (A) AKWABA'S FAILURE TO USE REASONABLE SKILL AND 
                DUE CARE; (B) AKWABA'S GROSS NEGLIGENCE, WILFUL MISCONDUCT OR FRAUD; OR (C) DEATH OR PERSONAL INJURY.
            </p>
            <p>
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT AKWABA AND ITS OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND 
                LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, 
                CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, 
                GOODWILL, USE, DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE 
                LOSSES (EVEN IF AKWABA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (I) 
                THE USE OR INABILITY TO USE THE SERVICE (II) ANY CHANGES MADE TO THE SERVICE OR ANY TEMPORARY 
                OR PERMANENT CESSATION OF THE SERVICE OR ANY PART THEREOF; (III) THE UNAUTHORIZED ACCESS TO OR 
                ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) THE DELETION OF, CORRUPTION OF, OR FAILURE TO 
                STORE AND/OR SEND OR RECEIVE YOUR TRANSMISSIONS OR DATA ON OR THROUGH THE SERVICE; (V) 
                STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; AND (VI) ANY OTHER MATTER RELATING TO 
                THE SERVICE.
            </p>

            <h4>Indemnity</h4>
            <p>
                You agree to defend, indemnify and hold Akwaba, its directors, officers, employees, agents, partners, contractors, 
                and licensors harmless from any claim or demand, including reasonable attorneys&rsquo; fees, 
                made by a third party, relating to or arising from: (a) your use of the Service; (b) any violation 
                by you of this Agreement; (c) any action taken by Akwaba as part of its investigation of a 
                suspected violation of this Agreement or as a result of its finding or decision that a violation 
                of this Agreement has occurred; or (d) your violation of any rights of another. This means that 
                you cannot sue Akwaba, its directors, officers, employees, agents, partners, contractors, and 
                licensors as a result of its decision to warn you, to suspend or terminate your access to the Service, 
                or to take any other action during the investigation of a suspected violation or as a result of 
                Akwaba&rsquo;s conclusion that a violation of this Agreement has occurred. 
            </p>
            <p>
                This waiver and indemnity provision applies to all violations described in or contemplated by 
                this Agreement. This obligation shall survive the termination or expiration of this Agreement 
                and/or your use of the Service. You acknowledge that you are responsible for all use of the 
                Service using your Account, and that this Agreement applies to any and all usage of your Account. 
                You agree to comply with this Agreement and to defend, indemnify and hold harmless Akwaba from and 
                against any and all claims and demands arising from usage of your Account, whether or not such usage 
                is expressly authorized by you.
            </p>

            <h4>Notices</h4>
            <p>
                Akwaba may provide you with notices regarding the Service, including changes to this Agreement, 
                by email to your email address (and/or other alternate email address associated with your Account 
                if provided), or by postings on the website for the Service.
            </p>

            <h4>Governing Law</h4>
            <p>
                Except to the extent expressly provided in the following paragraph, this Agreement and the 
                relationship between you and Akwaba shall be governed by the laws of the State of California, 
                excluding its conflicts of law provisions. You and Akwaba agree to submit to the personal and 
                exclusive jurisdiction of the courts located within the county of Santa Clara, California, 
                to resolve any dispute or claim arising from this Agreement. 
            </p>

            <h4>General</h4>
            <p>
                This Agreement constitutes the entire agreement between you and Akwaba, governs your use of the 
                Service and completely replaces any prior agreements between you and Akwaba in relation to the 
                Service. You may also be subject to additional terms and conditions that may apply when you 
                use affiliate services, third-party content, or third-party software. If any part of this 
                Agreement is held invalid or unenforceable, that portion shall be construed in a manner 
                consistent with applicable law to reflect, as nearly as possible, the original intentions of 
                the parties, and the remaining portions shall remain in full force and effect. The failure of 
                Akwaba to exercise or enforce any right or provision of this Agreement shall not constitute a 
                waiver of such right or provision. You agree that, except as otherwise expressly provided in 
                this Agreement, there shall be no third-party beneficiaries to this agreement.
            </p>

            <h4>Changes</h4>
            <p>
                Akwaba reserves the right at any time to modify this Agreement and to impose new or additional 
                terms or conditions on your use of the Service. If you do not agree with them, you must stop 
                using the Service. Your continued use of the Service will be deemed acceptance of such 
                modifications and additional terms and conditions.
            </p>

            <h4>ELECTRONIC CONTRACTING</h4>
            <p>
                Your use of the Service includes the ability to enter into agreements and/or to make transactions 
                electronically. YOU ACKNOWLEDGE THAT YOUR ELECTRONIC SUBMISSIONS CONSTITUTE YOUR AGREEMENT AND 
                INTENT TO BE BOUND BY AND TO PAY FOR SUCH AGREEMENTS AND TRANSACTIONS. YOUR AGREEMENT AND INTENT 
                TO BE BOUND BY ELECTRONIC SUBMISSIONS APPLIES TO ALL RECORDS RELATING TO ALL TRANSACTIONS YOU 
                ENTER INTO ON THIS SERVICE, INCLUDING NOTICES OF CANCELLATION, POLICIES, CONTRACTS, 
                AND APPLICATIONS. In order to access and retain your electronic records, you may be required to 
                have certain hardware and software, which are your sole responsibility.
            </p>
            <p className="revision">
                Last revised: October 28, 2019
            </p>
            <p className="copyright">
                {i18n.format("application.copyright", [new Date().getFullYear()])}
            </p>
        </div>
    </React.Fragment>
);

TermsAndConditionsText.propTypes = {
    i18n: PropTypes.object
};
