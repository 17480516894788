/** -------------------------------------------------------------------------------------------------------------------
 * RegistrationView
 * A component that renders the flow for creating a user account
 *
 * @examples
 *
 *  ```jsx
 *    <RegistrationView
 *      locale={locale}
 *      i18n={i18n}
 *      creatingAccount={false}
 *      onAccountDetailUpdated={(field, value) => console.log("Updated: ", field, value)}
 *      handleAccountCreation={() => console.log("Creating acount...")}
 *    />
 * ```
 *
 *  @component RegistrationView
 *  @import RegistrationView
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/


import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Button from "../../shared/button";
import { Loader } from "../../shared/spinner/loader";

import InvitationView from "./invitation-view";
import UserInfoView from "./user-info-view";
import UniversityView from "./university-view";
import AccountReview from "./account-review";

import { noop } from "../../../utils/functions";

export const SECRET_KEYWORD = "AKWABA:WELCOME";
const SPACE_REGEX = /\s+/;
const LETTER_REGEX = /^[a-zA-Z]/;
export const EMAIL_REGEX = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const VALID_CHARS = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s",
    "t", "u", "v", "w", "x", "y", "z", "_", "-", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];


export default class RegistrationView extends Component {

    constructor(props) {
        super(props);

        this.usernameField = React.createRef();

        const isStudent = this.props.accountType.toLowerCase() === "student";
        const steps = this._generateSteps(isStudent);
        const validationStates = this._initializeValidationStates(isStudent);

        this.state = {
            steps,
            currentStepIndex: 0,
            isStudent,
            validationStates,
            hasError: false
        };
    }

    _initializeValidationStates(isStudent = false) {
        const userInfoFields = {
            username: false,
            password: false,
            firstName: false,
            lastName: false,
            email: false,
            verification: false
        };
        const instructorUniversityFields = { universityName: false };
        const studentUniversityFields = { universityId: false };

        return isStudent ?
            [studentUniversityFields, userInfoFields] :
            [userInfoFields, instructorUniversityFields];
    }

    _generateSteps(isStudent = false) {
        const { i18n: { getString }} = this.props;
        const steps = isStudent ?
            [
                { stepNumber: 1, name: "invitation", title: getString("register.invitation")},
                { stepNumber: 2, name: "userInfo", title: getString("register.userInfo")}
            ] :
            [
                { stepNumber: 1, name: "userInfo", title: getString("register.userInfo")},
                { stepNumber: 2, name: "university", title: getString("generic.university")}
            ];

        steps.push({ stepNumber: 3, name: "review", title: getString("register.review")});

        return steps;
    }

    _gotoPreviousStep = () => {
        const {
            currentStepIndex
        } = this.state;

        if (currentStepIndex > 0) {
            this._gotoStep(currentStepIndex - 1);
        }
    };

    _gotoNextStep = () => {
        const {
            steps,
            currentStepIndex,
            validationStates
        } = this.state;
        const nextStep = currentStepIndex + 1;

        if (nextStep === steps.length) {
            return;
        }

        const shouldProceed = Object.values(validationStates[currentStepIndex]).every((field) => !!field);

        if (shouldProceed) {
            this._gotoStep(nextStep);
        }
    };

    _gotoStep(stepIndex) {
        this.setState({
            currentStepIndex: stepIndex
        });
    }

    _getTitleForPreviousStep() {
        const {
            steps,
            currentStepIndex
        } = this.state;

        return (currentStepIndex > 0) ?
            `${steps[currentStepIndex - 1].stepNumber}. ${steps[currentStepIndex - 1].title}` : "";
    }

    _getTitleForNextStep() {
        const {
            steps,
            currentStepIndex
        } = this.state;

        return (currentStepIndex < steps.length - 1) ?
            `${steps[currentStepIndex + 1].stepNumber}. ${steps[currentStepIndex + 1].title}` : "";
    }

    _renderSteps() {
        const {
            steps,
            currentStepIndex
        } = this.state;

        return steps.map((step) => {
            const key = `step-${step.stepNumber}`;
            const isActive = step.stepNumber === currentStepIndex + 1;

            return (
                <li
                    key={key}
                    className={classNames(`tabnav-item ${key}`, {
                        active: isActive
                    })}
                >
                    <span>{`${step.stepNumber}. ${step.title}`}</span>
                </li>
            );
        });
    }

    _onFieldValidated = (field, value) => {
        const {
            currentStepIndex,
            validationStates
        } = this.state;

        validationStates[currentStepIndex][field] = true;
        this.setState({
            [field]: value,
            validationStates
        });

        if (field !== "confirmedPassword") {
            this.props.onAccountDetailUpdated(field, value);
        }
    };

    _onFieldValidationFailed = (field, value) => {
        const {
            currentStepIndex,
            validationStates
        } = this.state;
        validationStates[currentStepIndex][field] = false;

        this.setState({
            [field]: value,
            validationStates
        });
    };

    _getFieldValidators() {
        const { getString } = this.props.i18n;

        return {
            isRequired: {
                test: (value) => !!value.trim().length,
                message: getString("form.fieldIsRequired")
            },
            hasMinimumLength: {
                test: (value) => !!(value.trim().length >= 8),
                message: getString("login.usernameTooShort")
            },
            hasMinimumPasswordLength: {
                test: (value) => !!(value.trim().length >= 8),
                message: getString("login.passwordTooShort")
            },
            noSpacesAllowed: {
                test: (value) => !(/SPACE_REGEX/.test(value)),
                message: getString("login.usernameContainsSpace")
            },
            isValidUsername: {
                test: (value) => {
                    if (SPACE_REGEX.test(value)) {
                        return false;
                    }

                    if (LETTER_REGEX.test(value.charAt(0))) {
                        return true;
                    }

                    let isValid = true;

                    for (let i = 0; i < value.length; i++) {
                        if (VALID_CHARS.indexOf(value.charAt(i) === -1)) {
                            isValid = false;
                            break;
                        }
                    }

                    return isValid;
                },
                message: getString("login.invalidUsername")
            },
            matchesPassword: {
                test: (value) => {
                    const { password } = this.state;
                    return value === password;
                },
                message: getString("profile.passwordMismatch")
            },
            isEmail: {
                test: (value) => (value.length && EMAIL_REGEX.test(value)),
                message: getString("login.invalidEmail")
            },
            isVerified: {
                test: (value) => value === SECRET_KEYWORD,
                message: getString("login.verificationHint")
            }
        };
    }

    _renderUserInfoView() {
        const {
            i18n,
            accountType
        } = this.props;

        return (
            <UserInfoView
                accountType={accountType}
                i18n={i18n}
                formData={this._getUserInfoFormData()}
                validators={this._getFieldValidators()}
                onFieldValidated={this._onFieldValidated}
                onFieldValidationFailed={this._onFieldValidationFailed}
            />
        );
    }

    _getUserInfoFormData() {
        const fields = ["username", "password", "confirmedPassword", "firstName", "lastName", "email", "verification"];
        return fields.reduce((result, current) => {
            result[current] = this.state[current];
            return result;
        }, {});
    }

    _renderUniversityView() {
        const { i18n } = this.props;

        const {
            universityName,
            universityDescription
        } = this.state;

        const formData = {
            universityName,
            universityDescription
        };

        return (
            <UniversityView
                i18n={i18n}
                formData={formData}
                validators={this._getFieldValidators()}
                onFieldValidated={this._onFieldValidated}
                onFieldValidationFailed={this._onFieldValidationFailed}
            />
        );
    }

    _renderReviewView() {
        const {
            i18n,
            accountType,
            invite
        } = this.props;

        const {
            universityName,
            universityDescription
        } = this.state;

        const university = {
            universityName,
            universityDescription
        };

        return (
            <AccountReview
                accountType={accountType}
                i18n={i18n}
                formData={this._getUserInfoFormData()}
                invite={invite}
                university={university}
            />
        );
    }

    _onInvitationCodeEntered = (event) => {
        const code = event.target.value;

        if (code.length === 4) {
            if (this.props.onInviteCodeVerification) {
                this.props.onInviteCodeVerification(code);
            }
        } else {
            if (this.state.hasError) {
                this.setState({
                    hasError: false
                });
            }
        }
    };

    _handleAccountCreation = () => {
        if (this.props.handleAccountCreation) {
            this.props.handleAccountCreation();
        }
    };

    componentDidMount() {
        this.props.onAccountDetailUpdated("accountType", this.props.accountType);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.disabled === true && this.props.disabled === false) {
            this.setState({
                hasError: !!this.props.error
            });

            if (this.props.invite) {
                this._onFieldValidated("universityId", this.props.invite.university.id);
                this._onFieldValidated("code", this.props.invite.code);
            } else {
                this._onFieldValidationFailed("universityId");
            }
        }
    }

    render() {
        const {
            invite,
            i18n,
            i18n: { getString },
            disabled,
            creatingAccount
        } = this.props;

        const {
            steps,
            currentStepIndex,
            isStudent,
            validationStates,
            hasError
        } = this.state;
        const isLastStep = currentStepIndex === steps.length - 1;

        const step1EntriesValid = Object.values(validationStates[0]).every((field) => !!field);
        const step2EntriesValid = Object.values(validationStates[1]).every((field) => !!field);
        const nextButtonDisabled = (currentStepIndex === 0) ? !step1EntriesValid : !step2EntriesValid;

        return (
            <div className="create-account-wrapper">
                <header>
                    <h3>
                        {getString("register.createAccount")}
                    </h3>
                </header>
                <div className="step-wrapper tabnav">
                    <ol className="tabnav-items">
                        {this._renderSteps()}
                    </ol>
                </div>
                <div className="content-view">
                    {currentStepIndex === 0 &&
                        <React.Fragment>
                            {isStudent ?
                                <InvitationView
                                    i18n={i18n}
                                    invite={invite}
                                    disabled={disabled}
                                    hasError={hasError}
                                    onInvitationCodeEntered={this._onInvitationCodeEntered}
                                /> :
                                this._renderUserInfoView()}
                        </React.Fragment>
                    }
                    {currentStepIndex === 1 &&
                        <React.Fragment>
                            {isStudent ? this._renderUserInfoView() : this._renderUniversityView()}
                        </React.Fragment>
                    }
                    {currentStepIndex === 2 && this._renderReviewView()}
                </div>
                <div className="row step-navigation">
                    {(currentStepIndex > 0) &&
                        <Button
                            className="offset-left"
                            title={this._getTitleForPreviousStep()}
                            disabled={creatingAccount}
                            onClick={this._gotoPreviousStep}
                        />
                    }
                    {!isLastStep &&
                        <Button
                            className="offset-right"
                            title={this._getTitleForNextStep()}
                            disabled={creatingAccount || nextButtonDisabled}
                            onClick={this._gotoNextStep}
                        />
                    }
                    {isLastStep &&
                        <Button
                            title={getString("register.createAccount")}
                            className="submit-button offset-right"
                            disabled={creatingAccount}
                            onClick={this._handleAccountCreation}
                        />
                    }
                </div>
                {creatingAccount && <div className="loader-wrapper">
                    <Loader />
                </div>}
            </div>
        );
    }
}

RegistrationView.propTypes = {
    i18n: PropTypes.object,
    locale: PropTypes.string,
    accountType: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    creatingAccount: PropTypes.bool,
    invite: PropTypes.object,
    invalidCode: PropTypes.bool,
    error: PropTypes.string,
    handleAccountCreation: PropTypes.func,
    onAccountDetailUpdated: PropTypes.func,
    onInviteCodeVerification: PropTypes.func
};

RegistrationView.defaultProps = {
    handleAccountCreation: noop,
    onAccountDetailUpdated: noop,
    onInviteCodeVerification: noop
};
