import * as types from "./videos.types";

const processVideos = (videoList = []) => {
    videoList.forEach((video) => {
        if (typeof video.metadata === "string") {
            video.metadata = JSON.parse(video.metadata);
        }
    });

    const uploaded = videoList.filter((video) => video.status.toLowerCase() === "pending");
    const published = videoList.filter((video) => video.status.toLowerCase() === "published");
    const unpublished = videoList.filter((video) => video.status.toLowerCase() === "unpublished");
    const intros = videoList.filter((video) => {
        const { metadata: { assignments }} = video;

        return (video.status.toLowerCase() === "assigned" &&
            Array.isArray(assignments) &&
            assignments.length > 0);
    });

    return {
        uploaded,
        published,
        unpublished,
        intros
    };
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    videoAssigned: false,
    allVideos: [],
    videoLists: {
        uploaded: [],
        published: [],
        unpublished: [],
        intros: []
    },
    currentStatus: "pending",
    deletingVideoId: null,
    assigningVideoId: null,
    courseIntros: [],
    lectureIntros: [],
    error: null
};

let updatedList;

const videos = (state = initialState, action = {}) => {
    switch (action.type) {

        case types.VIDEOS_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case types.VIDEOS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                allVideos: action.payload,
                videoLists: processVideos(action.payload)
            };

        case types.VIDEOS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case types.DELETING_VIDEO_ID:
            return {
                ...state,
                deletingVideoId: action.videoId
            };

        case types.DELETE_VIDEO_REQUEST:
            return {
                ...state,
                isUpdating: true
            };

        case types.DELETE_VIDEO_SUCCESS:
            updatedList = state.allVideos.filter((video) => video.id !== state.deletingVideoId);

            return {
                ...state,
                isUpdating: false,
                deletingVideoId: null,
                allVideos: updatedList,
                videoLists: processVideos(updatedList)
            };

        case types.DELETE_VIDEO_FAILURE:
            return {
                ...state,
                isUpdating: false,
                error: action.error,
                deletingVideoId: null
            };

        case types.ASSIGNING_VIDEO_ID:
            return {
                ...state,
                assigningVideoId: action.videoId
            };

        case types.ASSIGN_VIDEO_REQUEST:
        case types.UNASSIGN_VIDEO_REQUEST:
            return {
                ...state,
                isUpdating: true,
                videoAssigned: false,
            };

        case types.ASSIGN_VIDEO_SUCCESS:
        case types.UNASSIGN_VIDEO_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                videoAssigned: true,
                assigningVideoId: null
            };

        case types.ASSIGN_VIDEO_FAILURE:
        case types.UNASSIGN_VIDEO_FAILURE:
            const { data } = (action.error && action.error.response) || {};

            return {
                ...state,
                isUpdating: false,
                videoAssigned: false,
                assigningVideoId: null,
                error: (data && data.message) ? data.message : data
            };

        case types.VIDEOS_RESET_ERRORS:
            return {
                ...state,
                error: null
            };

        default:
            return state;
    }
};

export default videos;
