import * as types from "./courses.types";
import {
    removeQuotes,
    removeTags,
    toUnicode
} from "../../../utils/strings";


export const fetchUserCourses = (username) => (dispatch) => {
    const endpoint = `/users/${username}/courses`;

    return dispatch({
        types: [
            types.COURSES_REQUEST,
            types.COURSES_SUCCESS,
            types.COURSES_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const onCourseDetailUpdated = (name, value) => (dispatch) => {
    return dispatch({
        type: types.COURSE_DETAILS_UPDATED,
        details: {
            name,
            value
        }
    });
};


export const resetQuery = () => (dispatch) => {
    return dispatch({
        type: types.COURSES_RESET_QUERY
    });
};


export const resetErrors = () => (dispatch) => {
    return dispatch({
        type: types.COURSES_RESET_ERRORS
    });
};


export const addNewCourse = (username) => (dispatch, getState) => {
    const endpoint = `/users/${username}/courses`;
    const { query } = getState().courses;
    const { userInfo: { id }} = getState().auth;
    query.instructorId = id;

    const {
        title,
        description,
        ...rest
    } = query;

    const normalizedTitle = removeTags(removeQuotes(title));
    const normalizedDescription = removeTags(description);

    const body = {
        title: toUnicode(normalizedTitle),
        description: toUnicode(normalizedDescription),
        ...rest
    };

    return dispatch({
        types: [
            types.CREATE_COURSE_REQUEST,
            types.CREATE_COURSE_SUCCESS,
            types.CREATE_COURSE_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};


export const updateCourse = (username, courseId) => (dispatch, getState) => {
    const endpoint = `/users/${username}/courses/${courseId}`;
    const { query } = getState().courses;

    const course = getState().courses.courseList.find((course) => course.id === courseId);
    query.deliveryLanguage = course.deliveryLanguage;
    query.configuration = JSON.stringify(course.configuration);

    const {
        title,
        description,
        ...rest
    } = query;

    const normalizedTitle = removeTags(removeQuotes(title));
    const normalizedDescription = removeTags(description);

    const body = {
        title: toUnicode(normalizedTitle),
        description: toUnicode(normalizedDescription),
        ...rest
    };

    return dispatch({
        types: [
            types.UPDATE_COURSE_REQUEST,
            types.UPDATE_COURSE_SUCCESS,
            types.UPDATE_COURSE_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};


export const fetchCourseDiscussions = (userId, universityId, courseId) => (dispatch) => {
    const endpoint = `/universities/${universityId}/courses/${courseId}/discussions?userId=${userId}`;

    return dispatch({
        types: [
            types.COURSE_DISCUSSIONS_REQUEST,
            types.COURSE_DISCUSSIONS_SUCCESS,
            types.COURSE_DISCUSSIONS_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const setActiveDiscussion = (discussionId) => (dispatch) => {
    dispatch({
        type: types.SET_ACTIVE_DISCUSSION,
        discussionId
    });
};


export const startDiscussion = ({ userId, universityId, courseId, title }) => (dispatch) => {
    const endpoint = `/universities/${universityId}/courses/${courseId}/discussions`;
    const query = {
        body: title,
        userId
    };

    return dispatch({
        types: [
            types.START_DISCUSSION_REQUEST,
            types.START_DISCUSSION_SUCCESS,
            types.START_DISCUSSION_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body: query
        }
    });
};


export const fetchDiscussionComments = (userId, universityId, courseId, discussionId) => (dispatch) => {
    /* eslint-disable-next-line max-len */
    const endpoint = `/universities/${universityId}/courses/${courseId}/discussions/${discussionId}/comments?userId=${userId}`;

    return dispatch({
        types: [
            types.DISCUSSION_COMMENTS_REQUEST,
            types.DISCUSSION_COMMENTS_SUCCESS,
            types.DISCUSSION_COMMENTS_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const postComment = ({ userId, universityId, courseId, discussionId, comment }) => (dispatch) => {
    const endpoint = `/universities/${universityId}/courses/${courseId}/discussions/${discussionId}/comments`;
    const query = {
        body: comment,
        userId
    };

    return dispatch({
        types: [
            types.POST_COMMENT_REQUEST,
            types.POST_COMMENT_SUCCESS,
            types.POST_COMMENT_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body: query
        }
    });
};


export const likeDiscussion = ({ userId, universityId, courseId, discussionId }) => (dispatch) => {
    const endpoint = `/universities/${universityId}/courses/${courseId}/discussions/${discussionId}/like`;
    const body = {
        actor: userId
    };

    dispatch({
        type: types.ADD_DISCUSSION_LIKE,
        discussionId
    });

    return dispatch({
        types: [
            types.LIKE_DISCUSSION_REQUEST,
            types.LIKE_DISCUSSION_SUCCESS,
            types.LIKE_DISCUSSION_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};

export const likeComment = ({ userId, universityId, courseId, discussionId, commentId }) => (dispatch) => {
    /* eslint-disable-next-line max-len */
    const endpoint = `/universities/${universityId}/courses/${courseId}/discussions/${discussionId}/comments/${commentId}/like`;
    const body = {
        actor: userId
    };

    dispatch({
        type: types.ADD_COMMENT_LIKE,
        commentId
    });

    return dispatch({
        types: [
            types.LIKE_COMMENT_REQUEST,
            types.LIKE_COMMENT_SUCCESS,
            types.LIKE_COMMENT_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};

export const fetchLikedDiscussions = (userId, universityId) => (dispatch) => {
    const endpoint = `/universities/${universityId}/liked-discussions?userId=${userId}`;

    return dispatch({
        types: [
            types.LIKED_DISCUSSIONS_REQUEST,
            types.LIKED_DISCUSSIONS_SUCCESS,
            types.LIKED_DISCUSSIONS_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};

export const fetchLikedComments = (userId, universityId) => (dispatch) => {
    const endpoint = `/universities/${universityId}/liked-comments?userId=${userId}`;

    return dispatch({
        types: [
            types.LIKED_COMMENTS_REQUEST,
            types.LIKED_COMMENTS_SUCCESS,
            types.LIKED_COMMENTS_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const fetchCourseLectures = (userId, instructorId, courseId) => (dispatch) => {
    const endpoint = `/users/${userId}/courses/${courseId}/lectures?instructorId=${instructorId}`;

    return dispatch({
        types: [
            types.COURSE_LECTURES_REQUEST,
            types.COURSE_LECTURES_SUCCESS,
            types.COURSE_LECTURES_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};
