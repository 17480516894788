export const USER = "user";
export const USERS = "users";

export const INSTRUCTOR = "instructor";
export const INSTRUCTORS = "instructors";

export const STUDENT = "student";
export const STUDENTS = "students";

export const CLASSMATE = "classmate";
export const CLASSMATES = "classmates";

export const FOLLOWER = "follower";
export const FOLLOWERS = "followers";

export const FOLLOWING = "following";

export const SUGGESTION = "suggestion";
export const SUGGESTIONS = "suggestions";

export const ACTION_FOLLOW = "follow";
export const ACTION_FOLLOW_FROM_SUGGESTIONS = "followFromSuggestions";
export const ACTION_UNFOLLOW = "unfollow";
