export const MEMBERSHIP_REQUEST = "MEMBERSHIP_REQUEST";
export const MEMBERSHIP_SUCCESS = "MEMBERSHIP_SUCCESS";
export const MEMBERSHIP_FAILURE = "MEMBERSHIP_FAILURE";

export const PASSWORD_CHANGE_REQUEST = "PASSWORD_CHANGE_REQUEST";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";
export const PASSWORD_CHANGE_FAILURE = "PASSWORD_CHANGE_FAILURE";

export const PROFILE_SETTINGS_UPDATE_REQUEST = "PROFILE_SETTINGS_UPDATE_REQUEST";
export const PROFILE_SETTINGS_UPDATE_SUCCESS = "PROFILE_SETTINGS_UPDATE_SUCCESS";
export const PROFILE_SETTINGS_UPDATE_FAILURE = "PROFILE_SETTINGS_UPDATE_FAILURE";

export const PROFILE_SETTINGS_REQUEST = "PROFILE_SETTINGS_REQUEST";
export const PROFILE_SETTINGS_SUCCESS = "PROFILE_SETTINGS_SUCCESS";
export const PROFILE_SETTINGS_FAILURE = "PROFILE_SETTINGS_FAILURE";

export const LANGUAGE_CHANGE_REQUEST = "LANGUAGE_CHANGE_REQUEST";
export const LANGUAGE_CHANGE_SUCCESS = "LANGUAGE_CHANGE_SUCCESS";
export const LANGUAGE_CHANGE_FAILURE = "LANGUAGE_CHANGE_FAILURE";

export const TIMEZONE_REQUEST = "TIMEZONE_REQUEST";
export const TIMEZONE_SUCCESS = "TIMEZONE_SUCCESS";
export const TIMEZONE_FAILURE = "TIMEZONE_FAILURE";

export const TERMS_AND_CONDITIONS_REQUEST = "TERMS_AND_CONDITIONS_REQUEST";
export const TERMS_AND_CONDITIONS_SUCCESS = "TERMS_AND_CONDITIONS_SUCCESS";
export const TERMS_AND_CONDITIONS_FAILURE = "TERMS_AND_CONDITIONS_FAILURE";

export const NOTIFICATION_UPDATE_REQUEST = "NOTIFICATION_UPDATE_REQUEST";
export const NOTIFICATION_UPDATE_SUCCESS = "NOTIFICATION_UPDATE_SUCCESS";
export const NOTIFICATION_UPDATE_FAILURE = "NOTIFICATION_UPDATE_FAILURE";

export const ACCOUNT_STATUS_REQUEST = "ACCOUNT_STATUS_REQUEST";
export const ACCOUNT_STATUS_SUCCESS = "ACCOUNT_STATUS_SUCCESS";
export const ACCOUNT_STATUS_FAILURE = "ACCOUNT_STATUS_FAILURE";
