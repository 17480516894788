/** -------------------------------------------------------------------------------------------------------------------
 * CommentsView
 * A component that renders the view for setting comment privacies
 *
 * @examples
 *
 *  ```jsx
 *
 *    <CommentsView
 *      userInfo={userInfo}
 *      i18n={i18n}
 *      settings={settings}
 *      onChange={(setting, value) => console.log("Comment setting changed")}
 *    />
 * );
 * ```
 *
 *  @component CommentsView
 *  @import CommentsView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";

import allowedUsers from "../../../store/data/allowed-users-list";
import Selector from "../../shared/selector";

import { noop } from "../../../utils/functions";


export class CommentsView extends Component {

    constructor(props) {
        super(props);

        const { allowComments } = this.props.settings;
        this.state = {
            currentOption: allowComments
        };
    }

    _generateOptions() {
        const {
            userInfo: { accountType },
            i18n: { getString }
        } = this.props;

        const {
            currentOption
        } = this.state;

        const filteredItems = allowedUsers.filter((item) => item.role === "all" || item.role === accountType);
        const optionsList = filteredItems.map((option) => ({
            id: option.value,
            title: getString(option.localizedString)
        }));

        optionsList.forEach((option) => {
            option.selected = (option.id === currentOption);
        });

        return optionsList;
    }

    _onChange = (event) => {
        const { value } = event.target;

        this.setState({
            currentOption: value
        }, () => {
            this.props.onChange("allowComments", value);
        });
    };

    componentDidUpdate(prevProps) {
        const shouldUpdate = (
            prevProps.settings.allowComments !== this.props.settings.allowComments
        );

        if (shouldUpdate) {
            const { allowComments } = this.props.settings;

            this.setState({
                currentOption: allowComments
            });
        }
    }

    render() {
        const {
            i18n: { getString },
            disabled
        } = this.props;

        return (
            <div className="profile-section comments-wrapper">
                <h3 className="profile-section-header">{getString("generic.comments")}</h3>
                <div className="profile-section-wrapper">
                    <div className="row allowed-comment-selector">
                        <div className="column medium-6 small-12 label">
                            <label>{getString("profile.allowComments")}</label>
                        </div>
                        <div className="column medium-6 small-12">
                            <Selector
                                options={this._generateOptions()}
                                disabled={disabled}
                                onChange={this._onChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CommentsView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    context: PropTypes.object,
    settings: PropTypes.object,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

CommentsView.defaultProps = {
    onChange: noop
};

export default CommentsView;
