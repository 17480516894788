/** -------------------------------------------------------------------------------------------------------------------
 * VideoUploadView
 * A component that renders the subview for uploading videos
 *
 * @examples
 *
 *  ```jsx
 *
 *    <VideoUploadView
 *        userInfo={userInfo}
 *        i18n={i18n}
 *        auth={auth}
 *        serviceURL={serviceURL}
 *        isLoading={true}
 *        match={match}
 *        videos={[videoList]}
 *    />
 *
 * ```
 *
 *  @component VideoUploadView
 *  @import VideoUploadView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Extensions from "@akwaba/object-extensions";

import {
    ASSIGN,
    DELETE
} from "../../../store/reducers/videos/videos.types";

import FileUploader from "../../../utils/file-upload";
import ModalSheet from "../../shared/modal/modal-sheet";
import Video from "./video-details";

import { noop } from "../../../utils/functions";

import "../videos.scss";


export class VideoUploadView extends PureComponent {

    constructor(props) {
        super(props);

        this.fileUploadRef = React.createRef();

        this.state = {
            mode: "videoListing",
            showingFileUploadMessage: false,
            uploadFailed: false
        };
    }

    _onDeleteVideo = (id) => {
        if (this.props.onVideoActions) {
            this.props.onVideoActions(DELETE, id);
        }
    };

    _renderVideoList() {
        const {
            videos,
            i18n,
            i18n: { getString }
        } = this.props;

        return (videos.length === 0) ?
            (<div className="no-content">
                <p className="typography-body info">
                    {getString("videos.noVideosYet")}
                </p>
            </div>) :
            (<div className="video-list-wrapper">
                <ul className="row item-list video-list uploaded-video-list">
                    {videos.map((video) => {
                        return (
                            <Video
                                key={video.id}
                                i18n={i18n}
                                video={video}
                                deleteLabel={getString("generic.delete")}
                                onClick={this.onVideoClicked}
                                onDelete={this._onDeleteVideo}
                            />
                        );
                    })}
                </ul>
            </div>);
    }

    _initializeFileUploader() {
        const { i18n } = this.props;

        this.fileUploader = new FileUploader({
            id: "videoFileUploader",
            element: this.fileUploadRef.current,
            type: "video",
            mode: "normal",
            serverURL: this.props.serviceURL,
            i18n,
            delegate: this
        });
    }

    onUploadStart = noop;

    onUploadProgress = noop;

    onMaxSizeExceeded = noop;

    onUploadComplete = () => {
        const {
            i18n: { getString }
        } = this.props;

        this.setState({
            title: getString("videos.uploadSuccessful"),
            message: getString("videos.uploadSuccessfulInfo"),
            uploadFailed: false,
            showingFileUploadMessage: true
        });
    };

    _onUploadMessageConfirmed = () => {
        this.setState({
            uploadFailed: false,
            showingFileUploadMessage: false
        }, () => {
            this.props.onVideoUploadComplete();
        });
    };

    onUploadError = (title, response) => {
        const { i18n: { getString }} = this.props;
        title = (Extensions.isString(title) && title.length > 0) ? title : getString("videos.uploadError");
        const isConflict = Extensions.isNumber(response.status) && response.status === 409;
        const message = isConflict ? getString("videos.fileConflictErrorMessage") :
            Extensions.isString(response) ? response : getString("videos.uploadErrorMessage");

        this.setState({
            type: "error",
            title,
            message,
            uploadFailed: true,
            showingFileUploadMessage: true
        });

        return this;
    };

    onVideoClicked = (id) => {
        if (this.props.onVideoActions) {
            this.props.onVideoActions(ASSIGN, id);
        }
    };

    componentDidMount() {
        this._initializeFileUploader();
    }

    render() {
        const {
            i18n: { getString }
        } = this.props;
        const {
            showingFileUploadMessage,
            title,
            message,
            uploadFailed
        } = this.state;

        return (
            <React.Fragment>
                <div className="view-section-wrapper video-uploads-wrapper">
                    <header>
                        <h3>{getString("videos.recentUploads")}</h3>
                        <p className="typography-body">{getString("videos.uploadedVideosInfo")}</p>
                    </header>
                    {this._renderVideoList()}
                    <div ref={this.fileUploadRef} className="video-upload-container">
                    </div>
                </div>
                {showingFileUploadMessage && <ModalSheet
                    title={title}
                    okLabel={getString("generic.OK")}
                    showCancelButton={false}
                    type={uploadFailed ? "error" : "status"}
                    onOK={this._onUploadMessageConfirmed}
                    onModalClose={this._onUploadMessageConfirmed}>
                    <p>{message}</p>
                </ModalSheet>}
            </React.Fragment>

        );
    }
}

VideoUploadView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    auth: PropTypes.object,
    serviceURL: PropTypes.string,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    videos: PropTypes.array,
    onVideoActions: PropTypes.func,
    onVideoUploadComplete: PropTypes.func
};

VideoUploadView.defaultProps = {
    videos: [],
    onVideoActions: noop,
    onVideoUploadComplete: noop
};

export default VideoUploadView;
