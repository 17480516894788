/** -------------------------------------------------------------------------------------------------------------------
 * SocialNetworkView
 * A component that renders the social network summary for a user
 *
 * @examples
 *
 *  ```jsx
 *
 *    <SocialNetworkView
 *      userInfo={userInfo}
 *      summary={summary}
 *      i18n={i18n}
 *    />
 * );
 * ```
 *
 *  @component SocialNetworkView
 *  @import SocialNetworkView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";


export class SocialNetworkView extends PureComponent {

    render() {
        const {
            summary,
            i18n: { getString }
        } = this.props;

        return (
            <div className="profile-section social-network-wrapper">
                <ul className="summary-items">
                    <li>
                        <label>{summary.followers}</label>
                        <p className="info">{getString("profile.followers")}</p>
                    </li>
                    <li>
                        <label>{summary.following}</label>
                        <p className="info">{getString("profile.following")}</p>
                    </li>
                </ul>
            </div>
        );
    }
}

SocialNetworkView.propTypes = {
    summary: PropTypes.object,
    i18n: PropTypes.object
};

export default SocialNetworkView;
