import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import { InjectIntl } from "../../i18n/intl";
import { getPreferredLocale } from "../../store/reducers/application/context";

import Lectures from "./lectures";
import LectureDetails from "./views/lecture-details-view";

const LectureComponent = InjectIntl(Lectures, getPreferredLocale());
const LectureDetailsComponent = InjectIntl(LectureDetails, getPreferredLocale());


class LecturePage extends PureComponent {

    render() {
        const { match } = this.props;

        return (
            <React.Fragment>
                <Route
                    exact
                    path={match.url}
                    render={(props) => <LectureComponent {...props} />}
                />
                <Route
                    path={`${match.url}/:id`}
                    render={(props) => <LectureDetailsComponent {...props} />}
                />
            </React.Fragment>
        );
    }
}

LecturePage.propTypes = {
    match: PropTypes.object
};

export default LecturePage;
