/** -------------------------------------------------------------------------------------------------------------------
 * LectureInfoView
 * A component that renders the subview for showing the details of a given lecture
 *
 * @examples
 *
 *  ```jsx
 *
 *    <LectureInfoView
 *      lecture={lecture}
 *      i18n={i18n}
 *    />
 * );
 * ```
 *
 *  @component LectureInfoView
 *  @import LectureInfoView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import BlackAndWhite from "../../../media/black-and-white";

import "../lectures.scss";


export class LectureInfoView extends Component {

    constructor(props) {
        super(props);

        this.movieContainer = React.createRef();
        this.state = {
            lecture: {}
        };
    }


    _hasVideo() {
        const { lecture } = this.props;
        return !!(lecture && lecture.URL);
    }

    _renderLectureVideo() {
        if (!this._hasVideo() || (this._hasVideo() && this.mediaPlayer)) {
            return;
        }

        const {
            URL,
            poster
        } = this.props.lecture;

        this.mediaPlayer = new BlackAndWhite.MediaPlayer({
            id: "lecture-media-player",
            url: URL,
            poster,
            scaleFactor: 32,
            element: this.movieContainer.current
        });
    }

    componentDidMount() {
        this._renderLectureVideo();
    }

    componentDidUpdate() {
        this._renderLectureVideo();
    }

    componentWillUnmount() {
        if (this.mediaPlayer) {
            this.mediaPlayer.destroy();
            this.mediaPlayer = null;
        }
    }

    render() {
        const {
            getString
        } = this.props.i18n;

        const hasVideo = this._hasVideo();
        const className = classNames("lecture-video", {
            "no-content": !hasVideo
        });

        return (
            <React.Fragment>
                <div className="row lecture-video-wrapper">
                    <div className="video-container">
                        <div className={className}>
                            {hasVideo ?
                                <div className="movie-container" ref={this.movieContainer}></div> :
                                <p className="typography-body info">
                                    {getString("videos.noVideosYet")}
                                </p>
                            }
                        </div>

                    </div>
                </div>
                <div className="module-section description">
                </div>
                <div className="module-section rounded lecture-items">
                </div>
            </React.Fragment>
        );
    }
}

LectureInfoView.propTypes = {
    i18n: PropTypes.object,
    lecture: PropTypes.object,
    match: PropTypes.object
};

export default LectureInfoView;
