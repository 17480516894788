/* eslint-disable no-unused-vars, indent */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LocalNav from "../shared/local-nav";
import PageTemplate from "../shared/pages/template";
import ModalSheet from "../shared/modal/modal-sheet";
import ModalDialog from "../shared/modal";
import SegmentedControl from "../shared/segmented-control";

import VideoUploadView from "./views/video-upload-view";
import IntroVideosView from "./views/intro-videos-view";
import AssignedVideosView from "./views/assigned-videos-view";
import VideoAssignmentView from "./views/video-assignment-view";
import AllVideosView from "./views/all-videos-view";
import VideoPlaybackView from "./views/video-playback-view";

import { format } from "../../utils/strings";
import {
    ASSIGN,
    UNASSIGN,
    PLAYBACK,
    DELETE,
    ADD_COURSE,
    ADD_LECTURE,
    SET_ASSIGNMENT_TYPE,
    SET_ASSIGNED_COURSE_ID,
    SET_ASSIGNED_LECTURE_ID
} from "../../store/reducers/videos/videos.types";
import {
    videoUploadURL,
    fetchUserVideos,
    fetchUserVideosByStatus,
    deleteVideo,
    assignVideo,
    unassignVideo,
    resetErrors
} from "../../store/reducers/videos/videos.actions";

import { fetchUserCourses} from "../../store/reducers/courses/courses.actions";
import { fetchUserLectures } from "../../store/reducers/lectures/lectures.actions";

import "./videos.scss";
import "../../media/black-and-white/styles/main.scss";

const VIDEO_REFRESH_INTERVAL = 10000;


export class Videos extends Component {

    constructor(props) {
        super(props);

        const { getString } = this.props.i18n;
        const segments = [
            {id: "assigned-videos", title: getString("videos.assignedVideos") },
            {id: "all-videos", title: getString("videos.allVideos")}
        ];

        this.state = {
            deletingVideo: null,
            assigningVideo: false,
            unassigningVideo: false,
            playingVideo: false,
            assignmentType: null,
            assignmentItemId: null,
            currentVideo: null,
            showingDeleteConfirmation: false,
            showingErrorSheet: false,
            segments,
            selectedViewIndex: 0
        };
    }

    _onSegmentActivated = (segment) => {
        const selectedViewIndex = (segment.id === "assigned-videos") ? 0 : 1;
        const segments = this.state.segments.map((segment, index) => {
            segment.selected = (index === selectedViewIndex);
            return segment;
        });

        this.setState({
            segments,
            selectedViewIndex
        });
    };

    _onVideoUploadComplete = () => {
        const { id } = this.props.auth.userInfo;

        /** Retrieve the updated video list after the file upload is complete */
        setTimeout(() => {
            this.props.fetchUserVideos(id);
        }, VIDEO_REFRESH_INTERVAL);

        /** Schedule another request for the updated list after the files are moved to S3 */
        setTimeout(() => {
            this.props.fetchUserVideos(id);
        }, VIDEO_REFRESH_INTERVAL * 2);
    };

    _onVideoActions = (action, value) => {
        const {
            videos: { allVideos }
        } = this.props;
        let currentVideo;

        switch (action) {
            case DELETE:
                const video = allVideos.find((video) => video.id === value);
                const { metadata: { assignments }} = video;
                const hasAssignments = (Array.isArray(assignments) && assignments.length > 0);

                return hasAssignments ? this._displayWarningForAssignedVideo(video) :
                    this.setState({
                        deletingVideo: video,
                        showingDeleteConfirmation: true
                    });

            case ASSIGN:
                currentVideo = allVideos.find((video) => video.id === value);
                return this.setState({
                    assigningVideo: true,
                    currentVideo
                });

            case UNASSIGN:
                currentVideo = allVideos.find((video) => video.id === value);
                return this.setState({
                    unassigningVideo: true,
                    currentVideo
                });

            case SET_ASSIGNMENT_TYPE:
                return this.setState({
                    assignmentType: value
                });

            // Fall through
            case SET_ASSIGNED_COURSE_ID:
            case SET_ASSIGNED_LECTURE_ID:
                return this.setState({
                    assignmentItemId: value
                });

            case PLAYBACK:
                currentVideo = allVideos.find((video) => video.id === value);

                return this.setState({
                    playingVideo: true,
                    currentVideo
                });

            default:
                break;
        }
    };

    _displayWarningForAssignedVideo(video) {
        const { i18n } = this.props;
        const { metadata: { assignments: [assignment] }} = video;
        const { type, objectName } = assignment;
        const warningMessage = type === "courseIntro" ?
            i18n.format("videos.deleteCourseIntroMessage", [objectName]) :
            (type === "courseLecture") ? i18n.format("videos.deleteLectureVideoMessage", [objectName]) :
            i18n.getString("videos.deleteUniversityIntroMessage");

        this.setState({
            showingAssignedVideoWarning: true,
            warningMessage
        });
    }

    _onDeleteConfirmed = () => {
        const {
            auth: { userInfo: { id, universityId }}
        } = this.props;
        const { id: videoId, name: fileName } = this.state.deletingVideo;

        this.setState({
            showingDeleteConfirmation: false,
            deletingVideoId: null
        });

        this.props.deleteVideo(id, videoId, {
            universityId,
            fileName
        });
    };

    _onAssignmentConfirmed = () => {
        const {
            auth: { userInfo: { id: userId, universityId }}
        } = this.props;
        const {
            currentVideo: { id },
            assignmentType,
            assignmentItemId: itemId
        } = this.state;
        const options = {
            assignmentType,
            universityId
        };

        if (assignmentType !== "universityIntro") {
            options.itemId = itemId;
        }

        if (assignmentType === "courseLecture") {
            const lecture = this.props.lectures.find((lecture) => lecture.id === itemId);
            options.courseId = (lecture && lecture.course.id);
        }

        this.props.assignVideo(userId, id, options);
    };

    _onUnassignmentConfirmed = () => {
        const {
            auth: { userInfo: { id }}
        } = this.props;
        const { currentVideo } = this.state;

        this.setState({
            unassigningVideo: false,
            currentVideo: null
        });

        const videoId = currentVideo.id;
        let assignmentData = {};

        if (Array.isArray(currentVideo.metadata.assignments)) {
            [assignmentData] = currentVideo.metadata.assignments;
        }

        this.props.unassignVideo(id, videoId, assignmentData);
    };

    _onAssignmentCancelled = () => {
        this.setState({
            assigningVideo: false,
        });
    };

    _onModalActionCanceled = () => {
        this.setState({
            showingDeleteConfirmation: false,
            unassigningVideo: false,
            showingAssignedVideoWarning: false,
            playingVideo: false,
            deletingVideoId: null
        });
    };

    _onErrorSheetActions = () => {
        this.props.resetErrors();
        this.setState({
            showingErrorSheet: false
        });
    };

    componentDidMount() {
        const { id } = this.props.auth.userInfo;
        this.props.fetchUserVideos(id);
        this.props.fetchUserCourses(id);
        this.props.fetchUserLectures(id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.videos.isUpdating === true && this.props.videos.isUpdating === false) {
            this.setState({
                assigningVideo: false,
                showingErrorSheet: this.props.videos.error
            });
        }

        if (prevProps.videos.videoAssigned === false && this.props.videos.videoAssigned === true) {
            this.props.fetchUserVideos(this.props.auth.userInfo.id);
        }
    }

    render() {
        const {
            auth: { userInfo },
            i18n,
            videos: {
                videoLists,
                allVideos,
                isUpdating
            },
            courses,
            lectures,
            context
        } = this.props;

        const {
            assigningVideo,
            unassigningVideo,
            showingDeleteConfirmation,
            showingErrorSheet,
            deletingVideo,
            playingVideo,
            currentVideo,
            segments,
            selectedViewIndex,
            showingAssignedVideoWarning,
            warningMessage
        } = this.state;

        const serviceURL = format(videoUploadURL, {
            id: userInfo.id
        });

        return (
            <React.Fragment>
                <PageTemplate
                    {...this.props}
                    className="videos-wrapper dark">
                    <LocalNav
                        title={i18n.getString("title.videos")}
                        page="videos"
                        className="section-local-nav"
                        showPageInfo={true}
                        showContextualAction={false}
                    />
                    <div className="module-section">
                        <div className="main-section-wrapper">
                            <div className="sidebar-section">
                                <VideoUploadView
                                    userInfo={userInfo}
                                    i18n={i18n}
                                    context={context}
                                    serviceURL={serviceURL}
                                    videos={videoLists.uploaded}
                                    onVideoActions={this._onVideoActions}
                                    onVideoUploadComplete={this._onVideoUploadComplete}
                                />
                            </div>
                            <div className="video-items-section-wrapper">
                                <div className="view-selector">
                                    <SegmentedControl
                                        segments={segments}
                                        onSegmentActivated={this._onSegmentActivated}
                                    />
                                </div>
                                {(selectedViewIndex === 0) &&
                                    <React.Fragment>
                                        <div className="video-items-section">
                                            <IntroVideosView
                                                userInfo={userInfo}
                                                i18n={i18n}
                                                context={context}
                                                serviceURL={serviceURL}
                                                videos={videoLists.intros}
                                                onVideoActions={this._onVideoActions}
                                            />
                                        </div>
                                        <div className="video-items-section">
                                            <AssignedVideosView
                                                userInfo={userInfo}
                                                i18n={i18n}
                                                context={context}
                                                serviceURL={serviceURL}
                                                videos={videoLists.unpublished}
                                                onVideoActions={this._onVideoActions}
                                            />
                                        </div>
                                    </React.Fragment>
                                }
                                {(selectedViewIndex === 1) &&
                                    <div className="video-items-section">
                                        <div className="video-items-section">
                                            <AllVideosView
                                                userInfo={userInfo}
                                                i18n={i18n}
                                                context={context}
                                                videos={allVideos}
                                                onVideoActions={this._onVideoActions}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </PageTemplate>
                {assigningVideo &&
                    <ModalDialog
                        mode="compact"
                        className="video-assignment-modal"
                        title={i18n.getString("videos.assignVideo")}
                        okLabel={i18n.getString("videos.assignVideo")}
                        cancelLabel={i18n.getString("generic.cancel")}
                        autoDismiss={false}
                        okButtonDisabled={false}
                        onModalConfirm={this._onAssignmentConfirmed}
                        onModalClose={this._onAssignmentCancelled}>
                        <div className="assign-video-wrapper">
                            <VideoAssignmentView
                                userInfo={userInfo}
                                video={currentVideo}
                                i18n={i18n}
                                context={context}
                                courses={courses}
                                lectures={lectures}
                                showSpinner={isUpdating}
                                onVideoActions={this._onVideoActions}
                            />
                        </div>
                    </ModalDialog>}
                {showingDeleteConfirmation && <ModalSheet
                    title={i18n.getString("videos.deleteVideo")}
                    showCancelButton={true}
                    okLabel={i18n.getString("generic.delete")}
                    cancelLabel={i18n.getString("generic.doNotDelete")}
                    type="warning"
                    onOK={this._onDeleteConfirmed}
                    onModalClose={this._onModalActionCanceled}
                >
                    <p dangerouslySetInnerHTML={{
                        __html: i18n.format("videos.deleteVideoMessage", [deletingVideo.title])
                    }} />
                </ModalSheet>}
                {showingErrorSheet &&
                    <ModalSheet
                        title={i18n.getString("videos.videoAssignmentError")}
                        type="error"
                        mode="compact"
                        showCancelButton={false}
                        okLabel={i18n.getString("generic.OK")}
                        onOK={this._onErrorSheetActions}
                        onModalClose={this._onErrorSheetActions}>
                        <p>{i18n.getString(this.props.videos.error)}</p>
                    </ModalSheet>}
                {unassigningVideo && <ModalSheet
                    title={i18n.getString("videos.unassignVideo")}
                    showCancelButton={true}
                    okLabel={i18n.getString("videos.yesUnassignVideo")}
                    cancelLabel={i18n.getString("generic.cancel")}
                    type="warning"
                    onOK={this._onUnassignmentConfirmed}
                    onModalClose={this._onModalActionCanceled}
                >
                    <p>{i18n.getString("videos.unassignVideoMessage")}</p>
                </ModalSheet>}
                {showingAssignedVideoWarning && <ModalSheet
                    title={i18n.getString("videos.deleteVideo")}
                    okLabel={i18n.getString("generic.OK")}
                    showCancelButton={false}
                    type="error"
                    onOK={this._onModalActionCanceled}
                    onModalClose={this._onModalActionCanceled}
                >
                    <p dangerouslySetInnerHTML={{
                        __html: warningMessage
                    }} />
                </ModalSheet>}
                {playingVideo &&
                    <ModalDialog
                        mode="compact"
                        className="video-playback-modal"
                        showCancelButton={false}
                        onModalClose={this._onModalActionCanceled}>
                        <VideoPlaybackView
                            video={currentVideo}
                        />
                    </ModalDialog>}
            </React.Fragment>
        );
    }
}

Videos.propTypes = {
    title: PropTypes.string,
    i18n: PropTypes.object,
    profile: PropTypes.object
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth,
    courses: state.courses.courseList,
    lectures: state.lectures.lectureList,
    videos: state.videos,
    profile: state.profile
});

const mapDispatchToProps = {
    fetchUserVideos,
    fetchUserVideosByStatus,
    fetchUserCourses,
    fetchUserLectures,
    deleteVideo,
    assignVideo,
    unassignVideo,
    resetErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
