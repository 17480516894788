import * as types from "./universities.types";
import {
    ACCOUNT_STATUS_REQUEST,
    ACCOUNT_STATUS_SUCCESS,
    ACCOUNT_STATUS_FAILURE
} from "../profile/profile.types";
import { defaultAvatar } from "../../../constants";


const initialState = {
    isLoading: false,
    isLoadingStudents: false,
    isInvitingStudents: false,
    isLoadingUsers: false,
    isLoadingInvite: false,
    isLoadingActivities: false,
    isUpdatingUser: false,
    universityList: [],
    universityInstructors: [],
    universityStudents: [],
    allUsers: [],
    currentInvite: null,
    recentActivities: [],
    error: null
};

const processUsers = (users) => {
    return users.map((user) => {
        if (!(user.avatarURL && user.avatarURL.length)) {
            user.avatarURL = defaultAvatar;
        }

        return user;
    });
};

const processActivities = (activities) => {
    return activities.map((activity) => {
        if (typeof activity.details === "string") {
            activity.details = JSON.parse(activity.details);
        }

        return activity;
    });
};

const universities = (state = initialState, action = {}) => {
    switch (action.type) {

        case types.UNIVERSITIES_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case types.UNIVERSITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                universityList: action.payload
            };

        case types.UNIVERSITIES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case types.UNIVERSITY_INSTRUCTORS_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case types.UNIVERSITY_INSTRUCTORS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                universityInstructors: action.payload
            };

        case types.UNIVERSITY_INSTRUCTORS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case types.UNIVERSITY_STUDENTS_REQUEST:
            return {
                ...state,
                isLoadingStudents: true
            };

        case types.UNIVERSITY_STUDENTS_SUCCESS:
            return {
                ...state,
                isLoadingStudents: false,
                universityStudents: processUsers(action.payload)
            };

        case types.UNIVERSITY_STUDENTS_FAILURE:
            return {
                ...state,
                isLoadingStudents: false,
                error: action.error
            };

        case types.INVITE_STUDENTS_REQUEST:
            return {
                ...state,
                isInvitingStudents: true,
                error: null
            };

        case types.INVITE_STUDENTS_SUCCESS:
            return {
                ...state,
                isInvitingStudents: false,
                query: {}
            };

        case types.INVITE_STUDENTS_FAILURE: {
            const { data } = (action.error && action.error.response) || {};

            return {
                ...state,
                isInvitingStudents: false,
                error: (data && data.message) ? data.message : data
            };
        }

        case types.INVITE_REQUEST:
            return {
                ...state,
                isLoadingInvite: true,
                currentInvite: null,
                error: null
            };

        case types.INVITE_SUCCESS:
            return {
                ...state,
                isLoadingInvite: false,
                currentInvite: action.payload
            };

        case types.INVITE_FAILURE: {
            const { data } = (action.error && action.error.response) || {};

            return {
                ...state,
                isLoadingInvite: false,
                error: (data && data.message) ? data.message : data
            };
        }

        case types.UNIVERSITY_RESET_ERRORS:
            return {
                ...state,
                error: null
            };

        case types.RECENT_ACTIVITY_REQUEST:
            return {
                ...state,
                isLoadingActivities: true,
                error: null
            };

        case types.RECENT_ACTIVITY_SUCCESS:
            return {
                ...state,
                isLoadingActivities: false,
                recentActivities: processActivities(action.payload)
            };

        case types.RECENT_ACTIVITY_FAILURE: {
            const { data } = (action.error && action.error.response) || {};

            return {
                ...state,
                isLoadingActivities: false,
                error: (data && data.message) ? data.message : data
            };
        }

        case types.USERS_REQUEST:
            return {
                ...state,
                isLoadingUsers: true
            };

        case types.USERS_SUCCESS:
            return {
                ...state,
                isLoadingUsers: false,
                allUsers: processUsers(action.payload)
            };

        case types.USERS_FAILURE:
            return {
                ...state,
                isLoadingUsers: false,
                error: action.error
            };

        case ACCOUNT_STATUS_REQUEST:
            return {
                ...state,
                isUpdatingUser: true
            };

        case ACCOUNT_STATUS_SUCCESS: {
            const { id, accountStatus } = action.payload;
            const updatedUsers = state.allUsers.map((user) => {
                if (user.id === id) {
                    user.accountStatus = accountStatus;
                }

                return user;
            });

            return {
                ...state,
                isUpdatingUser: false,
                allUsers: updatedUsers
            };
        }

        case ACCOUNT_STATUS_FAILURE:
            return {
                ...state,
                isUpdatingUser: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default universities;
