/** -------------------------------------------------------------------------------------------------------------------
 * RecentActvity
 * A component that render a recent activity in the classroom
 *
 *  @examples
 *
 *  ```jsx
 *    <RecentActvity
 *      activity={activity}
 *      i18n={i18n}
 *    />
 * ```
 *
 *  @component RecentActvity
 *  @import RecentActvity
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/


import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { fromUTCString } from "../../../utils/dates";
import { noop } from "../../../utils/functions";


/* eslint-disable no-inline-comments */
class RecentActivity extends Component {
    static statements = {
        COURSE: "classroom.courseStatement",
        COURSE_INTRO: "classroom.courseIntroStatement",
        OUTBOUND_FOLLOW: "classroom.followStatement",
        LECTURE: "classroom.lectureScheduleStatement",
        LECTURE_RESCHEDULE: "classroom.lectureRescheduleStatement",
        LECTURE_CANCEL: "classroom.lectureCancelStatement",
        LECTURE_VIDEO: "classroom.lectureVideoStatement",
        DISCUSSION: "classroom.discussionStatement",
        COMMENT: "classroom.commentStatement",
        REPLY: "classroom.replyStatement",
        LIKE: "classroom.likeStatement",
        DISCUSSION_LIKE: "classroom.discussionLikeStatement",
        COMMENT_LIKE: "classroom.commentLikeStatement",
        QUESTION: "classroom.questionStatement"
    };

    _generateSentenceForActivity(activity) {
        const {
            i18n
        } = this.props;
        const {
            type,
            actor,
            details
        } = activity;
        let statement = RecentActivity.statements[type];
        let sentence;

        switch (type) {

            case "COURSE": {
                const course = `${details.courseId}: ${details.courseTitle}`;
                sentence = i18n.format(statement, [actor.id, course]);
                break;
            }

            case "COURSE_INTRO": {
                sentence = i18n.format(statement, [actor.id, details.courseDisplayId]);
                break;
            }

            case "OUTBOUND_FOLLOW": {
                const { followerId, followedId } = details;
                sentence = i18n.format(statement, [followerId, followedId]);
                sentence = sentence.replace(followerId, `<strong>${followerId}</strong>`);
                sentence = sentence.replace(followedId, `<strong>${followedId}</strong>`);
                break;
            }

            case "LECTURE": {
                const isRescheduleStatement = (details.action === "reschedule");

                if (isRescheduleStatement) {
                    statement = RecentActivity.statements.LECTURE_RESCHEDULE;
                }

                sentence = isRescheduleStatement ?
                    i18n.format(statement, [actor.id, details.lectureTitle]) :
                    i18n.format(statement, [actor.id, details.courseDisplayId, details.lectureTitle]);
                break;
            }

            case "LECTURE_VIDEO": {
                sentence = i18n.format(statement, [actor.id, details.lectureTitle]);
                break;
            }

            case "DISCUSSION": {
                sentence = i18n.format(statement, [actor.id, details.discussionBody]);
                break;
            }

            case "COMMENT": {
                const discussionAuthor = details.discussionAuthor || " ";
                const discussionBody = details.discussionBody || " ";
                sentence = i18n.format(statement, [actor.id, discussionAuthor, discussionBody]);
                sentence = sentence.replace(details.discussionAuthor, `<strong>${details.discussionAuthor}</strong>`);
                break;
            }

            case "DISCUSSION_LIKE": {
                sentence = i18n.format(statement, [actor.id, details.discussionAuthor, details.discussionBody]);
                sentence = sentence.replace(details.discussionAuthor, `<strong>${details.discussionAuthor}</strong>`);
                break;
            }

            case "COMMENT_LIKE": {
                sentence = i18n.format(statement, [actor.id, details.commentAuthor, details.commentBody]);
                sentence = sentence.replace(details.commentAuthor, `<strong>${details.commentAuthor}</strong>`);
                break;
            }

            case "QUESTION": {
                sentence = i18n.format(statement, [actor.id, details.questionBody]);
                break;
            }

            default:
                break;
        }

        if (type !== "OUTBOUND_FOLLOW") {
            sentence = sentence.replace(activity.actor.id, `<strong>${activity.actor.id}</strong>`);
        }

        const activityDate = fromUTCString(activity.createdDate);
        const timeIntervalInSecs = (new Date().getTime() - activityDate.getTime()) / 1000;
        const relativeTime = this._formatInRelativeTimeUnit(timeIntervalInSecs);
        sentence = `${sentence} <em>${relativeTime}</em>`;

        return sentence;
    }

    _generateActivityVideo(activity) {
        const {
            id,
            details: { URL, poster }
        } = activity;
        this.activityVideo = React.createRef();

        return (
            <div
                data-id={activity.id}
                className="movie-container"
            >
                <video
                    id={id}
                    className="activity-video"
                    controls
                >
                    <source type="video/mp4"
                        src={URL}
                        poster={poster}>
                    </source>
                </video>
            </div>
        );
    }

    _formatInRelativeTimeUnit(timeInterval) {
        const {
            i18n: { format }
        } = this.props;

        const hours = Math.floor(timeInterval / 3600);
        const days = Math.floor(hours / 24);
        const minutes = Math.floor((timeInterval / 60) % 60);
        const seconds = Math.floor(timeInterval % 60);
        const adjustedHours = (days > 0) ? hours - days * 24 : hours;

        return (days > 0) ? format("date.timeUnitDays", [days]) :
                (adjustedHours > 0) ? format("date.timeUnitHours", [adjustedHours]) : /* eslint-disable-line indent */
                (minutes > 0) ? format("date.timeUnitMinutes", [minutes]) : format("date.timeUnitSeconds", [seconds]);
    }

    render() {
        const {
            activity
        } = this.props;
        const {
            type,
            actor: { avatarURL }
        } = activity;
        const sentence = this._generateSentenceForActivity(activity);
        const hasVideo = ["COURSE_INTRO", "LECTURE_VIDEO"].includes(activity.type);

        return (
            <React.Fragment>
                <div className={classNames("recent-activity", type.toLowerCase())}>
                    <figure className="avatar">
                        <img src={avatarURL} />
                    </figure>
                    <div className="content">
                        <p className="typography-body" dangerouslySetInnerHTML={{
                            __html: sentence
                        }} />
                    </div>
                </div>
                {hasVideo && this._generateActivityVideo(activity)}
            </React.Fragment>
        );
    }
}

RecentActivity.propTypes = {
    activity: PropTypes.object.isRequired,
    className: PropTypes.string,
    i18n: PropTypes.object,
    onClick: PropTypes.func
};

RecentActivity.defaultProps = {
    onClick: noop
};

export default RecentActivity;
