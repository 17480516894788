import * as types from "./profile.types";
import {
    USER_INFO_UPDATE_REQUEST,
    USER_INFO_UPDATE_SUCCESS,
    USER_INFO_UPDATE_FAILURE
} from "../auth/auth.types";

import {
    removeQuotes,
    removeTags,
    toUnicode
} from "../../../utils/strings";

export const avatarUploadURL = "/users/{id}/avatar-upload";


export const retrieveUserMembership = (userId) => (dispatch) => {
    const endpoint = `/users/${userId}/profile/membership`;

    return dispatch({
        types: [
            types.MEMBERSHIP_REQUEST,
            types.MEMBERSHIP_SUCCESS,
            types.MEMBERSHIP_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};

export const retrieveProfileSettings = (userId) => (dispatch) => {
    const endpoint = `/users/${userId}/profile/settings`;

    return dispatch({
        types: [
            types.PROFILE_SETTINGS_REQUEST,
            types.PROFILE_SETTINGS_SUCCESS,
            types.PROFILE_SETTINGS_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};

export const retrieveTimezoneOffsets = (locale = "en") => (dispatch) => {
    const endpoint = `/app/timezone-offsets?locale=${locale}`;

    return dispatch({
        types: [
            types.TIMEZONE_REQUEST,
            types.TIMEZONE_SUCCESS,
            types.TIMEZONE_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};

export const changePassword = (userId, password) => (dispatch) => {
    const endpoint = `/users/${userId}/profile/password`;
    const body = {
        password
    };

    return dispatch({
        types: [
            types.PASSWORD_CHANGE_REQUEST,
            types.PASSWORD_CHANGE_SUCCESS,
            types.PASSWORD_CHANGE_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};

export const setPreferredLanguage = (userId, locale) => (dispatch) => {
    const endpoint = `/users/${userId}/profile/language`;
    const body = {
        locale
    };

    return dispatch({
        types: [
            types.LANGUAGE_CHANGE_REQUEST,
            types.LANGUAGE_CHANGE_SUCCESS,
            types.LANGUAGE_CHANGE_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};

export const changeProfileSetting = (userId, setting, value) => (dispatch) => {
    const endpoint = `/users/${userId}/profile/setting`;
    const body = {
        setting,
        value
    };

    return dispatch({
        types: [
            types.PROFILE_SETTINGS_UPDATE_REQUEST,
            types.PROFILE_SETTINGS_UPDATE_SUCCESS,
            types.PROFILE_SETTINGS_UPDATE_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};


export const updateProfile = (userId, details) => (dispatch, getState) => {
    const endpoint = `/accounts/${userId}/profile`;
    const {
        accountType,
        firstName,
        lastName
    } = getState().auth.userInfo;
    const body = {
        accountType,
        firstName,
        lastName,
        email: details.email
    };

    if (details.firstName) {
        const normalizedFirstName = removeTags(removeQuotes(details.firstName));
        body.firstName = toUnicode(normalizedFirstName);
    }

    if (details.lastName) {
        const normalizedLastName = removeTags(removeQuotes(details.lastName));
        body.lastName = toUnicode(normalizedLastName);
    }

    if (details.bio) {
        const normalizedBio = removeTags(details.bio);
        body.bio = toUnicode(normalizedBio);
    }

    if (details.gender) {
        body.gender = details.gender;
    }

    return dispatch({
        types: [
            USER_INFO_UPDATE_REQUEST,
            USER_INFO_UPDATE_SUCCESS,
            USER_INFO_UPDATE_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};

export const updateNotification = (userId, notification, value) => (dispatch) => {
    const endpoint = `/users/${userId}/profile/notification`;
    const body = {
        notification,
        value
    };

    return dispatch({
        types: [
            types.NOTIFICATION_UPDATE_REQUEST,
            types.NOTIFICATION_UPDATE_SUCCESS,
            types.NOTIFICATION_UPDATE_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};

export const acceptTermsAndConditions = (userId) => (dispatch) => {
    const endpoint = `/users/${userId}/profile/terms-and-conditions`;
    const body = {
        action: "accepted"
    };

    return dispatch({
        types: [
            types.TERMS_AND_CONDITIONS_REQUEST,
            types.TERMS_AND_CONDITIONS_SUCCESS,
            types.TERMS_AND_CONDITIONS_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};


export const updateAccountStatus = (requester, userId, accountStatus) => (dispatch) => {
    const endpoint = `/accounts/${userId}/status`;
    const body = {
        requester,
        accountStatus
    };

    return dispatch({
        types: [
            types.ACCOUNT_STATUS_REQUEST,
            types.ACCOUNT_STATUS_SUCCESS,
            types.ACCOUNT_STATUS_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};
