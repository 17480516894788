/** -------------------------------------------------------------------------------------------------------------------
 * UpcomingLecturesView
 * A component that renders the upcoming lectures for a given week. This list is currently displayed on
 * the Dashboard.
 *
 * @examples
 *
 *  ```jsx
 *
 *    <UpcomingLecturesView
 *        lecturesList={upcomingLectures}
 *        onLectureClicked={(lecture) => console.log("Lecture clicked: ", lecture)}
 *        i18n={i18n}
 *    />
 * );
 * ```
 *
 *  @component UpcomingLecturesView
 *  @import UpcomingLecturesView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import UpcomingLecture from "./upcoming-lecture";
import { noop } from "../../../utils/functions";
import { fromDateComponents } from "../../../utils/dates";

import { isBeforeDate } from "../../shared/calendar/date-utils";


export class UpcomingLecturesView extends PureComponent {

    constructor(props) {
        super(props);
    }

    _renderLectures() {
        const {
            lecturesList,
            i18n,
            onLectureClicked
        } = this.props;

        const sortedKeys = Object.keys(lecturesList).sort((key1, key2) => {
            let parts = key1.split("-");
            const date1 = fromDateComponents(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1,
                parseInt(parts[2], 10));

            parts = key2.split("-");
            const date2 = fromDateComponents(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1,
                parseInt(parts[2], 10));

            return isBeforeDate(date1, date2) ? -1 : isBeforeDate(date2, date1) ? 1 : 0;
        });

        const lectureSections = sortedKeys.map((key) => {
            const dateDetails = lecturesList[key];

            return (
                <div
                    key={`section-${key}`}
                    className="upcoming-lecture-container"
                >
                    <header>
                        <h4>{dateDetails.formattedDateString}</h4>
                    </header>
                    <ul className="lecture-list">
                        {dateDetails.lectures.map((lecture) => (
                            <li
                                key={lecture.id}
                                onClick={() => onLectureClicked(lecture)}
                            >
                                <UpcomingLecture
                                    i18n={i18n}
                                    lecture={lecture}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            );
        });

        return (
            <div className="lecture-list-wrapper">
                {lectureSections}
            </div>
        );
    }

    _renderNoLectureMessage() {
        const {
            i18n: { getString }
        } = this.props;

        return (
            <div className="content">
                <p className="info-message"><span>{getString("lecture.noLecturesScheduled")}</span></p>
            </div>
        );
    }

    render() {
        const {
            i18n: { getString },
            lecturesList
        } = this.props;
        const noLecturesAvailable = (Object.keys(lecturesList).length === 0);

        return (
            <React.Fragment>
                <div className="view-section-wrapper upcoming-lectures-wrapper">
                    <header>
                        <h4>{getString("dashboard.lecturesThisWeek")}</h4>
                    </header>
                    {noLecturesAvailable ? this._renderNoLectureMessage() : this._renderLectures()}
                </div>
            </React.Fragment>
        );
    }
}

UpcomingLecturesView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    lecturesList: PropTypes.object,
    onLectureClicked: PropTypes.func
};

UpcomingLecturesView.defaultProps = {
    lecturesList: {},
    onLectureClicked: noop
};

export default UpcomingLecturesView;
