/* global Classroom */
import * as types from "./auth.types";
import { localStorage } from "../../../utils/storage";

const initialState = {
    loginInProgress: false,
    isLoadingSession: false,
    isLoadingUserInfo: false,
    isUpdatingUserInfo: false,
    accountType: null,
    login: {
        auth: {}
    },
    userInfo: Classroom.user,
    authenticated: false,
    activeSession: false,
    sessionInfo: {},
    error: null
};

const processUserInfo = (userInfo) => {
    if (typeof userInfo.accountSettings === "string") {
        userInfo.accountSettings = JSON.parse(userInfo.accountSettings);
    }

    return userInfo;
};

const auth = (state = initialState, action = {}) => {
    switch (action.type) {

        case types.LOGIN_CHALLENGE_SUCCESS:
            return {
                ...state,
                login: {
                    ...state.login,
                    auth: action.payload
                }
            };

        case types.LOGIN_REQUEST:
            return {
                ...state,
                loginInProgress: true,
                error: null
            };

        case types.LOGIN_SUCCESS:
            return {
                ...state,
                loginInProgress: false,
                userInfo: {
                    ...state.userInfo,
                    ...processUserInfo(action.payload)
                },
                authenticated: true,
                activeSession: true
            };

        case types.LOGIN_FAILURE:
            return {
                ...state,
                loginInProgress: false,
                error: action.error
            };

        case types.SET_ACCOUNT_TYPE:
            return {
                ...state,
                accountType: action.accountType
            };

        case types.SESSION_ACTIVATED:
            return {
                ...state,
                activeSession: true
            };

        case types.SESSION_TIMEDOUT:
            return {
                ...state,
                activeSession: false
            };

        case types.LOGOUT_SUCCESS:
            localStorage.setItem("userInfo", JSON.stringify(state.userInfo));

            return {
                ...state,
                authenticated: false,
                activeSession: false
            };

        case types.LOGOUT_FAILURE:
            return {
                ...state,
                authenticated: false,
                activeSession: false
            };

        case types.USER_SESSION_REQUEST:
            return {
                ...state,
                isLoadingSession: true,
                error: null
            };

        case types.USER_SESSION_SUCCESS:
            return {
                ...state,
                isLoadingSession: false,
                sessionInfo: action.payload
            };

        case types.USER_SESSION_FAILURE:
            return {
                ...state,
                isLoadingSession: false,
                error: action.error
            };

        case types.USER_INFO_REQUEST:
            return {
                ...state,
                isLoadingUserInfo: true,
                error: null
            };

        case types.USER_INFO_SUCCESS:
            return {
                ...state,
                isLoadingUserInfo: false,
                userInfo: action.payload
            };

        case types.USER_INFO_FAILURE:
            return {
                ...state,
                isLoadingUserInfo: false,
                error: action.error
            };

        case types.USER_INFO_UPDATE_REQUEST:
            return {
                ...state,
                isUpdatingUserInfo: true,
                error: null
            };

        case types.USER_INFO_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdatingUserInfo: false,
                userInfo: {
                    ...state.userInfo,
                    ...action.payload
                }
            };

        case types.USER_INFO_UPDATE_FAILURE:
            return {
                ...state,
                isUpdatingUserInfo: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default auth;
