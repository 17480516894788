import * as types from "./lectures.types";

const initialState = {
    isLoading: false,
    isUpdating: false,
    isAddingSharedSpace: false,
    lectureList: [],
    upcomingLectures: [],
    sharedSpaces: [],
    query: {},
    error: null
};


const lectures = (state = initialState, action = {}) => {
    switch (action.type) {

        // Fall through
        case types.LECTURES_REQUEST:
        case types.UPCOMING_LECTURES_REQUEST:
        case types.SHARED_SPACES_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case types.LECTURES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                lectureList: action.payload
            };

        // Fall through
        case types.LECTURES_FAILURE:
        case types.UPCOMING_LECTURES_FAILURE:
        case types.SHARED_SPACES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case types.LECTURE_DETAILS_UPDATED:
            const { details: { name, value }} = action;

            return {
                ...state,
                query: {
                    ...state.query,
                    [name]: value
                }
            };

        case types.UPCOMING_LECTURES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                upcomingLectures: action.payload
            };

        // Fall through
        case types.SCHEDULE_LECTURE_REQUEST:
        case types.UPDATE_LECTURE_REQUEST:
            return {
                ...state,
                isUpdating: true
            };

        case types.SCHEDULE_LECTURE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                lectureList: [
                    ...state.lectureList,
                    action.payload
                ],
                query: {}
            };

        case types.SCHEDULE_LECTURE_FAILURE: {
            const { data } = (action.error && action.error.response) || {};

            return {
                ...state,
                isUpdating: false,
                error: (data && data.message) ? data.message : data,
                query: {}
            };
        }

        case types.UPDATE_LECTURE_SUCCESS:
            const lectureList = state.lectureList.map((lecture) => {
                const updatedLecture = (lecture.id === action.payload.id) ?
                    Object.assign({}, lecture, action.payload) :
                    lecture;
                return updatedLecture;
            });

            return {
                ...state,
                isUpdating: false,
                lectureList,
                query: {}
            };

        case types.UPDATE_LECTURE_FAILURE: {
            const { data } = (action.error && action.error.response) || {};

            return {
                ...state,
                isUpdating: false,
                error: (data && data.message) ? data.message : data,
                query: {}
            };
        }

        case types.LECTURES_RESET_QUERY:
            return {
                ...state,
                query: { }
            };

        case types.LECTURES_RESET_ERRORS:
            return {
                ...state,
                error: null
            };

        case types.SHARED_SPACES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                sharedSpaces: action.payload
            };

        case types.ADD_SHARED_SPACE_REQUEST:
            return {
                ...state,
                isAddingSharedSpace: true
            };

        case types.ADD_SHARED_SPACE_SUCCESS:
            return {
                ...state,
                isAddingSharedSpace: false,
                sharedSpaces: [
                    ...state.sharedSpaces,
                    action.payload
                ]
            };

        case types.ADD_SHARED_SPACE_FAILURE: {
            const { data } = (action.error && action.error.response) || {};

            return {
                ...state,
                isAddingSharedSpace: false,
                error: (data && data.message) ? data.message : data
            };
        }

        default:
            return state;
    }
};

export default lectures;
