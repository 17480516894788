export const LECTURES_REQUEST = "LECTURES_REQUEST";
export const LECTURES_SUCCESS = "LECTURES_SUCCESS";
export const LECTURES_FAILURE = "LECTURES_FAILURE";

export const UPCOMING_LECTURES_REQUEST = "UPCOMING_LECTURES_REQUEST";
export const UPCOMING_LECTURES_SUCCESS = "UPCOMING_LECTURES_SUCCESS";
export const UPCOMING_LECTURES_FAILURE = "UPCOMING_LECTURES_FAILURE";

export const LECTURE_DETAILS_UPDATED = "LECTURE_DETAILS_UPDATED";
export const LECTURES_RESET_QUERY = "LECTURES_RESET_QUERY";
export const LECTURES_RESET_ERRORS = "LECTURES_RESET_ERRORS";

export const SCHEDULE_LECTURE_REQUEST = "SCHEDULE_LECTURE_REQUEST";
export const SCHEDULE_LECTURE_SUCCESS = "SCHEDULE_LECTURE_SUCCESS";
export const SCHEDULE_LECTURE_FAILURE = "SCHEDULE_LECTURE_FAILURE";

export const UPDATE_LECTURE_REQUEST = "UPDATE_LECTURE_REQUEST";
export const UPDATE_LECTURE_SUCCESS = "UPDATE_LECTURE_SUCCESS";
export const UPDATE_LECTURE_FAILURE = "UPDATE_LECTURE_FAILURE";

export const SHARED_SPACES_REQUEST = "SHARED_SPACES_REQUEST";
export const SHARED_SPACES_SUCCESS = "SHARED_SPACES_SUCCESS";
export const SHARED_SPACES_FAILURE = "SHARED_SPACES_FAILURE";

export const ADD_SHARED_SPACE_REQUEST = "ADD_SHARED_SPACE_REQUEST";
export const ADD_SHARED_SPACE_SUCCESS = "ADD_SHARED_SPACE_SUCCESS";
export const ADD_SHARED_SPACE_FAILURE = "ADD_SHARED_SPACE_FAILURE";

export const DELETE_SHARED_SPACE_REQUEST = "DELETE_SHARED_SPACE_REQUEST";
export const DELETE_SHARED_SPACE_SUCCESS = "DELETE_SHARED_SPACE_SUCCESS";
export const DELETE_SHARED_SPACE_FAILURE = "DELETE_SHARED_SPACE_FAILURE";
