/* eslint-disable max-len, no-trailing-spaces, react/no-unescaped-entities */
import React, { Component } from "react";
import PropTypes from "prop-types";

import TabbedView from "../shared/tabbed-view";
import { TermsAndConditionsText } from "./terms-and-conditions-text";
import { PrivacyPolicyText } from "./privacy-policy-text";

import "./terms-and-conditions.scss";


export class Legal extends Component {

    constructor(props) {
        super(props);

        const { getString } = this.props.i18n;
        const tabs = [
            { id: "terms-and-conditions", title: getString("privacy.termsAndConditions") },
            { id: "privacy-policy", title: getString("privacy.policy") }
        ];

        this.state = {
            tabs,
            activeTabIndex: 0
        };
    }

    _onTabSelected = (index) => {
        this.setState({
            activeTabIndex: index
        });
    };

    render() {
        const { i18n } = this.props;
        const {
            tabs,
            activeTabIndex
        } = this.state;

        return (
            <section className="page-wrapper tnc-wrapper plain">
                <div className="background-mask" />
                <section className="main-content-container">
                    <section className="main-content">
                        <div className="tab-section">
                            <TabbedView
                                tabs={tabs}
                                onTabSelected={this._onTabSelected}
                            />
                        </div>
                        <div className="module-section rounded">
                            {(activeTabIndex === 0) &&
                                <div className="terms-and-conditions-section">
                                    <TermsAndConditionsText 
                                        i18n={i18n}
                                    />
                                </div>}
                            {(activeTabIndex === 1) &&
                                <div className="terms-and-conditions-section">
                                    <PrivacyPolicyText
                                        i18n={i18n}
                                    />
                                </div>}
                        </div>
                    </section>
                </section>
            </section>
        );
    }
}

Legal.propTypes = {
    title: PropTypes.string,
    auth: PropTypes.object,
    context: PropTypes.object,
    i18n: PropTypes.object
};

export default Legal;
