/** -------------------------------------------------------------------------------------------------------------------
 * Loader
 * A component that displays an inline "Loading..." text
 *
 * @examples
 *
 *  ```jsx
 *    <LoadingText
 *      text="Loading..."
 *    />
 * ```
 *
 *  @component LoadingText
 *  @import LoadingText
 *  @returns {object} React functional object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";

import "./spinner.scss";


export const LoadingText = ({ text }) => (
    <div className="loading-text">
        <p>{text}</p>
    </div>
);

LoadingText.propTypes = {
    text: PropTypes.string
};
