import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ModalSheet from "../shared/modal/modal-sheet";
import RegistrationView from "./views/registration-view";

import {
    onAccountDetailUpdated,
    createAccount,
    onAccountCreated,
    resetQuery,
    resetErrors
} from "../../store/reducers/account/account.actions";

import {
    findInviteByCode
} from "../../store/reducers/universities/universities.actions";
import { localStorage } from "../../utils/storage";

import "./sign-up.scss";


export class CreateAccount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showingErrorSheet: false
        };
    }

    _handleAccountCreation = () => {
        this.props.createAccount();
    };

    _onErrorSheetActions = () => {
        this.props.resetErrors();
        this.setState({
            showingErrorSheet: false
        });
    };

    _handleCodeVerification = (code) => {
        this.props.findInviteByCode(code);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.account.creatingAccount === true && this.props.account.creatingAccount === false) {
            const { account: { userInfo }} = this.props;

            if (userInfo && userInfo.id) {
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
                this.props.onAccountCreated(userInfo);

                setTimeout(() => {
                    this.props.history.push("/terms-and-conditions");
                }, 0);
            } else {
                this.setState({
                    showingErrorSheet: this.props.account.error
                });
            }
        }
    }

    render() {
        const {
            i18n,
            context: { locale },
            onAccountDetailUpdated,
            accountType,
            account: { creatingAccount },
            universities: { isLoadingInvite, currentInvite, error }
        } = this.props;
        const {
            showingErrorSheet
        } = this.state;

        return (
            <section className="page-wrapper">
                <div className="background-mask" />
                <section className="main-content-container">
                    <section className="main-content">
                        <RegistrationView
                            locale={locale}
                            i18n={i18n}
                            accountType={accountType || "instructor"}
                            disabled={isLoadingInvite}
                            creatingAccount={creatingAccount}
                            invite={currentInvite}
                            error={error}
                            onAccountDetailUpdated={onAccountDetailUpdated}
                            onInviteCodeVerification={this._handleCodeVerification}
                            handleAccountCreation={this._handleAccountCreation}
                        />
                        {showingErrorSheet &&
                            <ModalSheet
                                title={i18n.getString("register.accountNotCreated")}
                                type="error"
                                mode="compact"
                                showCancelButton={false}
                                okLabel={i18n.getString("generic.OK")}
                                onOK={this._onErrorSheetActions}
                                onModalClose={this._onErrorSheetActions}>
                                <p>{i18n.getString(this.props.account.error)}</p>
                            </ModalSheet>}
                    </section>
                    <section className="footer">
                        <div className="toolbar">
                        </div>
                    </section>
                </section>
            </section>
        );
    }
}

CreateAccount.propTypes = {
    account: PropTypes.object,
    universities: PropTypes.object,
    context: PropTypes.object,
    i18n: PropTypes.object,
    accountType: PropTypes.string,
    history: PropTypes.object,
    onAccountDetailUpdated: PropTypes.func,
    createAccount: PropTypes.func,
    onAccountCreated: PropTypes.func,
    resetQuery: PropTypes.func,
    resetErrors: PropTypes.func,
    findInviteByCode: PropTypes.func
};

const mapStateToProps = (state) => ({
    context: state.context,
    account: state.account,
    universities: state.universities
});

const mapDispatchToProps = {
    onAccountDetailUpdated,
    createAccount,
    onAccountCreated,
    resetQuery,
    resetErrors,
    findInviteByCode
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
