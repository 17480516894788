/** -------------------------------------------------------------------------------------------------------------------
 * EditLectureView
 * A component that renders the modal dialog for scheduling or rescheduling a lecture
 *
 * @examples
 *
 *  ```jsx
 *    <EditLectureView
 *      i18n={i18n}
 *      showSpinner={isUpdating}
 *      onChange={(name, value) => console.log("Field changed: ", name, value)}
 *      onDurationSelected={(duration) => console.log("Lecture duration selected", duration)}
 *      onError={(name) => console.log("Field error: ", name)}
 *     />
 * ```
 *
 *  @component EditLectureView
 *  @import EditLectureView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import InputField from "../../shared/input-field";
import DurationSelector from "../../shared/duration-selector";
import Selector from "../../shared/selector";
import DatePicker from "../../shared/calendar/date-picker";
import { Loader } from "../../shared/spinner/loader";
import languages from "../../../store/data/languages";
import { cloneAsDate } from "../../shared/calendar/date-utils";
import { fromUTCString } from "../../../utils/dates";
import { hours } from "../../../utils/numbers";


export class EditLectureView extends Component {

    constructor(props) {
        super(props);

        this.suggestedTimeBuffer = 2;
        const hourInfo = this._initializeStartTime(props.requires24HourFormat, props.mode);
        const minuteRange = this._initializeMinuteRange();
        const ampmInfo = this._initializeAMPMInfo(hourInfo);

        const { i18n: { getString }} = props;
        const templates = {
            MINUTES: getString("date.minutes"),
            HOUR: getString("date.oneHour"),
            HOURS: getString("date.hours")
        };

        this.state = {
            startDate: props.startTime,
            editingLecture: props.lecture || {},
            ...hourInfo,
            minuteRange,
            ampmInfo,
            templates,
            frequency: "none",
            repeatTimes: 1,
            repeatLabel: null,
            isEditMode: props.mode === "edit",
            enableLectureFrequency: false
        };
    }

    _generateCourseList() {
        const courses = this.props.courses.map((course) => {
            const displayId = course.courseId || course.id;

            return {
                id: course.id,
                title: `${displayId.toUpperCase()} - ${course.title}`
            };
        });

        if (this.state.isEditMode) {
            const courseId = this.state.editingLecture.course.id;

            courses.forEach((course) => {
                if (course.id === courseId) {
                    course.selected = true;
                }
            });
        }

        return courses;
    }

    _generateLanguageList() {
        const languageList = languages.map((language) => ({
            id: language.locale,
            title: language.displayName
        }));

        if (this.state.isEditMode) {
            const [locale] = this.state.editingLecture.deliveryLanguage.split("_");

            languageList.forEach((language) => {
                if (language.id === locale) {
                    language.selected = true;
                }
            });
        }

        return languageList;
    }

    _generateRoomList() {
        const rooms = this.props.lectureRooms.map((room) => ({
            id: room.id,
            title: room.name
        }));

        if (this.state.isEditMode) {
            const roomId = this.state.editingLecture.room.id;

            rooms.forEach((room) => {
                if (room.id === roomId) {
                    room.selected = true;
                }
            });
        }

        return rooms;
    }

    _generateFrequencyInfo() {
        const { getString } = this.props.i18n;

        return [
            {id: "none", title: getString("lecture.frequencyNone")},
            {id: "daily", title: getString("lecture.frequencyDaily") },
            {id: "weekly", title: getString("lecture.frequencyWeekly") }
        ];
    }

    _getSuggestedStartTime() {
        const {
            mode,
            startTime
        } = this.props;

        let suggestedStartTime;

        if (mode === "edit") {
            suggestedStartTime = fromUTCString(this.props.lecture.startDate);
        } else {

            /**
             * Suggest a start time. If the start hour is prior to 6:00 AM (for instance, 3:00 AM on the following day),
             * set it to 6:00 AM.
             */
            suggestedStartTime = new Date(startTime.getTime() + hours(this.suggestedTimeBuffer));

            if (suggestedStartTime.getHours() <= 6) {
                suggestedStartTime.setHours(6);
            }

            suggestedStartTime.setMinutes(0);
            suggestedStartTime.setSeconds(0);
        }

        return suggestedStartTime;
    }

    _initializeStartTime() {
        const { requires24HourFormat } = this.props;
        const lowerBound = requires24HourFormat ? 0 : 1;
        const upperBound = requires24HourFormat ? 23 : 12;
        const range = [];

        const suggestedStartTime = this._getSuggestedStartTime();
        let currentHours = suggestedStartTime.getHours();

        if (this.props.mode === "edit") {
            const startDate = fromUTCString(this.props.lecture.startDate);
            currentHours = startDate.getHours();
        }

        for (let i = lowerBound; i <= upperBound; i++) {
            const hourInfo = {
                id: `hour-${i}`,
                title: i,
                value: i
            };

            range.push(hourInfo);
        }

        return {
            hourRange: range,
            suggestedStartTime,
            startHour: currentHours
        };
    }

    _initializeMinuteRange() {
        const range = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
        const minutes = range.map((minute) => ({
            id: `minute-${minute}`,
            value: minute,
            title: (minute <= 5) ? `0${minute}` : `${minute}`
        }));

        return minutes;
    }

    _initializeAMPMInfo() {
        const {
            i18n: { getString }
        } = this.props;

        const info = ["AM", "PM"].map((ampm) => ({
            id: ampm.toLowerCase(),
            title: getString(`date.${ampm}`)
        }));

        return info;
    }

    _getHoursOptions() {
        const { requires24HourFormat } = this.props;
        const {
            hourRange,
            suggestedStartTime
        } = this.state;

        const currentHours = suggestedStartTime.getHours();
        let selectedHour = currentHours;

        if (!requires24HourFormat) {
            selectedHour = (currentHours <= 12) ? currentHours : currentHours % 12;
        }

        hourRange.forEach((hour) => {
            hour.selected = (hour.value === selectedHour);
        });

        return hourRange;
    }

    _getMinutesOptions() {
        const {
            minuteRange,
            suggestedStartTime
        } = this.state;

        minuteRange.forEach((minute) => {
            minute.selected = (minute.value === suggestedStartTime.getMinutes());
        });

        return minuteRange;
    }

    _getAMPMOptions() {
        const {
            ampmInfo,
            suggestedStartTime
        } = this.state;

        ampmInfo[0].selected = suggestedStartTime.getHours() < 12;
        ampmInfo[1].selected = suggestedStartTime.getHours() >= 12;

        return ampmInfo;
    }

    _getLectureValidators() {
        const { getString } = this.props.i18n;

        return {
            isRequiredTitle: {
                test: (value) => !!value.trim().length,
                message: getString("lecture.missingTitle")
            }
        };
    }

    _findSelectedItemFromList(list) {
        return !list.length ? null : list.find((item) => item.selected) || list[0];
    }

    _onDateSelected = (type, date) => {
        this.setState({
            [type]: date
        }, () => {
            this._onDateTimeChanged();
        });
    };

    _onStartTimeChanged = (component, value) => {
        const { requires24HourFormat } = this.props;
        const { suggestedStartTime } = this.state;

        value = (component !== "ampm") ? parseInt(value.split("-")[1], 10) : value;
        const updatedStartTime = suggestedStartTime;

        switch (component) {
            case "hours": {
                if (requires24HourFormat) {
                    updatedStartTime.setHours(value);
                } else {
                    const isPM = (updatedStartTime.getHours() >= 12);
                    const hours = (isPM && value === 12) ? value : isPM ? value + 12 : value;
                    updatedStartTime.setHours(hours);
                }

                break;
            }

            case "minutes":
                updatedStartTime.setMinutes(value);
                break;

            case "ampm": {
                const isPM = (value === "pm");
                let hours = updatedStartTime.getHours();

                if (isPM) {
                    hours = (hours === 12) ? hours : hours + 12;
                } else {
                    hours = (hours === 12) ? 0 : (hours > 12) ? hours - 12 : hours;
                }

                updatedStartTime.setHours(hours);

                break;
            }

            default:
                break;
        }

        this.setState({
            suggestedStartTime: updatedStartTime
        }, () => {
            this._onDateTimeChanged();
        });
    };

    _onDateTimeChanged() {
        const {
            startDate,
            suggestedStartTime: currentTime
        } = this.state;

        const lectureStartDate = cloneAsDate(startDate);
        lectureStartDate.setHours(currentTime.getHours());
        lectureStartDate.setMinutes(currentTime.getMinutes());

        this.props.onChange("startDate", lectureStartDate);
    }

    _onDurationSelected = (duration) => {
        this.props.onChange("duration", duration);
    };

    _onFrequencySelected = (frequency) => {
        this.setState({
            frequency
        }, () => {
            this._generateRepeatLabel();
        });

        this.props.onChange("frequency", frequency);
    };

    _onRepeatTimesChanged = (repeatTimes) => {
        repeatTimes = isNaN(repeatTimes) ? 1 : +repeatTimes;

        this.setState({
            repeatTimes
        }, () => {
            this._generateRepeatLabel();
        });

        this.props.onChange("repeatTimes", repeatTimes);
    };

    _generateRepeatLabel() {
        const { getString } = this.props.i18n;
        const { frequency, repeatTimes } = this.state;
        const isSingular = repeatTimes === 1;
        let repeatLabel;

        if (frequency === "daily") {
            repeatLabel = getString(isSingular ? "date.unitDay" : "date.unitDays");
        } else if (frequency === "weekly") {
            repeatLabel = getString(isSingular ? "date.unitWeek" : "date.unitWeeks");
        }

        this.setState({
            repeatLabel
        });
    }

    _onNewRoomAdded = (name) => {
        this.props.onChange("newRoom", name);
    };

    _onEditingLectureDetailsUpdated = (name, value) => {
        if (this.state.isEditMode) {
            const { editingLecture } = this.state;
            editingLecture[name] = value;

            this.setState({
                editingLecture
            });
        }

        this.props.onChange(name, value);
    };

    _onEditingLectureDetailsError = (name) => {
        if (this.state.isEditMode) {
            const { editingLecture } = this.state;
            delete editingLecture[name];
            this.setState({
                editingLecture
            });
        }

        this.props.onError(name);
    }


    componentDidMount() {
        const {
            mode,
            lecture,
            lectureRooms
        } = this.props;
        const { suggestedStartTime } = this.state;

        if (mode === "edit") {
            const { editingLecture } = this.state;

            this.props.onChange("id", lecture.id);
            this.props.onChange("title", lecture.title);
            this.props.onChange("courseId", editingLecture.course.id);
            this.props.onChange("roomId", editingLecture.room.id);
            this.props.onChange("deliveryLanguage", editingLecture.deliveryLanguage.split("_")[0]);

            const startDate = fromUTCString(editingLecture.startDate);
            this.props.onChange("startDate", startDate);
        } else {
            this.props.onChange("startDate", suggestedStartTime);
            const selectedCourse = this._findSelectedItemFromList(this._generateCourseList());

            if (selectedCourse) {
                this.props.onChange("courseId", selectedCourse.id);
            }

            const selectedLanguage = this._findSelectedItemFromList(this._generateLanguageList());

            if (selectedLanguage) {
                this.props.onChange("deliveryLanguage", selectedLanguage.id);
            }

            this.props.onChange("frequency", "none");
            this.props.onChange("repeatTimes", 1);

            if (lectureRooms.length) {
                this.props.onChange("roomId", lectureRooms[0].id);
            }
        }
    }

    render() {
        const {
            i18n,
            lectureRooms,
            onChange,
            onError,
            showSpinner,
            requires24HourFormat
        } = this.props;

        const {
            editingLecture,
            startDate,
            suggestedStartTime,
            templates,
            frequency,
            repeatTimes,
            repeatLabel,
            isEditMode,
            enableLectureFrequency
        } = this.state;

        const { getString } = i18n;
        const shouldShowFrequency = frequency !== "none";
        const hasRooms = (lectureRooms.length > 0);
        const duration = isEditMode ?
            (Date.parse(editingLecture.endDate) - Date.parse(editingLecture.startDate)) / 60000 : 0;

        return (
            <div className="edit-lecture-wrapper">
                <div className="row">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("generic.title")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <InputField
                            value={isEditMode ? editingLecture.title : ""}
                            placeholder={getString("lecture.titleHint")}
                            showClearControl={false}
                            validationTypes={["isRequiredTitle"]}
                            validators={this._getLectureValidators()}
                            disabled={showSpinner}
                            onValid={(value) => this._onEditingLectureDetailsUpdated("title", value)}
                            onError={() => this._onEditingLectureDetailsError("title")}
                        />
                    </div>
                </div>
                <div className="row course-section">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("lecture.course")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <Selector
                            options={this._generateCourseList()}
                            disabled={isEditMode || showSpinner}
                            onChange={(event) => onChange("courseId", event.target.value)}
                        />
                    </div>
                </div>
                <div className="row room-section">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("lecture.room")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        {hasRooms ?
                            <Selector
                                id="roomSelector"
                                options={this._generateRoomList()}
                                disabled={showSpinner}
                                onChange={(event) => onChange("roomId", event.target.value)}
                            /> :
                            <InputField
                                placeholder={getString("lecture.room")}
                                validationTypes={["isRequired"]}
                                disabled={isEditMode || showSpinner}
                                showClearControl={false}
                                onValid={(value) => onChange("newRoom", value)}
                                onError={() => onError("roomId")}
                            />}
                    </div>
                </div>
                <div className="row date-section start-date">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("lecture.startDate")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <DatePicker
                            date={startDate}
                            dateStrings={i18n.languagePack}
                            dateFormatter={i18n}
                            onDateSelected={(date) => this._onDateSelected("startDate", date)}
                        />
                    </div>
                </div>
                <div className="row date-section start-time">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("lecture.startTime")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <Selector
                            id="startHourSelector"
                            options={this._getHoursOptions()}
                            disabled={showSpinner}
                            onChange={(event) => this._onStartTimeChanged("hours", event.target.value)}
                        />
                        <Selector
                            id="startMinuteSelector"
                            options={this._getMinutesOptions()}
                            disabled={showSpinner}
                            onChange={(event) => this._onStartTimeChanged("minutes", event.target.value)}
                        />
                        {!requires24HourFormat && <Selector
                            id="ampmSelector"
                            options={this._getAMPMOptions()}
                            disabled={showSpinner}
                            onChange={(event) => this._onStartTimeChanged("ampm", event.target.value)}
                        />}
                    </div>
                </div>
                <div className="row date-section end-time">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("lecture.endTime")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        {<DurationSelector
                            startDate={suggestedStartTime}
                            duration={duration}
                            dateStrings={i18n.languagePack}
                            dateFormatter={i18n}
                            templates={templates}
                            onDurationSelected={this._onDurationSelected}
                        />}
                    </div>
                </div>
                <div className="row language-section">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("lecture.language")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <Selector
                            options={this._generateLanguageList()}
                            disabled={showSpinner}
                            onChange={(event) => onChange("deliveryLanguage", event.target.value)}
                        />
                    </div>
                </div>
                {enableLectureFrequency && <div className="row frequency-section">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("lecture.frequencyRepeats")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <Selector
                            options={this._generateFrequencyInfo()}
                            disabled={showSpinner}
                            onChange={(event) => this._onFrequencySelected(event.target.value)}
                        />
                    </div>
                </div>}
                {shouldShowFrequency && <div className="row frequency-section">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("lecture.frequencyEndsAfter")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <div className="repeat-times">
                            <InputField
                                value={`${repeatTimes}`}
                                showClearControl={false}
                                onChange={(value) => this._onRepeatTimesChanged(value)}
                            />
                            <label>{repeatLabel}</label>
                        </div>
                    </div>
                </div>}
                {showSpinner && <div className="loader-wrapper">
                    <Loader />
                </div>}
            </div>
        );
    }
}

EditLectureView.propTypes = {
    i18n: PropTypes.object,
    context: PropTypes.object,
    mode: PropTypes.string,
    requires24HourFormat: PropTypes.bool,
    courses: PropTypes.array,
    lecture: PropTypes.object,
    lectureRooms: PropTypes.array,
    startDate: PropTypes.object,
    startTime: PropTypes.object,
    showSpinner: PropTypes.bool,
    onChange: PropTypes.func,
    retrieveLectureRooms: PropTypes.func,
    onError: PropTypes.func
};

EditLectureView.defaultProps = {
    mode: "create",
    courses: [],
    lectureRooms: [],
    requires24HourFormat: false,
    startTime: new Date(),
    onChange: () => {},
    onError: () => {}
};

export default EditLectureView;
