/** -------------------------------------------------------------------------------------------------------------------
 * AvatarView
 * A component that renders the subview for showing and updating a user's avatar
 *
 * @examples
 *
 *  ```jsx
 *
 *    <AvatarView
 *      userInfo={user}
 *      serviceURL={serviceURL}
 *      i18n={i18n}
 *    />
 * );
 * ```
 *
 *  @component AvatarView
 *  @import AvatarView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Extensions from "@akwaba/object-extensions";

import FileUploader from "../../../utils/file-upload";
import ModalSheet from "../../shared/modal/modal-sheet";
import { noop } from "../../../utils/functions";

const DEFAULT_AVATAR = "https://s3-us-west-1.amazonaws.com/akwaba/assets/avatar-female.png";


export class AvatarView extends PureComponent {

    constructor(props) {
        super(props);

        this.fileUploadContainer = React.createRef();

        this.state = {
            uploadFailed: false,
            showingFileUploadMessage: false
        };
    }

    _generateDisplayName() {
        const {
            userInfo: { firstName, lastName }
        } = this.props;

        return (
            <React.Fragment>
                <span>{firstName}</span>
                <strong>{lastName}</strong>
            </React.Fragment>
        );
    }

    onUploadStart = noop;

    onUploadProgress = noop;

    onMaxSizeExceeded = noop;

    onUploadComplete = noop;

    onUploadError = (title, response) => {
        const { i18n: { getString }} = this.props;
        title = Extensions.isString(title) ? title : getString("videos.uploadError");
        const isConflict = Extensions.isNumber(response.status) && response.status === 409;
        const message = isConflict ? getString("videos.fileConflictErrorMessage") :
            Extensions.isString(response) ? response : getString("videos.uploadErrorMessage");

        this.setState({
            type: "error",
            title,
            message,
            uploadFailed: true,
            showingFileUploadMessage: true
        });

        return this;
    };

    _onUploadMessageConfirmed = () => {
        this.setState({
            uploadFailed: false,
            showingFileUploadMessage: false
        });
    };

    componentDidMount() {
        const {
            i18n,
            serviceURL,
            userInfo: { avatarURL }
        } = this.props;

        this.fileUploader = new FileUploader({
            id: "avatarFileUploader",
            element: this.fileUploadContainer.current,
            type: "image",
            mediaType: "image",
            mode: "compact",
            serverURL: serviceURL,
            imageURL: `${avatarURL || DEFAULT_AVATAR}?${+new Date()}`,
            i18n,
            delegate: this
        });
    }

    render() {
        const {
            i18n: { getString }
        } = this.props;

        const {
            showingFileUploadMessage,
            title,
            message,
            uploadFailed
        } = this.state;

        return (
            <React.Fragment>
                <div className="avatar-view-wrapper row">
                    <div className="avatar small-12 medium-12" ref={this.fileUploadContainer}>
                    </div>
                    <div className="name small-12 medium-12">
                        <h2>
                            {this._generateDisplayName()}
                        </h2>
                    </div>
                </div>
                {showingFileUploadMessage && <ModalSheet
                    title={title}
                    okLabel={getString("generic.OK")}
                    showCancelButton={false}
                    type={uploadFailed ? "error" : "status"}
                    onOK={this._onUploadMessageConfirmed}
                    onModalClose={this._onUploadMessageConfirmed}>
                    <p>{message}</p>
                </ModalSheet>}
            </React.Fragment>
        );
    }
}

AvatarView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    serviceURL: PropTypes.string
};

export default AvatarView;


