/** --------------------------------------------------------------------------------------------------------------------
 * Course
 * A component that render the details of a course
 *
 * @examples
 *
 *  ```jsx
 *    <Course
 *      course={course}
 *      i18n={i18n}
 *      onClick={() => console.log("Course clicked")}
 *    />
 * ```
 *
 *  @component Course
 *  @import Course
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Rating from "../../../components/shared/rating";
import Button from "../../../components/shared/button";


const Course = ({
    course,
    canEdit,
    onClick,
    className,
    url
}) => {

    const {
        id,
        courseId,
        title,
        colorCode = "blue",
        configuration: { thumbnail },
        formattedPrice,
        formattedStartDate,
        formattedEndDate,
        rating = 0
    } = course;

    const _onClick = (event) => {
        event.preventDefault();
        onClick("view", id);
    };

    return (
        <li key={id} className={className}>
            <div className="thumbnail">
                <Link
                    to={url}
                    title={title}
                    rel={id}
                    onClick={_onClick}>
                    <figure className={classNames("cover", `item-${colorCode.toLowerCase()}`)}>
                        <div className="image-container">
                            <img src={thumbnail} />
                        </div>
                        <span className="ribbon" />
                    </figure>
                </Link>
            </div>
            <div className="details">
                <p className="actions"></p>
                <p className="info course-id">{courseId || id}</p>
                <h4>
                    <Link to={url}>{title}</Link>
                </h4>
                {canEdit && <Button className="edit" onClick={() => onClick("edit", id)} />}
                <div className="rating">
                    <Rating average={rating} />
                </div>
                {formattedStartDate && <p className="info dates">{formattedStartDate} - {formattedEndDate}</p>}
                <p className="violator language">{course.deliveryLanguageName}</p>
                <p className="violator price">{formattedPrice}</p>
            </div>
        </li>
    );
};

Course.propTypes = {
    course: PropTypes.object.isRequired,
    className: PropTypes.string,
    url: PropTypes.string,
    canEdit: PropTypes.bool,
    onClick: PropTypes.func
};

Course.defaultProps = {
    onClick: () => {}
};

export default Course;
