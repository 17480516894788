const template = `
    <div class="lecture-details">
        <h4 class="typography-intro-product">{name}</h4>
        <dl>
            <dt class="time" />
            <dd>{startTime} - {endTime}</dd>
            <dt class="course" />
            <dd>{courseTitle}</dd>
            <dt class="room" />
            <dd>{room}</dd>
        </dl>
        <div class="actions">
            <p><button class="button ok-button" data-action="attend">{attendLabel}</button></p>
            <p><button class="button button-secondary reschedule-button" data-action="edit"
                style="display:none;">{rescheduleLabel}</button></p>
        </div>
    </div>
`;

export default template;
