import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import PageTemplate from "../shared/pages/template";
import LocalNav from "../shared/local-nav";
import Button from "../shared/button";
import MailComposer from "../shared/mail-composer";
import ModalDialog from "../shared/modal";
import ModalSheet from "../shared/modal/modal-sheet";
import ContactListView from "../contacts/views/contact-list-view";
import { LoadingText } from "../shared/spinner/loading-text";

import {
    trim,
    contains
} from "../../utils/strings";

import {
    fetchUniversityStudents,
    inviteStudents,
    resetErrors
} from "../../store/reducers/universities/universities.actions";

import {
    STUDENTS,
} from "../../constants/contacts";

import "./students.scss";
import "../contacts/contacts.scss";


export class Students extends Component {

    constructor(props) {
        super(props);

        const {
            auth: { userInfo: { accountType }}
        } = this.props;
        const canViewPage = accountType === "instructor";

        this.state = {
            filteredList: this._filterStudentsBy(""),
            isShowingSidebar: true,
            isShowingInviteForm: false,
            isShowingErrorSheet: false,
            canViewPage
        };
    }

    _filterStudentsBy(searchTerm = "") {
        searchTerm = trim(searchTerm).toLowerCase();
        const keywords = (!searchTerm.length) ? [] : searchTerm.split(/[\s+|,]/);

        return (!keywords.length) ? this.props.students :
            this.props.students.filter((student) => {
                return keywords.some((keyword) => {
                    return contains(student.firstName.toLowerCase(), keyword) ||
                        contains(student.lastName.toLowerCase(), keyword);
                });
            });
    }

    _onStudentInvitation = () => {
        this.setState({
            isShowingInviteForm: true
        });
    };

    _onCancel = () => {
        this.setState({
            isShowingInviteForm: false
        });
    };

    _sendInvites = (data) => {
        const {
            auth: { userInfo: { id, universityId }},
            inviteStudents
        } = this.props;
        inviteStudents(id, universityId, data);
    };

    _onErrorSheetActions = () => {
        this.props.resetErrors();
        this.setState({
            isShowingErrorSheet: false,
            isShowingInviteForm: false
        });
    };

    componentWillMount() {
        const {
            history,
            auth: { userInfo: { universityId }},
            fetchUniversityStudents
        } = this.props;

        if (!this.state.canViewPage) {
            history.goBack();
            return;
        }

        fetchUniversityStudents(universityId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading === true && this.props.isLoading === false) {
            const filteredList = this._filterStudentsBy("");
            this.setState({
                filteredList
            });
        }

        if (prevProps.isInvitingStudents === true && this.props.isInvitingStudents === false) {
            const { getString } = this.props.i18n;
            const { error } = this.props;
            const infoMessageTitle = getString(error ? "social.invitationNotSent" : "social.invitationSent");
            const infoMessageBody = error ? error : getString("social.invitationSentMessage");

            this.setState({
                isShowingErrorSheet: true,
                infoMessageType: error ? "error" : "success",
                infoMessageTitle,
                infoMessageBody,
            });
        }
    }

    render() {
        const {
            auth: { userInfo },
            i18n,
            isLoading,
            isInvitingStudents
        } = this.props;
        const {
            filteredList,
            isShowingInviteForm,
            isShowingErrorSheet,
            infoMessageType,
            infoMessageTitle,
            infoMessageBody,
            canViewPage
        } = this.state;
        const noStudentsFound = (Array.isArray(filteredList) && filteredList.length === 0);

        if (!canViewPage) {
            return null;
        }

        return (
            <React.Fragment>
                <PageTemplate
                    {...this.props}
                    className="students-wrapper"
                >
                    <LocalNav
                        title={i18n.getString("dashboard.students")}
                        page="students"
                        className="section-local-nav"
                        showPageInfo={true}
                        showContextualAction={true}
                        contextualActionLabel={i18n.getString("social.inviteStudents")}
                        onContextualAction={this._onStudentInvitation}
                    />
                    <div className="module-section">
                        <div className="section-content">
                            {isLoading ?
                                <div className="loader-wrapper">
                                    <LoadingText
                                        text={i18n.getString("generic.loading")}
                                    />
                                </div> :
                                noStudentsFound ?
                                    <div className="no-content">
                                        <p className="typography-body info">
                                            {i18n.getString("generic.noStudentsFound")}
                                            <span>
                                                <Button
                                                    title={i18n.getString("social.inviteStudents")}
                                                    className="invite-button"
                                                    onClick={this._onStudentInvitation}
                                                />
                                            </span>
                                        </p>
                                    </div> :
                                    <ContactListView
                                        type={STUDENTS}
                                        userInfo={userInfo}
                                        i18n={i18n}
                                        contacts={filteredList}
                                        onContactActions={this._onContactActions}
                                    />
                            }
                        </div>
                    </div>
                </PageTemplate>
                {isShowingInviteForm &&
                    <ModalDialog
                        mode="compact"
                        className="invite-modal"
                        title=""
                        showOKButton={false}
                        showCancelButton={false}
                        onModalClose={this._onCancel}>
                        <div className="invite-form-wrapper">
                            <MailComposer
                                i18n={i18n}
                                title={i18n.getString("social.inviteStudents")}
                                onSubmit={this._sendInvites}
                                showSpinner={isInvitingStudents}
                            />
                        </div>
                    </ModalDialog>
                }
                {isShowingErrorSheet &&
                    <ModalSheet
                        title={infoMessageTitle}
                        type={infoMessageType}
                        mode="compact"
                        showCancelButton={false}
                        okLabel={i18n.getString("generic.OK")}
                        onOK={this._onErrorSheetActions}
                        onModalClose={this._onErrorSheetActions}>
                        <p>{infoMessageBody}</p>
                    </ModalSheet>
                }
            </React.Fragment>
        );
    }
}

Students.propTypes = {
    context: PropTypes.object,
    students: PropTypes.array,
    auth: PropTypes.object,
    i18n: PropTypes.object,
    history: PropTypes.object,
    profile: PropTypes.object
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth,
    students: state.universities.universityStudents,
    profile: state.profile,
    isLoading: state.universities.isLoadingStudents,
    isInvitingStudents: state.universities.isInvitingStudents,
    error: state.universities.error
});

const mapDispatchToProps = {
    fetchUniversityStudents,
    inviteStudents,
    resetErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(Students);
