/** -------------------------------------------------------------------------------------------------------------------
 * PostsAndCommentsView
 * A component that renders the view for managing post and comment notifications
 *
 * @examples
 *
 *  ```jsx
 *
 *    <PostsAndCommentsView
 *      userInfo={userInfo}
 *      i18n={i18n}
 *      notifications={notifications}
 *      onNotificationChange={(notification, value) => console.log("Notification changed")}
 *    />
 * );
 * ```
 *
 *  @component PostsAndCommentsView
 *  @import PostsAndCommentsView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";

import allowedUsers from "../../../store/data/allowed-users-list";
import Selector from "../../shared/selector";

import { noop } from "../../../utils/functions";


export class PostsAndCommentsView extends Component {

    constructor(props) {
        super(props);

        const {
            likes,
            mentions
        } = this.props.notifications;

        this.state = {
            likes,
            mentions
        };
    }

    _generateOptions(type) {
        const {
            userInfo: { accountType },
            i18n: { getString }
        } = this.props;

        const {
            likes,
            mentions
        } = this.state;
        const selectedOptionType = (type === "likes") ? likes : mentions;

        const filteredItems = allowedUsers.filter((item) => item.role === "all" || item.role === accountType);
        const optionsList = filteredItems.map((option) => ({
            id: option.value,
            title: getString(option.localizedString)
        }));

        optionsList.forEach((option) => {
            if (option.id === selectedOptionType) {
                option.selected = true;
            }
        });

        return optionsList;
    }

    _onChange = (notification, value) => {
        this.setState({
            [notification]: value
        }, () => {
            this.props.onNotificationChange(notification, value);
        });
    };

    componentDidUpdate(prevProps) {
        const shouldUpdate = (
            (prevProps.notifications.likes !== this.props.notifications.likes) ||
            (prevProps.notifications.mentions !== this.props.notifications.mentions)
        );

        if (shouldUpdate) {
            const {
                likes,
                mentions
            } = this.props.notifications;

            this.setState({
                likes,
                mentions
            });
        }
    }

    render() {
        const {
            i18n: { getString },
            disabled
        } = this.props;

        return (
            <div className="profile-section comments-wrapper">
                <h3 className="profile-section-header">{getString("notifications.postsAndComments")}</h3>
                <div className="profile-section-wrapper">
                    <div className="row likes-selector">
                        <div className="column medium-6 small-12 label">
                            <label>{getString("notifications.likes")}</label>
                        </div>
                        <div className="column medium-6 small-12">
                            <Selector
                                options={this._generateOptions("likes")}
                                disabled={disabled}
                                onChange={(event) => this._onChange("likes", event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row mentions-selector">
                        <div className="column medium-6 small-12 label">
                            <label>{getString("notifications.mentions")}</label>
                        </div>
                        <div className="column medium-6 small-12">
                            <Selector
                                options={this._generateOptions("mentions")}
                                disabled={disabled}
                                onChange={(event) => this._onChange("mentions", event.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PostsAndCommentsView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    context: PropTypes.object,
    notifications: PropTypes.object,
    onNotificationChange: PropTypes.func,
    disabled: PropTypes.bool
};

PostsAndCommentsView.defaultProps = {
    onNotificationChange: noop
};

export default PostsAndCommentsView;
