import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import { InjectIntl } from "../../i18n/intl";
import { getPreferredLocale } from "../../store/reducers/application/context";

import Courses from "./courses";
import CourseDetails from "./views/course-details";

const CourseComponent = InjectIntl(Courses, getPreferredLocale());
const CourseDetailsComponent = InjectIntl(CourseDetails, getPreferredLocale());


class CoursePage extends PureComponent {

    render() {
        const { match } = this.props;

        return (
            <React.Fragment>
                <Route
                    exact
                    path={match.url}
                    render={(props) => <CourseComponent {...props} />}
                />
                <Route
                    path={`${match.url}/:id`}
                    render={(props) => <CourseDetailsComponent {...props} />}
                />
            </React.Fragment>
        );
    }
}

CoursePage.propTypes = {
    match: PropTypes.object
};

export default CoursePage;
