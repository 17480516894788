import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import Events from "@akwaba/events";

import LoginView from "./login-view";
import DestinationView from "./destination-view";
import languages from "../../../store/data/languages";

import {
    fetchAuthChallenge,
    setAccountType,
    login
} from "../../../store/reducers/auth/auth.actions";

import { changeLocale } from "../../../store/reducers/application/context.actions";
import { LOCALE_CHANGED_EVENT } from "../../../store/reducers/application/context.types";

import Crypto from "../../../utils/crypto";
import DigestMD5 from "../../../utils/crypto/md5";
import { toUnicode } from "../../../utils/strings";
import { localStorage } from "../../../utils/storage";

import "../home.scss";


export class HomeView extends Component {

    constructor(props) {
        super(props);

        this.destinationView = React.createRef();
        this.loginView = React.createRef();
        this.customLoginView = React.createRef();

        this.state = {
            activeView: "destination",
            decodedChallenge: null
        };
    }

    _onDestinationSelected = (destination) => {
        this.setState({
            activeView: "login"
        });

        this.props.setAccountType(destination);
    };

    _onBackToHome = () => {
        this.setState({
            activeView: "destination"
        });

        this._onViewSwitched();
    };

    componentDidMount() {
        this.props.fetchAuthChallenge();
        this._onViewSwitched();

        const preferredLanguage = this._getPreferredLocale();

        if (preferredLanguage.locale !== this.props.context.locale) {
            this._onLanguageSelected(preferredLanguage, false);
        }
    }

    componentDidUpdate() {
        this._onViewSwitched();

        let { decodedChallenge } = this.state;
        const {
            authenticated,
            login: { auth: { authChallenge }},
            userInfo
        } = this.props.auth;

        if (!decodedChallenge && authChallenge) {
            /* eslint-disable-next-line no-eval, prefer-template */
            const auth = eval( "(" + authChallenge + ")");
            decodedChallenge = new DigestMD5().decodeChallenge(auth.challenge);

            this.setState({
                decodedChallenge
            });
        }

        if (authenticated && userInfo && userInfo.id) {
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            const { acceptedTermsAndConditions } = userInfo.accountSettings;

            setTimeout(() => {
                this.props.history.push((acceptedTermsAndConditions === "true") ?
                    "/dashboard" :
                    "/terms-and-conditions"
                );
            }, 0);
        }
    }

    _onViewSwitched() {
        let incomingViews = [];
        let outgoingViews = [];

        switch (this.state.activeView) {
            case "login":
                outgoingViews = [this.destinationView.current];
                incomingViews = [this.loginView.current];
                break;

            default:
                outgoingViews = [this.loginView.current];
                incomingViews = [this.destinationView.current];
                break;
        }

        setTimeout(() => {
            outgoingViews.forEach((view) => view.classList.remove("revealed"));
            incomingViews.forEach((view) => view.classList.add("revealed"));
        }, 25);
    }

    _handleLogin = (username, password) => {
        const { accountType } = this.props.auth;
        const { decodedChallenge: { nonce, realm, qop }} = this.state;
        const digestURI = "/api/v3/login";
        const nc = accountType;
        let normalizedPassword = toUnicode(password);
        normalizedPassword = normalizedPassword.replace(/\\+u/g, "u");

        const credentials = {
            username,
            password: normalizedPassword,
            nonce,
            nc,
            realm,
            qop,
            digestURI,
            method: "POST"
        };

        const challengeResponse = new DigestMD5().handleChallenge(credentials);
        const authorizationString = `${username}:${nc}:${digestURI}:${challengeResponse}`;
        const authorization = Crypto.Base64.encode(authorizationString);

        this.props.login(username, accountType, authorization);
    };

    _handlePasswordReset = (password) => {
        this.setState({
            password
        });
    };

    _onLanguageSelected = (language, shouldUpdate = true) => {
        if (shouldUpdate) {
            localStorage.setItem("preferredLanguage", JSON.stringify(language));
        }

        this.props.changeLocale(language);
        Events.fire(document.body, LOCALE_CHANGED_EVENT, {
            locale: language.locale
        });
    };

    _getPreferredLocale() {
        let preferredLocale;

        try {

            /** This is needed since LocalStorage might not be available in Private mode */
            preferredLocale = JSON.parse(localStorage.getItem("preferredLanguage"));

        } catch (e) {
            /* eslint-disable-line no-empty */
        }

        if (!preferredLocale) {
            const { locale, country } = this.props.context;
            preferredLocale = {
                locale,
                country
            };
        }

        return preferredLocale;
    }

    render() {
        const {
            accountType,
            loginInProgress,
            authenticated,
            error
        } = this.props.auth;
        const {
            contextPath,
            locale,
            country
        } = this.props.context;
        const { activeView } = this.state;
        const isLoginViewActive = activeView === "login";
        const isCustomLoginViewActive = activeView === "custom-login";

        return (
            <section className="view-switcher">

                <div ref={this.destinationView}
                    className={classNames("view", {
                        "active-view": !(isLoginViewActive || isCustomLoginViewActive)
                    })}>
                    <DestinationView
                        contextPath={contextPath}
                        locale={locale}
                        country={country}
                        i18n={this.props.i18n}
                        languages={languages}
                        onDestinationSelected={this._onDestinationSelected}
                        onLanguageSelected={this._onLanguageSelected}
                    />
                </div>

                <div ref={this.loginView}
                    className={classNames("view", {
                        "active-view": isLoginViewActive
                    })}>
                    <LoginView
                        accountType={accountType}
                        contextPath={contextPath}
                        locale={locale}
                        country={country}
                        i18n={this.props.i18n}
                        languages={languages}
                        disabled={authenticated || loginInProgress}
                        showSpinner={loginInProgress || authenticated}
                        onBackToHome={this._onBackToHome}
                        onLogin={this._handleLogin}
                        loginFailed={!!error}
                        onPasswordReset={this._handlePasswordReset}
                        onLanguageSelected={this._onLanguageSelected}
                    />
                </div>

            </section>
        );
    }
}

HomeView.propTypes = {
    title: PropTypes.string,
    context: PropTypes.object,
    auth: PropTypes.object,
    fetchAuthChallenge: PropTypes.func,
    setAccountType: PropTypes.func,
    changeLocale: PropTypes.func,
    login: PropTypes.func,
    i18n: PropTypes.object,
    history: PropTypes.object
};

HomeView.defaultProps = {
    title: "1 University Avenue"
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth
});

const mapDispatchToProps = {
    fetchAuthChallenge,
    setAccountType,
    login,
    changeLocale
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
