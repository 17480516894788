/* eslint-disable max-len, indent */

const timezones = {

    SAMOA: {
        id: "samoa",
        timezoneId: "US/Samoa",
        shapes: ["M 582.375,5.666 564.875,5.666 564.875,23.625 570.25,23.625 582.375,35.125 582.375,46.375 568.667,59.625 568,60.375 564.875,60.375 564.875,95.625 582.375,95.625 z",
            "M 582.375,116.625 564.875,116.625 564.875,156.989 576.875,156.989 576.875,244.5 582.375,244.5 z",
            "M 2.5, 5.666 h 17 v 90.334 h -17 z",
            "M 2.5,116.688 19.5,116.688 19.5,158.5 7.25,158.5 7.25,171.75 16.25,181.75 19.5,181.75 19.5,244.5 2.5,244.5 z"
        ].join("")
    },

    HAWAII: {
        id: "hawaii",
        timezoneId: "US/Hawaii",
        shapes: ["M 20.5,5.666 45.25,5.666 45.25,33.301 20.5,31.246 z",
            "M 45.25,49.5 38,50.426 38,51.333 33.833,51.333 31.667,52.167 31.667,52.833 30.5,53.667 ",
            "30.5,54.833 28.75,55.375 28.75,56.375 26.625,56.375 26.625,57 25.75,57 25.75,56.125 24.5,56.125 24.5,",
            "55.375 23.5,55.25     23.5,56.25 21.75,56.25 20.625,56.176 20.625,96.5 2.5,96.5 2.5,116.167 20.875,116.167 ",
            "20.875,159.625 7.75,159.625 7.75,171.75     17.375,180.594 20.75,180.594 20.75,244.5 45.25,244.5 45.25,182.375 ",
            "57.875,182.375 57.875,181.5 59.375,181.5 59.375,180.594 50,168.625 45,168.625 z"
        ].join("")
    },

    ALASKA: {
        id: "alaska",
        timezoneId: "US/Alaska",
        shapes: ["M 46,32.833 46,5.666 69.167,5.666 69.167,32.051 65.333,32 62.333,32.167 61.5,33.301     ",
            "56.667,33.301 55.667,33.301 55.667,34.333 52.333,34.333 51.833,33.301 z",
            "M44.833,33.667h-8.5l-1.5-1.167L28.167,32H27l-2-0.333h-3.5l-1,1h-5.833L12.5,33.301v1.032L10.375,34v1.25 ",
            "H6.5v0.667v0.458h0.875h2.875v1.25c0,0,1.875-0.5,2.375,0s0,1.625,0,1.625H9.75h-4.5l-1.75,1V41h2.667l0.5,1.167h7.5v1H12.5v1 ",
            "l-1.25-1.25L9.917,44.25h-1.25l-1.042,1.042l-0.938,0.938l1,1L5.5,48.167l-0.333,2h3V49.5H9.5l3.667,0.667l0.167,1.667H20.5v1h-1 ",
            "L19.25,54h-1.875v1h-3.75v0.875H11.75V57H8.625v1H6.309v1h-2.64v0.875H2.5V61H6v-0.875h2.125V59h2.25v-0.875L11,58v0.875h3.625 ",
            "V57.25h1.625V58h2.25v-1.875h2v-0.75h2.125v-0.86l0.858-0.858v-0.531h1.767l0.125,2h2.25V54.25h2.75v-3.417h1.741l0.713-0.713H38 ",
            "v-1.495h8V34.333L44.833,33.667 z",
            "M 47,49.5 46.167,50.333 46.167,167.499 49.833,167.499 59.375,179.333 59.5,180.999 ",
            "57.167,183.333 46.167,183.333 46.167,244.5 69.167,244.5 69.167,66.166 68,65.666 67.333,64.999 66.667,61.999 66.833,61 ",
            "65.333,60.166 64.622,61 64.622,64.333 63.5,64.166 62,62.499 60.667,61.833 59.375,61 59.375,59.999 58.667,58.333 ",
            "59.375,57.172 61,57.166 62,57.833 64.167,56.999 64,56.166 62,56.166 57,50.833 55,50.833 54.833,52.166 52.333,52.166 ",
            "52.167,50.833 50.833,50.833 50.667,49.5 z"].join("")
    },

    PACIFIC: {
        id: "pacific",
        timezoneId: "America/Los_Angeles",
        shapes: ["M69.167,5.666V30.5l2.25-3.167l1.75-2.083c0,0,1.333-2.583,1.833-2.417s2.416-0.75,2.416-0.75l2.917-1.021",
			"l3.833-0.896c0,0,4.167-0.534,4.167-0.684s4.042-1.4,4.042-1.4V5.666H69.167 z",
            "M46,34.333v14.292h3.333V49.5l2.792,0.667l0.042,0.667h2.458V49.5h2.25L57,50.833l2.375,0.792l2.5,2.625",
			"l0.75,0.625h1.5L64,56.166h1.5v0.959h-1.25l-0.5,1.301v1.199l1.583,0.541l1.333,1.833h0.958v0.876h0.75v1h-1.292l0.25,1.124",
			"l0.542,0.052L68,65.666l1.167,0.5l-0.042,0.51V244.5H92.5V107.375h-8.125V99.5H88l0.625,0.75l1.133,0.104l1.617,0.146l1.125-0.199",
			"l-0.375-0.875l-1.75-1.5L89.5,97.301l-2.167,0.167L87.5,96h0.667l0.333-0.833l-0.167-2.333l1-0.5l0.167-2.167l-1-0.833",
			"L88.167,87.5h2.5v-9H85.5v-1h-2v-2.833h1.167l0.833-1l0.167-1h3.667l0.333-1.167l-1.333-1l-1-1l-1-0.667l0.333-3.667l-1-0.5",
			"L84.5,63.5h-1.167V61h-1.167l0.208-0.833L81.667,59.5c0,0-3-2.167-5.25-2.833S73,55.833,73,55.833L72.667,54.5l-1.25-1v-3.334",
			"l0.75-0.667l1.5-0.833l-1-1.25h-2.333H68.5h-1.167v-1.042l-1.667-1.042H64.5v-1.5l-3-3.583h-1.167l-0.958-0.75v-1.167H54.5v-2.417",
			"h-3.25v-1.583H46 z"
        ].join("")
    },

    MOUNTAIN: {
        id: "mountain",
        timezoneId: "America/Denver",
        shapes: ["M93.5,5.666v12.501l-1.167,1l-4.729,0.5l-3.438,0.5l-2.75,1.917l-5,0.75l-7.25,9.218H67.5v1.25h-6",
            "l-0.833,1.032h-5L54.5,35.917l1,1.583h3.875l0.958,0.812l1.5,1.188l1.667,1.688l1,1.146h0.833L66,44.062l0.333,1.271H68l0.5,1.042",
            "h0.75l1.083,1.042l1.083-1.042h1.917L74,47.417l0.667,1.083l-0.167,1h-2.333v2.875L74,53.167l1,1.167l1.417,2.333l1.654,0.669",
            "l1.427,0.769l1.273,0.788l1.604,1.275h1.458l0.667,1.5V63.5h1.083l1.083,1.667H87.5V69.5l0.833,1H89.5l0.917,0.667v1l-1.083,0.5",
            "h-2.667v1.167h-1.083l-0.917,0.833V77.5h5.75v10H89.5v2.667h1.167V92h-1.333v2.5H90.5l0.625,0.801L92.5,96v2.125h1v1.176v1.199",
            "l-0.125,0.676H88.5v-0.926h-3.167v6.333H93.5V244.5H117v-51.166H94.333V186.5H117v-66.449l-2.438-0.534l-3.062-0.966l-3.126-1.343",
            "l-1.623-1.042l-1.308-0.979l-1.193-1.012l-0.917-0.009v-1.51h2.167l0.833-1l0.167-1.031l-1.333-0.5l-0.917-0.074l-0.583-1.968",
            "l-1-0.75l-0.667-0.75l-0.667-2.25l-1-0.833l-1.167-1l-0.667-0.167V96h0.833l0.833-1.667l2.167,0.167L103.5,96h1.167v-2.333h2.833",
            "v-7h2.167L110.5,85.5v-2.167l-0.583-0.5V81l0.625-0.333l-0.042-2.333h0.833l0.167-1.5l1-0.333l-0.5-2.167l0.667-0.667l-0.5-2",
            "l-1.625-1.167l-0.042-1.167h-4.167v-1.667h-9.667V66.5l1.667-0.833v-1l-1.667-1V48.5h13.25v-5.333h-3.25l-0.833-0.667h-2.25v-0.667",
            "l-7.417-0.646L86.75,40.25l-1-1.938H83.5l-1.125-0.646l-1.604-1.75H80.25V35l-1-0.667v-1.032l6.333-0.426L86.5,32.5h9.875V18.167",
            "c0,0,0.792-1.167,1.292-0.75s5.917-0.167,5.917-0.167l2.75-0.917l4.208-0.667h4.875l1-0.167V5.666 z"
        ].join("")
    },

    CENTRAL: {
        id: "central",
        timezoneId: "America/Mexico_City",
        shapes: ["M 117.5,5.666 117.5,15 125.25,14.125 132.5,13.167 136.5,12 140.5,12 140.5,5.666 z",
            "M96.667,49.5v14.167h1.667H99.5v2.583h6.833v3.083h1.917h2.25l0.042,1.167h1.708v6l-0.917,1.833v2.833 ",
            "l-0.646-0.646V85.5l-2.188,1.583v6.583H106l-0.167,2.667L103.583,97l-0.083-1l-1.167-1.5l-1.631-0.125L99.333,96l0.053,6.688 ",
            "l4.197,3.895l2.139,3.75l0.347,1.641l-1.611,0.682l-0.208,1.52l3.312,2.512h2.938v1.434h1.75v0.666H115v1.264h2.5v67.574h-22 ",
            "v4.875h22v52h22.875V113.167c0,0-5.125-0.009-5.125,0s0-5.792,0-5.792h5.125v-9.199h-3.983l0.358-3.301l0.75-1.625l-0.875-1.375 ",
            "l-0.75-1.25V89.25l1.625-0.875V86.75l-3.625-2.125l-1.5-1.125l0.125-3.125l1.125-0.25l0.875-0.875l-1-1.125v-3L134.625,74v-1.25 ",
            "l-1.125-0.5l-1.125-0.75h-3v-3.125l-0.688-0.688V56.375l1.562-1.75V49.5H96.667 z"
        ].join("")
    },

    EASTERN: {
        id: "eastern",
        timezoneId: "America/New_York",
        shapes: ["M141.667,5.666L141.833,12l-18.518,2.343l-21.523,3.074l-4.626,0.75v15.134H85.583l-1.208,0.875h-4.25",
            "l0.125,1.741l1.227,0.771l0.898,0.979l3.375,0.646L89.5,39l2.833,1.25h5l0.047,1.043h7.787l0.667,1.207l4.083,0.667l0.583,0.896",
            "l0.479,2.021l1.354,1.333l-2.417,1.083v1.667H131.5V55H129v13.375h1.667l-0.167,1.458l-1.125,0.833l0.958,0.833h3.111v1.667h1.181",
            "v7h-1.187V84l1.065,0.994l0.935,0.548h2.061v1.208v1.625l-1.25,1.25v2.577l1.149,1.149v4.825h2.032h0.944l0.875,1v8.907h-4.917",
            "v5.08h4.833V244.5H164.5v-8.199c0,0-6.833,0.783-10-2.676s-3.167-4-3.167-4V223.5l1.167-0.074v-1.125l-1-1.801l1-0.449l0.667-1.718",
            "l1.333-1.032L154.167,213l1.208-2.699l0.125-5.801l1.875-2.324l0.875-2l-0.083-2.842l0.333-1.158l-0.333-2.509V191.5l0.5-1.166",
            "l1.791-5.662l-0.124-3.338l0.333-6.5l0.833-1.334l1.167-1l-0.167-1.833l-1.333-0.333v-1.167l1-0.833L162.5,165l-1.167-1l-0.833-0.5",
            "l-1-1.166l-1.833,0.166l-1.333-1.166l-0.917-0.834v-0.666V159l-0.917-0.166v-1.5l0.917-0.5v-2l0.917-1.334l1.333-1.166h3.667V146",
            "c0,0-1,1-1,0s0-2.5,0-2.5h1l0.167-1.166h3v-5h-2.167v-1.167h-5.167V134l-0.75-0.75l-0.25-4.416l0.833-1l1.667-1.658l0.833-1h5",
            "v-57.01l1.084-0.99l4.916-0.01l-0.083-0.99l0.083-2.926h-1l-0.667-0.75L167,62.333l-1.5-1l-1-1.083v-3l1-0.917h3.167l0.833,0.917h1",
            "v-3.125H169v-1h1.5v-4H169l-0.75,1.301l-0.625,0.875H166.5l-1,0.319l-0.5-0.444v-4.801l0.5-1.042h1.5l0.167-1.271H168l2.5-0.896",
            "l-0.875-1.241l-2.25-0.625l-1.875-0.114V39l0.833-0.688l1.542,0.864l1.75,1.125h3.625l2.125-0.875l-0.625-1.375l-3.25,0.261",
            "l-1-1.146l-1.452-1.089l-2.215-0.161l-0.208-1.866l-1.125-0.75l-1-1.301V18.083l2-0.916l1.5-1.833L170.5,15l1.333-0.083l1-0.75",
            "l1.333-0.667V12h-6l-3.667-1.167V5.666H141.667 z"
        ].join("")
    },

    ATLANTIC: {
        id: "atlantic",
        timezoneId: "America/Puerto_Rico",
        shapes: [
            "M 165.5,5.666v5.167c0,0,10.667,2,14,2s9.167,0,9.167,0V5.666H165.5 z",
            "M 177.083,24.051H165.5v7.074c0,0,0.413,4.081,3.548,4.953S173.333,37,173.333,37h2.042v3.176l-2.125,1.125",
            "l-0.917,1.032l-0.708,0.843l0.875,0.886l-1.208,1.271l-0.667,1.718l-1.958,0.366L168,46.375l-1-1.042h-1.5v5.843l1,0.125",
            "l1.75-0.875l0.75-1l1.5-0.301l0.792,1.042v8.042H169l0.729-0.729H165.5v0.729v1.458l0.833,0.583L168,61.833l0.833,0.667h2.458",
            "v3.76l-0.792,0.907l-1.667,0.003l-2.167,0.004L165.5,68.5v4.167h0.833l0.25,1.51l-1.168,1V127.5l1.584,0.834l1,0.842h1.333",
            "l1.167-1.009l0.75-0.667l1.917,0.801l1.167,0.875h2.75v1.658l0.792,0.967v1.366l-1.208,1.333l-1.292,1v1.667l1.125,1l-1.125,1.167",
            "h-3.729l-1.146,1.333v1l1.146,0.667v1.166l-1.146,1h-4l-1.084-1L164.5,143l-3.167,0.5v1v2.5h1v5.5H159.5v1h-2l-1,2.667",
            "l-1.083,0.667V159h1.25l0.5,1.667l0.004,1.399h3.162l0.706,1.757h1.461l0.833,1.302v3.542l-1.167-0.333v4.825l-1.013,0.896v8.246",
            "l-0.678,1.366v6.833l-0.911,0.979l-0.045,2.022l-0.769,1.799h-0.366l-0.217,2.033l1.208,1.833l-1.529,1.933l-0.548,2.389",
            "l-0.548,0.812l-1.25,1.875l-0.083,2.199l1.083,1.125l-1.125,0.801l0.125,4h-1v1.875l0.667,1.125H154.5l-0.079,1.726l-1.171,1.024",
            "h-0.75v1.449h1.333l-0.458,1.926H152.5v4.741l0.955,0.662l0.045,1.597l0.551,0.657l0.935,1l1.189,1.073l1.45,0.895l1.11,0.444",
            "h2.432l1.667,0.672h1.667h3l-2.312-1.542h-1.521v-2.542h-1.5L162,229.625h-3.5l-0.833-1.458l-2.333-0.667L155,225.333l1-1",
            "l1.167-1.333l1.333-1.667l-0.167-6l-0.761-0.815l0.928-1.185L158.167,209l1.167-1v-3.834l1,0.167L160.5,201l1.167-0.5l-0.167-2",
            "l-1.333-1.167V195.5l1-1.167l0.333-2.833l1.833-1.834v-4.5l1-0.666h0.854v-1.834l1.146-0.833l1-0.833l0.333-0.667l1.667,0.5",
            "l0.833,1.334l1.167-0.5l0.167-1.334l1.5,0.167l0.5,1.5l1,0.833l1.167,0.834v0.666l2.958,0.334v0.666l1.708,0.167l1.25,1.625",
            "l-1.083,1.208l-0.167,1.167h4l1-0.833l0.833-1.542V184l1.167-1.167l1.333-1.166l0.833-0.5l1-0.5v-1.5l1.167-0.667v-1.333l-1-1.042",
            "h-1.333H188.5l-0.333-3.292l1.333-1.333l1-0.667l1.167-1.333v-1l1-1.833v-2.501v-1V161.5h-3.5v-1l1.167-1l1-0.334l0.333-1.666",
            "l-0.833-1l-1.167-0.667L188.333,155v-1.167l0.833-1.667l0.833-0.5l0.5-3.416l-1.167-1.125l-1-1.792l-0.667-1L186.5,143.5l-1-1.167",
            "L183,142.5l-0.5-0.834l-0.917-1.166l-0.917-1.167l-0.333-0.667l1.25-1v-2.567h2.583l0.167-0.766h1.833v1h2.333V71.26h-9.916V67",
            "l0.916-1.823l1.167-0.917l1.667-1l1-0.927h6v-20.25l-1.083-0.917l-1.083-0.917h-0.833l-0.167-1.938v-2.396l-0.833-1.75L185.369,33",
            "l-0.869-1.833l-1-0.667l-1.333-1.833l-1-1.229L180.333,26l-1.833-0.75L177.083,24.051 z",
            "M 188.25,201.301 186.667,202.25 185.667,203 184.5,203 184.5,204.667 183.375,206.176 ",
            "182.232,207.281 181.5,208.176 180.242,208.854 175.667,209.833 175.375,210.667 175.667,212.5 173.434,212.5 172.583,213.333 ",
            "172.25,215.301 170.375,216.176 169.625,218.051 168.375,219.301 167.667,220.333 165.5,220.926 166.167,222 169.625,222.051 ",
            "169.625,223.426 168.375,224.176 167,225.176 165.5,227.021 165.5,232.083 166.667,232.083 168,233.176 169.625,233.176 ",
            "171.333,233.176 172.583,233.625 171.333,235.167 170.5,236.301 165.5,236.301 165.5,244.5 188.25,244.5 z"
        ].join("")
    },

    BRASILIA: {
        id: "brasilia",
        timezoneId: "America/Bahia",
        shapes: [
            "M 189.5,5.666v8.417l-14.125,0.246L174,15.875l-2.667-0.438l-4.833,0.646l3,1.083l1,0.25v0.75l-7,1.5h-6.083",
            "v1.417l2.167,0.792l1.75,0.833h3.167v0.542l13.917,0.801c0,0,2.334,1.116,4.167,3.116s1.889,3.333,1.889,3.333l1.903,0.75l0.208,3",
            "h2.083v2H187.5v4l0.833-0.833v1.75h1.167v95.996H188l-1.492-1.027l-4.924,1.027l-1.25,1.504l0.333,0.667l0.917,1.167l0.917,1.166",
            "l0.5,0.834l1.35-0.09l1.15-1.743h1.167L186.5,143.5l1.167,0.833l0.667,1L189.5,146v2.833l0.797,0.805v1.862l-1.13,0.666l0.5,3.667",
            "l1.495,1.061l0.505,0.606v2.5l1,0.833l1.167,0.834V163l-1.167,1.166v5.334l-2.167,1.333l-1.667,1.333l-0.583,1.491l1.083,2.468H190",
            "l1.667,1.042h1v1.666l-1.167,1l-1,0.834l-1.171,0.603l0.171,1.397h-1.25v1.166l-2.083,1.667v3.333l-0.5,1l-1.333-0.333l-1,1h-4",
            "v-1.833l1.167-0.334l0.167-1.208l-1.292-1.719l-2.5-0.937l-1.208-0.137l-0.693-1.162l-1.244-0.979l-1.146-0.524h-1.25l-0.664,0.284",
            "l-0.503,0.216L168.5,181.5l-1.167-0.5l-1,0.833l0.333,2l-1.479,0.667l-0.521,1l-1.333,1.625v1.708l0.167,1.667l-1.333,0.333",
            "l-0.802,1.818l0.135,3.016l-1.333,0.666V199h1.375v3.25h-1.104v2.083l-1.104,1.167v1.667h1.5l-1.5,0.833l0.333,1.666l-1.397,0.672",
            "v7.326h1.23v1.002l-1.046,1.001v1.666L157.167,223v1.833v0.667L155,225.333v2.167l1.346,0.289l1.321,0.378v1.458h5V227.5",
            "l0.333-0.834l1-0.666l1.5-1.334l0.667-1h1.167l0.167-0.766l-2-1.234L164,221l-0.167-1.167l1.792,0.343L165.5,219l1.833-0.334",
            "l1-0.833L168.5,216l1.167-0.5l1.167-0.199l-1.083-1.5c0,0-2.083-1.635-1.583-1.468s1.333-0.833,1.333-0.833h3.833v-1.167",
            "l1.542-1.032l-0.458-0.635h3.275l1.049-1.05h1.759l1.083-1.283l0.75-0.833l0.167-2.324l0.833-0.926l0.975-0.975h2.359l0.667-0.775",
            "l0.917-0.834l1.25,0.51V244.5h23.917v-7.167H203.5v-9.166h9.917v-63.042l0.75-1.459l2.167-2.166l0.917-1.324l0.375-5.875h-4.208",
            "V41.188l0.917-0.938h1.167l2.125-0.75v-1.187h1.875V37.5l2.167,0v-0.834l7.5-0.5c0,0,0.833,0.334,1.333-0.25",
            "s2.333-1.084,2.333-1.084L237,34l0.833-1h1.333l0.417-0.833l-1.25-1.208l0.417-1.542l0.833-0.917l0.75-1.062l1.417-0.104h1.417",
            "l1.333-1.083h2.25v-2.199h-2.083l-0.115-1.343l1.115-1.542l0.083-1.083l-1.198-0.417l1.198-0.583l-0.417-1.667l1.417-0.667",
            "l0.833-0.25v-0.625l3.25-0.438l1.917-0.271v-1.25h-12.583l-0.333-1.083h-2.5h-6.167v-2H213.5V5.666H189.5 z"
        ].join("")
    },

    NORONHA: {
        id: "noronha",
        timezoneId: "America/Noronha",
        shapes: [
            "M 214.667,5.666 214.667,10.833 231.167,10.833 231.167,12 237.5,12 237.5,5.666 z",
            "M 232.5,36.167 230.5,36.167 230.5,37.5 222.417,37.333 220.667,38.333 219.583,39.167 217.5,40.25 ",
            "214.667,41.188 214.5,154.051 216.167,154.301 217.625,154.301 218.5,155.426 218.5,160.051 216,163.051 214.125,165.051 ",
            "214.125,229.625 203.875,229.625 203.875,236.301 214.5,236.301 214.5,244.5 237.5,244.5 237.5,125.176 229.333,125.176 ",
            "229.333,115.188 237.5,115.188 237.5,91.375 220.688,91.375 220.688,79.25 237.5,79.25 237.5,45.333 232.5,45.333 z"
        ].join("")
    },

    AZORES: {
        id: "azores",
        timezoneId: "Atlantic/Azores",
        shapes: [
            "M 238.333,5.666 238.333,12.25 239.833,12.25 240.167,13.917 244.552,13.917 250.333,13.917 ",
            "253.667,13.917 254.5,14.125 254.5,15.438 253.667,16 249.667,16 246.417,17.219 246.417,21.875 245.667,23.25 246.75,24.051 ",
            "247.5,25.667 246.75,26.25 244.5,26.25 243.167,27.333 241.75,27.333 241.75,28.5 240.333,28.5 239.583,29.417 239.583,31.25 ",
            "240.5,31.562 240.5,33 239.167,33 238.333,34.5 238.333,36.167 261.5,36.167 261.5,5.666 z",
            "M 261.5,45.333 238.333,45.333 238.333,80.167 221.333,80.167 221.333,90.188 238.333,90.188 ",
            "238.333,116.833 230.5,116.833 230.5,125 238.333,125 238.333,244.5 261.5,244.5 261.5,216.5 251.333,216.5 251.333,203.5 ",
            "261.5,203.5 261.5,160.833 248.333,160.833 248.333,156.667 261.5,156.667 261.5,139.334 259.333,137.585 256.333,136.125 ",
            "254.375,134.375 252.75,133.75 251,131 248.667,129.609 247.167,128.5 246.5,127.5 246.5,123.833 246.5,122.833 244.552,122.833 ",
            "244.552,121.167 246.5,121 246.5,119.5 247.667,119 247.5,116.667 247.5,115.25 246.5,113.833 245.5,111.656 245.5,110.667 ",
            "246.5,108.333 248.5,106.875 249.667,105.167 250.75,104.125 252.75,102.656 243.333,102.656 243.333,92.333 248.667,87.083 ",
            "253.667,83.25 257.625,80.167 259.5,78.125 261.5,78.167 261.5,63.25 259.5,64 257.625,64 256.667,63.25 256.333,61.833 ",
            "257.625,59.875 256.667,59.333 257.625,58.125 258.667,57.25 259.833,57.25 261.5,56.167 z"
        ].join("")
    },

    GMT: {
        id: "gmt",
        timezoneId: "GMT",
        shapes: ["M262.75,5.666V37.25h-29.417v6.812h29.417v12.604l-1.25,0.583h-0.969v0.875h-2.156l-0.75,1.75l0.75,1.375 ",
            "l-0.75,0.625V62.5h2.906l0.969-1.167l1,1.042V75h8.875v-3l-0.312-1l-1.595-0.923L267.833,70l-1.208-1.125l-1.25-0.542V67.5 ",
            "l2.292-1.25c0,0,1.167,0.083,1.667,0s1.729-0.75,1.729-0.75h3.562l0.708-1.375l0.542-0.875l1.75,0.25l0.75-0.5L280,62.5l0.5-0.375 ",
            "l1-0.75L282.75,60v-1.792h2.875v-5.083L284.5,52.75l-1-0.75l-1-1h-1.083v-1.875L280,48.688l0.667-1.354l0.75-2l1.708-0.208 ",
            "l2.5-1.062V5.666H262.75z",
            "M 259.479,79.25 256.833,81.708 254.333,83.25 252.75,85.542 250.5,88 247.833,89 246.5,90.188 ",
            "245.5,91.375 244.667,92.5 244.667,101.667 252.75,101.667 252.75,103.125 250.75,104.125 250.167,106 249.5,106.875 ",
            "248.5,107.833 248.5,109 247.5,109.125 247.167,111.656 247.5,113.125 248.5,114.125 248.375,119 247.375,119.125 247.375,121 ",
            "246.5,121 246.5,122.833 247.25,123.125 247.25,126.25 249.25,128.125 251.25,129.25 252.5,130.176 253.25,131.375 253.375,133.5 ",
            "255.5,133.75 256.5,135.125 258.25,136.375 260.5,138.125 262.5,138.125 262.5,157.5 249.5,157.5 249.5,159.667 262.5,159.667 ",
            "262.5,204.334 252.75,204.334 252.75,215.501 262.5,215.501 262.5,244.5 285.333,244.5 285.333,139.334 283.375,139.334 ",
            "282.375,137.917 281.779,137.167 281.25,136.5 278.583,136.5 276.333,136.125 276.5,131.5 275.5,130.176 274.333,129.609 ",
            "274.333,128.167 275.667,127.834 277.5,127.667 277.5,126.667 275.667,125 274.333,123.5 273.167,122.5 274.5,121.5 ",
            "277.5,121.333 278.583,120.333 280,119.833 280,116 276.5,113.667 274.333,111.656 271.833,109.5 270.333,108.667 268.5,107.667 ",
            "265,105.5 262.667,104.333 261.5,103.5 259.479,102.5 259.479,99.5 260.5,99.167 261.5,98.5 263.333,98.333 264.625,97.667 ",
            "266.167,96.667 267.5,95.333 268.5,94.333 269.333,93.5 271.062,93.333 271.062,90.188 269.5,90 267.167,89.125 265.375,88.375 ",
            "264.5,88.375 263.625,87.125 261.5,87.083 261.5,85.542 262.667,84 261.5,82.208 261.5,81.708 262.333,81.167 262.333,79.25 z"
        ].join("")
    },

    CET: {
        id: "cet",
        timezoneId: "CET",
        shapes: [
            "M286.333,5.666v38.396L284.5,44.5l-1.464,0.833l-1.619,1v1.917l1.016,0.438l-0.266,2.146l2.167,1.667h2",
            "v6.687L283.875,60l-1.353,0.25h-1.106v2.25l-1.75,0.167l-1.083,0.583l-1.083,0.232l-1.167,1.352l-0.958,0.792l-0.75,0.625H271.5",
            "l0.125,1.375H266.5v1.196l3,0.679l1.75,1.25l0.906,1v0.875v3.125l-1.094,0.75h-8.938L261.5,75l-0.969,1.5H259.5l-0.021,1.375",
            "l1.052,0.292h3.094v3l-1.25,1.042l-0.125,3.333L264.458,88h1.958l0.75,1l1.5-1l2.396,2v2.625l1.094,0.75v1.25H269.5l-1.875,2",
            "L266.5,97.5l-1.5,0.875h-3.5l-1,1.125l-0.25,2.125L261.5,103l2,0.75l1.875,1l4.875,2.5l3,2.375l2.375,2.031L277,113.5h2.375",
            "l-0.125,1.375l0.75,0.875h1.417v3H280.5l-0.5,2v0.875h-5.25l-0.375,0.875l2.125,2.5l1.125,1h0.958v1.625l-0.958,0.875H275.5",
            "l-0.018,1.667l0.893,0.583v4l1.125,0.625h3.917l1.083,0.75l0.75,1.209l0.125,1.291h2.958V244.5H309.5v-43.625l-1.875,0.25",
            "L306.5,202.5l-1.375-0.25l-1.25-0.75l-0.875-1l-0.625-0.25v-1.5l1.125-0.125l0.125-1.125l-1-0.875l-0.375-1.125l-1-1.125l0.125-1",
            "l-1-1l-0.75-0.5l0.625-1.375l0.75-0.875h1.625l-0.25,1.125h3.125v-9.25h1.875v-6.125h2.125l0.875-0.375l3.125-0.125l-2.875-1.125",
            "l-1.125-1.125h-1.25v-6.25h4V164.5h-2.875v-2.625h-1v-3.5l-1-0.75h-1l-1-0.875l0.125-1.5l1.25-0.625L305.375,154l-0.125-0.625",
            "l1.25-0.75v-2.5l0.5-0.75h2.5l0.875-0.625l1-0.25l0.125-1.125l-1.375-0.875v-2.125l-0.625-0.625v-1.5l0.875-0.25v-3.5l1-1.166H318",
            "l-2.5-1.209l-2-2.375l-1.125-1.125l-1.25-1.25l0.25-1.875l-1-0.875L310.5,126h-1l-0.125-2.5l0.875-1.125l0.125-0.75l0.875-0.875",
            "h1.125v-5.5l-0.875-0.5l-2-1.125h-1l-1.125-1.125h-1.125l-0.5-0.844h-1.25l0.125-1.031h-2.375l0.25-0.875h-2v-1.25h-1.375",
            "l-0.75,1.25l-0.875,0.75h-1.125l-0.75-1l-1.125-0.75h-0.875l-1.125-1.125l-1-0.75h-1l-1-1.25l-0.76-0.375v-0.625l0.76-1v-5.25",
            "h-1.167V96.5L289,96.375l0.5-1.625l1.625-1l1.375-1.25l0.5,0.875l4.5-0.125v1.25h1.875l0.125,1.25l3.625,0.25v1.25l2.5-0.125",
            "v-1.792L306.333,94l2.834-1l1-0.167V88l-1.334-1.5l-1.333-0.958l-1-0.708l-0.333-1.583l-0.542-1.042l-1.125-0.5h1.798l1.327-1.542",
            "h0.875h1.875v-2h-0.75V75h-1.25l-0.625-1.375l-1.125-1v-1.25l0.75-0.875h1.25l1-1v-2.25l0.875-0.375V65.5l1.125-0.875v-2.958",
            "l0.75-1.417l-1.041-0.917l-0.667-1.125l-1,2.042h-1.5l-0.5-2.042l-0.5-0.958v-1.917c0,0-0.167-0.917,0.333-1.208s0.667-1,0.667-1",
            "l1.333-1v-1.958h-3.875h-1.25v-1.479l1.25-1.021l1.209-0.5l0.333-1L307,45.333l-0.5-0.667l2-0.667l1.5-0.333l1.5-0.167l0.875-0.833",
            "l0.959-0.167v-1l1.114-1.167h-2.997h-1.117l1-1.333l0.333-2.167l-3.167-0.667h-1L307,34.5l1.334-1.333l1.333,1.167h4.958",
            "L315.334,33l1.541-1l-0.708-0.833l-2.833-0.042L313,32.333l-2.166,0.167l-1.334-0.938v-7.188h23.875v-10.25H309.25V5.666H286.333z"
        ].join("")
    },

    EET: {
        id: "eet",
        timezoneId: "EET",
        shapes: ["M 310.5, 5.666 h 22.875 v 7.667 h -22.875 z",
            "M320.167,33H316v1.333l-0.667,1.833l-3.666,0.667l0.708,3.292l2.073,0.208l-1.114,2.167l-1.834,1",
			"l-2.167,0.562L308.5,44l0.125,4.125l-1.125,0.562v1.479h3.125v2.958H309l-0.75,1v1.75l1,1.125l1.125,0.25l1.125,0.958l0.875,1.167",
			"l1.459,0.875l-1.459,1v4.125H311.5l-1,1.5l-0.125,2.75H309.5v1.125l-1.125,0.75H307.5v1.428l0.288,0.782h2.837v2.04l0.875,1.333",
			"h-1.25l0.875,1.083l0.375,1.083l-1.625,0.917H308.5l-1,1.542V85l0.875,0.375l0.878,1.354h1.122l0.25,1.271h0.75v5.625H307.5",
			"l-1.125,0.5v3l-0.75,0.5h-2.5V96.5h-3.5l-0.125-0.75l-1.25-0.25v-1l-0.75-1.25l-5,0.25l-1,1l-1,1v1.25l-1,0.875v0.75l1,1.25v6.125",
			"l1,1.125h1l0.5,0.75h1.5v1.125h1l0.125,0.75h1.875v-0.875l1.438,0.188l0.438-1.438l1.125-0.125v1.25l0.875-0.125l1.125,1.375",
			"h0.625l1.5,0.875h1l0.625,1.031h1.25l0.25,1.219h1.875l0.542,1.125l1.333,0.75h0.875v-2.125h1v-2.25l1.25-1h15l0.875-0.042v-1",
			"v-1.667l-0.875-1l-0.125-1.542l-0.833-0.292l-0.042-1.708L329.5,101l0.125-2.333l2.709-0.167l1.833-0.667l-0.792-1.167",
			"l-0.875-1.333l0.875-0.667l3.125-0.333v-2.958h1.834v-1.188h1.166l1-0.188v-2.917h0.667l1.167-1.542h3.166v-4.375l-0.833,0.541",
			"l-0.333-1.542l-0.959-0.917H340.5l-0.833,0.917H334l-0.625-0.917V69.5h1.959L336,68.667h1.5l0.167-1.167l0.833-0.625l-1.125-0.625",
			"l-0.75-0.625H332.5l-1.375-1l-0.75-1.375h-0.75l-0.958-0.75L328,61.667l-1.625,0.833h-1.041l-0.834-0.667V60.25h1.167",
			"l-1.167-2.042l-1.333-0.958V56.5h-2.792l-1.125-1l-0.625-1.375l-0.25-1l-0.875-0.5l-0.625-2.458h1.375l0.125-1.479",
			"c0,0,0.25-1.188,0.75-1.188s1.375,0.125,1.375,0.125l0.75-1.25l1.375,0.125l0.75-1.167h0.875l0.375-1.271h-2l0.125-1.562",
			"l-0.812-0.75v-2.375l-1.562-1.062l1.562-0.562v-1.583h-2.312v-0.792l1-1L320.167,33z",
            "M318,137.334h-2.75v1.166h-3.75v3.75h1.25l-2.375,1.541l1.125,1.126l-0.461,2.165l1.336,0.543l1.25,0.625",
			"l-1,1.25h-2.25l-1,0.75h-1.75v2.375l-0.854,0.854v3.646l0.604,0.5h2v4.25L310,162.75h2.386c0,0,0.489,0.625,0.864,0.625",
			"s0,2.375,0,2.375l-0.812,0.812H309.5v4.812l1,1.125l0.842,1.53h3.158l-0.875,1.47l-1.239,0.625l-1.005-1.006H308.5v6.381h-1.729v9",
			"v1l-1.146,1.125h-4.375V194l0.688,0.688v0.812l1.031,1.031l1.531,0.719l-1,1.375l-0.375,1.25h1.25l0.5,1.875h1.75h2v-1.375h1.75",
			"V244.5h23v-70.875l1.5-0.5l1.625-0.5l0.75-0.75l0.875-0.5l1.25-0.875v-0.75l0.25-1.75v-4.625L338,163.25l-0.625,0.917H334.5",
			"l-0.125,0.958L332,165.75v-1.583h-2.625l-0.125-1.542l-0.625-0.75h-1h-1.25l-0.125-1.5l-1.875,0.125l-1-0.791l-1.25-1.334",
			"l-0.75-1.25v-1.875h-0.938v-2.875l2.188,0.25l1.125-0.875v-3.25h-2.375v-2.812l1-1.438l1.125-0.75l0.125-3.125l-1-0.875l-1-1.125",
			"l-1.188,1.125h-1.188l-0.75-0.875L318,137.334z"
        ].join("")
    },

    EAST_AFRICA: {
        id: "east_africa",
        timezoneId: "Africa/Dar_es_Salaam",
        shapes: ["M 334.668, 5.666 h 22.582 v 5.834 h -22.582 z",
            "M343.375,85.542l-1.361,0.423l-0.847,1.119H340.5V90l-0.875,1.375h-2.124l-1.001,1.292h1.001v2.167H336.5",
			"l-1.166,0.833V97.5l-1.167,0.333l-0.792,0.288l-0.874,1.378h-2.167L329.5,101l-0.875,1.125l0.042,1.708l0.902,1.147l1.888,0.752",
			"l0.918,1.517l-0.75,1.875l-1.125,0.208l-0.875,1.333l-1,0.989L328,110.667h-13.375v1.833l-0.791,1h-1.459v6.5l-1.125,0.75v1.417",
			"l-1,0.208L310.5,126l0.834,1.667l-0.123,1.689l1.164,0.353l0.125,1.166l0.75,0.75l2.375,2.125l0.75,0.5l0.25,1.875h1.5l0.5,1.209",
			"l0.75,1.041h1.062l1.062-1.041l0.875,1.041l0.666,1.489h1.584v3.627H323.5v1.217l-1,0.979v2.041h0.75l1.125,0.771v1.75h-0.625",
			"v1.904l-1.25,1.179h-1.25v3.042h1.125l-0.125,1.661h1l0.125,1.047l0.875,0.626h1.125l0.875,0.666h1.125h0.875v0.75h1.125v3.042",
			"h1.375c0,0,0.25-1.542,0.625-1.042s1.125,1.042,1.125,1.042h0.875l1.375-1.042l3.25,0.125l0.625-1l1,0.875l1,0.5v6.875l-0.75,1.5",
			"h-1.5l-0.75,1.375h-1.25l-0.875,1.5h-1V244.5h22.75v-80.333h-11v-5.084h11v-31.708h5v-4h-4.75V117l-1-0.833V115.5l0.875-0.75",
			"h3.125l0.875-1.25l0.875-1.125l1.375-0.719V110.5h-1l-1.25-1.25l-3,0.25l0.125-1h-0.75l-1.25-1h-1.125l0.125-1.125l1-0.75",
			"l0.875-1.5l-0.875-0.75l-1.188-0.812l-0.75-0.75l-1.294-1.074L353.5,99.5l-1.125-1l-0.75-0.75l-0.375-0.875l0.25-1.25h-0.875",
			"L350.5,94.5l-0.562-1l-1.688-1l-0.75-1.125h-1.375l0.25-1.188L347.75,90l-0.25-1l-1.125-1.917L345.5,86.5L343.375,85.542z"
        ].join("")
    },

    MOSCOW: {
        id: "moscow",
        timezoneId: "Europe/Moscow",
        shapes: ["M358.5,5.666v30.001l-1.25,0.667l-2.875-0.083l-0.5,0.5l-2.625,1.125l-0.75,0.792l-0.875,0.833h-1.5 ",
            "L347.5,38l-2,1.182l-1,1.318l-1-1.5l-2.25,1l-0.75,0.5h-1.75v2.125h-3.5v1L334.5,44.5h-1.125l-0.625-0.875l-1.375-0.125 ",
            "l-0.625-0.625l-1.125-1.25V40.25l-1,0.375l-0.625-1h-1.625L325.5,38.75V37.5h3.125l1,0.875l3.125,0.125l0.625-0.125L339,38 ",
            "l0.625-0.25l-1.5-0.875L335.25,36.5l-0.75-0.875l-3-0.25l-5.125,0.375v-0.875l-1-1.125l-0.875,0.875l-3.25-0.375l-1.375,0.875 ",
            "l2.625,1.625v1l-1.269,1.145L322.5,39.5v2.478l1.125,0.272v1.25l1,0.562l0.75,0.438l-1.125,0.833l-0.875,1.417l-1,0.625v2.792 ",
            "h-2.5L319.25,51l-0.875,1.125v1l0.875,1l1.25,1.5h2.875l-0.208,1.125L324,57.849l0.864,0.997l1.511,0.53v1.375v0.875h3.25 ",
            "l0.75,1.625c0,0,0.542,1.224,0.75,1.375s3.375,0,3.375,0l0.625,1h3.312l1.188,0.875l-0.25,1l-0.938,1l-0.812,1.25l-1.739,0.658 ",
            "h-0.761l-0.625,0.717v9.042h5.625l0.375-0.917l0.875-0.375l2-0.25l1.125,0.625l0.167,2.458h1.583l0.125,0.917h0.75L348.25,84  ",
            "l1-1.5l1.5-0.375l1,0.125c0,0,0.375,1.25,0.75,1.125s1.125-1.25,1.125-1.25l1.125-0.75l-0.25-1l-1.75-1l-1.375,1.5L350.25,80.5 ",
            "l-0.875-1l1-0.75v-1.125l-1-0.875V75.5H348.5l-0.125-1.625h1L349.25,72.5c0,0,0.625,0,1,0s1.375-0.875,1.375-0.875v-2.25H349.5  ",
            "l-0.25-0.875l-1-1.25l0.25-0.75l0.625-0.875L350.5,64.5h1.75l1.25-0.875l2-1.125l1-1l0.75-1v-1.875l2.125-0.125  ",
            "c0,0-0.375-1.875,0-1.875s1.375,0,1.375,0v-2.5V52.5l-0.875-1.125L359.5,50.75l-0.125-1.125h-2.125l-1.125-0.875v-1.375h7.5V46.5 ",
            "h5.25l0.875-0.875v-3.5c0,0,0.125-0.875,0.625-0.875s1.062-0.625,1.062-0.625h2.188V39.75h1.75l0.875-1.25l1.312-0.125V37.5 ",
            "l1.562,0.125l0.625-1.25l3.375-0.125l-0.625-1.125l-1.375-0.5l0.25-2.75l1.25-0.875V5.666H358.5z",
            "M 364,104 363.25,105 360.334,104.5 360.334,105 358.375,105.125 357.75,106.5 357.75,108.5 ",
            "358.5,108.5 360.875,108.5 360.875,109.292 362.5,109.292 363.625,110.5 363.625,112.667 362.667,113.5 361.5,114.75  ",
            "360.5,114.75 359.667,115.5 357.75,115.5 357.5,117 358.5,117 358.5,122.5 363.625,122.5 363.625,128.625 358.5,128.625  ",
            "358.5,159.083 347.375,159.083 347.375,163.625 358.375,163.625 358.375,244.5 382.25,244.5 382.25,108.5 380.25,108.625  ",
            "380.5,107.75 377.375,107.75 377.625,106 375.625,106 374.75,107.25 370.25,107.25 370.25,106.5 365.5,106.5 365.5,105.125 z"
        ].join("")
    },

    UZBEKISTAN: {
        id: "uzbekistan",
        timezoneId: "Asia/Samarkand",
        shapes: ["M 383.125,5.666 h 23.375 v 5.834 h -23.375 z",
            "M 380.5,58.208h-2.938l-0.896,1.292l-3.041-0.646v1.979H372.5l-1.062,1.833l1.062,2l-1.062,1.583H368.5",
			"v-1.917h-8.834v-0.667l-5.479-0.428V64.5l-1.999,1l-3.062,0.125v2.719h1.625l0.875,1.031l0.562,1.125v1.167h1.979l1.334-1",
			"l1.75-0.833l1.25,0.667l2.833,0.167l0.167,1l1.25,0.708l-0.125,1.5h-7.25v0.75l0.75,0.875l1.125,1.125l1.125,0.875v1l1.125,1.125",
			"v3.125l0.875,1.25l-0.125,1.542h2.125V84.75l4.75-0.125l0.5,0.917h2.875l-0.125,0.958h1.062l0.812,0.875l1,1.625h1.125l0.875-1",
			"h1.125l1-1.5l1.25-0.958V84.75h3.625v0.792h2.25l1.125-0.917L386.75,84l0.75-1l1-0.5h0.625v-1.125l1.875-0.75l-1.375-1l-1-0.875",
			"l-2-0.25l-1,1.25l-1.375,0.625h-2l-0.875-0.75l-1.25-1l1.25-1.25V76.75l1.75-0.125v-1l-1.5-1.125v-0.896l-1-0.854l-1.375-1.125",
			"l-2.875-0.25l-0.75-0.625l-0.25-1.375l1.875-0.625l-1.75-1.25l-1.25-1c0,0-0.25-1.625,0.125-1.875s0.75-1.125,0.75-1.125h3.125",
			"v-1.125l1.375-0.875l0.75-1L380.5,58.208 z",
            "M 394.625,87.25H391L390.5,88v2l-0.875,0.188v1.188L388.5,92.5l-1,1l-1.125,0.75v1.625L385.5,96.5h-3.25",
			"l-0.75,0.875v2h-8.625l1.5,1.5h1v0.75l1.25,0.75l-2.25,0.75l-1,0.875l-0.5,1h1.375l1.125-1l0.375,1h5l0.75,0.125l0.875,1.75",
			"l1.375-1.25l0.625,1.25h4.125l-0.125-1.375l-0.625-0.75l-1.125-0.75l-1-1.25l0.75-1v-1l1.5-0.5l0.625-0.75l0.75,1l1-0.75L391,98.5",
			"l1.5-0.875l0.875-1.125c0,0-0.25-1.125,0.125-1s1.125-0.875,1.125-0.875v-1.25l-1-0.75L393.5,90h1.125l0.625,0.188h2.125L398.5,90",
			"v-2h-2.125h-1.5L394.625,87.25 z",
            "M 387.75,108.625h-2.625l-0.562,0.562H383.5V244.5h22.875V119l-1.458,0.625l-0.917,0.75l-0.25,1.875",
			"l-1.125,1.875l-0.375,1.051l-0.625,0.991v2.583l-1,0.959l-0.25,2.416l-0.75,1.375h-1.375l-1-2l-0.75-3.125l-1.25-2.208v-1.917",
			"l-0.75-2.125c0,0-1.125-0.625-1.125-1.25s-0.125-2.375-0.125-2.375l-1.125-0.875v-5l-0.5-0.969l-0.125-0.781l-1.375-0.625",
			"l-0.5,1.406l0.25,0.844l-0.375,0.625h-1.25h-0.5l-0.875-0.875l-1.625-0.594l0.438-1.351l1.688-0.931L387.75,108.625z"
        ].join("")
    },

    BANGLADESH: {
        id: "bangladesh",
        timezoneId: "BST",
        shapes: ["M 407.501, 5.666 h 23 v 5.834 h -23 z",
            "M 389.125,26.875 387.25,26.875 387.25,28 386.334,28 385.375,29.25 384.25,30.125 384.25,31.667 382.625,31 ",
            "381.375,31 380.5,31.667 380.5,33.875 381.125,34.625 382.5,35.125 383.125,35.125 383.125,36.25 382.25,36.282 ",
            "381.5,37.5 379.125,37.625 378.334,38.667 377.562,39.333 375.375,39.75 374.667,40.333 372.875,40.625 372.875,41.667 ",
            "371.438,41.667 370.834,41.667 370.834,43.667 369.75,43.667 368.875,46.5 368,46.5 368,47.667 363.625,47.375 362.5,48.5 ",
            "360.5,48.5 360.5,51.375 361.5,53.125 361.5,55.333 360.75,56.625 360.5,58.208 358.5,59.167 358.5,60.5 357.25,61.167 ",
            "357.25,62.667 359.5,62.667 359.5,63.667 361.5,63.667 362.167,64.333 363.625,63.5 367.667,63.5 368.5,64.333 ",
            "371.438,64.333 370.062,62.667 370.062,61.667 371.688,61.667 372.5,60.833 372.875,59.333 373.625,58.854 375.375,58.208 377.165,58.781 ",
            "377.562,57.5 382.25,57.333 383.271,56.312 385.688,56.312 385.688,58.208 384.334,58.208 383.5,58.208 381.5,59 381.5,60.333 ",
            "380,61 379.625,61.5 379.75,63.667 378.834,64.333 374.375,64.625 374.25,66.5 375.5,67.5 377.562,67.5 ",
            "377.25,68.75 377.562,70.667 379.75,70.667 379.75,71.667 382.25,71.667 382.25,73.604 383.5,73.604 383.5,75 ",
            "384.5,75 383.125,76.625 381.375,77.375 381.5,79.25 382.25,79.25 385.625,79.25 386.625,78.5 387.334,77.083 ",
            "388.5,77.083 388.625,78.75 389.625,79.625 391,79.625 391,80.625 389.645,81.167 389.834,82.208 392.334,82.208 ",
            "393.375,81.5 394.125,80.5 397.625,80.5 398.5,79.5 399.375,79.5 400.375,78.5 402.125,78.5 403.5,77.5 ",
            "403.5,72.625 406.375,72.625 406.375,71.75 407.25,70.625 407.25,69.625 408.625,68.5 409.375,69.5 410,70.5 ",
            "411.5,69.875 411.5,68.375 412.625,68.375 413.812,67.5 413.812,66.75 411.25,66.75 410.625,65.625 409.375,64.5 ",
            "405.125,64.5 404.25,63.625 403.375,64.5 402.375,64.5 402.375,63.625 401.25,62.25 400.5,61.5 399.625,60.75 ",
            "398.5,59.5 397.5,58.208 396.375,58.208 395.354,59.229 394.375,60.375 390.375,60.375 389.875,59.625 388.375,58.208 ",
            "387.375,58.208 387.375,55.625 388.375,54.542 387.541,53.708 387.541,52.375 388.646,51.271 389.75,51.271 ",
            "389.75,52.5 392.625,52.5 393.438,51.688 394.938,51.688 395.75,52.5 396.125,53.75 397.625,53.875 398.5,52.625 ",
            "398.5,51.375 399.625,50.125 400.625,49.25 400.375,47.5 404.875,47.5 405.125,46.5 406.375,46.5 407.375,47.625 ",
            "410.75,47.625 410.75,46.25 411.5,45.875 411.5,44.833 410.5,43.75 410.625,42.5 409.625,41.625 409.625,40.5 ",
            "408.375,39.5 407.375,38.75 407.375,35.875 406.375,35.125 403.334,35.333 403.334,34.5 402.5,33 398.5,32.167 ",
            "395.875,31.125 394.25,30 392.625,31.375 393.25,34.25 394.938,34.25 395.5,35.125 396.625,35.125 397.375,34.25 ",
            "399,34.25 399.5,35.125 400.25,35.875 400.625,37.625 398.375,37.5 397.625,36.5 394.375,37 393.375,38.25 392.75,38.25 ",
            "391,39.625 389.625,39.625 389.625,40.75 386.875,40.375 386.375,39.625 385.625,39.625 385.531,38.625 386.75,37.625 ",
            "388.375,37.25 389.25,37.75 390.25,37.75 391.5,37.125 391.5,35.625 390.375,35.625 390.375,33 389.375,31.375 ",
            "389.375,30.25 390.375,30 389.5,28.625 z",
            "M 387.501,152 h 4.833 v 7.083 h -4.833 z",
            "M 422.667,102.5 421.501,100.5 418.834,100.5 417.667,101.5 417.667,103 416.334,103 416.334,106.875 417.334,106.875 ",
            "417.75,111.125 415.75,112 414.125,113.875 413,114.625 411.501,114.625 410.375,116.25 409.334,116.25 ",
            "408.751,118.011 407.167,118.011 407.167,244.5 430.834,244.5 430.834,145.688 429.334,144.708 428.501,143.491 ",
            "427.25,141.983 426.501,141.983 426.334,140.5 425.334,139.667 424.501,138.5 420.334,138.5 420.334,118.5 ",
            "425.334,118.5 426.25,119.75 427.25,119.5 427.25,134.25 428.668,133.75 429.668,132.167 430.834,131.5 ",
            "430.834,120.5 429.75,119.375 429.334,118.5 428.834,117.333 426.25,117.333 425.375,117.333 425,115.875 ",
            "425,114.625 424,113.5 424.25,112.25 425.375,111.656 425.375,110.625 424.875,109.375 423.625,110.125 ",
            "422.5,110.625 422.5,108.625 420.375,108.625 420.375,106.875 421.375,106.875 422.375,106.875 422.5,105.25 ",
            "419,105.25 418.5,104 418.5,102.583 z"
        ].join("")
    },

    WESTERN_INDONESIA: {
        id: "western_indonesia",
        timezoneId: "Asia/Jakarta",
        shapes: ["M 431.501, 5.666 h 23.167 v 5.834 h -23.167 z",
            "M 394.812,51.688 392.979,52.146 392.979,53.5 390.167,53.5 389.75,52.5 388.334,52.594 388.334,53.5 ",
            "389.334,54.542 389.667,55.5 388.334,55.5 387.375,55.625 387.375,57.167 388.334,57.167 389.354,58.188 ",
            "391.094,58.188 391.094,59.667 394.812,59.667 394.812,58.208 396.375,58.208 397.5,58.208 397.5,55.677 ",
            "396.125,53.75 394.938,53.5 z",
            "M 443.667,108.625h-1.333h-4l-1.459,1.875v1.156l-1.208,0.844l-1.333,1l-1,1.25h-1.667v2.75l0.834,1 ",
            "l1.166,1l-0.333,1.333h-0.833v1.833h1v1.583l1,0.926v2.324l-1.126,0.625l-0.041,1.584l-0.959,0.458l-0.708,1.333l-2.684,1.75v1 ",
            "l-1.733,0.917h-3.916v2l2.6,3l3.848,4.833l1.053,0.688h0.667V244.5h22.833v-93.781h2.791c0,0-0.876-0.844,0-1.031 ",
            "s1.376-0.562,1.376-0.562v-2l0.833-0.792l1-2.542l1.791-1.808h-1.624h-1.5l-1.042,2.725h-1.792l-0.5-0.917l-1.166,0.917h-3v-1.625 ",
            "h-1.168v-1.1h2.292l0.792-0.816l0.958-0.792v-19h-1.75l-1.125-1.125h-1.75l-0.25-0.375l-0.875-1.25l-1.25-0.625v-6.344 ",
            "l-0.875-1.031h-1.125v-1.25L443.667,108.625z"
        ].join("")
    },

    CHINA: {
        id: "china",
        timezoneId: "CTT",
        shapes: ["M 488.5,68.5l-0.75,1h-2.375l-1.125-1l-0.875-1.125L482.5,67.5l-0.625-1h-2.625l-0.688-0.625V64.25",
            "l-1.312-0.5l0.25-1.5l-0.875-0.875l-1.375-1h-2.26l-0.865,0.875h-2.75l-0.874,1.5l-1.084,0.875L466.5,64.25v1.25l-1.188,1H460.5",
            "l-0.25-1h-3l-0.875,1H453.5l0.125,1h-4.208l-0.792-1h-1.25l-0.875-1.125h-3.771l-0.354,1.25L441.75,67l-1.25-0.25l-0.25-1.25",
            "l-0.75-0.125l-0.75-0.875l-1.875-1.125l-1.25,0.25l-1-1L433.5,62.5v-1l-1-0.75l-1.666-0.625v-0.458H428.5",
            "c0,0-1.375-1.626-1.25-1.459s1.25-0.833,1.25-0.833l0.875-0.75l1.459-0.948l-0.333-1.135c0,0-0.249-1.292,0-1.292s1.001-0.875,1-1",
            "s0.624-1.125,0.624-1.125h4.125l1-0.375l4.25-0.25l0.875-0.625l0.709-1.125v-2.375c0,0,0.791-0.5,1.291-0.75",
            "s1.125-0.792,1.125-0.792l0.125-1.583l-1.25-0.75l-0.625-1l-0.125-1l0.75-0.875c0,0-1.832-1.125-1.291-1.125",
            "s1.291-0.875,1.291-0.875l0.875-1.125l1.25,0.125l1-0.875V34.5l1-1.5h1l0.5,1.5l0.5,0.75l1-0.75V33h0.625h1.375l1.168-2",
            "c0,0,0.457-0.375,0.832,0s0.875,0,0.875,0l-0.625-1l-1.082-1.375h-1.293v-1.188L454.668,26h1.707c0,0,0.25,1.25,0.75,1.25",
            "S458.5,27,458.5,27l1.875-0.125c0,0,0.625,0.375,1,0.562s6,0,6,0S467.625,27.5,468,28s2.375,0,2.375,0h2l0.875-1h5.5V5.666H455.5",
            "v16.209h-7l-1,1.25l-1-0.125h-1l-1.125-1.938H438v1.062h-1.125l-0.625,1.107l-3.875-0.107V24.5h-1.874l-1.168-1.5l-1.083-1h-1.75v1",
            "l-3-0.25l-0.166,1.75h-6.584v0.562h-2.938v2.562h-9.062v1.125h1.625l0.5,0.5V31l-0.375,1l-0.875-0.875L404.75,30h-3.375",
            "c0,0,0,1.125,0,1S402,31,402,31l1.375,2l-0.041,1.5h4.167l0.874,0.875v2.875h1.125v2.25l1,0.75l0.125,1.25l0.5,0.625l1.125,0.938",
            "l0.125,1.812H411.5l-0.125,2l-0.875,0.75h-1.125l-0.875-1l-3.625-0.125l-0.375,1.125h-3.964v1.028l1.089,0.722l-1.089,0.75",
            "L400,51.688h-0.625L398.5,50.25h-1l-1.25,0.875v1.625l1.375,1.125l-0.125,0.667l1,1.135v1.698l-1,0.833l1,1.292h1.125l1.125,1",
            "l0.312,1.562l0.511,0.583l0.802,0.98h3.25l0.75-1.25l0.792,1.25h2.458l-0.25,0.875l1.314,1.242h0.686l1-1.117l1.438,1.117v1.008",
            "h2.688v0.75l-3.329,0.473l-0.546,1.527l-1.56,0.556L410.6,70.25l-0.225,1.5h-1.25L408.5,70.5h-2v1.089l0.667,0.911h1.583l0.5,1.104",
            "L408.5,74.5h-2.125v-0.896h-1.458L404.25,74.5v0.75l1.375,1.125l-1.25,1l-1.041,0.875l-0.959,1.375l-0.125,1.125h-1.375h-1.75",
            "L398.5,81.5l-1,0.708h-1.25h-0.75h-1.25V84h1.25v1.542l1,1.708h1l1,1.75h1.875v-1.75h1.125l0.75,1.75l1.084,0.5l-0.834,1.125V93.5",
            "l-1.25,0.875l1.25,1.25h0.834l0.791,0.75l0.792-1l1.458,1h0.792l1.333,0.688l1,1.688l0.75,0.625l1.375,1.125h5v-1.375H419v1.375",
            "h4.334v-1h1.541v-0.875l1.5-1h3.125v1.25l0.125,0.625h2.875v2.125h1V104l-1,0.625v0.875h-1v2h0.875l1,1.125l0.875,0.875v0.875",
            "l1,1.281h1.125l1.125-0.781l-0.625-1.5l1.459-0.75l0.666-1h2.375v1h3.75l0.25,0.75l1.125,1.25l2.125-0.25l0.792,0.875l3.083,0.406",
            "l0.625-1.406l1.625-0.125l0.75,1.531v31.427l1,0.408c0,0,1.009-0.04,1.459,0s0.848-0.999,0.848-0.999l0.568-1.242v-0.625l1.25-1",
            "V138.5h3.125v1.125l-1.625,0.625v1.733l-0.569,0.574l-0.056,0.935l-1.283,0.597l0.408,1.599l-1.291,0.646l0.041,2.792h-0.874",
            "l-0.542,1.594l1.416,0.844l-0.042,1.188h-2.208l-0.458-1.562h-1.292V244.5h23.25c0,0-0.25-46.875,0-46.875s3.25,0,3.25,0v-27.5",
            "l-1.375-1l-1.25-1h-3.625l-0.375-5.792l-0.875-1h-0.75v-1.125l0.75-0.499v-8.99l-0.75-1.031v-0.562h1.875c0,0-0.25-2,0-2",
            "s0.75-0.792,0.75-0.792v-1.625l1.125-1.217v-1.508h1.188v-32.358h-8.188v-8.232h2.615V81.25l1.385-0.625l1.25-0.375l0.75-0.75",
            "l1.25-1l2.625-0.125l1-1l0.625-1l2.375-0.5v-1.25l1.125-1.25l0.875-1.25l2,0.125l1.25-0.5v-1l1-1.125V68.5H488.5z M424.5,65.812",
            "h-1.125v0.812h-2.75v0.75H418v-2.5h1.001V64.25v-0.875h3.374v-1h2.125V65.812 z"
        ].join("")
    },

    JAPAN: {
        id: "japan",
        timezoneId: "Asia/Tokyo",
        shapes: ["M 479.667,5.666 479.667,25.062 502.667,11.5 502.667,5.666 z",
            "M 444.94,36.898 445.5,44.833 444.94,46.667 443.084,47.5 443.084,50.5 442.375,51.688 440.834,51.688 440.834,50.539 ",
            "439.812,51.688 437.834,51.688 437.834,53.667 435.25,53.667 435.25,52.5 432.501,52.5 431.501,53.667 ",
            "430.834,55.677 430.501,57 429.375,56.625 428.5,57.375 428.5,58.208 429.501,59.667 432.167,59.667 ",
            "432.5,60.75 434.625,62.625 437,62.625 437.88,63.978 438.75,64.5 440.501,64.5 440.501,65.5 442.729,65.5 ",
            "442.136,64.906 445.5,64.906 446.297,65.703 447.5,65.703 447.5,64.833 448.562,63.771 449.542,62.792 ",
            "453.334,62.667 454.668,61.667 456.501,61.833 457.959,60.833 457.959,59.667 457.959,58.208 460.667,58.208 ",
            "462.125,58.208 461.334,56.667 460.334,56.667 459.334,55.677 459.334,54.542 463.167,54.542 463.167,51.688 ",
            "466.5,51.688 466.5,51.301 465.375,49.833 463.334,50.333 462.125,49.667 460.667,48.5 457.959,48.5 ",
            "457.959,49.667 456.334,50.5 455.501,51.301 454.668,51.301 453.334,50.333 452.501,51.688 451.334,51.688 ",
            "451.167,50.167 452.501,48.667 454.668,47.667 453.334,46.5 451.501,45 450.834,45 449.417,44.833 449.417,42.333 ",
            "448.625,41.5 447.5,41.5 446.5,39.333 z",
            "M 484.251,77.167 482.896,77.167 481.323,78.667 480.5,79.5 479.75,79.5 478.562,78.456 477.375,79.5 476.667,80.5 ",
            "474.375,80.625 474.375,102.5 472.042,102.5 472.042,108.625 479.75,108.625 479.75,140.5 480.5,142 481.25,141.983 ",
            "482.896,145 482.896,145.688 481.25,146.333 481.25,148.125 481.323,149.125 482.168,149.688 482.896,151.562 ",
            "481.323,152.625 477.375,152.625 477.375,152 475.375,151.562 475.375,159.083 477.375,159.083 477.375,156.989 ",
            "479.75,156.989 479.75,158.083 481.323,158.083 481.323,156.989 484.251,156.989 484.251,160.083 476.667,160.083 ",
            "477,161.333 476.667,163.25 476.667,165.167 478.146,166.833 479.25,168.125 479.75,169.167 481.375,170.125 ",
            "482.896,170.125 484.251,168.5 482.896,167.333 482.896,165.167 482.896,164.167 482.896,163.25 484.251,163.25 ",
            "484.251,164.167 485.292,164.167 486.583,163.25 486.583,162.333 487.875,163.25 489,164.167 491.375,164.167 ",
            "492.5,163.25 495,163.25 495.833,165.167 494.625,166.5 494.625,167.25 495.833,169.167 496,171.125 494.667,171.125 ",
            "494.667,172.5 496,173.5 497.833,173.5 497.833,171.333 499.5,171.333 500.5,173.75 501.375,172.875 501.375,171.167 ",
            "501.293,164.146 500.667,163.25 500.667,150 501.293,148.5 502.167,146.333 503.667,145.688 503.667,135.5 ",
            "492,135.5 492,99.667 503.625,99.667 503.625,82.208 505.75,80.667 506.833,79.833 508.5,78.5 509.5,77.167 ",
            "510.334,77.167 511.376,77.167 511.376,75.5 510.334,74.333 508.5,74.333 507.667,72.667 505.75,72.667 ",
            "504.5,72.667 503.625,71.5 501.5,71.5 501.293,72.333 499.333,73.604 498.333,74.333 497.5,75.5 496.333,76.333 ",
            "495.25,77.333 494.5,78.667 485.292,78.667 z",
            "M 479.75,197.625 479.75,244.5 502.5,244.5 502.5,208.25 500.5,208.334 499.667,207.083 499.25,205.5 499.25,204.25 ",
            "497.5,203.25 496.625,204.25 493.667,204.25 493.667,203.167 492.375,202.25 490.5,202.25 490.5,200.334 489.75,199.5 ",
            "489.75,198.188 487.875,198.188 485.292,197.625 484.251,197.5 481.25,197.5 z"
        ].join("")
    },

    AUSTRALIA_ACT: {
        id: "australia_act",
        timezoneId: "Australia/ACT",
        shapes: ["M 503.625,5.666l-0.958,5.834l-0.792,0.467l-2.506,1.478l-2.191,1.292l-3.345,2.482l-3.333,2.375",
			"l-3.5,2.281L485,23l-2.333,1.125h-1.41v0.938L479.667,26v1.25l-1.104,1.417H477v-1.229l-3.75,0.438l-1.208,0.792h-10.875v-1.229",
			"H458.5v0.938l-1,1.625v1h-2l-0.832,1l-1.168,1h-1.375l-0.625,1.5l-1.346,0.231h-0.779h-1L447.5,35.75v1.148h-1.125v4.477h1.125",
			"l0.875-0.875l0.75,0.875l1.375,0.875v0.875l1.892,2.604l0.942,0.771h1.334l0.957,0.875l-0.957,1.125H453.5l-1.399,0.617v1.258",
			"h3.149l0.875-0.875L457,48.625l1.5-1.125h1.625l0.542,1h1.458v1.167h4.375v0.708l0.625,0.926h1.5v2.324H466.5l-0.875-1.125",
			"l-1.375-0.25v2.25h-1.083l-0.542,1.125h-1.5l0.209,1.042l1.291,0.708l-1,0.833h-0.958L459.75,59.5h-1.25v2h-3.25l0.25,1h-0.832",
			"v0.875h-6.105l-0.458,0.854l-0.604,0.604l1.125,1.667h6.75l1-0.797h0.875l0.75-1.328h1.625l0.625,1.125h5.375l0.875-1.25",
			"l0.917-0.625l0.478-0.386l0.606-0.489l0.874-1.5l1.125-1.125l1.542,0.375h0.948l0.51-1.25h2.625v1.73l0.75,0.645l1.375,0.75",
			"c0,0-0.062,0.125,0.125,1s1.292,1.125,1.292,1.125v0.75l1.708,0.453L482.5,66.5l0.875,0.875h2l1.125-0.75l2-0.125l0.75-0.797",
			"l1.25-1.078l1.209-0.875V62.5l-1.209-1l-1.125-0.875l-0.75-0.875l-0.125-2.375h-1.125v-1.25l1.125-0.375l0.125-1.375l-1.375-0.625",
			"v-0.875l1-0.125h0.875v-1.449h4.625V50.5h3.75l0.75-1l1-0.875v-1.5V46.5h1.125v-2.438h-3.5v1.562h-1.625v-1.562h-5.75",
			"l-0.125-0.938h-1L488.5,40.5l-1.25-1h-0.75l-0.75-1L485,37.625l0.75-0.727l0.75-1.273l-1.125-1L484,33.75l1.625-1.75l1.75-2h1.5",
			"l0.25,2h2.042v-1.75h2.333c0,0-0.375,2.125,0,1.75s4.875,0,4.875,0l-0.5-1.75l0.75-0.375h2h2.042V28h7.708v1.375h5.375l0.5,0.625",
			"h2.5l0.375,1.125l1,0.875h1.25h5.25V5.666H503.625 z",
            "M 526.625,65.703 504.5,82.5 504.5,100.5 493.25,100.5 493.25,128.625 526.625,128.625 z",
            "M 503.667,140.375 503.667,146.333 502.5,147.125 502.5,149.125 501.293,149.688 501.293,163.25 502.5,163.25 ",
            "502.5,171.5 501.375,172.875 501.375,173.75 500.5,175 499.375,175.25 498.5,174 497.833,173.5 496.625,173.5 ",
            "496.625,179.375 497.375,180.209 498.25,180.209 499.625,181.5 500.146,182.25 502.5,183.5 504.625,184.375 ",
            "508.625,187.625 509.375,188.75 510.334,190.375 510.5,191.75 511.376,191.75 511.376,192.875 510.334,192.875 ",
            "510.5,194.75 509.625,195.875 509.375,197.5 508.625,198.5 508.25,199.375 506.625,199.375 506.625,200.5 ",
            "501.293,200.5 501.293,207.375 503.5,207.375 503.5,244.5 526.375,244.5 526.375,163.125 525.5,162.333 ",
            "524.375,161.333 523.25,160.708 522.375,160.708 521.418,160.083 522.375,158.625 523.25,158.036 524.416,156.25 ",
            "525.5,156.104 525.5,155.25 526.625,155.25 526.625,146.333 522.375,146.333 522.375,140.375 z"
         ].join("")
    },

    NEW_ZEALAND: {
        id: "new_zealand",
        timezoneId: "Antarctica/South_Pole",
        shapes: ["M 551.501,5.666V11.5l-2.129,0.661l-1.284,0.751l-9.921,6.681l-9.333,5.469h-1.5v6.062h-4.916V32h-3.751",
            "l-2.166-1l-0.876-1.125l-2.25-0.5l-6.374,0.5L505.75,31l-1.416,1.285v1.382v1.167l-0.709,0.667v4l-0.958,1.167V41.5l0.958,1.625",
            "l-0.958,0.938L503.36,45h5.14l1,1.5l0.553,0.552v2.615l-0.693,0.693h-5.665v8.307h0.807l1.249,0.833c0,0,0,6.239,0,6.203",
            "s1.918,1.672,1.918,1.672h-1.334h-1.333l-0.667,1.125v2.167l1.416,0.833l2.75,2.167h2.876V74.5h1.999l13.25-10.625h11.209",
            "l1.084-2.208V60l2.583-5.458l0.167-2.042h9.833v48h-17.834v47.229h17.834V197.5l-11.334,12.583v21.584h11.334V244.5h23.833v-86.417",
            "h-11.667V115.5h11.667V96.333h-12v-37h4.167L581.667,45v-9.333l-12-11.167h-6.166V5.666H551.501 z"
        ].join("")
    }
};

export default timezones;
