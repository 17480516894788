/** -------------------------------------------------------------------------------------------------------------------
 * VideoDetails
 * A component that render the details of a video
 *
 * @examples
 *
 *  ```jsx
 *    <VideoDetails
 *      video={video}
 *      deleteLabel="Delete video"
 *      onClick={() => console.log("Video clicked")}
 *      onDelete={() => console.log("Deleting video...")}
 *    />
 * ```
 *
 *  @component VideoDetails
 *  @import VideoDetails
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/


import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "../../../components/shared/button";
import Rating from "../../../components/shared/rating";

import {
    formatFileSize,
    formatDurationInMinSecs
} from "../../../utils/numbers";


class Video extends PureComponent {

    _renderSubtitle() {
        const {
            i18n: { getString },
            video: { size, metadata: { assignments }}
        } = this.props;

        const formattedSize = formatFileSize(size);

        if (!Array.isArray(assignments)) {
            return (
                <React.Fragment>
                    <span className="size">{formattedSize}</span>
                    <span className="violator inline">{getString("videos.unassigned")}</span>
                </React.Fragment>
            );
        }

        const assignmentList = (assignments.length === 0) ?
            <li className="video-assignment-item">
                <span className="violator inline">{getString("videos.unassigned")}</span>
            </li> :
            assignments.map((item) => (
                <li className="video-assignment-item" key={item.objectId}>
                    {item.objectName}
                </li>
            ));

        return (
            <ul className="video-assignment-list">
                {assignmentList}
            </ul>
        );
    }

    render() {

        const {
            video,
            className,
            showDeleteButton,
            deleteLabel,
            onClick,
            onDelete,
            i18n,
            showDuration,
            showRating
        } = this.props;
        const {
            id,
            title,
            URL,
            thumbnail,
            duration
        } = video;

        return (
            <li className={classNames("video-item", className)}>
                <a
                    href="javascript:void(0);"
                    data-id={id}
                    data-url={URL}
                    className="thumbnail"
                    title={title}
                    onClick={() => onClick(id)}
                >
                    <img src={thumbnail} />
                    <span className="video-mask" />
                </a>
                <dl>
                    <dt>Title</dt>
                    <dd className="title">{title}</dd>
                    <dt>Subtitle</dt>
                    <dd className="subtitle">{this._renderSubtitle()}</dd>
                    {showDuration &&
                        <React.Fragment>
                            <dt className="duration">{i18n.getString("generic.duration")}</dt>
                            <dd className="subtitle duration">{formatDurationInMinSecs(duration)}</dd>
                        </React.Fragment>
                    }
                    {showRating &&
                        <React.Fragment>
                            <dt>{i18n.getString("generic.rating")}</dt>
                            <dd className="rating">
                                <Rating average={0} />
                            </dd>
                        </React.Fragment>
                    }
                </dl>
                {showDeleteButton && <Button
                    className="delete-button icon icon-resetsolid"
                    onClick={() => onDelete(id)}
                >
                    {deleteLabel}
                </Button>}
            </li>
        );
    }
}

Video.propTypes = {
    video: PropTypes.object.isRequired,
    className: PropTypes.string,
    showDeleteButton: PropTypes.bool,
    showDuration: PropTypes.bool,
    showRating: PropTypes.bool,
    deleteLabel: PropTypes.string,
    i18n: PropTypes.object,
    onClick: PropTypes.func,
    onDelete: PropTypes.func
};

Video.defaultProps = {
    showDeleteButton: true,
    onClick: () => {},
    onDelete: () => {}
};

export default Video;
