/** -------------------------------------------------------------------------------------------------------------------
 * AllVideosView
 * A component that renders the subview for showing all the videos for the current user
 *
 * @examples
 *
 *  ```jsx
 *
 *    <AllVideosView
 *      videos={videos}
 *      i18n={i18n}
 *      onVideoActions={this.onVideoActions}
 *    />
 * );
 * ```
 *
 *  @component AllVideosView
 *  @import AllVideosView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/


import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Video from "./video-details";

import {
    ASSIGN,
    DELETE,
    PLAYBACK
} from "../../../store/reducers/videos/videos.types";

import "../videos.scss";


export class AllVideosView extends PureComponent {

    constructor(props) {
        super(props);
    }

    _renderVideoList() {
        const {
            videos,
            i18n
        } = this.props;

        return (videos.length === 0) ?
            (<div className="no-content">
                <p className="typography-body info">
                    {i18n.getString("videos.noVideosYet")}
                </p>
            </div>) :
            (<div className="video-list-wrapper">
                <ul className="row item-list video-list all-videos-list">
                    {videos.map((video) => {
                        return (
                            <Video
                                key={video.id}
                                video={video}
                                i18n={i18n}
                                showRating={true}
                                showDuration={true}
                                onClick={this._onVideoClicked}
                                onDelete={this._onDeleteVideo}
                            />
                        );
                    })}
                </ul>
            </div>);
    }

    _onVideoClicked = (id) => {
        const video = this.props.videos.find((video) => video.id === id);

        if (!video) {
            return;
        }

        let assignments = [];

        if (video.metadata && Array.isArray(video.metadata.assignments)) {
            assignments = video.metadata.assignments; // eslint-disable-line prefer-destructuring
        }

        const hasAssignments = (assignments.length > 0);
        this.props.onVideoActions(!hasAssignments ? ASSIGN : PLAYBACK, id);
    };

    _onDeleteVideo = (id) => {
        if (this.props.onVideoActions) {
            this.props.onVideoActions(DELETE, id);
        }
    };

    render() {
        const {
            getString
        } = this.props.i18n;

        return (
            <React.Fragment>
                <div className="view-section-wrapper video-view-section-wrapper all-videos-wrapper">
                    <header>
                        <h3>{getString("videos.allVideos")}</h3>
                    </header>
                    {this._renderVideoList()}
                </div>
            </React.Fragment>
        );
    }
}

AllVideosView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    auth: PropTypes.object,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    videos: PropTypes.array,
    onVideoActions: PropTypes.func
};

AllVideosView.defaultProps = {
    videos: [],
    onVideoActions: () => {}
};

export default AllVideosView;
