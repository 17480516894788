import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LoginView from "../../home/views/login-view";
import languages from "../../../store/data/languages";

import {
    fetchAuthChallenge,
    setAccountType,
    login
} from "../../../store/reducers/auth/auth.actions";

import Crypto from "../../../utils/crypto";
import DigestMD5 from "../../../utils/crypto/md5";
import { toUnicode } from "../../../utils/strings";
import { localStorage } from "../../../utils/storage";
import { noop } from "../../../utils/functions";

import "../../home/home.scss";
import "../../home/views/login-view.scss";


export class AdminView extends Component {

    constructor(props) {
        super(props);

        this.loginView = React.createRef();
        this.state = {
            decodedChallenge: null
        };
    }

    componentDidMount() {
        this.props.fetchAuthChallenge();
    }

    componentDidUpdate() {
        let { decodedChallenge } = this.state;
        const {
            authenticated,
            login: { auth: { authChallenge }},
            userInfo
        } = this.props.auth;

        if (!decodedChallenge && authChallenge) {
            /* eslint-disable-next-line no-eval, prefer-template */
            const auth = eval( "(" + authChallenge + ")");
            decodedChallenge = new DigestMD5().decodeChallenge(auth.challenge);

            this.setState({
                decodedChallenge
            });
        }

        if (authenticated && userInfo && userInfo.id) {
            localStorage.setItem("userInfo", JSON.stringify(userInfo));

            setTimeout(() => {
                this.props.history.push("/dashboard");
            }, 0);
        }
    }

    _handleLogin = (username, password) => {
        const { accountType } = this.props;
        const { decodedChallenge: { nonce, realm, qop }} = this.state;
        const digestURI = "/api/v3/login";
        const nc = accountType;
        let normalizedPassword = toUnicode(password);
        normalizedPassword = normalizedPassword.replace(/\\+u/g, "u");

        const credentials = {
            username,
            password: normalizedPassword,
            nonce,
            nc,
            realm,
            qop,
            digestURI,
            method: "POST"
        };

        const challengeResponse = new DigestMD5().handleChallenge(credentials);
        const authorizationString = `${username}:${nc}:${digestURI}:${challengeResponse}`;
        const authorization = Crypto.Base64.encode(authorizationString);

        this.props.login(username, accountType, authorization);
    };

    render() {
        const {
            accountType,
            auth: {
                loginInProgress,
                authenticated,
                error
            }
        } = this.props;
        const {
            contextPath,
            locale,
            country
        } = this.props.context;

        return (
            <section className="view-container">
                <div ref={this.loginView}
                    className="view">
                    <LoginView
                        accountType={accountType}
                        contextPath={contextPath}
                        locale={locale}
                        country={country}
                        i18n={this.props.i18n}
                        languages={languages}
                        disabled={authenticated || loginInProgress}
                        showSpinner={loginInProgress || authenticated}
                        onBackToHome={noop}
                        onLogin={this._handleLogin}
                        loginFailed={!!error}
                        onPasswordReset={this._handlePasswordReset}
                        onLanguageSelected={this._onLanguageSelected}
                    />
                </div>

            </section>
        );
    }
}

AdminView.propTypes = {
    title: PropTypes.string,
    context: PropTypes.object,
    auth: PropTypes.object,
    accountType: PropTypes.string,
    fetchAuthChallenge: PropTypes.func,
    setAccountType: PropTypes.func,
    changeLocale: PropTypes.func,
    login: PropTypes.func,
    i18n: PropTypes.object,
    history: PropTypes.object
};

AdminView.defaultProps = {
    title: "1 University Avenue",
    accountType: "administrator"
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth
});

const mapDispatchToProps = {
    fetchAuthChallenge,
    setAccountType,
    login
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminView);
