/** -------------------------------------------------------------------------------------------------------------------
 * LectureDiscussionsView
 * A component that renders the subview for showing the discussions for a given lecture
 *
 * @examples
 *
 *  ```jsx
 *
 *    <LectureDiscussionsView
 *      lecture={lecture}
 *      i18n={i18n}
 *    />
 * );
 * ```
 *
 *  @component LectureDiscussionsView
 *  @import LectureDiscussionsView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import DashboardModule from "../../../components/shared/dashboard-module";

import "../lectures.scss";


export class LectureDiscussionsView extends PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            getString
        } = this.props.i18n;

        return (
            <React.Fragment>
                <div className="module-section rounded discussion-wrapper">
                    <DashboardModule
                        className="apps-module top-bordered-header"
                        header={
                            <h3>{getString("discussions.discussions")}</h3>
                        }
                        content={
                            <div className="discussion-list-wrapper no-content">
                                <p className="typography-body info">
                                    {getString("discussions.noDiscussions")}
                                </p>
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

LectureDiscussionsView.propTypes = {
    i18n: PropTypes.object,
    lectures: PropTypes.object,
    lecture: PropTypes.object,
    match: PropTypes.object
};

export default LectureDiscussionsView;
