import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Events from "@akwaba/events";

import {
    avatarUploadURL,
    retrieveUserMembership,
    retrieveTimezoneOffsets,
    changePassword,
    setPreferredLanguage,
    retrieveProfileSettings,
    changeProfileSetting,
    updateProfile,
    updateNotification
} from "../../store/reducers/profile/profile.actions";

import {
    retrieveSocialNetworkSummary
} from "../../store/reducers/network/network.actions";

import { changeLocale } from "../../store/reducers/application/context.actions";
import { LOCALE_CHANGED_EVENT } from "../../store/reducers/application/context.types";

import PageTemplate from "../shared/pages/template";
import AvatarView from "./views/avatar-view";
import UserInfoView from "./views/personal-info-view";
import MembershipView from "./views/membership-view";
import PasswordChangeView from "./views/password-change-view";
import PreferredLanguageView from "./views/language-view";
import PrivacySettings from "./views/privacy-settings-view";
import TimezoneView from "./views/timezone-view";
import CommentsView from "./views/comments-view";
import PostsAndCommentsView from "./views/posts-comments-view";
import ContactNotificationsView from "./views/contact-notifications-view";
import MessageNotificationsView from "./views/message-notifications-view";
import LectureNotificationsView from "./views/lecture-notifications-view";
import SocialNetworkSummaryView from "./views/social-network-summary-view";
import ModalSheet from "../shared/modal/modal-sheet";
import TabbedView from "../shared/tabbed-view";
import Toggle from "../shared/toggle";

import { format } from "../../utils/strings";
import { localStorage } from "../../utils/storage";

import "./profile.scss";


export class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showingInfoMessage: false,
            tabs: this._generateTabs(),
            turnOffNotifications: false,
            currentTab: 0
        };
    }

    _generateTabs() {
        const { getString } = this.props.i18n;

        return [
            {id: "account", title: getString("profile.account")},
            {id: "notifications", title: getString("notification.notifications")}
        ];
    }

    _onTabSelected = (index) => {
        this.setState({
            currentTab: index
        });
    };

    _onPasswordChange = (password) => {
        const {
            auth: {userInfo: { id }}
        } = this.props;

        this.props.changePassword(id, password);
    };

    _onMessageConfirmed = () => {
        this.setState({
            showingInfoMessage: false,
            passwordChangeFailed: false
        });
    };

    _onLanguageSelected = (language) => {
        this.props.setPreferredLanguage(this.props.auth.userInfo.id, language.locale);
        localStorage.setItem("preferredLanguage", JSON.stringify(language));

        this.props.changeLocale(language);
        Events.fire(document.body, LOCALE_CHANGED_EVENT, {
            locale: language.locale
        });
    };

    _onTimezoneSelected = (timezone) => {
        this.props.changeProfileSetting(this.props.auth.userInfo.id, "timezone", timezone);
    };

    _onProfileSettingChanged = (setting, value) => {
        this.props.changeProfileSetting(this.props.auth.userInfo.id, setting, value);
    };

    _onUserInfoUpdated = (details) => {
        this.props.updateProfile(this.props.auth.userInfo.id, details);
    };

    _onNotificationUpdated = (notification, value) => {
        this.props.updateNotification(this.props.auth.userInfo.id, notification, value);
    };

    componentDidMount() {
        const {
            auth: { userInfo: { id }},
            context: { locale }
        } = this.props;

        this.props.retrieveUserMembership(id);
        this.props.retrieveProfileSettings(id);
        this.props.retrieveTimezoneOffsets(locale);
        this.props.retrieveSocialNetworkSummary(id);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.profile.isUpdating === true &&
            this.props.profile.isUpdating === false) {
            const { error } = this.props.profile;
            const key = error ? "profile.passwordChangeFailure" : "profile.passwordChangeSuccess";

            this.setState({
                message: this.props.i18n.getString(key),
                showingInfoMessage: true,
                passwordChangeFailed: !!error
            });
        }

        if (
            prevProps.profile.isUpdatingPreferences === true &&
            this.props.profile.isUpdatingPreferences === false) {
            const { settings: prevSettings } = prevProps.profile;
            const { settings } = this.props.profile;

            const { notifications: previousNotifications } = prevSettings;
            const { notifications: currentNotifications } = settings;
            const notificationsFirstLoaded = !!(!previousNotifications && currentNotifications);
            const notificationsHaveChanged = !!(
                previousNotifications &&
                currentNotifications &&
                previousNotifications.turnOffNotifications !== currentNotifications.turnOffNotifications
            );

            if (notificationsFirstLoaded || notificationsHaveChanged) {
                this.setState({
                    turnOffNotifications: currentNotifications.turnOffNotifications === "true"
                });
            }
        }

        /**
         * Check whether the tab labels have changed (for instance, when the user changes her language) and, if so,
         * re-render the tabbed view.
         */
        if (prevProps.context.locale !== this.props.context.locale) {
            this.setState({
                tabs: this._generateTabs()
            });
        }
    }

    render() {
        const {
            auth: {
                userInfo,
                isUpdatingUserInfo
            },
            profile,
            profile: {
                membership: { type },
                settings,
                isUpdating,
                timezoneOffsets
            },
            socialNetwork: {
                summary
            },
            i18n,
            context,
        } = this.props;

        const {
            showingInfoMessage,
            passwordChangeFailed,
            message,
            tabs,
            currentTab,
            turnOffNotifications
        } = this.state;

        const serviceURL = format(avatarUploadURL, {
            id: userInfo.id
        });
        const shouldRenderTimezoneView = (settings.timezone && timezoneOffsets.length > 0);
        const socialNetworkSummaryAvailable = (Object.keys(summary).length > 0);

        return (
            <PageTemplate
                {...this.props}
                className="profile-wrapper"
                showLocalTime={false}
            >
                <div className="module-section profile-sections">
                    <div className="main-section-wrapper">
                        <div className="sidebar-section">
                            <section className="avatar-section">
                                <AvatarView
                                    userInfo={userInfo}
                                    profile={profile}
                                    i18n={i18n}
                                    serviceURL={serviceURL}
                                />
                                {socialNetworkSummaryAvailable &&
                                    <SocialNetworkSummaryView
                                        summary={summary}
                                        i18n={i18n}
                                    />
                                }
                            </section>
                        </div>
                        <div className="settings-section-wrapper">
                            <TabbedView
                                tabs={tabs}
                                onTabSelected={this._onTabSelected}
                            />
                            {(currentTab === 0) &&
                                <div className="tab-content-wrapper">
                                    <UserInfoView
                                        userInfo={userInfo}
                                        i18n={i18n}
                                        updating={isUpdatingUserInfo}
                                        updateInfo={this._onUserInfoUpdated}
                                    />
                                    {type && <MembershipView
                                        userInfo={userInfo}
                                        membership={profile.membership}
                                        i18n={i18n}
                                    />}
                                    <PasswordChangeView
                                        userInfo={userInfo}
                                        i18n={i18n}
                                        updating={isUpdating}
                                        changePassword={this._onPasswordChange}
                                    />
                                    <PreferredLanguageView
                                        userInfo={userInfo}
                                        context={context}
                                        i18n={i18n}
                                        onLanguageSelected={this._onLanguageSelected}
                                    />
                                    <PrivacySettings
                                        userInfo={userInfo}
                                        i18n={i18n}
                                        settings={settings}
                                        onChange={this._onProfileSettingChanged}
                                    />
                                    {shouldRenderTimezoneView && <TimezoneView
                                        userInfo={userInfo}
                                        context={context}
                                        timezone={settings.timezone}
                                        timezoneOffsets={timezoneOffsets}
                                        i18n={i18n}
                                        onTimezoneSelected={this._onTimezoneSelected}
                                    />}
                                </div>
                            }
                            {(currentTab === 1) &&
                                <div className="tab-content-wrapper">
                                    {settings.notifications &&
                                        <React.Fragment>
                                            <div className="row notification-toggle-wrapper">
                                                <Toggle
                                                    label={i18n.getString("notifications.turnOffAll")}
                                                    checked={turnOffNotifications}
                                                    onChange={(id, checked) => {
                                                        /* eslint-disable-next-line max-len */
                                                        this._onNotificationUpdated("turnOffNotifications", `${checked}`);
                                                    }}
                                                />
                                            </div>
                                            <PostsAndCommentsView
                                                userInfo={userInfo}
                                                i18n={i18n}
                                                notifications={settings.notifications}
                                                disabled={turnOffNotifications}
                                                onNotificationChange={this._onNotificationUpdated}
                                            />
                                            {settings.allowComments && <CommentsView
                                                userInfo={userInfo}
                                                i18n={i18n}
                                                settings={settings}
                                                disabled={turnOffNotifications}
                                                onChange={this._onProfileSettingChanged}
                                            />}
                                            <ContactNotificationsView
                                                userInfo={userInfo}
                                                i18n={i18n}
                                                notifications={settings.notifications}
                                                disabled={turnOffNotifications}
                                                onNotificationChange={this._onNotificationUpdated}
                                            />
                                            <MessageNotificationsView
                                                userInfo={userInfo}
                                                i18n={i18n}
                                                notifications={settings.notifications}
                                                disabled={turnOffNotifications}
                                                onNotificationChange={this._onNotificationUpdated}
                                            />
                                            <LectureNotificationsView
                                                userInfo={userInfo}
                                                i18n={i18n}
                                                notifications={settings.notifications}
                                                disabled={turnOffNotifications}
                                                onNotificationChange={this._onNotificationUpdated}
                                            />
                                        </React.Fragment>}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {showingInfoMessage && <ModalSheet
                    title={i18n.getString("profile.passwordChange")}
                    okLabel={i18n.getString("generic.OK")}
                    showCancelButton={false}
                    type={passwordChangeFailed ? "error" : "status"}
                    onOK={this._onMessageConfirmed}
                    onModalClose={this._onMessageConfirmed}>
                    <p>{message}</p>
                </ModalSheet>}
            </PageTemplate>
        );
    }
}

Profile.propTypes = {
    title: PropTypes.string,
    i18n: PropTypes.object,
    auth: PropTypes.object,
    context: PropTypes.object,
    profile: PropTypes.object,
    socialNetwork: PropTypes.object
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth,
    profile: state.profile,
    socialNetwork: state.network
});

const mapDispatchToProps = {
    retrieveUserMembership,
    retrieveTimezoneOffsets,
    changePassword,
    setPreferredLanguage,
    retrieveProfileSettings,
    changeProfileSetting,
    updateProfile,
    updateNotification,
    retrieveSocialNetworkSummary,
    changeLocale
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
