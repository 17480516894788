/** -------------------------------------------------------------------------------------------------------------------
 * CourseDiscussionsView
 * A component that renders the subview for showing the discussions for a given course
 *
 * @examples
 *
 *  ```jsx
 *
 *    <CourseDiscussionsView
 *      courses={courses}
 *      course={course}
 *      discussions={discussions}
 *      i18n={i18n}
 *      onDiscussionActions={(discussion, action) => { console.log("onDiscussionAction"); }}
 *    />
 * );
 * ```
 *
 *  @component CourseDiscussionsView
 *  @import CourseDiscussionsView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import CourseLecture from "./course-lecture";
import LectureDetails from "../../lectures/views/lecture-details";

import {
    fromDateComponents,
    fromUTCString
} from "../../../utils/dates";
import { isBeforeDate } from "../../shared/calendar/date-utils";
import { noop } from "../../../utils/functions";
import { ACTION_ATTEND } from "../../../constants/lecture-actions";

import "../courses.scss";


export class CourseLecturesView extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            currentLecture: null
        };
    }

    _onLectureSelected = (event) => {
        const {
            i18n: { formatDate, languagePack }
        } = this.props;

        const lectureId = event.currentTarget.getAttribute("data-id");
        const currentLecture = this._findLectureById(lectureId);

        if (!currentLecture.startTime) {
            const startDate = fromUTCString(currentLecture.startDate);
            const endDate = fromUTCString(currentLecture.endDate);

            /** The LectureDetails component expects a "name" property instead of the title */
            currentLecture.name = currentLecture.title;
            currentLecture.startTime = formatDate(startDate, languagePack.timeFormat.MEDIUM);
            currentLecture.endTime = formatDate(endDate, languagePack.timeFormat.MEDIUM);
        }

        this.setState({
            currentLecture,
            isShowingLectureDetails: true
        });
    };

    _findLectureById(id) {
        return this.props.lectures.find((lecture) => lecture.id === id);
    }

    _hideLectureDetails = () => {
        this.setState({
            isShowingLectureDetails: false
        });
    };

    _renderLecturesByDate() {
        const {
            lecturesByDate,
            i18n
        } = this.props;
        const { currentLecture } = this.state;

        const sortedKeys = Object.keys(lecturesByDate).sort((key1, key2) => {
            let parts = key1.split("-");
            const date1 = fromDateComponents(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1,
                parseInt(parts[2], 10));

            parts = key2.split("-");
            const date2 = fromDateComponents(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1,
                parseInt(parts[2], 10));

            return isBeforeDate(date1, date2) ? -1 : isBeforeDate(date2, date1) ? 1 : 0;
        });

        const lectureSections = sortedKeys.map((key) => {
            const dateDetails = lecturesByDate[key];

            return (
                <li data-date={key}
                    key={`weekday-${key}`}>
                    <h5 className="block">
                        <span className="weekday">{dateDetails.formattedDateString}</span>
                    </h5>
                    <ol data-type="event-container" className="events">
                        {dateDetails.lectures.map((lecture) => {
                            const className = classNames({
                                selected: lecture === currentLecture
                            });

                            return (
                                <li
                                    className={className}
                                    data-id={lecture.id}
                                    key={lecture.id}
                                    onClick={this._onLectureSelected}
                                >
                                    <CourseLecture
                                        lecture={lecture}
                                        i18n={i18n}
                                    />
                                </li>
                            );
                        })}
                    </ol>
                </li>
            );
        });

        return (
            <ul className="block weekdays">
                {lectureSections}
            </ul>
        );
    }

    _renderLectureList() {
        return (
            <React.Fragment>
                <div className="sidebar-wrapper">
                    {this._renderLecturesByDate()}
                </div>
                <div className="details-wrapper">
                    {this._renderDetailsForCurrentLecture()}
                </div>
            </React.Fragment>
        );
    }

    _renderDetailsForCurrentLecture() {
        const { i18n } = this.props;
        const { currentLecture } = this.state;

        if (!currentLecture) {
            return (
                <div className="lecture-details-wrapper">
                    <p className="info-message">
                        {i18n.getString("lecture.noLectureSelected")}
                    </p>
                </div>
            );
        }

        const startDate = fromUTCString(currentLecture.startDate);
        const formattedDate = i18n.formatDate(startDate, i18n.languagePack.dateFormat.LONG);

        return (
            <div className="lecture-details-wrapper">
                <header className="selected-date">
                    <h3 className="typography-headline-elevated">{startDate.getDate()}</h3>
                    <p className="typography-body">{formattedDate}</p>
                </header>
                <div className="content">
                    <LectureDetails
                        lecture={currentLecture}
                        canEdit={false}
                        attendLabel={i18n.getString("lecture.attend")}
                        rescheduleLabel={i18n.getString("lecture.reschedule")}
                        onClick={() => this._onLectureActions(ACTION_ATTEND)}
                    />
                </div>
            </div>
        );
    }

    _renderEmptyContentMessage() {
        const {
            i18n: { getString }
        } = this.props;

        return (
            <p className="info-message">
                <span>{getString("course.noRecentLectures")}</span>
            </p>
        );
    }

    _onLectureActions = (action) => {
        this.props.onLectureActions(this.state.currentLecture, action);
    };

    render() {
        const {
            lecturesByDate,
        } = this.props;
        const { isShowingLectureDetails } = this.state;

        const lecturesAvailable = (lecturesByDate && Object.keys(lecturesByDate).length > 0);
        const wrapperClassName = classNames("section-content-wrapper course-lecture-wrapper", {
            "no-background": !lecturesAvailable,
            "showing-details": isShowingLectureDetails
        });

        return (
            <div className={wrapperClassName}>
                {!lecturesAvailable ? this._renderEmptyContentMessage() : this._renderLectureList()}
                {isShowingLectureDetails && <div className="modal-actions">
                    <button className="modal-close"
                        data-action="close"
                        onClick={this._hideLectureDetails}
                    />
                </div>}
            </div>
        );
    }
}

CourseLecturesView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    lectures: PropTypes.array,
    lecturesByDate: PropTypes.object,
    match: PropTypes.object,
    onLectureActions: PropTypes.func,
    onLectureSelected: PropTypes.func
};

CourseLecturesView.defaultProps = {
    onLectureActions: noop,
    onLectureSelected: noop
};

export default CourseLecturesView;
