import {
    fromUTCString,
    formatInRelativeTimeUnit
} from "./dates";

export const generateDiscussionStats = (discussion, i18n) => {
    const {
        createdDate,
        stats: { likes, comments }
    } = discussion;

    const discussionDate = fromUTCString(createdDate);
    const timeIntervalInSecs = (new Date().getTime() - discussionDate.getTime()) / 1000;
    const relativeTime = formatInRelativeTimeUnit(timeIntervalInSecs, i18n);

    /* eslint-disable indent */
    const totalLikes = (likes === 0) ? i18n.format("discussions.totalLikes", ["0"]) :
        (likes === 1) ? i18n.getString("discussions.oneLike") :
        i18n.format("discussions.totalLikes", [likes]);
    const totalComments = (comments === 0) ? i18n.getString("discussions.noCommentsTitle") :
        (comments === 1) ? i18n.getString("discussions.oneComment") :
        i18n.format("discussions.totalComments", [comments]);
    /* eslint-enable indent */

    return {
        relativeTime,
        totalLikes,
        totalComments
    };
};

