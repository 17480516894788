/** -------------------------------------------------------------------------------------------------------------------
 * RecentActivities
 * A component that renders the stream of recent activities in the classroom
 *
 * @examples
 *
 *  ```jsx
 *
 *    <RecentActivitiesView
 *      activities={activities}
 *      i18n={i18n}
 *    />
 * );
 * ```
 *
 *  @component RecentActivitiesView
 *  @import RecentActivitiesView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import RecentActivity from "./recent-activity";
import { noop } from "../../../utils/functions";


export class RecentActivitiesView extends PureComponent {

    constructor(props) {
        super(props);
    }

    _renderActivities() {
        const {
            activities,
            i18n
        } = this.props;

        return (
            <div className="activity-list-wrapper">
                <ul className="row item-list activity-list">
                    {activities.map((activity) => (
                        <li key={activity.id}>
                            <RecentActivity
                                activity={activity}
                                i18n={i18n}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    _renderNoActivityMessage() {
        const {
            i18n: { getString }
        } = this.props;

        return (
            <div className="content">
                <p className="info-message"><span>{getString("classroom.noActivity")}</span></p>
            </div>
        );
    }

    render() {
        const {
            i18n: { getString },
            activities
        } = this.props;
        const noActivitiesAvailable = (activities.length === 0);

        return (
            <React.Fragment>
                <div className="view-section-wrapper recent-activity-wrapper">
                    <header>
                        <h4>{getString("classroom.activity")}</h4>
                    </header>
                    {noActivitiesAvailable ? this._renderNoActivityMessage() : this._renderActivities()}
                </div>
            </React.Fragment>
        );
    }
}

RecentActivitiesView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    activities: PropTypes.array,
    onActivityActions: PropTypes.func
};

RecentActivitiesView.defaultProps = {
    activities: [],
    onActivityActions: noop
};

export default RecentActivitiesView;
