/** -------------------------------------------------------------------------------------------------------------------
 * CourseLecture
 * A component that render the summary of a course lecture
 *
 *  @examples
 *
 *  ```jsx
 *    <CourseLecture
 *      lecture={lecture}
 *      i18n={i18n}
 *      onLectureActions={(lecture, action) => { console.log("onLectureAction"); }}
 *    />
 * ```
 *
 *  @component CourseLecture
 *  @import CourseLecture
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { fromUTCString } from "../../../utils/dates";
import { noop } from "../../../utils/functions";

import "../courses.scss";


class CourseLecture extends PureComponent {

    _onLectureActions = (action) => {
        const {
            lecture,
            onLectureActions
        } = this.props;

        onLectureActions(lecture, action);
    };

    render() {
        const {
            lecture,
            i18n: { formatDate, languagePack }
        } = this.props;

        const startTime = fromUTCString(lecture.startDate);
        const formattedStartTime = formatDate(startTime, languagePack.timeFormat.MEDIUM);
        const endTime = fromUTCString(lecture.endDate);
        const formattedEndTime = formatDate(endTime, languagePack.timeFormat.MEDIUM);
        const colorCode = `color-${lecture.course.colorCode}`;

        return (
            <div className="course-lecture-wrapper">
                <em data-type="color-code" className={classNames("circle-code filled", colorCode)} />
                <h4 data-type="event-name" className="ellipsis">{lecture.title}</h4>
                <p data-type="event-info" className="info">
                    {lecture.course.id.toUpperCase()}: {lecture.course.title}
                </p>
                <p data-type="event-info" className="info">
                    {lecture.room.name}
                </p>
                <span data-type="event-time" className="date-time">{formattedStartTime} - {formattedEndTime}</span>
            </div>
        );
    }
}

CourseLecture.propTypes = {
    lecture: PropTypes.object,
    className: PropTypes.string,
    i18n: PropTypes.object,
    onLectureActions: PropTypes.func
};

CourseLecture.defaultProps = {
    onLectureActions: noop
};

export default CourseLecture;
