import URLResource              from "./media/url-resource";
import MediaElement             from "./media/media-element";
import HTML5Video               from "./media/html5-video";
import MediaPlayer              from "./media/media-player";
import * as MediaDetector       from "./media/media-detector";
import * as MediaFactory        from "./media/media-factory";
import * as MediaPlayerState    from "./constants/media-player-state";
import * as MediaType           from "./constants/media-type";
import * as MediaErrorEvent     from "./constants/media-error-event-type";
import * as ScaleMode           from "./constants/scale-mode";
import * as EventDispatcher     from "./events/event-dispatcher";


export default {
    EventDispatcher,
    URLResource,
    MediaElement,
    HTML5Video,
    MediaPlayer,
    MediaDetector,
    MediaFactory,
    MediaPlayerState,
    MediaType,
    MediaErrorEvent,
    ScaleMode
};
