import * as types from "./videos.types";

export const videoUploadURL = "/users/{id}/video-upload";

export const fetchUserVideos = (username) => (dispatch) => {
    const endpoint = `/users/${username}/videos`;

    return dispatch({
        types: [
            types.VIDEOS_REQUEST,
            types.VIDEOS_SUCCESS,
            types.VIDEOS_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const fetchUserVideosByStatus = (username, status = "pending") => (dispatch) => {
    const endpoint = `/users/${username}/videos?status=${status}`;

    return dispatch({
        types: [
            types.VIDEOS_BY_STATUS_REQUEST,
            types.VIDEOS_BY_STATUS_SUCCESS,
            types.VIDEOS_BY_STATUS_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const deleteVideo = (userId, videoId, options) => (dispatch) => {
    const endpoint = `/users/${userId}/videos/${videoId}/delete`;
    const { universityId, fileName } = options;

    dispatch({
        type: types.DELETING_VIDEO_ID,
        videoId
    });

    return dispatch({
        types: [
            types.DELETE_VIDEO_REQUEST,
            types.DELETE_VIDEO_SUCCESS,
            types.DELETE_VIDEO_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body: {
                universityId,
                fileName
            }
        }
    });
};


export const assignVideo = (userId, videoId, options = {}) => (dispatch) => {
    const endpoint = `/users/${userId}/videos/${videoId}/assign`;
    const body = {
        assignmentType: options.assignmentType,
        universityId: options.universityId
    };

    if (options.itemId) {
        body.itemId = options.itemId;
    }

    if (options.courseId) {
        body.courseId = options.courseId;
    }

    dispatch({
        type: types.ASSIGNING_VIDEO_ID,
        videoId
    });

    return dispatch({
        types: [
            types.ASSIGN_VIDEO_REQUEST,
            types.ASSIGN_VIDEO_SUCCESS,
            types.ASSIGN_VIDEO_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};

export const unassignVideo = (userId, videoId, options = {}) => (dispatch) => {
    const endpoint = `/users/${userId}/videos/${videoId}/unassign`;
    const body = {};

    if (options.type) {
        body.type = options.type;
    }

    if (options.objectId) {
        body.itemId = options.objectId;
    }

    if (options.courseId) {
        body.courseId = options.courseId;
    }

    return dispatch({
        types: [
            types.UNASSIGN_VIDEO_REQUEST,
            types.UNASSIGN_VIDEO_SUCCESS,
            types.UNASSIGN_VIDEO_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};

export const resetErrors = () => (dispatch) => {
    return dispatch({
        type: types.VIDEOS_RESET_ERRORS
    });
};


