/** -------------------------------------------------------------------------------------------------------------------
 * DashboardItems
 * A component that renders the link items on a dashboard page
 *
 * @examples
 *
 *  ```jsx
 *    <DashboardItems
 *      userId="jsmith"
 *      accountType="student"
 *      items={itemList}
 *      i18n={i18n}
 *      context={context}
 *    />
 * ```
 *
 *  @component DashboardItems
 *  @import DashboardItems
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { noop } from "../../utils/functions";

const DashboardItems = ({
    items,
    className,
    accountType,
    i18n,
    onClick
}) => {

    const onItemClicked = (event) => {
        event.preventDefault();

        const { currentTarget } = event;
        onClick(currentTarget.getAttribute("data-id"), currentTarget.getAttribute("data-url"));
    };

    const generateItems = () => {
        const isAdminAccount = (accountType === "administrator");
        const filteredItems = isAdminAccount ?
            items.filter((item) => item.role === "administrator") :
            items.filter((item) => item.role === "all" || item.role === accountType);

        return filteredItems.map((item) => {
            const url = `/${item.id}`;

            return (
                <li key={item.id}>
                    <Link
                        to={url}
                        className={classNames("dashboard-icon", item.icon)}
                        data-id={item.id}
                        data-url={url}
                        onClick={onItemClicked}
                    >
                        <figure className={item.icon} />
                        <span>{i18n.getString(item.localizedString)}</span>
                    </Link>
                </li>
            );
        });
    };

    return (
        <div className={classNames("dashboard-items-wrapper", className, accountType)}>
            <ul className="dashboard-items">
                {generateItems()}
            </ul>
        </div>
    );
};

DashboardItems.propTypes = {
    userId: PropTypes.string,
    items: PropTypes.array.isRequired,
    className: PropTypes.string,
    accountType: PropTypes.string,
    i18n: PropTypes.object,
    context: PropTypes.object,
    onClick: PropTypes.func
};

DashboardItems.defaultProps = {
    className: "",
    accountType: "student",
    onClick: noop
};

export default DashboardItems;
