import * as types from "./network.types";

export const retrieveUserNetwork = (username) => (dispatch) => {
    const endpoint = `/users/${username}/network`;

    return dispatch({
        types: [
            types.USER_NETWORK_REQUEST,
            types.USER_NETWORK_SUCCESS,
            types.USER_NETWORK_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const retrieveSocialNetworkSummary = (userId) => (dispatch) => {
    const endpoint = `/accounts/${userId}/summary`;

    return dispatch({
        types: [
            types.SOCIAL_NETWORK_SUMMARY_REQUEST,
            types.SOCIAL_NETWORK_SUMMARY_SUCCESS,
            types.SOCIAL_NETWORK_SUMMARY_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const retrieveInboundFollows = (userId) => (dispatch) => {
    const endpoint = `/users/${userId}/network/followers`;

    return dispatch({
        types: [
            types.INBOUND_FOLLOWS_REQUEST,
            types.INBOUND_FOLLOWS_SUCCESS,
            types.INBOUND_FOLLOWS_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const retrieveOutboundFollows = (userId) => (dispatch) => {
    const endpoint = `/users/${userId}/network/following`;

    return dispatch({
        types: [
            types.OUTBOUND_FOLLOWS_REQUEST,
            types.OUTBOUND_FOLLOWS_SUCCESS,
            types.OUTBOUND_FOLLOWS_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const retrieveContactSuggestions = (userId) => (dispatch) => {
    const endpoint = `/users/${userId}/network/suggestions`;

    return dispatch({
        types: [
            types.FOLLOW_SUGGESTIONS_REQUEST,
            types.FOLLOW_SUGGESTIONS_SUCCESS,
            types.FOLLOW_SUGGESTIONS_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const followContact = (userId, contact) => (dispatch) => {
    const endpoint = `/users/${contact.id}/network/follow`;
    const body = {
        followerId: userId
    };

    dispatch({
        type: types.ADD_OUTBOUND_FOLLOW,
        payload: contact
    });

    return dispatch({
        types: [
            types.ADD_OUTBOUND_FOLLOW_REQUEST,
            types.ADD_OUTBOUND_FOLLOW_SUCCESS,
            types.ADD_OUTBOUND_FOLLOW_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};


export const followContactFromSuggestions = (userId, contact) => (dispatch) => {
    dispatch({
        type: types.REMOVE_SUGGESTION,
        payload: contact
    });

    return followContact(userId, contact)(dispatch);
};


export const unfollowContact = (userId, contact) => (dispatch) => {
    const endpoint = `/users/${contact.id}/network/unfollow`;
    const body = {
        followerId: userId
    };

    dispatch({
        type: types.REMOVE_OUTBOUND_FOLLOW,
        payload: contact
    });

    return dispatch({
        types: [
            types.REMOVE_OUTBOUND_FOLLOW_REQUEST,
            types.REMOVE_OUTBOUND_FOLLOW_SUCCESS,
            types.REMOVE_OUTBOUND_FOLLOW_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};
