/** -------------------------------------------------------------------------------------------------------------------
 * LectureRoomsView
 * A component that renders the lecture rooms for a user
 *
 * @examples
 *
 *  ```jsx
 *
 *    <LectureRoomsView
 *      rooms={sharedSpaces}
 *      i18n={i18n}
 *      showSpinner={false}
 *      onAddRoom={() => console.log("Adding room...")}
 *      onDeleteRoom={() => console.log("Deleting room...")}
 *    />
 * );
 * ```
 *
 *  @component LectureRoomsView
 *  @import LectureRoomsView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import InputField from "../../shared/input-field";
import Button from "../../shared/button";
import Toggle from "../../shared/toggle";
import { Loader } from "../../shared/spinner/loader";
import LectureRoom from "./lecture-room";
import { noop } from "../../../utils/functions";


export class LectureRoomsView extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showingRoomForm: false,
            rooms: this.props.rooms,
            roomInfo: {
                name: "",
                accessLevel: "PUBLIC"
            },
            validationStates: {
                roomName: false
            }
        };
    }

    _onDeleteRoom = (id) => {
        const rooms = this.state.rooms.filter((room) => room.id !== id);
        this.setState({
            rooms
        });

        this.props.onDeleteRoom(id);
    };

    _renderRoomList() {
        return (
            <ul className="row item-list room-list">
                {this.state.rooms.map((room) => (
                    <li key={room.id} className="room-list-item">
                        <LectureRoom
                            room={room}
                            onDelete={this._onDeleteRoom}
                        />
                    </li>
                ))}
            </ul>
        );
    }

    _renderNoRoomMessage() {
        const {
            i18n: { getString }
        } = this.props;

        return (
            <p className="info-message"><span>{getString("classroom.noLectureRooms")}</span></p>
        );
    }

    _showAddRoomForm = () => {
        this.setState({
            showingRoomForm: true
        });
    };

    _hideAddRoomForm = () => {
        this.setState({
            showingRoomForm: false
        });
    };

    _onRoomNameChanged = (value) => {
        const isValidForm = value.length > 3;
        const { roomInfo } = this.state;
        roomInfo.name = value;

        this.setState({
            roomInfo,
            isValidForm
        });
    };

    _onAccessLevelChanged = (checked) => {
        const { roomInfo } = this.state;
        roomInfo.accessLevel = checked ? "PRIVATE" : "PUBLIC";

        this.setState({
            roomInfo
        });
    };

    _addNewRoom = () => {
        const {
            roomInfo: { name, accessLevel }
        } = this.state;
        this.props.onAddRoom(name, accessLevel);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.showSpinner === true && this.props.showSpinner === false) {
            this.setState({
                showingRoomForm: false,
                rooms: this.props.rooms,
                roomInfo: {}
            });
        }
    }

    render() {
        const {
            i18n: { getString },
            showSpinner
        } = this.props;
        const {
            rooms,
            showingRoomForm,
            roomInfo,
            isValidForm
        } = this.state;
        const noRoomsAvailable = (rooms.length === 0);
        const isPrivateRoom = (roomInfo.accessLevel === "PRIVATE");

        return (
            <div className="lecture-rooms-wrapper">
                {!showingRoomForm && <div className="room-list-section">
                    {noRoomsAvailable ? this._renderNoRoomMessage() : this._renderRoomList()}
                    <div className="actions">
                        <Button
                            title={getString("classroom.addNewRoom")}
                            className="submit-button"
                            onClick={this._showAddRoomForm}
                        />
                    </div>
                </div>}
                {showingRoomForm &&
                    <div className="room-form-section">
                        <div className="row">
                            <div className="column small-12">
                                <InputField
                                    value={roomInfo.name}
                                    placeholder={getString("classroom.roomNameHint")}
                                    showClearControl={false}
                                    onChange={this._onRoomNameChanged}
                                />
                            </div>
                        </div>
                        <div className="row access-level-wrapper">
                            <Toggle
                                label={getString("classroom.roomIsPrivate")}
                                checked={isPrivateRoom}
                                disabled={showSpinner}
                                onChange={(id, checked) => this._onAccessLevelChanged(checked)}
                            />
                        </div>
                        <div className="row actions">
                            <Button
                                secondary={true}
                                title={getString("generic.cancel")}
                                disabled={showSpinner}
                                onClick={this._hideAddRoomForm}
                            />
                            <Button
                                title={getString("generic.add")}
                                disabled={showSpinner || !isValidForm}
                                onClick={this._addNewRoom}
                            />
                        </div>
                        {showSpinner && <div className="loader-wrapper">
                            <Loader />
                        </div>}
                    </div>
                }
            </div>
        );
    }
}

LectureRoomsView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    rooms: PropTypes.array,
    showSpinner: PropTypes.bool,
    onAddRoom: PropTypes.func,
    onDeleteRoom: PropTypes.func
};

LectureRoomsView.defaultProps = {
    rooms: [],
    onAddRoom: noop,
    onDeleteRoom: noop
};

export default LectureRoomsView;
