import * as types from "./account.types";

const initialState = {
    creatingAccount: false,
    userInfo: null,
    query: {},
    error: null
};


const account = (state = initialState, action = {}) => {
    switch (action.type) {

        case types.ACCOUNT_DETAIL_SET:
            const { payload } = action;
            return {
                ...state,
                query: {
                    ...state.query,
                    [payload.name]: payload.value
                }
            };

        case types.CREATE_ACCOUNT_REQUEST:
            return {
                ...state,
                creatingAccount: true,
                error: null
            };

        case types.CREATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                creatingAccount: false,
                userInfo: action.payload,
                query: {}
            };

        case types.CREATE_ACCOUNT_FAILURE:
            const { data } = (action.error && action.error.response) || {};

            return {
                ...state,
                creatingAccount: false,
                error: (data && data.message) ? data.message : data
            };

        case types.RESET_QUERY:
            return {
                ...state,
                query: {},
                error: null
            };

        case types.ACCOUNT_RESET_ERRORS:
            return {
                ...state,
                error: null
            };

        default:
            return state;
    }
};

export default account;
