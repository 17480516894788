/** -------------------------------------------------------------------------------------------------------------------
 * TimezoneView
 * A component that renders the view for changing the user's timezone
 *
 * @examples
 *
 *  ```jsx
 *
 *    <TimezoneView
 *      userInfo={userInfo}
 *      i18n={i18n}
 *      timezone={timezone}
 *      onTimezoneSelected={(timezone) => console.log("Timezone changed: ", timezone)}
 *    />
 * );
 * ```
 *
 *  @component TimezoneView
 *  @import TimezoneView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import TimezoneSelector from "../../shared/timezone-selector";
import { noop } from "../../../utils/functions";


export class TimezoneView extends PureComponent {

    constructor(props) {
        super(props);
    }

    _onTimezoneSelected = (timezone) => {
        if (this.props.onTimezoneSelected) {
            this.props.onTimezoneSelected(timezone);
        }
    };

    render() {
        const {
            context,
            i18n,
            i18n: { getString },
            timezone,
            timezoneOffsets
        } = this.props;

        return (
            <div className="profile-section timezone-wrapper">
                <h3 className="profile-section-header">{getString("profile.timezoneSelector")}</h3>
                <p className="info typography-body">{getString("profile.timezoneSelectorInfo")}</p>
                <div className="timezone-canvas-wrapper">
                    <TimezoneSelector
                        locale={context.locale}
                        i18n={i18n}
                        timezone={timezone}
                        timezoneOffsets={timezoneOffsets}
                        onTimezoneSelected={this._onTimezoneSelected}
                    />
                </div>
            </div>
        );
    }
}

TimezoneView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    context: PropTypes.object,
    timezone: PropTypes.string,
    timezoneOffsets: PropTypes.array,
    onTimezoneSelected: PropTypes.func
};

TimezoneView.defaultProps = {
    onTimezoneSelected: noop,
    timezoneOffsets: []
};

export default TimezoneView;
