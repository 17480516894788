/** -------------------------------------------------------------------------------------------------------------------
 * LectureDetails
 * A component that render the details of a lecture
 *
 * @examples
 *
 *  ```jsx
 *    <LectureDetails
 *      lecture={lecture}
 *      canEdit={true}
 *      attendLabel="Attend lecture"
 *      rescheduleLabel="Reschedule lecture"
 *      i18n={i18n}
 *      onClick={() => console.log("Lecture clicked")}
 *    />
 * ```
 *
 *  @component LectureDetails
 *  @import LectureDetails
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/shared/button";
import languages from "../../../store/data/languages";


const LectureDetails = ({
    lecture,
    canEdit,
    attendLabel,
    rescheduleLabel,
    onClick
}) => {

    const {
        name,
        course,
        instructor,
        startTime,
        endTime,
        room,
        deliveryLanguage
    } = lecture;
    const language = languages.find((language) => language.locale === deliveryLanguage.split("_")[0]);

    return (
        <div className="lecture-details">
            <h4 className="typography-intro-product">{name}</h4>
            <dl>
                <dt className="time" />
                <dd>{startTime} - {endTime}</dd>
                <dt className="course" />
                <dd>{[(course.id || course.courseId).toUpperCase(), course.title].join(": ")}</dd>
                <dt className="instructor" />
                <dd>{[instructor.firstName, instructor.lastName].join(" ")}</dd>
                <dt className="room" />
                <dd>{room.name}</dd>
                <dt className="language" />
                <dd>{language.displayName}</dd>
            </dl>
            <div className="actions">
                <p>
                    <Button
                        className="ok-button"
                        title={attendLabel}
                        onClick={() => onClick("attend", lecture.id)}
                    />
                </p>
                {canEdit && <p>
                    <Button
                        className="reschedule-button"
                        secondary={true}
                        title={rescheduleLabel}
                        onClick={() => onClick("edit", lecture.id)}
                    />
                </p>}
            </div>
        </div>
    );
};

LectureDetails.propTypes = {
    lecture: PropTypes.object.isRequired,
    className: PropTypes.string,
    attendLabel: PropTypes.string,
    rescheduleLabel: PropTypes.string,
    canEdit: PropTypes.bool,
    onClick: PropTypes.func
};

export default LectureDetails;
