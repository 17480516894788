/** -------------------------------------------------------------------------------------------------------------------
 * MembershipView
 * A component that renders the membership details for a user
 *
 * @examples
 *
 *  ```jsx
 *
 *    <MembershipView
 *      userInfo={userInfo}
 *      membership={membership}
 *      i18n={i18n}
 *    />
 * );
 * ```
 *
 *  @component MembershipView
 *  @import MembershipView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { fromUTCString } from "../../../utils/dates";


export class MembershipView extends PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            membership,
            i18n
        } = this.props;

        const membershipDate = i18n.formatDate(fromUTCString(membership.createdDate),
            i18n.languagePack.dateFormat.LONG);
        const currentPlanLabel = `membership.${membership.type.toLowerCase()}`;

        return (
            <div className="profile-section membership-wrapper">
                <h3 className="profile-section-header">{i18n.getString("membership.myMembership")}</h3>
                <div className="profile-section-wrapper">
                    <dl className="details">
                        <dt>{i18n.getString("membership.currentPlan")}:</dt>
                        <dd className="type">{i18n.getString(currentPlanLabel)}</dd>
                        <dt className="expiration">{i18n.getString("membership.memberSince")}:</dt>
                        <dd>{membershipDate}</dd>
                    </dl>
                </div>
            </div>
        );
    }
}

MembershipView.propTypes = {
    membership: PropTypes.object,
    i18n: PropTypes.object
};

export default MembershipView;
