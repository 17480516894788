import * as types from "./account.types";
import { LOGIN_SUCCESS } from "../auth/auth.types";

import {
    removeQuotes,
    removeTags,
    toUnicode
} from "../../../utils/strings";

export const onAccountDetailUpdated = (name, value) => (dispatch) => {
    return dispatch({
        type: types.ACCOUNT_DETAIL_SET,
        payload: {
            name,
            value
        }
    });
};

export const createAccount = () => (dispatch, getState) => {
    const endpoint = "/accounts/";
    const {
        account: { query },
        context: { locale }
    } = getState();

    const {
        firstName,
        lastName,
        universityName,
        universityDescription,
        ...rest
    } = query;

    const normalizedFirstName = removeTags(removeQuotes(firstName));
    const normalizedLastName = removeTags(removeQuotes(lastName));

    const body = {
        locale,
        ...rest,
        firstName: toUnicode(normalizedFirstName),
        lastName: toUnicode(normalizedLastName)
    };

    if (universityName) {
        const normalizedUniversityName = removeTags(removeQuotes(universityName));
        body.universityName = toUnicode(normalizedUniversityName);
    }

    if (universityDescription) {
        const normalizedUniversityDescription = removeTags(universityDescription);
        body.universityDescription = toUnicode(normalizedUniversityDescription);
    }

    return dispatch({
        types: [
            types.CREATE_ACCOUNT_REQUEST,
            types.CREATE_ACCOUNT_SUCCESS,
            types.CREATE_ACCOUNT_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};

export const onAccountCreated = (userInfo) => (dispatch) => {
    return dispatch({
        type: LOGIN_SUCCESS,
        payload: userInfo
    });
};

export const resetAccountForm = () => (dispatch) => {
    return dispatch({
        type: types.RESET_QUERY,
    });
};

export const resetErrors = () => (dispatch) => {
    return dispatch({
        type: types.ACCOUNT_RESET_ERRORS
    });
};

