/** -------------------------------------------------------------------------------------------------------------------
 * AccountReview
 * A component that displays a summary of the user's account details prior to its creation
 *
 * @examples
 *
 *  ```jsx
 *
 *    <AccountReview
 *       accountType={accountType}
 *       i18n={i18n}
 *       formData={this._getUserInfoFormData()}
 *       invite={invite}
 *       university={university}
 *    />
 * ```
 *
 *  @component AccountReview
 *  @import AccountReview
 *  @returns {object} React component
 *
*--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";


export class AccountReview extends PureComponent {

    render() {
        const {
            i18n: { getString },
            accountType,
            formData,
            invite,
            university
        } = this.props;

        const {
            username,
            password,
            firstName,
            lastName,
            email
        } = formData;

        const {
            universityName,
            universityDescription
        } = university;

        const isStudent = accountType === "student";
        const maskedPassword = password ? password.split("").map(() => "*").join("") : "***";
        const universityDisplayName = (isStudent && invite) ? invite.university.name : universityName;

        return (
            <div className="review-wrapper">
                <div className="review-section user-review">
                    <h5>{getString("register.userInfo")}</h5>
                    <dl>
                        <dt>{getString("generic.accountType")}</dt>
                        <dd>{getString(`generic.${accountType.toLowerCase()}`)}</dd>
                        <dt>{getString("login.username")}</dt>
                        <dd>{username}</dd>
                        <dt>{getString("login.password")}</dt>
                        <dd>{maskedPassword}</dd>
                        <dt>{getString("login.firstName")}</dt>
                        <dd>{firstName}</dd>
                        <dt>{getString("login.lastName")}</dt>
                        <dd>{lastName}</dd>
                        <dt>{getString("login.email")}</dt>
                        <dd className="ellipsis">{email}</dd>
                    </dl>
                </div>
                <div className="review-section university-review">
                    <h5>{getString("generic.university")}</h5>
                    <dl>
                        <dt>{getString("register.universityName")}</dt>
                        <dd>{universityDisplayName}</dd>
                        {!isStudent &&
                            <React.Fragment>
                                <dt>{getString("generic.description")}</dt>
                                <dd className="description">{universityDescription}</dd>
                            </React.Fragment>
                        }
                    </dl>
                </div>
            </div>
        );
    }
}

AccountReview.propTypes = {
    i18n: PropTypes.object,
    accountType: PropTypes.string,
    invite: PropTypes.object,
    formData: PropTypes.object,
    university: PropTypes.object
};


export default AccountReview;
