/** -------------------------------------------------------------------------------------------------------------------
 * PasswordChangeView
 * A component that renders the view for changing a user's password
 *
 * @examples
 *
 *  ```jsx
 *
 *    <PasswordChangeView
 *      userInfo={userInfo}
 *      i18n={i18n}
 *      updating={isUpdating}
 *      changePassword={(value) => console.log("Changing password: ", value)}
 *    />
 * );
 * ```
 *
 *  @component PasswordChangeView
 *  @import PasswordChangeView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import InputField from "../../shared/input-field";
import Button from "../../shared/button";
import { Loader } from "../../shared/spinner/loader";

import Crypto from "../../../utils/crypto";
import { noop } from "../../../utils/functions";


export class PasswordChangeView extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            password: "",
            confirmedPassword: "",
            validationStates: {
                password: false,
                confirmedPassword: false
            }
        };
    }

    _getPasswordValidators() {
        const { getString } = this.props.i18n;

        return {
            matchesPassword: {
                test: (value) => {
                    const { password } = this.state;
                    return value === password;
                },
                message: getString("profile.passwordMismatch")
            }
        };
    }

    _onChange = (field, value) => {
        const { validationStates } = this.state;

        this.setState({
            [field]: value
        });

        if (Object.keys(validationStates).includes(field)) {
            validationStates[field] = true;

            if (field === "password") {
                validationStates["confirmedPassword"] = false;
            }

            const isValidForm = Object.values(validationStates).every((item) => !!item);

            this.setState({
                validationStates,
                isValidForm
            });
        }
    };

    _onError = (field) => {
        const { validationStates } = this.state;
        validationStates[field] = false;
        const isValidForm = Object.values(validationStates).every((item) => !!item);

        this.setState({
            validationStates,
            isValidForm
        });
    };

    _handlePasswordChange = () => {
        if (!this.state.isValidForm) {
            return;
        }

        if (this.props.changePassword) {
            const { id } = this.props.userInfo;
            const passwordString = `${id}:${this.state.password}`;
            const encryptedPassword = Crypto.Base64.encode(passwordString);

            this.props.changePassword(encryptedPassword);
        }
    };

    render() {
        const {
            i18n: { getString },
            updating
        } = this.props;

        const {
            password,
            confirmedPassword,
        } = this.state;

        return (
            <div className="profile-section password-change-wrapper">
                <h3 className="profile-section-header">{getString("profile.passwordChange")}</h3>
                <div className="profile-section-wrapper">
                    <div className="row">
                        <div className="column medium-6 small-12 label">
                            <label>{getString("profile.newPassword")}</label>
                        </div>
                        <div className="column medium-6 small-12">
                            <InputField
                                type="password"
                                value={password}
                                placeholder={getString("profile.newPasswordHint")}
                                validationTypes={["isRequired"]}
                                disabled={updating}
                                showClearControl={false}
                                onValid={(value) => this._onChange("password", value)}
                                onError={() => this._onError("password")}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="column medium-6 small-12 label">
                            <label>{getString("profile.confirmNewPassword")}</label>
                        </div>
                        <div className="column medium-6 small-12">
                            <InputField
                                type="password"
                                value={confirmedPassword}
                                placeholder={getString("profile.confirmNewPasswordHint")}
                                validationTypes={["matchesPassword"]}
                                validators={this._getPasswordValidators()}
                                disabled={updating}
                                showClearControl={false}
                                onValid={(value) => this._onChange("confirmedPassword", value)}
                                onError={() => this._onError("confirmedPassword")}
                            />
                        </div>
                    </div>
                    <div className="row actions">
                        <Button
                            title={getString("profile.changeLabel")}
                            disabled={updating}
                            onClick={this._handlePasswordChange}
                        />
                    </div>
                    {updating && <div className="loader-wrapper">
                        <Loader />
                    </div>}
                </div>
            </div>
        );
    }
}

PasswordChangeView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    updating: PropTypes.bool,
    changePassword: PropTypes.func
};

PasswordChangeView.defaultProps = {
    changePassword: noop
};

export default PasswordChangeView;
