/** -------------------------------------------------------------------------------------------------------------------
 * AssignedVideosView
 * A component that renders the subview for showing the assigned videos section of the Videos page
 *
 * @examples
 *
 *  ```jsx
 *
 *    <AssignedVideosView
 *      videos={videos}
 *      i18n={i18n}
 *    />
 * );
 * ```
 *
 *  @component AssignedVideosView
 *  @import AssignedVideosView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/


import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import {
    UNASSIGN,
    PLAYBACK
} from "../../../store/reducers/videos/videos.types";

import Video from "./video-details";

import "../videos.scss";


export class AssignedVideosView extends PureComponent {

    constructor(props) {
        super(props);
    }

    _renderVideoList() {
        const {
            videos,
            i18n,
        } = this.props;

        return (videos.length === 0) ?
            (<div className="no-content">
                <p className="typography-body info">
                    {i18n.getString("videos.noVideosYet")}
                </p>
            </div>) :
            (<div className="video-list-wrapper">
                <ul className="row item-list video-list unpublished-video-list">
                    {videos.map((video) => {
                        return (
                            <Video
                                key={video.id}
                                video={video}
                                i18n={i18n}
                                showRating={true}
                                showDuration={true}
                                onClick={this._onVideoClicked}
                                onDelete={this._onVideoUnassignment}
                            />
                        );
                    })}
                </ul>
            </div>);
    }

    _onVideoClicked = (id) => {
        if (this.props.onVideoActions) {
            this.props.onVideoActions(PLAYBACK, id);
        }
    };

    _onVideoUnassignment = (id) => {
        if (this.props.onVideoActions) {
            this.props.onVideoActions(UNASSIGN, id);
        }
    };

    render() {
        const {
            getString
        } = this.props.i18n;

        return (
            <React.Fragment>
                <div className="view-section-wrapper video-view-section-wrapper unpublished-videos-wrapper">
                    <header>
                        <h3>{getString("videos.courseLectures")}</h3>
                        <p className="typography-body">{getString("lecture.unpublishedVideosInfo")}</p>
                    </header>
                    {this._renderVideoList()}
                </div>
            </React.Fragment>
        );
    }
}

AssignedVideosView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    auth: PropTypes.object,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    videos: PropTypes.array,
    onVideoActions: PropTypes.func
};

AssignedVideosView.defaultProps = {
    videos: [],
    onVideoActions: () => {}
};

export default AssignedVideosView;
