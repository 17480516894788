/** ------------------------------------------------------------------------------------------------------------------
 * Lectures
 * A top-level component for the Lectures page
 *
 * @examples
 *
 *  ```jsx
 *    let LectureComponent = WithRouter(InjectIntl(Lectures, initialState.locale));
 *    LectureComponent = StoreEnhancedComponent(LectureComponent, lectureReducers);
 *
 *    ReactDOM.render(
 *       <Provider store={store}>
 *          <LectureComponent />
 *       </Provider>
 *       document.getElementById("app-root")
 * );
 * ```
 *
 *  @component Lectures
 *  @import Lectures
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LocalNav from "../shared/local-nav";
import PageTemplate from "../shared/pages/template";
import ModalDialog from "../shared/modal";
import ModalSheet from "../shared/modal/modal-sheet";
import ContextualDialog from "../shared/modal/contextual-dialog";

import HTML from "@akwaba/html";
import DOM from "@akwaba/dom";
import Event from "@akwaba/events";

import { format } from "../../utils/strings";
import { isInstructorAccount } from "../../utils/app-utils";

import Calendar from "../shared/calendar";
import CalendarListView from "../shared/calendar/calendar-list-view";
import EditLectureView from "./views/edit-lecture-view";
import SegmentedControl from "../shared/segmented-control";
import LectureDetails from "./views/lecture-details";
import detailsTemplate from "./views/lecture-details-template";
import EditCourseView from "../courses/views/edit-course-view";

import {
    fromDateComponents,
    fromUTCString
} from "../../utils/dates";

import {
    fetchUserCourses,
    addNewCourse,
    onCourseDetailUpdated,
    resetQuery as resetCourseQuery,
    resetErrors as resetCourseErrors
} from "../../store/reducers/courses/courses.actions";

import {
    fetchUserLectures,
    fetchSharedSpaces,
    onLectureDetailUpdated,
    scheduleLecture,
    rescheduleLecture,
    resetQuery,
    resetErrors
} from "../../store/reducers/lectures/lectures.actions";

import "./lectures.scss";
import "../courses/courses.scss";
import "../../media/black-and-white/styles/main.scss";


export class Lectures extends Component {

    constructor(props) {
        super(props);

        const requires24HourFormat = /fr|it|de|ja/i.test(props.context.locale);
        const lectureStartTime = new Date();
        const { getString } = this.props.i18n;

        const segments = [
            {id: "month-view", title: getString("lecture.monthView") },
            {id: "list-view", title: getString("lecture.listView") },
        ];

        this.state = {
            showingModalDialog: false,
            showingErrorSheet: false,
            validationStates: this._initialValidationStates(),
            courseValidationStates: this._initialCourseValidationStates(),
            isValid: false,
            isEditMode: false,
            segments,
            selectedView: "month-view",
            currentDate: new Date(),
            selectedDate: new Date(),
            requires24HourFormat,
            lectureStartTime,
            eventList: [],
            selectedEventDate: null,
            selectedEvent: null,
            selectedNoEventDay: null,
            pendingScheduling: false
        };
    }

    _onSegmentActivated = (segment) => {
        const selectedIndex = (segment.id === "month-view") ? 0 : 1;
        const segments = this.state.segments.map((segment, index) => {
            segment.selected = (index === selectedIndex);
            return segment;
        });

        this.setState({
            selectedView: segment.id,
            segments,
            selectedEvent: null
        });
    };

    _initialValidationStates() {
        return {
            title: false,
            courseId: false,
            roomId: false
        };
    }

    _initialCourseValidationStates() {
        return {
            id: false,
            title: false,
            startDate: false,
            endDate: false
        };
    }

    _onInvalidLectureDetails = (name) => {
        const { validationStates } = this.state;
        validationStates[name] = false;

        this.setState({
            validationStates
        });
    };

    _onScheduleLecture = () => {
        const { auth: { userInfo }} = this.props;

        if (!isInstructorAccount(userInfo)) {
            return;
        }

        return (this.props.courses.courseList.length === 0) ?
            this.setState({
                showingNoCoursesErrorSheet: true,
                pendingScheduling: true
            }) :
            this.setState({
                lectureStartTime: new Date(),
                autoselectDate: null,
                showingModalDialog: true
            });
    };

    _onCancel = () => {
        this.props.resetQuery();
        this.setState({
            validationStates: this._initialValidationStates(),
            showingModalDialog: false,
            isEditMode: false
        });
    };

    _onCourseActionCancelled = () => {
        this.props.resetCourseQuery();
        this.setState({
            courseValidationStates: this._initialCourseValidationStates(),
            showingModalDialog: false,
            showingCourseCreationDialog: false,
            showingCourseCreationError: false,
        });
    };

    _onLectureDetailsUpdated = (name, value) => {
        const { i18n } = this.props;
        const { validationStates } = this.state;

        if (name === "startDate") {
            const dateString = i18n.formatDate(value, i18n.languagePack.dateFormat.SHORT_DASHED);
            value = dateString.split("-").concat(value.getHours(), value.getMinutes(), 0).join(",");
        }

        this.props.onLectureDetailUpdated(name, value);

        if (name === "newRoom") {
            validationStates.roomId = true;
        } else if (Object.keys(validationStates).includes(name)) {
            validationStates[name] = true;
        }

        this.setState({
            validationStates
        });
    };

    _onUpdateLecture = () => {
        const { id } = this.props.auth.userInfo;

        if (this.state.isEditMode) {
            this.props.rescheduleLecture(id, this.state.editingLecture.id);
        } else {
            this.props.scheduleLecture(id);
        }
    };

    _listEventRenderer = (date, events) => {
        const { i18n } = this.props;
        const {
            selectedEvent,
            selectedNoEventDay
        } = this.state;
        const formattedDate = i18n.formatDate(date, i18n.languagePack.dateFormat.SHORT_DASHED);
        const formattedEmptyDate = !selectedNoEventDay ? null :
            i18n.formatDate(selectedNoEventDay, i18n.languagePack.dateFormat.SHORT_DASHED);

        return !events.length ? (
            <li data-date={formattedDate}
                className={classNames("events no-items", { "active-day": formattedDate === formattedEmptyDate })}
                onClick={this._onListViewDayClicked}
                onDoubleClick={this._onListViewDayDoubleClicked}>
                <p className="info">{i18n.getString("lecture.noLecturesScheduled")}</p>
            </li>
        ) : events.map((event) => (
            <li data-id={event.id}
                data-date={formattedDate}
                key={event.id}
                className={classNames({"active-day": (selectedEvent && selectedEvent.id === event.id)})}
                onClick={this._onListViewDayClicked}>
                <em data-type="color-code" className={classNames("circle-code filled", event.type)} />
                <h4 data-type="event-name" className="ellipsis">{event.name}</h4>
                <p data-type="event-info" className="info">
                    {event.course.id.toUpperCase()}: {event.course.title}
                </p>
                <p data-type="event-info" className="info">
                    {event.room.name}
                </p>
                <span data-type="event-time" className="date-time">{event.startTime} - {event.endTime}</span>
            </li>
        ));
    };

    _onErrorSheetActions = () => {
        this.props.resetErrors();
        this.props.resetCourseErrors();

        this.setState({
            showingErrorSheet: false,
            showingNoCoursesErrorSheet: false,
            showingCourseCreationError: false
        });
    };

    _onCreateCourseAction = () => {
        this.setState({
            showingErrorSheet: false,
            showingNoCoursesErrorSheet: false
        }, () => {
            setTimeout(() => {
                this.setState({
                    showingCourseCreationDialog: true
                });
            }, 50);
        });
    };

    _onDateChanged = (date) => {
        this.setState({
            currentDate: date
        });

        if (this.state.selectedView === "list-view") {
            const selectedDate = new Date(date.getFullYear(), date.getMonth(), 1);

            this.setState({
                selectedDate
            });
        }
    };

    _generateEventList() {
        const { formatDate, languagePack } = this.props.i18n;

        return this.props.lectures.lectureList.map((lecture) => {
            const startTime = fromUTCString(lecture.startDate);
            const endTime = fromUTCString(lecture.endDate);

            return {
                ...lecture,
                name: lecture.title,
                type: `color-${lecture.course.colorCode}`,
                startTime: formatDate(startTime, languagePack.timeFormat.MEDIUM),
                endTime: formatDate(endTime, languagePack.timeFormat.MEDIUM),
            };
        });
    }

    _renderDetailsForSelectedDay() {
        const {
            i18n,
            auth: { userInfo: { id }}
        } = this.props;
        const {
            selectedEventDate,
            selectedEvent
        } = this.state;

        if (!selectedEventDate) {
            return null;
        }

        const formattedDate = i18n.formatDate(selectedEventDate, i18n.languagePack.dateFormat.LONG);

        return (
            <React.Fragment>
                <header className="selected-date">
                    <h3>{selectedEventDate.getDate()}</h3>
                    <p>{formattedDate}</p>
                </header>
                <div className="content">
                    {!selectedEvent ?
                        <div className="no-content">
                            <p className="info">
                                {i18n.getString("lecture.noLecturesScheduled")}
                            </p>
                        </div> :
                        <LectureDetails
                            lecture={selectedEvent}
                            canEdit={id === selectedEvent.instructor.id}
                            attendLabel={i18n.getString("lecture.attend")}
                            rescheduleLabel={i18n.getString("lecture.reschedule")}
                            onClick={this._onLectureDetailsActions}
                        />
                    }
                </div>
            </React.Fragment>
        );
    }

    _onLectureDetailsActions = (action, id) => {
        const lecture = this.props.lectures.lectureList.find((lecture) => lecture.id === id);
        const lectureStartTime = fromUTCString(lecture.startDate);

        if (action === "edit") {
            return this.setState({
                editingLecture: lecture,
                lectureStartTime,
                isEditMode: true,
                autoselectDate: null,
                showingModalDialog: true
            });
        }

        this.props.history.push(`${this.props.match.url}/${lecture.id}`);
    };

    _onMonthViewDayClicked = (event) => {
        const node = event.target;
        let selectedEvent = null;
        let eventElement = null;

        switch (node.getAttribute("data-type")) {
            case "color-code":
            case "event-name":
            case "event-time":
                eventElement = node.parentNode;
                const lectureId = eventElement.getAttribute("data-id");
                selectedEvent = this._findEventById(lectureId);
                break;

            default:
                selectedEvent = null;
                break;
        }

        this.setState({
            selectedEvent
        });

        if (eventElement) {
            this._renderDetailsForSelectedMonthEvent(selectedEvent, eventElement);
        }
    };

    _renderDetailsForSelectedMonthEvent(selectedEvent, eventElement) {
        if (this.contextualDialog) {
            this.contextualDialog.hide();
            this.contextualDialog = null;
        }

        const {
            auth: { userInfo: { id }},
            i18n
        } = this.props;
        const {
            course,
            room
        } = selectedEvent;

        const markup = format(detailsTemplate, {
            name: selectedEvent.title,
            courseTitle: [(course.id || course.courseId).toUpperCase(), course.title].join(": "),
            startTime: selectedEvent.startTime,
            endTime: selectedEvent.endTime,
            room: room.name,
            attendLabel: i18n.getString("lecture.attend"),
            rescheduleLabel: i18n.getString("lecture.reschedule")
        });

        const contentContainer = HTML.createElement("div", {
            className: "weekday-detail-container lecture-detail-container"
        });
        HTML.setContent(contentContainer, markup);
        Event.add(contentContainer, "click", this._onContextualDialogActions);

        if (id === selectedEvent.instructor.id) {
            const [rescheduleButton] = DOM.select(".reschedule-button", contentContainer);
            HTML.show(rescheduleButton);
        }

        this.contextualDialog = new ContextualDialog({
            content: contentContainer,
            relativeTo: eventElement,
            size: "small",
            theme: "dark",
            offsetLeft: 18,
            offsetTop: -this.pageContainer.scrollTop,
            modalDidHide: this._onContextualModalDismissed
        });
    }

    _onContextualDialogActions = (event) => {
        this.contextualDialog.hide();
        this._onLectureDetailsActions(event.target.getAttribute("data-action"), this.state.selectedEvent.id);
    };

    _onContextualModalDismissed = () => {
        this.contextualDialog = null;
    };

    _onMonthViewDayDoubleClicked = (date) => {
        this._scheduleLectureForDate(date);
    };

    _onListViewDayClicked = (event) => {
        const node = event.currentTarget;
        const components = node.getAttribute("data-date").split("-");
        const selectedDateISO = fromDateComponents(parseInt(components[0], 10), parseInt(components[1], 10) - 1,
            parseInt(components[2], 10));

        if (node.classList.contains("no-items")) {
            return this.setState({
                selectedEvent: null,
                selectedEventDate: selectedDateISO,
                selectedNoEventDay: selectedDateISO
            });
        }

        const selectedEvent = this.state.eventList.find((event) => event.id === (node.getAttribute("data-id")));

        this.setState({
            selectedEvent,
            selectedEventDate: selectedDateISO,
            selectedNoEventDay: null
        });
    };

    _onListViewDayDoubleClicked = (event) => {
        const selectedDate = event.currentTarget.getAttribute("data-date");
        this._scheduleLectureForDate(selectedDate);
    };

    _findEventById(eventId) {
        return this.state.eventList.find((event) => event.id === eventId);
    }

    _scheduleLectureForDate(selectedDate) {
        const { auth: { userInfo }} = this.props;

        if (!isInstructorAccount(userInfo)) {
            return;
        }

        const components = selectedDate.split("-");
        const lectureStartTime = fromDateComponents(parseInt(components[0], 10), parseInt(components[1], 10) - 1,
            parseInt(components[2], 10));

        const { localTime } = userInfo;
        let startTime;

        if (localTime) {
            startTime = fromUTCString(localTime);
        }

        lectureStartTime.setHours(!isNaN(startTime) ? startTime.getHours() : new Date().getHours());

        this.setState({
            autoselectDate: selectedDate,
            lectureStartTime,
            showingModalDialog: true
        });
    }

    _onCourseDetailsUpdated = (name, value) => {
        value = (name === "startDate" || name === "endDate") ? this.props.i18n.formatDate(value, "%Y-%m-%D") :
            (name === "price") ? parseFloat(value.replace(/[,]+/, ".")) : value;
        this.props.onCourseDetailUpdated(name, value);
        const { courseValidationStates } = this.state;

        if (Object.keys(courseValidationStates).includes(name)) {
            courseValidationStates[name] = true;

            this.setState({
                courseValidationStates
            });
        }
    };

    _onColorCodeSelected = (colorCode, selected) => {
        this.props.onCourseDetailUpdated("colorCode", selected ? colorCode : null);
    };

    _onInvalidCourseDetails = (name) => {
        const { courseValidationStates } = this.state;
        courseValidationStates[name] = false;

        this.setState({
            courseValidationStates
        });
    };

    _onAddNewCourse = () => {
        this.props.addNewCourse(this.props.auth.userInfo.id);
    };

    componentDidMount() {
        this.pageContainer = DOM.find(".main-content", document.body);
        const lectureListAvailable = (this.props.lectures.lectureList.length > 0);

        if (lectureListAvailable) {
            this.setState({
                eventList: this._generateEventList()
            });
        } else {
            const { id } = this.props.auth.userInfo;
            this.props.fetchUserCourses(id);
            this.props.fetchUserLectures(id);
            this.props.fetchSharedSpaces(id);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lectures.lectureList.length !== this.props.lectures.lectureList.length) {
            this.setState({
                eventList: this._generateEventList()
            });
        }

        if (prevProps.lectures.isUpdating === true && this.props.lectures.isUpdating === false) {
            const eventList = this._generateEventList();
            let selectedEvent = null;
            let selectedEventDate = null;

            if (this.state.selectedEvent) {
                const updatedEvent = eventList.find((event) => event.id === this.state.selectedEvent.id);
                const previousStartMonth = fromUTCString(this.state.selectedEvent.startDate).getMonth();
                const currentStartMonth = fromUTCString(updatedEvent.startDate).getMonth();

                if (previousStartMonth === currentStartMonth) {
                    selectedEvent = updatedEvent;
                    selectedEventDate = fromUTCString(updatedEvent.startDate);
                }
            } else if (this.state.autoselectDate) {
                const { i18n } = this.props;
                const components = this.state.autoselectDate.split("-");
                selectedEventDate = fromDateComponents(parseInt(components[0], 10), parseInt(components[1], 10) - 1,
                    parseInt(components[2], 10));

                selectedEvent = eventList.find((event) => {
                    const startDateString = i18n.formatDate(fromUTCString(event.startDate),
                        i18n.languagePack.dateFormat.SHORT_DASHED);
                    return startDateString === this.state.autoselectDate;
                });
            }

            const { validationStates } = this.state;
            Object.keys(validationStates).forEach((key) => {
                validationStates[key] = false;
            });

            this.setState({
                eventList,
                validationStates,
                selectedEvent,
                selectedEventDate,
                autoselectDate: null,
                showingModalDialog: false,
                showingErrorSheet: this.props.lectures.error
            });

            this.props.fetchSharedSpaces(this.props.auth.userInfo.id);
        }

        if (prevProps.courses.isUpdating === true && this.props.courses.isUpdating === false) {
            const { courseValidationStates } = this.state;
            Object.keys(courseValidationStates).forEach((key) => {
                courseValidationStates[key] = false;
            });

            const newState = {
                courseValidationStates,
                showingCourseCreationDialog: false
            };

            if (this.props.courses.courseList.length > 0 && this.state.pendingScheduling) {
                newState.pendingScheduling = false;
                newState.showingModalDialog = true;
            }

            newState.showingCourseCreationError = !!this.props.courses.error;

            this.setState(newState);
        }
    }

    render() {
        const {
            auth: { userInfo },
            i18n,
            courses: { courseList },
            lectures: {
                isUpdating,
                sharedSpaces
            },
            courses: {
                isUpdating: isCreatingCourse
            }
        } = this.props;
        const {
            segments,
            selectedView,
            currentDate,
            showingModalDialog,
            showingErrorSheet,
            showingNoCoursesErrorSheet,
            editingLecture,
            isEditMode,
            eventList,
            requires24HourFormat,
            lectureStartTime,
            selectedEvent,
            showingCourseCreationDialog,
            showingCourseCreationError
        } = this.state;
        const isValidForm = Object.values(this.state.validationStates).every((item) => !!item);
        const isValidCourseForm = Object.values(this.state.courseValidationStates).every((item) => !!item);

        return (
            <React.Fragment>
                <PageTemplate
                    {...this.props}
                    className="lecture-wrapper">
                    <LocalNav
                        title={i18n.getString("title.lectures")}
                        page="lectures"
                        className="section-local-nav"
                        showPageInfo={true}
                        showContextualAction={isInstructorAccount(userInfo)}
                        contextualActionLabel={i18n.getString("lecture.scheduleLecture")}
                        onContextualAction={this._onScheduleLecture}
                    />
                    <div className="module-calendar">
                        <div className="view-selector">
                            <SegmentedControl
                                segments={segments}
                                onSegmentActivated={this._onSegmentActivated}
                            />
                        </div>
                        { (selectedView === "month-view") ?
                            <React.Fragment>
                                <Calendar
                                    date={currentDate}
                                    dateStrings={i18n.languagePack}
                                    dateFormatter={i18n}
                                    events={eventList}
                                    activeEvent={selectedEvent}
                                    onDateChanged={this._onDateChanged}
                                    onDayClicked={this._onMonthViewDayClicked}
                                    onDayDoubleClicked={this._onMonthViewDayDoubleClicked}
                                />
                            </React.Fragment> :
                            <div className="calendar-list-wrapper">
                                <CalendarListView
                                    date={currentDate}
                                    dateStrings={i18n.languagePack}
                                    dateFormatter={i18n}
                                    events={eventList}
                                    eventRenderer={this._listEventRenderer}
                                    onDateChanged={this._onDateChanged}
                                />
                                <div className="weekday-detail-container">
                                    {this._renderDetailsForSelectedDay()}
                                </div>
                            </div>
                        }
                    </div>
                </PageTemplate>
                {showingModalDialog &&
                    <ModalDialog
                        mode="compact"
                        title={i18n.getString(isEditMode ? "lecture.updateLecture" : "lecture.scheduleLecture")}
                        okLabel={isEditMode ? i18n.getString("lecture.updateLecture") :
                            i18n.getString("lecture.scheduleLecture")}
                        cancelLabel={i18n.getString("generic.cancel")}
                        autoDismiss={false}
                        okButtonDisabled={isUpdating || !isValidForm}
                        onModalConfirm={this._onUpdateLecture}
                        onModalClose={this._onCancel}>
                        <div className="add-course-wrapper">
                            <EditLectureView
                                mode={isEditMode ? "edit" : "create"}
                                courses={courseList}
                                lecture={isEditMode ? editingLecture : null}
                                lectureRooms={sharedSpaces}
                                i18n={this.props.i18n}
                                context={this.props.context}
                                requires24HourFormat={requires24HourFormat}
                                startTime={lectureStartTime}
                                showSpinner={isUpdating}
                                onChange={this._onLectureDetailsUpdated}
                                onColorCodeSelected={this._onColorCodeSelected}
                                onError={this._onInvalidLectureDetails}
                            />
                        </div>
                    </ModalDialog>}
                {showingErrorSheet &&
                    <ModalSheet
                        title={i18n.getString("lecture.lectureScheduleError")}
                        type="error"
                        mode="compact"
                        showCancelButton={false}
                        okLabel={i18n.getString("generic.OK")}
                        onOK={this._onErrorSheetActions}
                        onModalClose={this._onErrorSheetActions}>
                        <p>{i18n.getString(this.props.lectures.error)}</p>
                    </ModalSheet>}
                {showingNoCoursesErrorSheet &&
                    <ModalSheet
                        title={i18n.getString("course.noCoursesInfo")}
                        type="error"
                        mode="compact"
                        okLabel={i18n.getString("course.addCourseLabel")}
                        cancelLabel={i18n.getString("generic.notNow")}
                        onOK={this._onCreateCourseAction}
                        onModalClose={this._onErrorSheetActions}>
                        <p dangerouslySetInnerHTML={{__html: i18n.getString("course.noCoursesMessage")}} />
                    </ModalSheet>}
                {showingCourseCreationDialog &&
                    <ModalDialog
                        mode="compact"
                        title={i18n.getString("course.addNewCourse")}
                        okLabel={i18n.getString("course.addCourse")}
                        cancelLabel={i18n.getString("generic.cancel")}
                        autoDismiss={false}
                        okButtonDisabled={isCreatingCourse || !isValidCourseForm}
                        onModalConfirm={this._onAddNewCourse}
                        onModalClose={this._onCourseActionCancelled}>
                        <div className="add-course-wrapper">
                            <EditCourseView
                                mode="create"
                                course={null}
                                i18n={i18n}
                                showSpinner={isCreatingCourse}
                                onChange={this._onCourseDetailsUpdated}
                                onColorCodeSelected={this._onColorCodeSelected}
                                onError={this._onInvalidCourseDetails}
                            />
                        </div>
                    </ModalDialog>}
                {showingCourseCreationError &&
                    <ModalSheet
                        title={i18n.getString("course.courseCreationError")}
                        type="error"
                        mode="compact"
                        showCancelButton={false}
                        okLabel={i18n.getString("generic.OK")}
                        onOK={this._onErrorSheetActions}
                        onModalClose={this._onErrorSheetActions}>
                        <p>{i18n.getString(this.props.courses.error)}</p>
                    </ModalSheet>}
            </React.Fragment>
        );
    }
}

Lectures.propTypes = {
    title: PropTypes.string,
    i18n: PropTypes.object,
    profile: PropTypes.object
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth,
    courses: state.courses,
    lectures: state.lectures,
    profile: state.profile
});

const mapDispatchToProps = {
    fetchUserCourses,
    fetchUserLectures,
    fetchSharedSpaces,
    onLectureDetailUpdated,
    scheduleLecture,
    rescheduleLecture,
    resetQuery,
    resetErrors,
    onCourseDetailUpdated,
    resetCourseQuery,
    resetCourseErrors,
    addNewCourse
};

export default connect(mapStateToProps, mapDispatchToProps)(Lectures);
