const isAccountOfType = (userInfo, type) => {
    if (!(userInfo && userInfo.accountType)) {
        return false;
    }

    return (userInfo.accountType === type);
};

export const isStudentAccount = (userInfo) => isAccountOfType(userInfo, "student");

export const isInstructorAccount = (userInfo) => isAccountOfType(userInfo, "instructor");

export const isAdminAccount = (userInfo) => isAccountOfType(userInfo, "administrator");


