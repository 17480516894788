/* global Classroom */
/** ----------------------------------------------------------------------------------------------------------------
 * AuthenticatedPage
 * A component that wraps the main page container components with the logic to validate active sessions. It also
 * ends the session after a certain period of inactivity.
 *
 * @examples
 *
 *  ```jsx
 *    <AuthenticatedPage>
 *      <PageTemplate>
 *          <LocalNav />
 *          ...
 *       </PageTemplate>
 *    </AuthenticatedPage>
 * ```
 *
 *  @component AuthenticatedPage
 *  @import AuthenticatedPage
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Spinner from "../spinner";

import {
    retrieveUserInfo,
    retrieveUserSession,
    logout,
    userSessionTimedOut
} from "../../../store/reducers/auth/auth.actions";

import { localStorage } from "../../../utils/storage";


/* Set the session duration to 20 minutes */
const SESSION_TIMEOUT = 20 * 60 * 1000;


export class AuthenticatedPage extends PureComponent {

    constructor(props) {
        super(props);

        const userInfoAvailable = !!(
            this.props.auth.userInfo &&
            this.props.auth.userInfo.id &&
            this.props.auth.userInfo.avatarURL
        );

        this.state = {
            userInfoAvailable,
            userSessionAvailable: this.props.auth.activeSession
        };
    }

    onSessionReset = () => {
        this.restartSessionTimer();
    };

    restartSessionTimer = () => {
        if (this.sessionTimer) {
            clearTimeout(this.sessionTimer);
            this.sessionTimer = null;
        }

        this.sessionTimer = setTimeout(this.onSessionTimeout, SESSION_TIMEOUT);
    };

    onSessionTimeout = () => {
        this.props.userSessionTimedOut();
        this.props.logout(this.props.auth.userInfo.id);
    };

    suspendSession = () => {
        if (this.sessionTimer) {
            clearTimeout(this.sessionTimer);
            this.sessionTimer = null;
        }

        this.stopObservingBrowserEvents();
    };

    resumeSession = () => {
        this.observeBrowserEvents();
    };

    observeBrowserEvents = () => {
        document.addEventListener("mousemove", this.onSessionReset);
        document.addEventListener("keyup", this.onSessionReset);
    };

    stopObservingBrowserEvents = () => {
        document.removeEventListener("mousemove", this.onSessionReset);
        document.removeEventListener("keyup", this.onSessionReset);
    };

    checkForExistingUserSession = () => {
        setTimeout(() => {
            this.props.retrieveUserSession(this.props.auth.userInfo.id);
        }, 300);
    };

    componentDidMount() {
        this.observeBrowserEvents();

        if (this.state.userInfoAvailable) {
            return this.checkForExistingUserSession();
        }

        /**
         * This use case handles a page refresh. It checks whether the user info is available in the local storage
         * and, if so, retrieves the details for the current user. Otherwise, it redirects to the home page.
         */
        let shouldRedirectToHomePage = false;
        let userId;
        let userInfo = localStorage.getItem("userInfo");

        userInfo = userInfo ? JSON.parse(userInfo) : null;
        const userIdAvailable = !!(userInfo && userInfo.id);
        shouldRedirectToHomePage = !userIdAvailable;

        if (userIdAvailable) {
            userId = userInfo.id;
        }

        if (shouldRedirectToHomePage) {
            location.href = Classroom.contextPath;
            return;
        }

        this.props.retrieveUserInfo(userId)
            .then(this.checkForExistingUserSession);
    }

    componentWillUnmount() {
        if (this.sessionTimer) {
            clearTimeout(this.sessionTimer);
            this.sessionTimer = null;
        }

        this.stopObservingBrowserEvents();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.auth.isLoadingUserInfo === true &&
            this.props.auth.isLoadingUserInfo === false &&
            this.props.auth.userInfo.id
        ) {
            this.setState({
                userInfoAvailable: true
            });
        }

        const isSessionAvailable = !!(
            prevProps.auth.isLoadingSession === true &&
            this.props.auth.isLoadingSession === false &&
            this.props.auth.sessionInfo.id
        );

        if (isSessionAvailable) {
            const isSessionActive = this.props.auth.sessionInfo.isActive === "true";

            if (!isSessionActive) {
                location.href = Classroom.contextPath;
            }
        }
    }

    render() {
        const { userInfoAvailable } = this.state;

        return !userInfoAvailable ?
            <Spinner /> :
            <React.Fragment>
                {this.props.children}
            </React.Fragment>;
    }
}

AuthenticatedPage.propTypes = {
    children: PropTypes.node,
    context: PropTypes.object,
    auth: PropTypes.object,
    retrieveUserInfo: PropTypes.func,
    retrieveUserSession: PropTypes.func,
    logout: PropTypes.func,
    userSessionTimedOut: PropTypes.func
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth
});

const mapDispatchToProps = {
    retrieveUserInfo,
    retrieveUserSession,
    logout,
    userSessionTimedOut
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedPage);
