/** --------------------------------------------------------------------------------------------------------------------
 * LoginView
 * A component that renders the different views for managing the login and password reset experiences
 *
 * @examples
 *
 *  ```jsx
 *    <LoginView
 *      className="user-login"
 *      onLogin={(username, password) => console.log("Logging in...")}
 *      onPasswordForgotten={(username) => console.log("I forgot my password...")}
 *    />
 * ```
 *
 *  @component LoginView
 *  @import LoginView
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import LoginForm from "./login-form";
import PasswordForm from "./password-form";
import Button from "../../shared/button";
import LanguageSelector from "../../shared/language-selector";
import { Loader } from "../../shared/spinner/loader";
import { SiteInfo } from "./site-info";

import "./login-view.scss";


export default class LoginView extends Component {

    constructor(props) {
        super(props);

        this.loginFormContainer = React.createRef();
        this.state = {
            username: "",
            password: "",
            isFlipped: false
        };
    }

    _onLogin = (username, password) => {
        if (!(username.length && password.length)) {
            return this._onLoginError();
        }

        if (this.props.onLogin) {
            this.props.onLogin(username, password);
        }
    };

    _onPasswordForgotten = () => {
        this.setState({
            isFlipped: true
        });
    };

    _onPasswordHelpCancelled = () => {
        this.setState({
            isFlipped: false
        });
    };

    _onPasswordReset = (username) => {
        this.setState({
            isFlipped: false
        });

        if (this.props.onPasswordReset) {
            this.props.onPasswordReset(username);
        }
    };

    _onBackToHome = () => {
        if (this.props.onBackToHome) {
            this.props.onBackToHome();
        }
    };

    _onLanguageSelected = (language) => {
        if (this.props.onLanguageSelected) {
            this.props.onLanguageSelected(language);
        }
    };

    _onLoginError() {
        if (!this.loginFormContainer) {
            return;
        }

        setTimeout(() => {
            this.loginFormContainer.current.classList.add("shake");
        }, 10);

        setTimeout(() => {
            this.loginFormContainer.current.classList.remove("shake");
        }, 700);
    }

    componentDidUpdate() {
        if (this.props.loginFailed) {
            this._onLoginError();
        }
    }

    render() {
        const {
            accountType,
            title,
            locale,
            disabled,
            showSpinner,
            country,
            languages,
            i18n,
            i18n: { format, getString }
        } = this.props;
        const { isFlipped } = this.state;
        const classListFront = classNames("login-view front", { "flip-to-back": isFlipped });
        const classListBack = classNames("login-view back", { "flip-to-front": isFlipped });
        const isStudent = accountType === "student";

        return (
            <section className={classNames("view-wrapper login-view-wrapper", accountType)}>
                <section className="branding">
                    <header>
                        <h1>
                            <a href="/" title={title}>
                                <img alt={title}
                                    src="https://akwaba.s3-us-west-1.amazonaws.com/assets/university-avenue-logo.png" />
                            </a>
                        </h1>
                    </header>
                    <div className="locale-selector">
                        <LanguageSelector
                            locale={locale}
                            iconClassName="icon-chevron-down-white"
                            country={country}
                            languages={languages}
                            onLanguageSelected={this._onLanguageSelected}
                        />
                    </div>
                </section>
                <section className="container">

                    <div className={classNames("container section-login", {
                        "student-login": isStudent,
                        "instructor-login": !isStudent
                    })}>
                        <h2 className={classNames("intro", locale)}>
                            {getString(isStudent ? "login.studentIntro" : "login.instructorIntro")}
                        </h2>
                        <div className={classListFront} ref={this.loginFormContainer}>
                            <LoginForm
                                i18n={this.props.i18n}
                                disabled={disabled}
                                onLogin={this._onLogin}
                                onPasswordForgotten={this._onPasswordForgotten}
                            />
                            {showSpinner && <div className="loader-wrapper">
                                <Loader />
                            </div>}
                        </div>
                        <div className={classListBack}>
                            <PasswordForm
                                locale={locale}
                                i18n={this.props.i18n}
                                onCancel={this._onPasswordHelpCancelled}
                                onPasswordReset={this._onPasswordReset}
                            />
                        </div>
                    </div>
                    <div className="action-buttons">
                        <Button
                            title={getString("login.backToHome")}
                            onClick={this._onBackToHome}
                        />
                    </div>
                    <div className="signup-wrapper">
                        <p>
                            <span>{getString("login.noAccountPrompt")}</span>
                            <strong>
                                <Link to={isStudent ? "/join-university" : "/create-account"}>
                                    {getString("login.createAnAccount")}
                                </Link>
                            </strong>
                        </p>
                    </div>
                    <div className={classNames("app-store-download", locale)} style={{display: "none"}}>
                        <a id="appStoreDownload" className="app-store-badge" title={getString("application.appStore")}
                            href="https://itunes.apple.com/us/app/1-university-avenue/id919768724?ls=1&mt=8">
                            {getString("application.appStore")}
                        </a>
                    </div>
                </section>

                <section className="site-info">
                    <footer className="block">
                        <p className="copyright">
                            <span>{format("application.copyright", [new Date().getFullYear()])}</span>
                            <SiteInfo i18n={i18n} />
                        </p>
                    </footer>
                </section>

            </section>
        );
    }
}

LoginView.propTypes = {
    accountType: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    locale: PropTypes.string,
    country: PropTypes.string,
    i18n: PropTypes.object,
    disabled: PropTypes.bool,
    showSpinner: PropTypes.bool,
    languages: PropTypes.array,
    onBackToHome: PropTypes.func,
    onLogin: PropTypes.func,
    loginFailed: PropTypes.bool,
    onPasswordReset: PropTypes.func,
    onLanguageSelected: PropTypes.func
};

LoginView.defaultProps = {
    accountType: "student",
    languages: [],
    onBackToHome: () => {},
    onLogin: () => {},
    onPasswordReset: () => {},
    onLanguageSelected: () => {}
};
