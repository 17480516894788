/** -------------------------------------------------------------------------------------------------------------------
 * Rating
 * A component that renders a rating with a maximum value of 5
 *
 * @examples
 *
 *  ```jsx
 *    <Rating
 *      average={4}
 *      size="compact"
 *    />
 * ```
 *
 *  @component Rating
 *  @import Rating
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./rating.scss";

const metrics = {
    small: { width: 12, margin: 3 },
    medium: { width: 16, margin: 4 },
    large: { width: 32, margin: 8 },
    compact: { width: 16, margin: 0 }
};


const Rating = (props) => {
    const {
        onClick,
        tabIndex,
        average,
        hidden,
        size,
        ariaLabel
    } = props;

    const metric = metrics[size];
    const width = parseInt(average, 10) * metric.width + (metric.width - metric.margin) * (average % 1);
    const style = { width: `${width}px` };

    return (
        <div className={classNames("rating-wrapper", size, { "hide-content": hidden })}>
            <span
                className="star-container"
                onClick={onClick}
                alt={ariaLabel}
                tabIndex={tabIndex}
                aria-label={ariaLabel}
                role="link">
                <span
                    className="star"
                    alt="rating star"
                    style={style}
                />
            </span>
        </div>
    );
};

Rating.propTypes = {
    className: PropTypes.string,
    average: PropTypes.number.isRequired,
    count: PropTypes.number,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(["small", "medium", "large", "compact"]),
    hidden: PropTypes.bool,
    tabIndex: PropTypes.number,
    ariaLabel: PropTypes.string
};

Rating.defaultProps = {
    size: "medium",
    count: 0,
    average: 4,
    tabIndex: 0,
    hidden: false,
    onClick: () => {}
};

export default Rating;
