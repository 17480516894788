import { combineReducers } from "redux";
import universities from "./reducers/universities/universities";
import auth from "./reducers/auth/auth";
import courses from "./reducers/courses/courses";
import dashboard from "./reducers/dashboard/dashboard";
import lectures from "./reducers/lectures/lectures";
import videos from "./reducers/videos/videos";
import profile from "./reducers/profile/profile";
import notifications from "./reducers/notifications/notifications";
import network from "./reducers/network/network";
import context from "./reducers/application/context";
import account from "./reducers/account/account";

const defaultReducers = {
    context,
    auth,
    account,
    network,
    profile,
    notifications
};

export default combineReducers({
    ...defaultReducers,
    universities,
    courses,
    dashboard,
    lectures,
    videos
});

export const dashboardReducers = combineReducers({
    ...defaultReducers,
    dashboard,
    courses,
    lectures
});

export const authReducers = combineReducers({
    context,
    auth
});

export const courseReducers = combineReducers({
    ...defaultReducers,
    universities,
    courses,
    lectures,
    videos
});

export const lectureReducers = combineReducers({
    ...defaultReducers,
    courses,
    lectures,
    videos
});

export const videoReducers = combineReducers({
    ...defaultReducers,
    courses,
    videos,
    lectures
});

export const profileReducers = combineReducers({
    ...defaultReducers
});

export const accountReducers = combineReducers({
    context,
    account
});
