/** -------------------------------------------------------------------------------------------------------------------
 * LectureDetailsView
 * A component that renders the details of a course. It handles the course home page and sub-navigation.
 *
 * @examples
 *
 *  ```jsx
 *
 *    ReactDOM.render(
 *       <LectureDetailsView
 *          courses={courses}
 *          lectures={lectures}
 *       />
 * ```
 *
 *  @component LectureDetailsView
 *  @import LectureDetailsView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import LocalNav from "../../shared/local-nav";
import lectureNav from "../../../store/data/lecture-nav";
import PageTemplate from "../../shared/pages/template";

import {
    fetchUserCourses
} from "../../../store/reducers/courses/courses.actions";

import {
    fetchUserLectures,
    fetchSharedSpaces,
    onLectureDetailUpdated,
    scheduleLecture,
    rescheduleLecture,
    resetQuery,
    resetErrors
} from "../../../store/reducers/lectures/lectures.actions";

import "../lectures.scss";
import LectureInfoView from "./lecture-info-view";
import LectureDiscussionsView from "./lecture-discussions-view";


export class LectureDetailsView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            course: {},
            isEditMode: false
        };
    }

    _getCurrentLecture() {
        const { id } = this.props.match.params;
        const lecture = this.props.lectures.lectureList.find((lecture) => lecture.id === id);

        return lecture || {};
    }

    _generateLectureNav() {
        const {
            i18n,
            match
        } = this.props;

        return lectureNav.map((link, index) => {
            const url = (index === 0) ? match.url : `${match.url}/${link.id}`;

            return {
                id: link.id,
                icon: link.icon,
                title: i18n.getString(link.localizedString),
                url
            };
        });
    }

    componentDidMount() {
        this.props.fetchUserLectures(this.props.auth.userInfo.id);
    }

    render() {
        const {
            match,
        } = this.props;
        const lecture = this._getCurrentLecture();
        const navLinks = this._generateLectureNav();

        return (
            <PageTemplate
                {...this.props}
                className="lecture-wrapper">
                <LocalNav
                    match={match}
                    title={lecture.title}
                    page="lecture-details"
                    caption=""
                    className="section-local-nav"
                    showPageInfo={true}
                    showContextualAction={false}
                    showLinks={false}
                    links={navLinks}
                />
                <Route exact path={match.url}
                    render={() => {
                        return (
                            <LectureInfoView
                                {...this.props}
                                lecture={lecture}
                            />
                        );
                    }}
                />
                <Route path={`${match.url}/discussions`}
                    render={() => <LectureDiscussionsView {...this.props} />}
                />

            </PageTemplate>
        );
    }
}

LectureDetailsView.propTypes = {
    id: PropTypes.string,
    auth: PropTypes.object,
    context: PropTypes.object,
    i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth,
    courses: state.courses,
    lectures: state.lectures
});

const mapDispatchToProps = {
    fetchUserCourses,
    fetchUserLectures,
    fetchSharedSpaces,
    onLectureDetailUpdated,
    scheduleLecture,
    rescheduleLecture,
    resetQuery,
    resetErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(LectureDetailsView);
