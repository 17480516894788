/** -------------------------------------------------------------------------------------------------------------------
 * ContactListView
 * A component that renders the contact list view for a given student (classmates, followers, following, etc)
 *
 * @examples
 *
 *  ```jsx
 *
 *    <ContactListView
 *      userInfo={userInfo}
 *      contacts={contacts}
 *      i18n={i18n}
 *      outboundFollows={followingList}
 *      onContactActions={(contact, action) => { console.log("onContactAction"); }}
 *    />
 * );
 * ```
 *
 *  @component ClassmatesView
 *  @import ClassmatesView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/


import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ContactInfo from "./contact-info";
import ContactDetailsView from "./contact-details-view";

import {
    STUDENTS,
    STUDENT,
    CLASSMATE,
    CLASSMATES,
    FOLLOWER,
    FOLLOWERS,
    FOLLOWING,
    SUGGESTION,
    SUGGESTIONS,
    USERS,
    USER,
    ACTION_UNFOLLOW
} from "../../../constants/contacts";

import { noop } from "../../../utils/functions";

const emptyContentMessages = {
    [STUDENTS]: "generic.noStudentsFound",
    [CLASSMATES]: "generic.noStudentsFound",
    [FOLLOWERS]: "contacts.noFollowers",
    [FOLLOWING]: "contacts.noFollowing",
    [SUGGESTIONS]: "contacts.noSuggestionsFound"
};


export class ContactListView extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            currentContact: null
        };
    }

    _onContactSelected = (event) => {
        const contactId = event.currentTarget.getAttribute("data-id");
        const currentContact = this._findContactById(contactId);

        this.setState({
            currentContact,
            isShowingContactDetails: true
        });
    };

    _findContactById(id) {
        return this.props.contacts.find((student) => student.id === id);
    }

    _hideContactDetails = () => {
        this.setState({
            isShowingContactDetails: false
        });
    };

    _isStudentsType() {
        return this.props.type === STUDENTS;
    }

    _isClassmatesType() {
        return this.props.type === CLASSMATES;
    }

    _isFollowersType() {
        return this.props.type === FOLLOWERS;
    }

    _isFollowingType() {
        return this.props.type === FOLLOWING;
    }

    _isSuggestionsType() {
        return this.props.type === SUGGESTIONS;
    }

    _getContactType() {
        switch (this.props.type) {
            case STUDENTS:
                return STUDENT;

            case FOLLOWERS:
                return FOLLOWER;

            case FOLLOWING:
                return FOLLOWING;

            case SUGGESTIONS:
                return SUGGESTION;

            case USERS:
                return USER;

            default:
                return CLASSMATE;
        }
    }

    _isFollowingContact(contact) {
        if (!contact) {
            return false;
        }

        const outboundFollowIDs = this.props.outboundFollows.map((user) => user.id);
        return outboundFollowIDs.includes(contact.id);
    }

    _renderContactList() {
        const {
            contacts,
            i18n
        } = this.props;
        const { currentContact } = this.state;

        const shouldShowFollowButton = this._isSuggestionsType() ? true :
            (this._isFollowersType() && !this._isFollowingContact(currentContact));
        const shouldShowFollowingLabel = this._isFollowingContact(currentContact);

        return (
            <React.Fragment>
                <div className="sidebar-wrapper">
                    <ul className="row item-list contact-list">
                        {contacts.map((contact) => {
                            const className = classNames("contact", {
                                selected: contact === currentContact
                            });

                            const props = {
                                type: this._getContactType(),
                                contact,
                                i18n
                            };

                            if (this._isFollowersType()) {
                                const isFollowingContact = this._isFollowingContact(contact);
                                props.showsFollowButton = !isFollowingContact;
                                props.showsFollowingLabel = isFollowingContact;
                            }

                            return (
                                <li
                                    key={contact.id}
                                    data-id={contact.id}
                                    className={className}
                                    onClick={this._onContactSelected}
                                >
                                    <ContactInfo {...props} />
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="details-wrapper">
                    <ContactDetailsView
                        type={this._getContactType()}
                        contact={currentContact}
                        i18n={i18n}
                        showsFollowButton={shouldShowFollowButton}
                        showsUnfollowButton={shouldShowFollowingLabel}
                        onContactActions={this._onContactActions}
                    />
                </div>
            </React.Fragment>
        );
    }

    _renderEmptyContentMessage() {
        const {
            type,
            i18n: { getString }
        } = this.props;

        return (
            <p className="info-message">
                <span>{getString(emptyContentMessages[type])}</span>
            </p>
        );
    }

    _onContactActions = (contact, action) => {
        const shouldResetDetails = (
            this._isSuggestionsType() ||
            (this._isFollowingType() && action === ACTION_UNFOLLOW)
        );

        if (shouldResetDetails) {
            this.setState({
                currentContact: null
            });
        }

        this.props.onContactActions(contact, action);
    };

    render() {
        const {
            type,
            contacts
        } = this.props;
        const { isShowingContactDetails } = this.state;

        const noContacts = (Array.isArray(contacts) && contacts.length === 0);
        const wrapperClassName = classNames("section-content-wrapper", `${type}-wrapper`, {
            "no-background": noContacts,
            "showing-details": isShowingContactDetails
        });

        return (
            <React.Fragment>
                <div className={wrapperClassName}>
                    {noContacts ? this._renderEmptyContentMessage() : this._renderContactList()}
                    {isShowingContactDetails && <div className="modal-actions">
                        <button className="modal-close icon icon-close"
                            data-action="close"
                            onClick={this._hideContactDetails}
                        />
                    </div>}
                </div>
            </React.Fragment>
        );
    }
}

ContactListView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    type: PropTypes.string,
    contacts: PropTypes.array.isRequired,
    outboundFollows: PropTypes.array,
    onContactActions: PropTypes.func
};

ContactListView.defaultProps = {
    type: "classmates",
    outboundFollows: [],
    onContactActions: noop
};

export default ContactListView;
