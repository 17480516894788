/** -------------------------------------------------------------------------------------------------------------------
 * InvitationView
 * A component that renders the view for processing a student invite.
 *
 * @examples
 *
 *  ```jsx
 *
 *    <InvitationView
 *       i18n={i18n}
 *       invite={invite}
 *       disabled={false}
 *       hasError={false}
 *       onInvitationCodeEntered={(code) => console.log("Processing code: ", code)}
 *    />
 * ```
 *
 *  @component InvitationView
 *  @import InvitationView
 *  @returns {object} React component
 *
*--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Loader } from "../../shared/spinner/loader";
import { noop } from "../../../utils/functions";


export class InvitationView extends PureComponent {

    render() {
        const {
            i18n: { getString },
            disabled,
            invite,
            hasError,
            onInvitationCodeEntered
        } = this.props;

        return (
            <div className="invitation-wrapper">
                <div className="invite-code-section">
                    <p className="label">{getString("register.enterInvitationCode")}</p>
                    <div className="invite-code-wrapper">
                        <input
                            type="text"
                            maxLength="4"
                            disabled={disabled}
                            className="invite-code"
                            defaultValue={invite ? invite.code : ""}
                            onChange={onInvitationCodeEntered}
                        />
                    </div>
                    <p className="instructions">{getString("register.inviteCodeInstructions")}</p>
                </div>
                <div className="verification-section">
                    {disabled && <div className="loader-wrapper">
                        <Loader />
                    </div>}
                    {invite && <dl>
                        <dt>{getString("register.invitationFrom")}:</dt>
                        <dd>{`${invite.initiator.firstName} ${invite.initiator.lastName}`}</dd>
                        <dt>{getString("generic.university")}:</dt>
                        <dd>{invite.university.name}</dd>
                    </dl>}
                    {hasError && <p className="error">{getString("register.invalidCode")}</p>}
                </div>
            </div>
        );
    }
}

InvitationView.propTypes = {
    invite: PropTypes.object,
    disabled: PropTypes.bool,
    i18n: PropTypes.object,
    hasError: PropTypes.bool,
    onInvitationCodeEntered: PropTypes.func
};

InvitationView.defaultProps = {
    onInvitationCodeEntered: noop
};

export default InvitationView;
