/** -------------------------------------------------------------------------------------------------------------------
 * CommentForm
 * A component that render the form for commenting on a discussion
 *
 *  @examples
 *
 *  ```jsx
 *    <CommentForm
 *      userInfo={userInfo}
 *      i18n={i18n}
 *      onSubmit={(comment) => { console.log("Comment submitted"); }}
 *    />
 * ```
 *
 *  @component CommentForm
 *  @import CommentForm
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import InputField from "../../shared/input-field";
import { noop } from "../../../utils/functions";
import {
    toUnicode,
    removeTags
} from "../../../utils/strings";

import "../courses.scss";


class CommentForm extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            userComment: ""
        };
    }

    _onChange = (value) => {
        this.setState({
            userComment: value
        });
    };

    _onSubmit = () => {
        const { userComment } = this.state;

        this.setState({
            userComment: ""
        });

        this.props.onSubmit(toUnicode(removeTags(userComment)));
    };

    render() {
        const {
            userInfo,
            i18n: { getString }
        } = this.props;
        const { userComment } = this.state;

        return (
            <div className="comment-form-wrapper">
                <figure className="avatar">
                    <img src={userInfo.avatarURL} />
                </figure>
                <div className="comment-form">
                    <InputField
                        value={userComment}
                        placeholder={getString("discussions.postComment")}
                        showClearControl={false}
                        resetValueOnSubmit={true}
                        onChange={this._onChange}
                        onSubmit={this._onSubmit}
                    />
                </div>
            </div>
        );
    }
}

CommentForm.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    onSubmit: PropTypes.func
};

CommentForm.defaultProps = {
    onSubmit: noop
};

export default CommentForm;
