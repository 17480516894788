/* global Classroom */
/* eslint-disable max-len, no-trailing-spaces, react/no-unescaped-entities */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Button from "../shared/button";
import Checkbox from "../shared/checkbox";
import { TermsAndConditionsText } from "./terms-and-conditions-text";

import { acceptTermsAndConditions } from "../../store/reducers/profile/profile.actions";

import "./terms-and-conditions.scss";


export class TermsAndConditions extends Component {

    constructor(props) {
        super(props);

        let shouldAcknowledgeTNC = false;
        const { auth } = this.props;

        if (auth && auth.userInfo) {
            shouldAcknowledgeTNC = auth.userInfo.acceptedTermsAndConditions !== true;
        }

        this.state = {
            shouldAcknowledgeTNC,
            tncAcknowledged: false
        };
    }

    _onTNCAcknowledged = (id, checked) => {
        this.setState({
            tncAcknowledged: checked
        });
    };

    _onCancel = () => {
        location.href = Classroom.contextPath;
    };

    _onConfirm = () => {
        this.props.acceptTermsAndConditions(this.props.auth.userInfo.id);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.profile.isUpdatingPreferences === true && this.props.profile.isUpdatingPreferences === false) {
            this.props.history.push("/dashboard"); 
        }
    }

    render() {
        const {
            i18n,
            profile,
        } = this.props;

        const {
            shouldAcknowledgeTNC,
            tncAcknowledged
        } = this.state;

        const isUpdatingPreferences = (profile && profile.isUpdatingPreferences);
        const logoURL = "https://akwaba.s3-us-west-1.amazonaws.com/assets/university-avenue-logo-small.png";

        return (
            <section className="page-wrapper tnc-wrapper">
                <div className="background-mask" />
                <section className="main-content-container">
                    <section className="main-content">
                        <header>
                            <h1>
                                <img src={logoURL} />
                            </h1>
                        </header>
                        <div className="module-section rounded">
                            <TermsAndConditionsText
                                i18n={i18n}
                            />
                            {shouldAcknowledgeTNC && <div className="section-legal">
                                <div className="row">
                                    <Checkbox
                                        id="tnc-acknowledge"
                                        label={i18n.getString("privacy.iagree")}
                                        checked={tncAcknowledged}
                                        disabled={isUpdatingPreferences}
                                        highlightOnHover={false}
                                        onChange={this._onTNCAcknowledged}
                                    />
                                </div>
                                <div className="row actions">
                                    <Button
                                        title={i18n.getString("generic.cancel")}
                                        secondary={true}
                                        disabled={isUpdatingPreferences}
                                        onClick={this._onCancel}
                                    />
                                    <Button
                                        title={i18n.getString("generic.submit")}
                                        disabled={!tncAcknowledged || isUpdatingPreferences}
                                        onClick={this._onConfirm}
                                    />
                                </div>
                            </div>}
                        </div>
                    </section>
                </section>
            </section>
        );
    }
}

TermsAndConditions.propTypes = {
    title: PropTypes.string,
    auth: PropTypes.object,
    context: PropTypes.object,
    i18n: PropTypes.object,
    history: PropTypes.object,
    profile: PropTypes.object,
    acceptTermsAndConditions: PropTypes.func
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth,
    profile: state.profile
});

const mapDispatchToProps = {
    acceptTermsAndConditions
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
