/** -------------------------------------------------------------------------------------------------------------------
 * CourseInfoView
 * A component that renders the subview for showing the info on a given course (description, start and end dates, etc)
 *
 * @examples
 *
 *  ```jsx
 *
 *    <CourseInfoView
 *      course={course}
 *      i18n={i18n}
 *    />
 * );
 *
 * ```
 *
 *  @component CourseInfoView
 *  @import CourseInfoView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ModalDialog from "../../shared/modal";
import ModalSheet from "../../shared/modal/modal-sheet";
import Rating from "../../shared/rating";
import TabbedView from "../../shared/tabbed-view";
import { LoadingText } from "../../shared/spinner/loading-text";
import CourseDiscussionsView from "./course-discussions-view";
import CourseLecturesView from "./course-lectures-view";
import BlackAndWhite from "../../../media/black-and-white";
import DiscussionForm from "./discussion-form";

import { noop } from "../../../utils/functions";
import {
    toUnicode,
    removeTags
} from "../../../utils/strings";
import { ACTION_LIKE } from "../../../constants/social-actions";
import { fromUTCString } from "../../../utils/dates";
import { ACTION_ATTEND } from "../../../constants/lecture-actions";

import "../courses.scss";


export class CourseInfoView extends Component {

    constructor(props) {
        super(props);

        this.movieContainer = React.createRef();
        const { getString } = this.props.i18n;
        const tabs = [
            {id: "discussions", title: getString("discussions.discussions")},
            {id: "lectures", title: getString("title.lectures")}
        ];

        const validationStates = {
            title: false
        };

        this.state = {
            course: {},
            tabs,
            currentTab: 0,
            discussionsRetrieved: false,
            lecturesRetrieved: false,
            showingModalDialog: false,
            showingErrorSheet: false,
            newDiscussionTitle: "",
            validationStates
        };
    }

    _onTabSelected = (index) => {
        this.setState({
            currentTab: index
        });
    };

    _getCurrentCourse() {
        const { id } = this.props.match.params;
        const course = this.props.courses.courseList.find((course) => course.id === id);

        return course || {};
    }

    _renderCourseDetails() {
        const {
            getString,
            formatDate,
            languagePack
        } = this.props.i18n;
        const course = this._getCurrentCourse();

        const formattedStartDate = course.startDate ? formatDate(new Date(Date.parse(course.startDate)),
            languagePack.dateFormat.LONG) : getString("generic.notAvailable");
        const formattedEndDate = course.endDate ? formatDate(new Date(Date.parse(course.endDate)),
            languagePack.dateFormat.LONG) : getString("generic.notAvailable");
        const formattedPrice = (course.price === 0) ? getString("course.free") : course.formattedPrice;

        return (
            <dl>
                {course.instructor &&
                    <React.Fragment>
                        <dt>{getString("generic.instructor")}</dt>
                        <dd>{`${course.instructor.firstName} ${course.instructor.lastName}`}</dd>
                    </React.Fragment>}
                <dt>{getString("course.starts")}</dt>
                <dd>{formattedStartDate}</dd>
                <dt>{getString("course.ends")}</dt>
                <dd>{formattedEndDate}</dd>
                <dt>{getString("generic.rating")}</dt>
                <dd><Rating average={course.rating} /> <em>(1 rating)</em></dd>
                <dt className="label-price">{getString("course.price")}</dt>
                <dd>
                    <p className="violator price">{formattedPrice}</p>
                </dd>
            </dl>
        );
    }

    _hasIntroVideo() {
        const course = this._getCurrentCourse();
        return !!(course && course.configuration && course.configuration.intro);
    }

    _renderIntroVideo() {
        if (!this._hasIntroVideo() || (this._hasIntroVideo() && this.mediaPlayer)) {
            return;
        }

        const {
            configuration: {
                intro: { URL, poster }
            }
        } = this._getCurrentCourse();

        this.mediaPlayer = new BlackAndWhite.MediaPlayer({
            id: "course-intro-media-player",
            url: URL,
            poster,
            width: "100%",
            height: "100%",
            element: this.movieContainer.current
        });
    }

    _onDiscussionSelected = (discussion) => {
        this.props.setActiveDiscussion(discussion.id);
    };

    _onDiscussionActions = (discussion, action) => {
        const {
            auth: { userInfo: { id, universityId }},
            courses: { likedDiscussions }
        } = this.props;
        const course = this._getCurrentCourse();

        switch (action) {
            case ACTION_LIKE: {
                const isLiked = likedDiscussions.includes(discussion.id);

                if (!isLiked) {
                    const parameters = {
                        userId: id,
                        universityId,
                        courseId: course.id,
                        discussionId: discussion.id
                    };
                    this.props.likeDiscussion(parameters);
                }

                break;
            }

            default:
                break;
        }
    };

    _fetchDiscussionComments = (discussion) => {
        const {
            auth: { userInfo: { id, universityId }},
            fetchDiscussionComments
        } = this.props;
        const course = this._getCurrentCourse();

        fetchDiscussionComments(id, universityId, course.id, discussion.id);
    };

    _onCommentPost = (discussion, comment) => {
        const {
            auth: { userInfo: { id, universityId }},
            postComment
        } = this.props;

        const course = this._getCurrentCourse();
        const parameters = {
            userId: id,
            universityId,
            courseId: course.id,
            discussionId: discussion.id,
            comment
        };

        postComment(parameters);
    };

    _onCommentActions = (discussion, comment, action) => {
        const {
            auth: { userInfo: { id, universityId }}
        } = this.props;
        const course = this._getCurrentCourse();

        switch (action) {
            case ACTION_LIKE:
                const parameters = {
                    userId: id,
                    universityId,
                    courseId: course.id,
                    discussionId: discussion.id,
                    commentId: comment.id
                };
                this.props.likeComment(parameters);
                break;

            default:
                break;
        }
    };

    _showNewDiscussionForm = () => {
        const { validationStates } = this.state;
        validationStates.title = false;

        this.setState({
            showingModalDialog: true,
            validationStates
        });
    };

    _startNewDiscussion = () => {
        const {
            auth: { userInfo: { id, universityId }},
            startDiscussion
        } = this.props;
        const { newDiscussionTitle } = this.state;

        const course = this._getCurrentCourse();
        const parameters = {
            userId: id,
            universityId,
            courseId: course.id,
            title: toUnicode(removeTags(newDiscussionTitle))
        };

        startDiscussion(parameters);
    };

    _onCancel = () => {
        this.setState({
            showingModalDialog: false
        });
    };

    _onErrorSheetActions = () => {
        this.setState({
            showingErrorSheet: false,
            showingModalDialog: false
        });
    };

    _onDiscussionTitleChanged = (value) => {
        const { validationStates } = this.state;
        validationStates.title = !!value.length;

        this.setState({
            newDiscussionTitle: value,
            validationStates
        });
    };

    _onInvalidDiscussionTitle = () => {
        const { validationStates } = this.state;
        validationStates.title = false;

        this.setState({
            validationStates
        });
    };

    _buildLecturesList() {
        const {
            courses: { lectures },
            i18n,
            i18n: { languagePack: { dateFormat }}
        } = this.props;

        const lecturesList = lectures.reduce((result, lecture) => {
            const startDate = fromUTCString(lecture.startDate);
            const dateString = i18n.formatDate(startDate, dateFormat.SHORT_DASHED);
            let dateDetails = result[dateString];

            if (!dateDetails) {
                const formattedDateString = i18n.formatDate(startDate, dateFormat.LONG);
                dateDetails = {
                    formattedDateString,
                    lectures: []
                };
                result[dateString] = dateDetails;
            }

            dateDetails.lectures.push(lecture);
            return result;
        }, {});

        return lecturesList;
    }

    _onLectureActions = (lecture, action) => {
        switch (action) {
            case ACTION_ATTEND:
                this.props.history.push(`/lectures/${lecture.id}`);
                break;

            default:
                break;
        }
    };

    componentDidMount() {
        const {
            auth: { userInfo: { id, universityId }},
            fetchLikedDiscussions,
            fetchLikedComments
        } = this.props;

        this._renderIntroVideo();

        /** Preload the discussions and comments liked by the current user */
        fetchLikedDiscussions(id, universityId);
        fetchLikedComments(id, universityId);
    }

    componentDidUpdate(prevProps) {
        const {
            auth: { userInfo: { id, universityId }},
            fetchCourseDiscussions,
            fetchCourseLectures,
            courses
        } = this.props;
        const {
            discussionsRetrieved,
            lecturesRetrieved
        } = this.state;

        this._renderIntroVideo();

        const shouldRetrieveElements = (
            courses.courseList.length > 0 &&
            (!discussionsRetrieved || !lecturesRetrieved)
        );

        if (shouldRetrieveElements) {
            const course = this._getCurrentCourse();

            if (course) {
                if (!discussionsRetrieved) {
                    fetchCourseDiscussions(id, universityId, course.id);

                    this.setState({
                        discussionsRetrieved: true,
                        currentCourse: course
                    });
                }

                if (!lecturesRetrieved) {
                    fetchCourseLectures(id, course.instructor.id, course.id);

                    this.setState({
                        lecturesRetrieved: true,
                        currentCourse: course
                    });
                }
            }
        }

        if (prevProps.courses.isStartingDiscussion === true && courses.isStartingDiscussion === false) {
            this.setState({
                showingModalDialog: false,
                showingErrorSheet: !!courses.error
            });
        }
    }

    componentWillUnmount() {
        if (this.mediaPlayer) {
            this.mediaPlayer.destroy();
            this.mediaPlayer = null;
        }
    }

    render() {
        const {
            auth: { userInfo },
            i18n,
            i18n: { getString },
            courses: {
                discussions,
                lectures,
                comments,
                isLoadingDiscussions,
                isLoadingLectures,
                isStartingDiscussion,
                likedDiscussions,
                likedComments
            }
        } = this.props;
        const {
            tabs,
            currentTab,
            showingModalDialog,
            showingErrorSheet,
            validationStates
        } = this.state;

        const course = this._getCurrentCourse();
        const hasIntro = this._hasIntroVideo();
        const className = classNames("course-video", {
            "no-content": !hasIntro
        });

        const isValidDiscussionForm = Object.values(validationStates).every((item) => !!item);
        const shouldShowDiscussionsTab = (!isLoadingDiscussions && course.id && currentTab === 0);
        const shouldShowLecturesTab = (!isLoadingLectures && currentTab === 1);

        return (
            <React.Fragment>
                <div className="module-section row course-intro-wrapper">
                    <div className="column medium-8 small-12">
                        <div className={className}>
                            {hasIntro ?
                                <div className="movie-container" ref={this.movieContainer}></div> :
                                <p className="typography-body info">
                                    {getString("course.noIntroVideo")}
                                </p>
                            }
                        </div>
                    </div>
                    <div className="column medium-4 small-12">
                        <div className="course-info">
                            {this._renderCourseDetails()}
                        </div>
                    </div>
                </div>
                <div className="module-section description">
                    <p className="typography-body">{course.description}</p>
                </div>
                <div className="module-section section-tabs">
                    <TabbedView
                        tabs={tabs}
                        onTabSelected={this._onTabSelected}
                    />
                </div>
                <div className="module-section section-course-items">
                    {shouldShowDiscussionsTab &&
                        <div className="contextual-action">
                            <a href="javascript:void(0)"
                                data-type="context menu"
                                onClick={this._showNewDiscussionForm}>
                                <span className="icon icon-plus-circle-white" />
                                <span>{i18n.getString("discussions.startNewDiscussion")}</span>
                            </a>
                        </div>
                    }
                    <div className="section-content">
                        {isLoadingDiscussions &&
                            <div className="loader-wrapper">
                                <LoadingText
                                    text={i18n.getString("generic.loading")}
                                />
                            </div>
                        }
                        {shouldShowDiscussionsTab &&
                            <CourseDiscussionsView
                                userInfo={userInfo}
                                course={course}
                                discussions={discussions}
                                likedDiscussions={likedDiscussions}
                                comments={comments}
                                likedComments={likedComments}
                                i18n={i18n}
                                onDiscussionSelected={this._onDiscussionSelected}
                                fetchDiscussionComments={this._fetchDiscussionComments}
                                onCommentPost={this._onCommentPost}
                                onDiscussionActions={this._onDiscussionActions}
                                onCommentActions={this._onCommentActions}
                            />
                        }
                        {shouldShowLecturesTab &&
                            <CourseLecturesView
                                userInfo={userInfo}
                                lectures={lectures}
                                lecturesByDate={this._buildLecturesList()}
                                i18n={i18n}
                                onLectureActions={this._onLectureActions}
                            />
                        }
                    </div>
                </div>
                {showingModalDialog &&
                    <ModalDialog
                        mode="compact"
                        title={getString("discussions.startNewDiscussion")}
                        okLabel={getString("discussions.startDiscussion")}
                        cancelLabel={getString("generic.cancel")}
                        autoDismiss={true}
                        okButtonDisabled={isStartingDiscussion || !isValidDiscussionForm}
                        onModalConfirm={this._startNewDiscussion}
                        onModalClose={this._onCancel}>
                        <DiscussionForm
                            i18n={i18n}
                            showSpinner={isStartingDiscussion}
                            onChange={this._onDiscussionTitleChanged}
                            onError={this._onInvalidDiscussionTitle}
                        />
                    </ModalDialog>}
                {showingErrorSheet &&
                    <ModalSheet
                        title={getString("general.errorOccurred")}
                        type="error"
                        mode="compact"
                        showCancelButton={false}
                        okLabel={getString("generic.OK")}
                        onOK={this._onErrorSheetActions}
                        onModalClose={this._onErrorSheetActions}>
                        <p>{getString("generic.errorMessage")}</p>
                    </ModalSheet>}
            </React.Fragment>
        );
    }
}

CourseInfoView.propTypes = {
    auth: PropTypes.object,
    i18n: PropTypes.object,
    courses: PropTypes.object,
    course: PropTypes.object,
    universities: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    fetchCourseDiscussions: PropTypes.func,
    fetchCourseLectures: PropTypes.func,
    fetchDiscussionComments: PropTypes.func,
    fetchLikedDiscussions: PropTypes.func,
    fetchLikedComments: PropTypes.func,
    setActiveDiscussion: PropTypes.func,
    startDiscussion: PropTypes.func,
    postComment: PropTypes.func,
    likeDiscussion: PropTypes.func,
    likeComment: PropTypes.func
};

CourseInfoView.defaultProps = {
    fetchCourseDiscussions: noop,
    fetchDiscussionComments: noop,
    fetchCourseLectures: noop,
    fetchLikedDiscussions: noop,
    fetchLikedComments: noop,
    setActiveDiscussion: noop,
    startDiscussion: noop,
    postComment: noop,
    likeDiscussion: noop,
    likeComment: noop
};

export default CourseInfoView;
