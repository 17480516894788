import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import LocalNav from "../shared/local-nav";
import ModalDialog from "../shared/modal";
import PageTemplate from "../shared/pages/template";
import DashboardItems from "./dashboard-items";
import RecentActivitiesView from "./views/recent-activities-view";
import UpcomingLecturesView from "./views/upcoming-lectures-view";
import LectureRoomsView from "./views/lecture-rooms-view";
import { LoadingText } from "../shared/spinner/loading-text";

import dashboardLinks from "../../store/data/dashboard-items";
import { getRecentActivities } from "../../store/reducers/universities/universities.actions";
import {
    fetchUserLecturesForDateRange,
    fetchSharedSpaces,
    addSharedSpace,
    deleteSharedSpace
} from "../../store/reducers/lectures/lectures.actions";

import { fromUTCString } from "../../utils/dates";

import {
    getFirstDayOfWeek,
    addDays,
    normalizeDate,
    cloneAsDate
} from "../shared/calendar/date-utils";
import { isStudentAccount } from "../../utils/app-utils";

import "./dashboard.scss";


export class Dashboard extends Component {

    constructor(props) {
        super(props);

        const currentWeek = this._computeCalendarWeek();
        this.state = {
            currentWeek,
            upcomingLectures: [],
            retrievingActivities: true,
            retrievingUpcomingLectures: true,
            showingModalDialog: false
        };
    }

    componentDidMount() {
        const {
            auth: { userInfo: { id, universityId }},
            universities: { recentActivities },
            lectures: { upcomingLectures },
            i18n,
            getRecentActivities,
            fetchUserLecturesForDateRange,
            fetchSharedSpaces
        } = this.props;
        const activitiesAvailable = (Array.isArray(recentActivities) && recentActivities.length > 0);
        const lecturesAvailable = (Array.isArray(upcomingLectures) && upcomingLectures.length > 0);

        if (activitiesAvailable) {
            this._buildUpcomingLecturesList();
            this.setState({
                retrievingActivities: false
            });
        } else {
            getRecentActivities(universityId);
        }

        if (lecturesAvailable) {
            this.setState({
                retrievingUpcomingLectures: false
            });
        } else {
            const { currentWeek } = this.state;
            const minDate = i18n.formatDate(currentWeek[0], i18n.languagePack.dateFormat.SHORT_DASHED);
            const maxDate = i18n.formatDate(currentWeek[1], i18n.languagePack.dateFormat.SHORT_DASHED);

            fetchUserLecturesForDateRange(id, minDate, maxDate);
        }

        fetchSharedSpaces(id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.universities.isLoadingActivities === true &&
            this.props.universities.isLoadingActivities === false) {
            this.setState({
                retrievingActivities: false
            });
        }

        if (prevProps.lectures.isLoading === true &&
            this.props.lectures.isLoading === false) {
            this._buildUpcomingLecturesList();
            this.setState({
                retrievingUpcomingLectures: false
            });
        }
    }

    _computeCalendarWeek() {
        const beginningOfWeek = getFirstDayOfWeek();
        normalizeDate(beginningOfWeek);

        let endOfWeek = cloneAsDate(beginningOfWeek);
        endOfWeek = addDays(endOfWeek, 6);
        endOfWeek.setHours(23, 59, 59);

        return [beginningOfWeek, endOfWeek];
    }

    _onItemClicked = (type, url) => {
        /** Temporarily disabled */
        if (type === "rooms") {
            return this._showModalDialog();
        }

        if (type === "learning") {
            return;
        }

        this.props.history.push(url);
    };

    _buildUpcomingLecturesList() {
        const {
            lectures: { upcomingLectures},
            i18n,
            i18n: { languagePack: { dateFormat }}
        } = this.props;

        const lecturesList = upcomingLectures.reduce((result, lecture) => {
            const startDate = fromUTCString(lecture.startDate);
            const dateString = i18n.formatDate(startDate, dateFormat.SHORT_DASHED);
            let dateDetails = result[dateString];

            if (!dateDetails) {
                const formattedDateString = i18n.formatDate(startDate, dateFormat.LONG);
                dateDetails = {
                    formattedDateString,
                    lectures: []
                };
                result[dateString] = dateDetails;
            }

            dateDetails.lectures.push(lecture);
            return result;
        }, {});

        this.setState({
            upcomingLectures: lecturesList
        });
    }

    _onLectureClicked = (lecture) => {
        this.props.history.push(`lectures/${lecture.id}`);
    };

    _showModalDialog = () => {
        this.setState({
            showingModalDialog: true
        });
    };

    _hideModalDialog = () => {
        this.setState({
            showingModalDialog: false
        });
    };

    _addRoom = (name, accessLevel) => {
        const {
            auth: { userInfo: { id }},
            addSharedSpace
        } = this.props;

        addSharedSpace(id, name, accessLevel);
    };

    _deleteRoom = (sharedSpaceId) => {
        const {
            auth: { userInfo: { id }},
            deleteSharedSpace
        } = this.props;

        deleteSharedSpace(id, sharedSpaceId);
    };

    render() {
        const {
            auth: { userInfo },
            i18n,
            context,
            universities: { recentActivities },
            lectures: {
                sharedSpaces,
                isAddingSharedSpace
            }
        } = this.props;
        const {
            retrievingActivities,
            retrievingUpcomingLectures,
            upcomingLectures,
            showingModalDialog
        } = this.state;

        const { id, accountType } = userInfo;
        const isStudent = isStudentAccount(userInfo);

        return (
            <React.Fragment>
                <PageTemplate
                    {...this.props}
                    className="dashboard-wrapper">
                    <LocalNav
                        title={i18n.getString("title.dashboard")}
                        page="dashboard"
                        className="section-local-nav"
                        showPageInfo={true}
                    />
                    <div className="module-section">
                        <div className="row dashboard-section-wrapper">
                            <div className="dashboard-items-section">
                                <DashboardItems
                                    userId={id}
                                    accountType={accountType}
                                    items={dashboardLinks}
                                    i18n={i18n}
                                    context={context}
                                    onClick={this._onItemClicked}
                                />
                                {retrievingUpcomingLectures ?
                                    <LoadingText
                                        text={i18n.getString("generic.loading")}
                                    /> :
                                    <UpcomingLecturesView
                                        i18n={i18n}
                                        lecturesList={upcomingLectures}
                                        onLectureClicked={this._onLectureClicked}
                                    />
                                }
                            </div>
                            <div className="sidebar-section">
                                { retrievingActivities ?
                                    <LoadingText
                                        text={i18n.getString("generic.loading")}
                                    /> :
                                    <RecentActivitiesView
                                        i18n={i18n}
                                        activities={recentActivities}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </PageTemplate>
                {showingModalDialog &&
                    <ModalDialog
                        mode="compact"
                        className="lecture-rooms-modal"
                        title={i18n.getString(isStudent ? "classroom.myStudyRooms" : "classroom.myTeachingRooms")}
                        showOKButton={false}
                        showCancelButton={false}
                        onModalClose={this._hideModalDialog}>
                        <LectureRoomsView
                            rooms={sharedSpaces}
                            i18n={this.props.i18n}
                            showSpinner={isAddingSharedSpace}
                            onAddRoom={this._addRoom}
                            onDeleteRoom={this._deleteRoom}
                        />
                    </ModalDialog>}
            </React.Fragment>
        );
    }
}

Dashboard.propTypes = {
    title: PropTypes.string,
    auth: PropTypes.object,
    context: PropTypes.object,
    i18n: PropTypes.object,
    profile: PropTypes.object
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth,
    profile: state.profile,
    universities: state.universities,
    lectures: state.lectures
});

const mapDispatchToProps = {
    getRecentActivities,
    fetchUserLecturesForDateRange,
    fetchSharedSpaces,
    addSharedSpace,
    deleteSharedSpace
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
