/** -------------------------------------------------------------------------------------------------------------------
 * ContactNotificationsView
 * A component that renders the view for managing contact notifications
 *
 * @examples
 *
 *  ```jsx
 *
 *    <ContactNotificationsView
 *      userInfo={userInfo}
 *      i18n={i18n}
 *      notifications={notifications}
 *      onNotificationChange={(notification, value) => console.log("Notification changed")}
 *    />
 * );
 * ```
 *
 *  @component ContactNotificationsView
 *  @import ContactNotificationsView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";

import Toggle from "../../shared/toggle";
import { noop } from "../../../utils/functions";


export class ContactNotificationsView extends Component {

    constructor(props) {
        super(props);

        const {
            newFollowers,
            acceptedFollowRequests
        } = this.props.notifications;

        this.state = {
            newFollowers: newFollowers === "true",
            acceptedFollowRequests: acceptedFollowRequests === "true"
        };
    }

    _onChange = (notification, value) => {
        this.setState({
            [notification]: value
        }, () => {
            this.props.onNotificationChange(notification, `${value}`);
        });
    };

    render() {
        const {
            i18n: { getString },
            disabled
        } = this.props;
        const {
            newFollowers,
            acceptedFollowRequests
        } = this.state;

        return (
            <div className="profile-section contact-notifications-wrapper">
                <h3 className="profile-section-header">{getString("notifications.contacts")}</h3>
                <div className="profile-section-wrapper settings">
                    <div className="row">
                        <Toggle
                            label={getString("notifications.fromNewFollowers")}
                            checked={disabled ? false : newFollowers}
                            disabled={disabled}
                            onChange={(id, checked) => this._onChange("newFollowers", checked)}
                        />
                    </div>
                    <div className="row">
                        <Toggle
                            label={getString("notifications.fromAcceptedFollowRequests")}
                            checked={disabled ? false : acceptedFollowRequests}
                            disabled={disabled}
                            onChange={(id, checked) => this._onChange("acceptedFollowRequests", checked)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

ContactNotificationsView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    notifications: PropTypes.object,
    onNotificationChange: PropTypes.func,
    disabled: PropTypes.bool
};

ContactNotificationsView.defaultProps = {
    onNotificationChange: noop
};

export default ContactNotificationsView;
