export default [
    {id: "courses", role: "all", icon: "courses", localizedString: "title.courses"},
    {id: "lectures", role: "all", icon: "lectures", localizedString: "title.lectures"},
    {id: "videos", role: "instructor", icon: "videos", localizedString: "title.videos"},
    {id: "university", role: "instructor", icon: "university", localizedString: "generic.university"},
    {id: "students", role: "instructor", icon: "students", localizedString: "dashboard.students"},
    {id: "learning", role: "student", icon: "learning", localizedString: "title.learning"},
    {id: "contacts", role: "all", icon: "contacts", localizedString: "dashboard.myContacts"},
    {id: "rooms", role: "all", icon: "rooms", localizedString: "dashboard.rooms"},
    {id: "users", role: "administrator", icon: "users", localizedString: "dashboard.users"},
    {id: "universities", role: "administrator", icon: "universities", localizedString: "dashboard.universities"}
];
