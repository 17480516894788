/** -------------------------------------------------------------------------------------------------------------------
 * DiscussionForm
 * A component that renders the form for starting a new discussion
 *
 *  @examples
 *
 *  ```jsx
 *    <DiscussionForm
 *      i18n={i18n}
 *      showSpinner={isStartingDiscussion}
 *      onChange={(value) => { console.log("Title changed: ", value); }}
 *      onError={(value) => { console.log("Title error: ", value); }}
 *    />
 * ```
 *
 *  @component DiscussionForm
 *  @import DiscussionForm
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import InputField from "../../shared/input-field";
import { Loader } from "../../shared/spinner/loader";
import { noop } from "../../../utils/functions";

import "../courses.scss";


class DiscussionForm extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            discussionTitle: ""
        };
    }

    _getValidators() {
        return {
            isRequired: {
                message: this.props.i18n.getString("form.fieldIsRequired")
            }
        };
    }

    render() {
        const {
            i18n: { getString },
            showSpinner,
            onChange,
            onError
        } = this.props;
        const { discussionTitle } = this.state;

        return (
            <div className="discussion-form-wrapper">
                <div className="row">
                    <div className="column medium-3 small-12 label">
                        <label>{getString("generic.title")}</label>
                    </div>
                    <div className="column medium-9 small-12">
                        <InputField
                            value={discussionTitle}
                            placeholder={getString("discussions.titleHint")}
                            showClearControl={false}
                            validationTypes={["isRequired"]}
                            validators={this._getValidators()}
                            disabled={showSpinner}
                            onChange={(value) => onChange(value)}
                            onError={() => onError("title")}
                        />
                    </div>
                </div>
                {showSpinner && <div className="loader-wrapper">
                    <Loader />
                </div>}
            </div>
        );
    }
}

DiscussionForm.propTypes = {
    i18n: PropTypes.object,
    showSpinner: PropTypes.bool,
    onChange: PropTypes.func,
    onError: PropTypes.func
};

DiscussionForm.defaultProps = {
    onChange: noop,
    onError: noop
};

export default DiscussionForm;
