/* eslint-disable max-len, no-trailing-spaces, react/no-unescaped-entities */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { PrivacyPolicyText } from "./privacy-policy-text";

import "./terms-and-conditions.scss";


export class PrivacyPolicy extends PureComponent {

    render() {
        const { i18n } = this.props;
        const logoURL = "https://akwaba.s3-us-west-1.amazonaws.com/assets/university-avenue-logo-small.png";

        return (
            <section className="page-wrapper tnc-wrapper">
                <div className="background-mask" />
                <section className="main-content-container">
                    <section className="main-content">
                        <header>
                            <h1>
                                <img src={logoURL} />
                            </h1>
                        </header>
                        <div className="module-section rounded">
                            <PrivacyPolicyText
                                i18n={i18n}
                            />
                        </div>
                    </section>
                </section>
            </section>
        );
    }
}

PrivacyPolicy.propTypes = {
    i18n: PropTypes.object
};

export default PrivacyPolicy;
