/* eslint-disable no-return-assign */

const setDOMStorage = (type) => {
    let storage;
    const defaultStore = {};

    try {

        const storageKey = "__Akwaba__";
        storage = window[type];
        storage.setItem(storageKey, "Welcome");
        storage.removeItem(storageKey);

    } catch (notAvailable) {
        storage = {
            setItem: (key, value) => (defaultStore[key] = value),
            getItem: (key) => defaultStore[key] || null,
            removeItem: (key) => (defaultStore[key] = null),
            clear: () => Object.keys(defaultStore).forEach((k) => defaultStore[k] = null)
        };
    }

    return storage;
};

export const localStorage = setDOMStorage("localStorage");
export const sessionStorage = setDOMStorage("sessionStorage");
