/* eslint-disable max-len, no-trailing-spaces, react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";

import "./terms-and-conditions.scss";


export const PrivacyPolicyText = ({ i18n }) => (
    <React.Fragment>
        <div className="section-header">
            <h2>
                {i18n.getString("privacy.policy")}
            </h2>
        </div>
        <div className="content">
            <p>
                Your privacy is important to Akwaba. So we’ve developed a Privacy Policy that 
                covers how we collect, use, disclose, transfer, and store your information. Please take a moment 
                to familiarize yourself with our privacy policies and practices and contact us if you have any questions.
            </p>
            <h4>Collection and Use of Personal Information</h4>
            <p>Personal information is data that can be used to identify or contact a single person.</p>
            <p>
                You may be asked to provide your personal information anytime you are in contact with Akwaba. 
                Akwaba may use it consistent with this Privacy Policy. Akwaba may also combine it with other information to 
                provide and improve our products, services, content, and advertising. You are not required to 
                provide the personal information that we have requested, but, if you choose not to do so, 
                in many cases we will not be able to provide you with our products or services or respond 
                to any queries you may have.
            </p>
            <p>Here are some examples of the types of personal information Akwaba may collect and how we may use it:</p>
            <h4>What Personal Information We Collect</h4>
            <ul>
                <li>
                    When you create an account to use Akwaba products or services, download a software update, 
                    contact us or participate in an online survey, we may collect a variety of information, 
                    including your name, mailing address, phone number, email address, and contact preferences.
                </li>
                <li>
                    When you share your content with friends using Akwaba products, or invite others to participate 
                    in Akwaba services or discussions, Akwaba may collect the information you provide about those 
                    people such as name, mailing address, email address, and phone number. Akwaba will use such 
                    information to fulfill your requests, provide the relevant product or service, or for anti-fraud purposes.
                </li>
            </ul>
            <h4>How We Use Your Personal Information</h4>
            <ul>
                <li>
                    The personal information we collect allows us to keep you posted on Akwaba’s latest product announcements 
                    and software updates.
                </li>
                <li>
                    We also use personal information to help us create, develop, operate, deliver, and improve our products, 
                    services, and advertising, and for loss prevention and anti-fraud purposes.
                </li>
                <li>
                    From time to time, we may use your personal information to send important notices, 
                    such as communications about changes to our terms, conditions, and policies. Because this 
                    information is important to your interaction with Akwaba, you may not opt out of receiving 
                    these communications.
                </li>
                <li>
                    We may also use personal information for internal purposes such as auditing, data analysis, 
                    and research to improve Akwaba’s products and services.
                </li>
            </ul>
            
            <h4>Collection and Use of Non-Personal Information</h4>
            <p>
                We also collect data in a form that does not, on its own, permit direct association with any 
                specific individual. We may collect, use, transfer, and disclose non-personal information for 
                any purpose. The following are some examples of non-personal information that we collect and 
                how we may use it:
            </p>
            <ul>
                <li>
                    We may collect information such as language, unique device identifier, referrer URL, 
                    geo-location, and the time zone where an Akwaba product is used so that we can better understand 
                    customer behavior and improve our products, services, and advertising.
                </li>
                <li>
                    We may collect information regarding customer activities in our products or services. 
                    This information is aggregated and used to help us provide more useful information to our customers 
                    and to understand which parts of our products and services are of most interest. 
                    Aggregated data is considered non‑personal information for the purposes of this Privacy Policy.
                </li>
                <li>
                    We may collect and store details of how you use our services, including your login sessions, your interactions 
                    with videos or other media, and your Chat and Video Chat sessions. This information may be used to 
                    improve the user experience of our products, and the performance of our Media servers. 
                    Except in limited instances to ensure quality of our services over the Internet, such information 
                    will not be associated with your IP address.
                </li>
            </ul>
            <p>
                If we do combine non-personal information with personal information the combined information will 
                be treated as personal information for as long as it remains combined.
            </p>
            <h4>Cookies and Other Technologies</h4>
            <p>
                Akwaba’s websites, applications, email messages, and advertisements may use “cookies” and other 
                technologies such as pixel tags and local storage. These technologies help us better understand 
                user behavior. We treat information collected by cookies and other technologies as non‑personal 
                information. However, to the extent that Internet Protocol (IP) addresses or similar identifiers 
                are considered personal information by local law, we also treat these identifiers as personal information. 
                Similarly, to the extent that non-personal information is combined with personal information, 
                we treat the combined information as personal information for the purposes of this Privacy Policy.
            </p>
            <p>
                Akwaba also uses cookies and other technologies to remember personal information when you use our websites 
                and applications. Our goal in these cases is to make your experience with Akwaba more convenient 
                and personal. For example, knowing your first name lets us welcome you the next time you use 
                the &ldquo;1 University Avenue&rdquo; app. Knowing your country and language − and if you are an instructor, 
                your virtual university − helps us provide a customized and more useful online learning experience.
            </p>
            <p>
                As is true of most internet services, we gather some information automatically and store it in 
                log files. This information includes Internet Protocol (IP) addresses, browser type and language, 
                referring and exit websites and applications, operating system, and date/time stamp.
            </p>
            <p>
                We use this information to understand and analyze trends, to administer the site, to learn about 
                user behavior on the site, to improve our product and services, and to gather demographic information 
                about our user base as a whole. Akwaba may use this information in our marketing and advertising services.
            </p>
            <p>
                Pixel tags enable us to send email messages in a format customers can read, and they tell us whether 
                mail has been opened. We may use this information to reduce or eliminate messages sent to customers.
            </p>
            
            <h4>Disclosure to Third Parties</h4>
            <p>
                It may be necessary − by law, legal process, litigation, and/or requests from public and governmental 
                authorities within or outside your country of residence − for Akwaba to disclose your personal 
                information. We may also disclose information about you if we determine that for purposes of 
                national security, law enforcement, or other issues of public importance, disclosure is necessary 
                or appropriate.
            </p>
            <p>
                We may also disclose information about you if we determine that disclosure is reasonably necessary 
                to enforce our terms and conditions or protect our operations or users. Additionally, in the event 
                of a reorganization, merger, or sale we may transfer any and all personal information we collect 
                to the relevant third party.
            </p>
            
            <h4>Protection of Personal Information</h4>
            <p>
                Akwaba takes the security of your personal information very seriously. Akwaba applications protect your personal information during transit using encryption 
                such as Transport Layer Security (TLS). When your personal data is stored or backed up by Akwaba, we use 
                the Cloud services provided by Amazon Web Services with proven secure computing environments.
            </p>
            <p>
                When you use some Akwaba products, services, or applications, or post to a classroom discussion, 
                or write a review on a course, the personal information and content you share is visible to other 
                users and can be read, collected, or used by them. You are responsible for the personal information 
                you choose to share or submit in these instances. For example, if you list your name and email address 
                in a course review, that information is public. Please take care when using these features.
            </p>
            
            <h4>Integrity and Retention of Personal Information</h4>
            <p>
                Akwaba makes it easy for you to keep your personal information accurate, complete, and up to date. 
                We will retain your personal information for the period necessary to fulfill the purposes outlined 
                in this Privacy Policy unless a longer retention period is required or permitted by law.
            </p>
            <h4>Access to Personal Information</h4>
            <p>
                You can help ensure that your contact information and preferences are accurate, complete, and 
                up to date by signing in to your account for a given Akwaba product. For other personal information we hold, 
                we will provide you with access for any purpose including to request that we correct the data if it is 
                inaccurate or delete the data if Akwaba is not required to retain it by law or for legitimate business 
                purposes. We may decline to process requests that are frivolous/vexatious, jeopardize the privacy of 
                others, are extremely impractical, or for which access is not otherwise required by local law. 
                Access, correction, or deletion requests can be made by sending an email to <a href="mailto:team@akwabasystems.com">
                team@akwabasystems.com</a>.
            </p>
            
            <h4>Location-Based Services</h4>
            <p>
                To provide location-based services, Akwaba may collect, use, and share precise 
                location data, including the real-time geographic location of your computer or device. Where available, 
                location-based services may use GPS, Bluetooth, and your IP Address, along with 
                crowd-sourced Wi-Fi hotspot and cell tower locations, and other technologies to determine the 
                approximate location of your device. On iOS devices, this location data is collected with your consent. On other devices 
                such as desktops or laptops, unless you provide consent, this location data is collected anonymously in a form 
                that does not personally identify you. The data is used by Akwaba to provide and improve the user experience 
                of our application.
            </p>
            
            <h4>Third‑Party Sites and Services</h4>
            <p>
                Akwaba websites, applications, and services may contain links to third-party websites, products, 
                and services. Our products and services may also use products or services from third parties. 
                Information collected by third parties, which may include such things as location data or contact details, 
                is governed by their privacy practices. We encourage you to learn about the privacy practices of 
                those third parties.
            </p>
            
            <h4>International Users</h4>
            <p>
                All the information you provide may be transferred or accessed by entities around the world as described 
                in this Privacy Policy. Akwaba abides by the “safe harbor” frameworks set forth by the U.S. Department 
                of Commerce regarding the collection, use, and retention of personal information collected by 
                organizations in the European Economic Area and Switzerland. 
                Learn more about the <a href="http://www.export.gov/safeharbor/index.asp" target="_blank" rel="noopener noreferrer">
                    U.S. Department of Commerce Safe Harbor Program</a>.
            </p>
            
            <h4>Our Companywide Commitment to Your Privacy</h4>
            <p>
                To make sure your personal information is secure, we communicate our privacy and security guidelines 
                to Akwaba employees and strictly enforce privacy safeguards within the company.
            </p>
            
            <h4>Privacy Questions</h4>
            <p>
                If you have any questions or concerns about Akwaba’s Privacy Policy or data processing or if you 
                would like to make a complaint about a possible breach of local privacy laws, please contact us 
                at <a href="mailto:team@akwabasystems.com">team@akwabasystems.com</a>.
            </p>
            <p>
                All such communications are examined and replies are issued where appropriate as soon as possible. 
                If you are unsatisfied with the reply received, you may refer your complaint to the relevant 
                regulator in your jurisdiction. If you ask us, we will endeavor to provide you with information 
                about relevant complaint avenues which may be applicable to your circumstances.
            </p>
            <p>
                Akwaba may update its Privacy Policy from time to time. When we change the policy in a material way, 
                a notice will be posted on our website along with the updated Privacy Policy.
            </p>
            <p className="revision">
                Last revised: October 31, 2019
            </p>
            <p className="copyright">
                {i18n.format("application.copyright", [new Date().getFullYear()])}
            </p>
        </div>
    </React.Fragment>
);

PrivacyPolicyText.propTypes = {
    i18n: PropTypes.object
};
