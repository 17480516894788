import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import LocalNav from "../shared/local-nav";
import PageTemplate from "../shared/pages/template";
import TabbedView from "../shared/tabbed-view";
import ContactListView from "./views/contact-list-view";

import {
    retrieveInboundFollows,
    retrieveOutboundFollows,
    retrieveContactSuggestions,
    followContact,
    followContactFromSuggestions,
    unfollowContact
} from "../../store/reducers/network/network.actions";

import {
    fetchUniversityStudents
} from "../../store/reducers/universities/universities.actions";

import {
    CLASSMATES,
    FOLLOWERS,
    FOLLOWING,
    SUGGESTIONS,
    ACTION_FOLLOW,
    ACTION_UNFOLLOW,
    ACTION_FOLLOW_FROM_SUGGESTIONS
} from "../../constants/contacts";
import { isStudentAccount } from "../../utils/app-utils";

import "./contacts.scss";


export class Contacts extends Component {

    constructor(props) {
        super(props);

        const {
            auth: { userInfo },
            i18n: { getString }
        } = this.props;
        const isStudent = isStudentAccount(userInfo);

        const tabs = [
            {id: "followers", title: getString("contacts.followers")},
            {id: "following", title: getString("contacts.following")},
            {id: "suggestions", title: getString("contacts.suggestions")}
        ];

        if (isStudent) {
            tabs.unshift({ id: "classmates", title: getString("contacts.classmates")});
        }

        this.state = {
            showingInfoMessage: false,
            tabs,
            currentTab: 0
        };
    }

    _onTabSelected = (index) => {
        this.setState({
            currentTab: index
        });
    };

    _processUserClassmates() {
        const {
            auth: { userInfo: { id }},
            universityStudents
        } = this.props;

        const classmates = universityStudents.filter((student) => student.id !== id);

        this.setState({
            classmates
        });
    }

    _processInboundFollows() {
        this.setState({
            followers: this.props.followers
        });
    }

    _processOutboundFollows() {
        this.setState({
            following: this.props.following
        });
    }

    _processSuggestions() {
        this.setState({
            suggestions: this.props.suggestions
        });
    }

    _onContactActions = (contact, action) => {
        const {
            auth: { userInfo: { id }}
        } = this.props;

        if (action === ACTION_FOLLOW) {
            this.props.followContact(id, contact);
        }

        if (action === ACTION_FOLLOW_FROM_SUGGESTIONS) {
            this.props.followContactFromSuggestions(id, contact);
        }

        if (action === ACTION_UNFOLLOW) {
            this.props.unfollowContact(id, contact);
        }
    };

    _showClassmates() {
        const {
            auth: { userInfo },
            i18n
        } = this.props;
        const { classmates } = this.state;

        return classmates ? (
            <ContactListView
                type={CLASSMATES}
                userInfo={userInfo}
                i18n={i18n}
                contacts={classmates}
                onContactActions={this._onContactActions}
            />
        ) : null;
    }

    _showFollowers() {
        const {
            auth: { userInfo },
            i18n
        } = this.props;
        const {
            followers,
            following
        } = this.state;

        return followers ? (
            <ContactListView
                type={FOLLOWERS}
                userInfo={userInfo}
                i18n={i18n}
                contacts={followers}
                outboundFollows={following}
                onContactActions={this._onContactActions}
            />
        ) : null;
    }

    _showFollowing() {
        const {
            auth: { userInfo },
            i18n
        } = this.props;
        const { following } = this.state;

        return following ? (
            <ContactListView
                type={FOLLOWING}
                userInfo={userInfo}
                i18n={i18n}
                contacts={following}
                outboundFollows={following}
                onContactActions={this._onContactActions}
            />
        ) : null;
    }

    _showSuggestions() {
        const {
            auth: { userInfo },
            i18n
        } = this.props;
        const { suggestions } = this.state;

        return suggestions ? (
            <ContactListView
                type={SUGGESTIONS}
                userInfo={userInfo}
                i18n={i18n}
                contacts={suggestions}
                onContactActions={this._onContactActions}
            />
        ) : null;
    }

    componentDidMount() {
        const {
            auth: { userInfo: { id, accountType, universityId }},
            retrieveInboundFollows,
            retrieveOutboundFollows,
            fetchUniversityStudents,
            retrieveContactSuggestions
        } = this.props;
        const isStudent = (accountType === "student");

        if (isStudent) {
            fetchUniversityStudents(universityId);
        }

        retrieveOutboundFollows(id);
        retrieveInboundFollows(id);
        retrieveContactSuggestions(id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoadingStudents === true && this.props.isLoadingStudents === false) {
            this._processUserClassmates();
        }

        if (prevProps.isLoadingFollowers === true && this.props.isLoadingFollowers === false) {
            this._processInboundFollows();
        }

        if (prevProps.isLoadingFollowing === true && this.props.isLoadingFollowing === false) {
            this._processOutboundFollows();
        }

        if (prevProps.isLoadingSuggestions === true && this.props.isLoadingSuggestions === false) {
            this._processSuggestions();
        }

        /**
         * If the outbound follows have changed in size (the user starts following another user),
         * rebuild the list.
         */
        const shouldUpdateOutboundFollows = (
            prevProps.following &&
            this.props.following &&
            (prevProps.following.length !== this.props.following.length)
        );

        if (shouldUpdateOutboundFollows) {
            this._processOutboundFollows();
        }

        /**
         * If the suggestions have changed in size (the user starts following a suggested contact),
         * rebuild the list.
         */
        const shouldUpdateSuggestions = (
            prevProps.suggestions &&
            this.props.suggestions &&
            (prevProps.suggestions.length !== this.props.suggestions.length)
        );

        if (shouldUpdateSuggestions) {
            this._processSuggestions();
        }
    }

    render() {
        const {
            auth: { userInfo },
            i18n: { getString }
        } = this.props;
        const {
            tabs,
            currentTab
        } = this.state;
        const isStudent = isStudentAccount(userInfo);

        return (
            <PageTemplate
                {...this.props}
                className="contacts-wrapper"
            >
                <LocalNav
                    title={getString("dashboard.myContacts")}
                    page="contacts"
                    caption=""
                    className="section-local-nav"
                />
                <div className="module-section">
                    <div className="section-tabs">
                        <TabbedView
                            tabs={tabs}
                            onTabSelected={this._onTabSelected}
                        />
                    </div>
                    <div className="section-content">
                        {(currentTab === 0) && (isStudent ? this._showClassmates() : this._showFollowers())}
                        {(currentTab === 1) && (isStudent ? this._showFollowers() : this._showFollowing())}
                        {(currentTab === 2) && (isStudent ? this._showFollowing() : this._showSuggestions())}
                        {(currentTab === 3) && this._showSuggestions()}
                    </div>
                </div>
            </PageTemplate>
        );
    }
}

Contacts.propTypes = {
    title: PropTypes.string,
    i18n: PropTypes.object,
    auth: PropTypes.object,
    context: PropTypes.object,
    universityStudents: PropTypes.array,
    followers: PropTypes.array,
    following: PropTypes.array,
    suggestions: PropTypes.array
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth,
    universityStudents: state.universities.universityStudents,
    followers: state.network.followers,
    following: state.network.following,
    suggestions: state.network.suggestions,
    isLoadingStudents: state.universities.isLoadingStudents,
    isLoadingFollowers: state.network.isLoadingFollowers,
    isLoadingFollowing: state.network.isLoadingFollowing,
    isLoadingSuggestions: state.network.isLoadingSuggestions
});

const mapDispatchToProps = {
    retrieveInboundFollows,
    retrieveOutboundFollows,
    fetchUniversityStudents,
    retrieveContactSuggestions,
    followContact,
    followContactFromSuggestions,
    unfollowContact
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
