/** -------------------------------------------------------------------------------------------------------------------
 * UniversityView
 * A component that renders the view for entering the details of a university
 *
 * @examples
 *
 *  ```jsx
 *
 *    <InvitationView
 *       i18n={i18n}
 *       invite={invite}
 *       disabled={false}
 *       hasError={false}
 *       onInvitationCodeEntered={(code) => console.log("Processing code: ", code)}
 *    />
 * ```
 *
 *  @component InvitationView
 *  @import InvitationView
 *  @returns {object} React component
 *
*--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import InputField from "../../shared/input-field";
import TextArea from "../../shared/text-area";

import { noop } from "../../../utils/functions";


export class UniversityView extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.formData
        };
    }

    render() {
        const {
            i18n: { getString },
            validators,
            onFieldValidated,
            onFieldValidationFailed
        } = this.props;

        const {
            universityName,
            universityDescription
        } = this.state;

        return (
            <div className="university-info-wrapper">
                <div className="row university-name">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("register.universityName")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <InputField
                            value={universityName}
                            placeholder={getString("register.universityNameHint")}
                            validationTypes={["isRequired"]}
                            validators={validators}
                            showClearControl={false}
                            onValid={(value) => onFieldValidated("universityName", value)}
                            onError={(value) => onFieldValidationFailed("universityName", value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("generic.description")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <TextArea
                            value={universityDescription}
                            placeholder={getString("generic.description")}
                            onChange={(value) => onFieldValidated("universityDescription", value)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

UniversityView.propTypes = {
    i18n: PropTypes.object,
    formData: PropTypes.object,
    validators: PropTypes.object,
    onFieldValidated: PropTypes.func,
    onFieldValidationFailed: PropTypes.func
};

UniversityView.defaultProps = {
    onFieldValidated: noop,
    onFieldValidationFailed: noop
};

export default UniversityView;
