import * as types from "./network.types";

const initialState = {
    isLoading: false,
    isLoadingFollowers: false,
    isLoadingFollowing: false,
    isLoadingSuggestions: false,
    contacts: [],
    pendingRequets: [],
    summary: {},
    following: [],
    followers: [],
    suggestions: [],
    error: null
};

const network = (state = initialState, action = {}) => {
    switch (action.type) {

        case types.USER_NETWORK_REQUEST:
        case types.SOCIAL_NETWORK_SUMMARY_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case types.USER_NETWORK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                contacts: action.payload
            };

        case types.SOCIAL_NETWORK_SUMMARY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                summary: action.payload
            };

        case types.USER_NETWORK_FAILURE:
        case types.SOCIAL_NETWORK_SUMMARY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case types.INBOUND_FOLLOWS_REQUEST:
            return {
                ...state,
                isLoadingFollowers: true,
                error: null
            };

        case types.INBOUND_FOLLOWS_SUCCESS:
            return {
                ...state,
                isLoadingFollowers: false,
                followers: action.payload
            };

        case types.INBOUND_FOLLOWS_FAILURE:
            return {
                ...state,
                isLoadingFollowers: false,
                error: action.error
            };

        case types.OUTBOUND_FOLLOWS_REQUEST:
            return {
                ...state,
                isLoadingFollowing: true,
                error: null
            };

        case types.OUTBOUND_FOLLOWS_SUCCESS:
            return {
                ...state,
                isLoadingFollowing: false,
                following: action.payload
            };

        case types.OUTBOUND_FOLLOWS_FAILURE:
            return {
                ...state,
                isLoadingFollowing: false,
                error: action.error
            };

        case types.FOLLOW_SUGGESTIONS_REQUEST:
            return {
                ...state,
                isLoadingSuggestions: true,
                error: null
            };

        case types.FOLLOW_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                isLoadingSuggestions: false,
                suggestions: action.payload
            };

        case types.FOLLOW_SUGGESTIONS_FAILURE:
            return {
                ...state,
                isLoadingSuggestions: false,
                error: action.error
            };

        case types.ADD_OUTBOUND_FOLLOW:
            return {
                ...state,
                following: [
                    ...state.following,
                    action.payload
                ]
            };

        case types.REMOVE_OUTBOUND_FOLLOW: {
            const updatedFollowing = state.following.filter((contact) => {
                return (contact.id !== action.payload.id);
            });

            return {
                ...state,
                following: updatedFollowing
            };
        }

        case types.REMOVE_SUGGESTION: {
            const updatedSuggestions = state.suggestions.filter((contact) => {
                return (contact.id !== action.payload.id);
            });

            return {
                ...state,
                suggestions: updatedSuggestions
            };
        }

        default:
            return state;
    }
};

export default network;
