import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import LocalNav from "../shared/local-nav";
import PageTemplate from "../shared/pages/template";
import { Loader } from "../shared/spinner/loader";

import { fetchUniversities } from "../../store/reducers/universities/universities.actions";
import { isAdminAccount } from "../../utils/app-utils";

import "../contacts/contacts.scss";

/* eslint-disable */
export class Universities extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showingInfoMessage: false
        };
    }

    _processUniversities() {
    }

    _onUniversityActions = (university, action) => {
    };

    _showInstructors() {
        const {
            auth: { userInfo },
            i18n
        } = this.props;
        const { instructors } = this.state;

        return instructors ? (
            <ContactListView
                type={USERS}
                userInfo={userInfo}
                i18n={i18n}
                contacts={instructors}
                outboundFollows={[]}
                onContactActions={this._onContactActions}
            />
        ) : null;
    }

    componentDidMount() {
        const {
            auth: { userInfo },
            fetchUniversities,
            history
        } = this.props;

        if (!isAdminAccount(userInfo)) {
            history.push("/dashboard");
            return;
        }

        // fetchUniversities();
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.isLoading === true && this.props.isLoading === false) {
    //         this._processUniversities();
    //     }
    // }

    render() {
        const {
            i18n: { getString },
            isLoading
        } = this.props;

        return (
            <PageTemplate
                {...this.props}
                className="universities-wrapper"
            >
                <LocalNav
                    title={getString("dashboard.universities")}
                    page="universities"
                    caption=""
                    className="section-local-nav"
                />
                <div className="module-section">
                    <div className="section-content">
                        {isLoading && <div className="loader-wrapper"><Loader /></div>}
                        {!isLoading && <div />}
                    </div>
                </div>
            </PageTemplate>
        );
    }
}

Universities.propTypes = {
    title: PropTypes.string,
    i18n: PropTypes.object,
    auth: PropTypes.object,
    context: PropTypes.object,
    universities: PropTypes.array,
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth,
    universities: state.universities.universityList,
    isLoading: state.universities.isLoading
});

const mapDispatchToProps = {
    fetchUniversities
};

export default connect(mapStateToProps, mapDispatchToProps)(Universities);
