/** -------------------------------------------------------------------------------------------------------------------
 * PersonalInfoView
 * A component that renders the view for updating a user's personal info
 *
 * @examples
 *
 *  ```jsx
 *
 *    <PersonalInfoView
 *      userInfo={userInfo}
 *      i18n={i18n}
 *      updating={isUpdating}
 *      onUpdate={(details) => console.log("Updating user info: ", details)}
 *    />
 * );
 * ```
 *
 *  @component PersonalInfoView
 *  @import PersonalInfoView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import InputField from "../../shared/input-field";
import TextArea from "../../shared/text-area";
import Button from "../../shared/button";
import Selector from "../../shared/selector";
import { Loader } from "../../shared/spinner/loader";

import { noop } from "../../../utils/functions";
import { EMAIL_REGEX } from "../../sign-up/views/registration-view";
import genders from "../../../store/data/genders";


export class PersonalInfoView extends PureComponent {

    constructor(props) {
        super(props);

        const {
            id: username,
            firstName,
            lastName,
            email,
            bio,
            gender
        } = this.props.userInfo;

        const validators = this._getFieldValidators();
        const validationStates = {
            firstName: true,
            lastName: true,
            email: true
        };

        this.state = {
            username,
            firstName,
            lastName,
            email,
            bio,
            gender,
            validators,
            validationStates,
            isValidForm: true
        };
    }

    _getFieldValidators() {
        const { getString } = this.props.i18n;

        return {
            isRequired: {
                test: (value) => !!value.trim().length,
                message: getString("form.fieldIsRequired")
            },
            isEmail: {
                test: (value) => (value.length && EMAIL_REGEX.test(value)),
                message: getString("login.invalidEmail")
            }
        };
    }

    _generateGenderOptions() {
        const {
            i18n: { getString }
        } = this.props;

        const { gender } = this.state;
        const optionsList = genders.map((gender) => ({
            id: gender.value,
            title: getString(gender.localizedString)
        }));

        optionsList.forEach((option) => {
            if (option.id === gender) {
                option.selected = true;
            }
        });

        return optionsList;
    }

    _onFieldValidated = (field, value) => {
        const { validationStates } = this.state;
        validationStates[field] = true;
        const isValidForm = Object.values(validationStates).every((item) => !!item);

        this.setState({
            [field]: value,
            validationStates,
            isValidForm
        });
    };

    _onFieldValidationFailed = (field, value) => {
        const { validationStates } = this.state;
        validationStates[field] = false;

        this.setState({
            [field]: value,
            validationStates,
            isValidForm: false
        });
    };

    _onGenderSelected = (event) => {
        this.setState({
            gender: event.target.value
        });
    };

    _updateUserInfo = () => {
        if (!this.state.isValidForm) {
            return;
        }

        const {
            firstName,
            lastName,
            email,
            bio,
            gender
        } = this.state;
        const updatedDetails = {
            firstName,
            lastName,
            email,
            bio,
            gender
        };

        this.props.updateInfo(updatedDetails);
    };

    render() {
        const {
            i18n: { getString },
            updating
        } = this.props;

        const {
            username,
            firstName,
            lastName,
            email,
            bio,
            validators,
            isValidForm
        } = this.state;

        return (
            <div className="profile-section user-info-wrapper">
                <h3 className="profile-section-header">{getString("register.userInfo")}</h3>
                <div className="profile-section-wrapper">
                    <div className="row">
                        <div className="column medium-6 small-12 label">
                            <label>{getString("login.username")}</label>
                        </div>
                        <div className="column medium-6 small-12">
                            <InputField
                                value={username}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="column medium-6 small-12 label">
                            <label>{getString("login.firstName")}</label>
                        </div>
                        <div className="column medium-6 small-12">
                            <InputField
                                value={firstName}
                                placeholder={getString("login.firstNameHint")}
                                validationTypes={["isRequired"]}
                                validators={validators}
                                showClearControl={false}
                                onValid={(value) => this._onFieldValidated("firstName", value)}
                                onError={(value) => this._onFieldValidationFailed("firstName", value)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="column medium-6 small-12 label">
                            <label>{getString("login.lastName")}</label>
                        </div>
                        <div className="column medium-6 small-12">
                            <InputField
                                value={lastName}
                                placeholder={getString("login.lastNameHint")}
                                validationTypes={["isRequired"]}
                                validators={validators}
                                showClearControl={false}
                                onValid={(value) => this._onFieldValidated("lastName", value)}
                                onError={(value) => this._onFieldValidationFailed("lastName", value)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="column medium-6 small-12 label">
                            <label>{getString("profile.gender")}</label>
                        </div>
                        <div className="column medium-6 small-12">
                            <Selector
                                options={this._generateGenderOptions()}
                                onChange={this._onGenderSelected}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="column medium-6 small-12 label">
                            <label>{getString("login.email")}</label>
                        </div>
                        <div className="column medium-6 small-12">
                            <InputField
                                value={email}
                                placeholder={getString("login.emailHint")}
                                validationTypes={["isRequired", "isEmail"]}
                                validators={validators}
                                showClearControl={false}
                                onValid={(value) => this._onFieldValidated("email", value)}
                                onError={(value) => this._onFieldValidationFailed("email", value)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="column medium-6 small-12 label">
                            <label>{getString("profile.biography")}</label>
                        </div>
                        <div className="column medium-6 small-12">
                            <TextArea
                                value={bio}
                                onChange={(value) => this._onFieldValidated("bio", value)}
                            />
                        </div>
                    </div>
                    <div className="row actions">
                        <Button
                            title={getString("generic.update")}
                            disabled={!isValidForm || updating}
                            onClick={this._updateUserInfo}
                        />
                    </div>
                    {updating && <div className="loader-wrapper">
                        <Loader />
                    </div>}
                </div>
            </div>
        );
    }
}

PersonalInfoView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    updating: PropTypes.bool,
    updateInfo: PropTypes.func
};

PersonalInfoView.defaultProps = {
    updateInfo: noop
};

export default PersonalInfoView;
