/** ----------------------------------------------------------------------------------------------------------------
 * PageTemplate
 * A component that renders the main template of inline pages, which includes the global navigation, the footer,
 * and other such common components
 *
 * @examples
 *
 *  ```jsx
 *    <PageTemplate
 *      className="dashboard-wrapper">
 *          <LocalNav />
 *          <div className="module-content-section" />
 *    />
 * ```
 *
 *  @component PageTemplate
 *  @import PageTemplate
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";


const PageNotFound = ({ i18n }) => (
    <section className="page-wrapper error-wrapper">
        <section className="global-nav-wrapper">
            <nav className="main-nav" role="navigation" aria-label="Main Navigation">
                <div className="nav-wrapper">
                    <div className="nav-content">
                        <div className="row">
                            <div className="column medium-2 small-12 logo-wrapper">
                                <h2 className="nav-title">
                                    <a href="/">
                                        <span className="localnav-title icon"></span>{"1UA"}
                                    </a>
                                </h2>
                            </div>
                            <div className="column medium-10 small-12 medium-show nav-menu">
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </section>

        <div className="module-section error-section">
            <div className="content-wrapper">
                <h2>{i18n.getString("error.title")}</h2>
                <p className="typography-intro">{i18n.getString("error.description")}</p>
                <p className="actions">
                    <button className="button" onClick={() => history.back()}>
                        {i18n.getString("error.backToHome")}
                    </button>
                </p>
            </div>
        </div>
    </section>
);


PageNotFound.propTypes = {
    i18n: PropTypes.object
};

export default PageNotFound;
