import * as types from "./context.types";

let preferredLanguage;

try {

    /** This is needed since LocalStorage might not be available in Private mode */
    preferredLanguage = JSON.parse(localStorage.getItem("preferredLanguage"));

} catch (e) {
    /* eslint-disable-line no-empty */
}

if (!preferredLanguage) {
    const locale = window.Classroom ? window.Classroom.locale : "en";
    const country = window.Classroom ? window.Classroom.country : "us";

    preferredLanguage = {
        locale,
        country
    };
}

export const getPreferredLocale = () => preferredLanguage.locale;

export const initialState = {
    contextPath: window.Classroom ? window.Classroom.contextPath : "/",
    ...preferredLanguage
};

const context = (state = initialState, action = {}) => {
    switch (action.type) {

        case types.GET_APPLICATION_CONTEXT:
            return {
                ...state
            };

        case types.LOCALE_CHANGED:
            preferredLanguage = {
                ...action.locale
            };

            return {
                ...state,
                ...preferredLanguage
            };

        default:
            return state;
    }
};

export default context;
