export const USER_NETWORK_REQUEST = "USER_NETWORK_REQUEST";
export const USER_NETWORK_SUCCESS = "USER_NETWORK_SUCCESS";
export const USER_NETWORK_FAILURE = "USER_NETWORK_FAILURE";

export const SOCIAL_NETWORK_SUMMARY_REQUEST = "SOCIAL_NETWORK_SUMMARY_REQUEST";
export const SOCIAL_NETWORK_SUMMARY_SUCCESS = "SOCIAL_NETWORK_SUMMARY_SUCCESS";
export const SOCIAL_NETWORK_SUMMARY_FAILURE = "SOCIAL_NETWORK_SUMMARY_FAILURE";

export const INBOUND_FOLLOWS_REQUEST = "INBOUND_FOLLOWS_REQUEST";
export const INBOUND_FOLLOWS_SUCCESS = "INBOUND_FOLLOWS_SUCCESS";
export const INBOUND_FOLLOWS_FAILURE = "INBOUND_FOLLOWS_FAILURE";

export const OUTBOUND_FOLLOWS_REQUEST = "OUTBOUND_FOLLOWS_REQUEST";
export const OUTBOUND_FOLLOWS_SUCCESS = "OUTBOUND_FOLLOWS_SUCCESS";
export const OUTBOUND_FOLLOWS_FAILURE = "OUTBOUND_FOLLOWS_FAILURE";

export const ADD_OUTBOUND_FOLLOW = "ADD_OUTBOUND_FOLLOW";
export const FOLLOW_FROM_SUGGESTIONS = "FOLLOW_FROM_SUGGESTIONS";
export const ADD_OUTBOUND_FOLLOW_REQUEST = "ADD_OUTBOUND_FOLLOW_REQUEST";
export const ADD_OUTBOUND_FOLLOW_SUCCESS = "ADD_OUTBOUND_FOLLOW_SUCCESS";
export const ADD_OUTBOUND_FOLLOW_FAILURE = "ADD_OUTBOUND_FOLLOW_FAILURE";

export const REMOVE_OUTBOUND_FOLLOW = "REMOVE_OUTBOUND_FOLLOW";
export const REMOVE_SUGGESTION = "REMOVE_SUGGESTION";
export const REMOVE_OUTBOUND_FOLLOW_REQUEST = "REMOVE_OUTBOUND_FOLLOW_REQUEST";
export const REMOVE_OUTBOUND_FOLLOW_SUCCESS = "REMOVE_OUTBOUND_FOLLOW_SUCCESS";
export const REMOVE_OUTBOUND_FOLLOW_FAILURE = "REMOVE_OUTBOUND_FOLLOW_FAILURE";

export const FOLLOW_SUGGESTIONS_REQUEST = "FOLLOW_SUGGESTIONS_REQUEST";
export const FOLLOW_SUGGESTIONS_SUCCESS = "FOLLOW_SUGGESTIONS_SUCCESS";
export const FOLLOW_SUGGESTIONS_FAILURE = "FOLLOW_SUGGESTIONS_FAILURE";
