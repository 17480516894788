import * as types from "./context.types";

export const getApplicationContext = () => (dispatch) => {
    return dispatch({
        type: types.GET_APPLICATION_CONTEXT
    });
};

export const changeLocale = (language) => (dispatch) => {
    return dispatch({
        type: types.LOCALE_CHANGED,
        locale: {
            ...language
        }
    });
};

