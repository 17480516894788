/** -------------------------------------------------------------------------------------------------------------------
 * PrivacySettingsView
 * A component that renders the view for changing a user's password
 *
 * @examples
 *
 *  ```jsx
 *
 *    <PrivacySettingsView
 *      userInfo={userInfo}
 *      i18n={i18n}
 *      settings={settings}
 *      onChange={(setting, checked) => console.log("Setting changed: ", setting, checked)}
 *    />
 * );
 * ```
 *
 *  @component PrivacySettingsView
 *  @import PrivacySettingsView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";

import Toggle from "../../shared/toggle";
import { noop } from "../../../utils/functions";


export class PrivacySettingsView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            makeProfilePrivate: false
        };
    }

    componentDidUpdate(prevProps) {
        const shouldUpdate = (
            prevProps.settings.makeProfilePrivate !== this.props.settings.makeProfilePrivate
        );

        if (shouldUpdate) {
            const {
                makeProfilePrivate,
            } = this.props.settings;

            this.setState({
                makeProfilePrivate: makeProfilePrivate === "true"
            });
        }
    }

    _onChange = (setting, value) => {
        this.setState({
            [setting]: value
        }, () => {
            this.props.onChange(setting, `${value}`);
        });
    };

    render() {
        const {
            i18n: { getString },
        } = this.props;
        const { makeProfilePrivate } = this.state;

        return (
            <div className="profile-section privacy-settings-wrapper">
                <h3 className="profile-section-header">{getString("privacy.settings")}</h3>
                <div className="profile-section-wrapper settings">
                    <div className="row">
                        <Toggle
                            label={getString("profile.makeProfilePrivate")}
                            checked={makeProfilePrivate}
                            onChange={(id, checked) => this._onChange("makeProfilePrivate", checked)}
                        />
                        <p className="info">
                            {getString("profile.makeProfilePrivateInfo")}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

PrivacySettingsView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    settings: PropTypes.object,
    onChange: PropTypes.func
};

PrivacySettingsView.defaultProps = {
    onChange: noop
};

export default PrivacySettingsView;
