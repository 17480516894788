/* eslint-disable */
import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Home from "./home";
import Dashboard from "./dashboard";
import Courses from "./courses";
import Lectures from "./lectures";
import Videos from "./videos";
import Profile from "./profile";
import Students from "./students";
import Contacts from "./contacts";
import Users from "./users";
import Universities from "./universities";
import PageNotFound from "./shared/pages/not-found";
import Promo from "./shared/pages/promo";
import CreateAccount from "./sign-up";
import Admin from "./admin";
import TermsAndConditions from "./legal";
import PrivacyPolicy from "./legal/privacy-policy";
import Legal from "./legal/legal";
import AuthenticatedPage from "./shared/pages/authenticated-page";

import { InjectIntl } from "../i18n/intl";
import { getPreferredLocale } from "../store/reducers/application/context";

const LocalizedErrorPage = InjectIntl(PageNotFound, getPreferredLocale());
const LocalizedPrivacyPolicy = InjectIntl(PrivacyPolicy, getPreferredLocale());
const LocalizedLegal = InjectIntl(Legal, getPreferredLocale());
const LocalizedPromoPage = InjectIntl(Promo, getPreferredLocale());

/* eslint-disable no-inline-comments */
export class Routes extends Component {

    render() {
        return (
            <Switch>
                <Route
                    exact
                    path="/"
                    component={LocalizedPromoPage}
                />

                {/* <Route
                    exact
                    path="/"
                    component={Home}
                />

                <Route
                    path="/1UA"
                    render={() => (
                        <Redirect to="/" />
                    )}
                />

                <Route
                    path="/dashboard"
                    render={(props) => (
                        <AuthenticatedPage>
                            <Dashboard {...props} />
                        </AuthenticatedPage>
                    )}
                />

                <Route
                    path="/courses"
                    render={(props) => (
                        <AuthenticatedPage>
                            <Courses {...props} />
                        </AuthenticatedPage>
                    )}
                />

                <Route
                    path="/lectures"
                    render={(props) => (
                        <AuthenticatedPage>
                            <Lectures {...props} />
                        </AuthenticatedPage>
                    )}
                />

                <Route
                    path="/videos"
                    render={(props) => (
                        <AuthenticatedPage>
                            <Videos {...props} />
                        </AuthenticatedPage>
                    )}
                />

                <Route
                    path="/profile"
                    render={(props) => (
                        <AuthenticatedPage>
                            <Profile {...props} />
                        </AuthenticatedPage>
                    )}
                />

                <Route
                    path="/students"
                    render={(props) => (
                        <AuthenticatedPage>
                            <Students {...props} />
                        </AuthenticatedPage>
                    )}
                />

                <Route
                    path="/contacts"
                    render={(props) => (
                        <AuthenticatedPage>
                            <Contacts {...props} />
                        </AuthenticatedPage>
                    )}
                />

                <Route
                    path="/users"
                    render={(props) => (
                        <AuthenticatedPage>
                            <Users {...props} />
                        </AuthenticatedPage>
                    )}
                />

                <Route
                    path="/universities"
                    render={(props) => (
                        <AuthenticatedPage>
                            <Universities {...props} />
                        </AuthenticatedPage>
                    )}
                />

                <Route
                    path="/create-account"
                    component={CreateAccount}
                />

                <Route
                    path="/join-university"
                    render={(props) => (
                        <CreateAccount {...props} accountType="student" />
                    )}
                />

                <Route
                    path="/pattern-library"
                    component={PatternLibrary}
                />

                <Route
                    path="/legal"
                    component={LocalizedLegal}
                />

                <Route
                    path="/admin"
                    render={(props) => (
                        <Admin {...props} />
                    )}
                /> */}
                
                <Route
                    path="/terms-and-conditions"
                    render={(props) => (
                        <TermsAndConditions {...props} />
                    )}
                />

                <Route
                    path="/privacy-policy"
                    component={LocalizedPrivacyPolicy}
                />

                <Route component={LocalizedErrorPage} />
            </Switch>
        );
    }
}

/* eslint-enable no-inline-comments */
export default Routes;
