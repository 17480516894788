/** --------------------------------------------------------------------------------------------------------------------
 * LectureRoom
 * A component that render a lecture room
 *
 * @examples
 *
 *  ```jsx
 *    <LectureRoom
 *      room={room}
 *      onDelete={(id) => console.log("Deleting room")}
 *    />
 * ```
 *
 *  @component LectureRoom
 *  @import LectureRoom
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/


import React from "react";
import PropTypes from "prop-types";


const LectureRoom = ({
    room,
    onDelete
}) => {
    const {
        id,
        name,
        accessLevel,
    } = room;
    const isPrivate = accessLevel.toLowerCase() === "private";

    return (
        <div className="lecture-room">
            <h5>{name}</h5>
            {isPrivate && <span className="icon icon-lock" />}
            <button
                className="icon icon-trash"
                onClick={() => onDelete(id)}
            />
        </div>
    );
};

LectureRoom.propTypes = {
    room: PropTypes.object,
    onDelete: PropTypes.func
};

export default LectureRoom;
