/** -------------------------------------------------------------------------------------------------------------------
 * UpcomingLecture
 * A component that render an upcoming lecture
 *
 *  @examples
 *
 *  ```jsx
 *    <UpcomingLecture
 *      lecture={lecture}
 *      i18n={i18n}
 *    />
 * ```
 *
 *  @component UpcomingLecture
 *  @import UpcomingLecture
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { fromUTCString } from "../../../utils/dates";


class UpcomingLecture extends PureComponent {

    render() {
        const {
            i18n,
            i18n: { languagePack: { timeFormat }},
            lecture
        } = this.props;

        const startTime = fromUTCString(lecture.startDate);
        const formattedStartTime = i18n.formatTime(startTime, timeFormat.MEDIUM);
        const endTime = fromUTCString(lecture.endDate);
        const formattedEndTime = i18n.formatTime(endTime, timeFormat.MEDIUM);

        return (
            <React.Fragment>
                <div className="date-time">
                    <em>{formattedStartTime}</em>
                    <em>{formattedEndTime}</em>
                </div>
                <div className="lecture-info">
                    <p>{lecture.title}</p>
                    <em>{lecture.course.courseId}</em>
                </div>
            </React.Fragment>
        );
    }
}

UpcomingLecture.propTypes = {
    lecture: PropTypes.object.isRequired,
    className: PropTypes.string,
    i18n: PropTypes.object
};

UpcomingLecture.defaultProps = {
};

export default UpcomingLecture;
