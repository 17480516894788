/** -------------------------------------------------------------------------------------------------------------------
 * UserInfoView
 * A component that renders the view for processing the details for a new user.
 *
 * @examples
 *
 *  ```jsx
 *
 *    <UserInfoView
 *       i18n={i18n}
 *       formData={formData}
 *       validators={validators}
 *       onFieldValidated={(field, value) => console.log("Field validated")}
 *       onFieldValidationFailed={(field, value) => console.log("Field validation failed")}
 *    />
 * ```
 *
 *  @component UserInfoView
 *  @import UserInfoView
 *  @returns {object} React component
 *
*--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";

import InputField from "../../shared/input-field";

import { SECRET_KEYWORD } from "./registration-view";
import { noop } from "../../../utils/functions";


export class UserInfoView extends Component {

    constructor(props) {
        super(props);

        const {
            username,
            password,
            confirmedPassword,
            firstName,
            lastName,
            email,
            verification
        } = this.props.formData;

        this.state = {
            username,
            password,
            confirmedPassword,
            firstName,
            lastName,
            email,
            verification
        };
    }

    /**
     * This logic is needed so that, assuming the user enters the verification last (which is more likely
     * since it is the last field), the "Next" button enabled before she tabs out of the field
     */
    _onVerificationEntered = (value) => {
        this.setState({
            verification: value
        });

        if (value === SECRET_KEYWORD) {
            this.props.onFieldValidated("verification", value);
        } else {
            this.props.onFieldValidationFailed("verification", value);
        }
    };

    render() {
        const {
            i18n: { getString },
            accountType,
            validators,
            onFieldValidated,
            onFieldValidationFailed
        } = this.props;

        const {
            username,
            password,
            confirmedPassword,
            firstName,
            lastName,
            email,
            verification
        } = this.state;

        return (
            <div className="user-info-wrapper">
                <div className="row account-type">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("generic.accountType")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <p>{getString(`generic.${accountType.toLowerCase()}`)}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("login.username")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <InputField
                            value={username}
                            placeholder={getString("login.usernameHint")}
                            validationTypes={[
                                "isRequired", "hasMinimumLength", "noSpacesAllowed", "isValidUsername"
                            ]}
                            validators={validators}
                            showClearControl={false}
                            onValid={(value) => onFieldValidated("username", value)}
                            onError={(value) => onFieldValidationFailed("username", value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("login.password")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <InputField
                            type="password"
                            value={password}
                            placeholder={getString("login.passwordHint")}
                            validationTypes={[
                                "isRequired", "hasMinimumPasswordLength", "noSpacesAllowed", "isValidPassword"
                            ]}
                            validators={validators}
                            showClearControl={false}
                            onValid={(value) => onFieldValidated("password", value)}
                            onError={(value) => onFieldValidationFailed("password", value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("login.passwordConfirm")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <InputField
                            type="password"
                            value={confirmedPassword}
                            placeholder={getString("profile.confirmNewPasswordHint")}
                            validationTypes={["matchesPassword"]}
                            validators={validators}
                            showClearControl={false}
                            onValid={(value) => onFieldValidated("confirmedPassword", value)}
                            onError={(value) => onFieldValidationFailed("confirmedPassword", value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("login.firstName")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <InputField
                            value={firstName}
                            placeholder={getString("login.firstNameHint")}
                            validationTypes={["isRequired"]}
                            validators={validators}
                            showClearControl={false}
                            onValid={(value) => onFieldValidated("firstName", value)}
                            onError={(value) => onFieldValidationFailed("firstName", value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("login.lastName")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <InputField
                            value={lastName}
                            placeholder={getString("login.lastNameHint")}
                            validationTypes={["isRequired"]}
                            validators={validators}
                            showClearControl={false}
                            onValid={(value) => onFieldValidated("lastName", value)}
                            onError={(value) => onFieldValidationFailed("lastName", value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("login.email")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <InputField
                            value={email}
                            placeholder={getString("login.emailHint")}
                            validationTypes={["isRequired", "isEmail"]}
                            validators={validators}
                            showClearControl={false}
                            onValid={(value) => onFieldValidated("email", value)}
                            onError={(value) => onFieldValidationFailed("email", value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="column medium-4 small-12 label">
                        <label>{getString("login.verification")}</label>
                    </div>
                    <div className="column medium-8 small-12">
                        <InputField
                            value={verification}
                            placeholder={getString("login.verificationHint")}
                            validationTypes={["isRequired", "isVerified"]}
                            validators={validators}
                            showClearControl={false}
                            onChange={this._onVerificationEntered}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

UserInfoView.propTypes = {
    i18n: PropTypes.object,
    accountType: PropTypes.string,
    formData: PropTypes.object,
    validators: PropTypes.object,
    onFieldValidated: PropTypes.func,
    onFieldValidationFailed: PropTypes.func
};

UserInfoView.defaultProps = {
    onFieldValidated: noop,
    onFieldValidationFailed: noop
};

export default UserInfoView;
