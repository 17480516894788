export const UNIVERSITIES_REQUEST = "UNIVERSITIES_REQUEST";
export const UNIVERSITIES_SUCCESS = "UNIVERSITIES_SUCCESS";
export const UNIVERSITIES_FAILURE = "UNIVERSITIES_FAILURE";

export const UNIVERSITY_INSTRUCTORS_REQUEST = "UNIVERSITY_INSTRUCTORS_REQUEST";
export const UNIVERSITY_INSTRUCTORS_SUCCESS = "UNIVERSITY_INSTRUCTORS_SUCCESS";
export const UNIVERSITY_INSTRUCTORS_FAILURE = "UNIVERSITY_INSTRUCTORS_FAILURE";

export const UNIVERSITY_STUDENTS_REQUEST = "UNIVERSITY_STUDENTS_REQUEST";
export const UNIVERSITY_STUDENTS_SUCCESS = "UNIVERSITY_STUDENTS_SUCCESS";
export const UNIVERSITY_STUDENTS_FAILURE = "UNIVERSITY_STUDENTS_FAILURE";

export const INVITE_STUDENTS_REQUEST = "INVITE_STUDENTS_REQUEST";
export const INVITE_STUDENTS_SUCCESS = "INVITE_STUDENTS_SUCCESS";
export const INVITE_STUDENTS_FAILURE = "INVITE_STUDENTS_FAILURE";

export const INVITE_REQUEST = "INVITE_REQUEST";
export const INVITE_SUCCESS = "INVITE_SUCCESS";
export const INVITE_FAILURE = "INVITE_FAILURE";

export const UNIVERSITY_RESET_ERRORS = "UNIVERSITY_RESET_ERRORS";

export const RECENT_ACTIVITY_REQUEST = "RECENT_ACTIVITY_REQUEST";
export const RECENT_ACTIVITY_SUCCESS = "RECENT_ACTIVITY_SUCCESS";
export const RECENT_ACTIVITY_FAILURE = "RECENT_ACTIVITY_FAILURE";

export const USERS_REQUEST = "USERS_REQUEST";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_FAILURE = "USERS_FAILURE";
