import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import LocalNav from "../shared/local-nav";
import PageTemplate from "../shared/pages/template";
import TabbedView from "../shared/tabbed-view";
import ContactListView from "../contacts/views/contact-list-view";
import { Loader } from "../shared/spinner/loader";

import { fetchAllUsers } from "../../store/reducers/universities/universities.actions";
import { updateAccountStatus } from "../../store/reducers/profile/profile.actions";
import { isAdminAccount } from "../../utils/app-utils";

import {
    INSTRUCTOR,
    STUDENT,
    USERS
} from "../../constants/contacts";

import "../contacts/contacts.scss";


export class Users extends Component {

    constructor(props) {
        super(props);

        const { getString } = this.props.i18n;
        const tabs = [
            {id: "instructors", title: getString("dashboard.instructors")},
            {id: "students", title: getString("dashboard.students")}
        ];

        this.state = {
            showingInfoMessage: false,
            tabs,
            currentTab: 0
        };
    }

    _onTabSelected = (index) => {
        this.setState({
            currentTab: index
        });
    };

    _processUsers() {
        const {
            auth: { userInfo: { id }},
            users
        } = this.props;

        const usersList = users.filter((user) => user.id !== id);
        const instructors = usersList.filter((user) => user.accountType === INSTRUCTOR);
        const students = usersList.filter((user) => user.accountType === STUDENT);

        this.setState({
            instructors,
            students
        });
    }

    _onContactActions = (contact, action) => {
        const {
            auth: { userInfo: { id }}
        } = this.props;

        const accountStatus = (action === "activate") ? "active" : "inactive";
        this.props.updateAccountStatus(id, contact.id, accountStatus);
    };

    _showInstructors() {
        const {
            auth: { userInfo },
            i18n
        } = this.props;
        const { instructors } = this.state;

        return instructors ? (
            <ContactListView
                type={USERS}
                userInfo={userInfo}
                i18n={i18n}
                contacts={instructors}
                outboundFollows={[]}
                onContactActions={this._onContactActions}
            />
        ) : null;
    }

    _showStudents() {
        const {
            auth: { userInfo },
            i18n
        } = this.props;
        const { students } = this.state;

        return students ? (
            <ContactListView
                type={USERS}
                userInfo={userInfo}
                i18n={i18n}
                contacts={students}
                outboundFollows={[]}
                onContactActions={this._onContactActions}
            />
        ) : null;
    }

    componentDidMount() {
        const {
            auth: { userInfo },
            fetchAllUsers,
            history
        } = this.props;

        if (!isAdminAccount(userInfo)) {
            history.push("/dashboard");
            return;
        }

        fetchAllUsers();
    }

    componentDidUpdate(prevProps) {
        const shouldUpdateUsers = (
            (prevProps.isLoadingUsers === true && this.props.isLoadingUsers === false) ||
            (prevProps.isUpdatingUser === true && this.props.isUpdatingUser === false)
        );

        if (shouldUpdateUsers) {
            this._processUsers();
        }
    }

    render() {
        const {
            i18n: { getString },
            isLoadingUsers
        } = this.props;
        const {
            tabs,
            currentTab
        } = this.state;

        return (
            <PageTemplate
                {...this.props}
                className="contacts-wrapper"
            >
                <LocalNav
                    title={getString("dashboard.users")}
                    page="contacts"
                    caption=""
                    className="section-local-nav"
                />
                <div className="module-section">
                    <div className="section-tabs">
                        <TabbedView
                            tabs={tabs}
                            onTabSelected={this._onTabSelected}
                        />
                    </div>
                    <div className="section-content">
                        {isLoadingUsers && <div className="loader-wrapper"><Loader /></div>}
                        {(!isLoadingUsers && currentTab === 0) && this._showInstructors()}
                        {(!isLoadingUsers && currentTab === 1) && this._showStudents()}
                    </div>
                </div>
            </PageTemplate>
        );
    }
}

Users.propTypes = {
    title: PropTypes.string,
    i18n: PropTypes.object,
    auth: PropTypes.object,
    context: PropTypes.object,
    users: PropTypes.array,
};

const mapStateToProps = (state) => ({
    context: state.context,
    auth: state.auth,
    users: state.universities.allUsers,
    isLoadingUsers: state.universities.isLoadingUsers,
    isUpdatingUser: state.universities.isUpdatingUser
});

const mapDispatchToProps = {
    fetchAllUsers,
    updateAccountStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
