/** -------------------------------------------------------------------------------------------------------------------
 * DiscussionComment
 * A component that renders a discussion comment
 *
 *  @examples
 *
 *  ```jsx
 *    <DiscussionComment
 *      comment={comment}
 *      i18n={i18n}
 *      onCommentActions={(comment, action) => { console.log("onCommentAction"); }}
 *    />
 * ```
 *
 *  @component DiscussionComment
 *  @import DiscussionComment
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { generateDiscussionStats } from "../../../utils/discussion-utils";
import { noop } from "../../../utils/functions";
import {
    ACTION_REPLY,
    ACTION_LIKE
} from "../../../constants/social-actions";

import "../courses.scss";


class DiscussionComment extends PureComponent {

    _onCommentActions = (event) => {
        const action = event.target.getAttribute("data-action");
        this.props.onCommentActions(this.props.comment, action);
    };

    render() {
        const {
            comment,
            comment: { author },
            i18n
        } = this.props;
        const stats = generateDiscussionStats(comment, i18n);

        return (
            <div className="discussion-info-wrapper">
                <figure className="avatar">
                    <img src={author.avatarURL} />
                </figure>
                <div className="discussion-info">
                    <h5>{author.id}</h5>
                    <p>{comment.body}</p>
                    <dl className="stats">
                        <dt>Time</dt>
                        <dd>{stats.relativeTime}</dd>
                        <dt>Likes</dt>
                        <dd>{stats.totalLikes}</dd>
                        <dt>Reply</dt>
                        <dd>
                            <button
                                className="reply"
                                data-action={ACTION_REPLY}
                                onClick={this._onCommentActions}
                            >
                                <strong>{i18n.getString("discussions.reply")}</strong>
                            </button>
                        </dd>
                    </dl>
                </div>
                <div className="actions">
                    <button
                        className="like"
                        data-action={ACTION_LIKE}
                        onClick={this._onCommentActions}
                    />
                </div>
            </div>
        );
    }
}

DiscussionComment.propTypes = {
    comment: PropTypes.object.isRequired,
    className: PropTypes.string,
    i18n: PropTypes.object,
    onCommentActions: PropTypes.func
};

DiscussionComment.defaultProps = {
    onCommentActions: noop
};

export default DiscussionComment;
