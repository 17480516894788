/** -------------------------------------------------------------------------------------------------------------------
 * CourseDiscussionDetailsView
 * A component that renders the details of a course discussion
 *
 *  @examples
 *
 *  ```jsx
 *    <CourseDiscussionDetailsView
 *      discussion={discussion}
 *      i18n={i18n}
 *      onDiscussionActions={(discussion, action) => { console.log("onDiscussionAction"); }}
 *    />
 * ```
 *
 *  @component CourseDiscussionDetailsView
 *  @import CourseDiscussionDetailsView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { LoadingText } from "../../shared/spinner/loading-text";
import DiscussionComment from "./discussion-comment";

import {
    ACTION_LIKE,
    ACTION_SAVE
} from "../../../constants/social-actions";

import { noop } from "../../../utils/functions";
import { generateDiscussionStats } from "../../../utils/discussion-utils";

import "../courses.scss";


class CourseDiscussionDetailsView extends PureComponent {

    _onDiscussionActions = (event) => {
        const {
            discussion,
            onDiscussionActions
        } = this.props;
        const action = event.target.getAttribute("data-action");

        onDiscussionActions(discussion, action);
    };

    _onCommentActions = (comment, action) => {
        this.props.onCommentActions(this.props.discussion, comment, action);
    };

    _renderEmptyContentMessage() {
        const {
            i18n: { getString }
        } = this.props;

        return (
            <div className="discussion-details-wrapper">
                <p className="info-message">
                    <span>{getString("discussions.noDiscussionSelected")}</span>
                </p>
            </div>
        );
    }

    _renderCommentsList() {
        const {
            comments,
            likedComments,
            i18n
        } = this.props;

        if (!comments || comments.length === 0) {
            return (
                <p className="info-message">
                    <span>{i18n.getString("discussions.noComments")}</span>
                </p>
            );
        }

        return (
            <ul className="item-list discussion-list comment-list">
                {comments.map((comment) => {
                    const isLiked = likedComments.includes(comment.id);
                    const className = classNames("discussion comment", {
                        "is-liked": isLiked
                    });

                    return (
                        <li
                            key={comment.id}
                            data-id={comment.id}
                            className={className}
                        >
                            <DiscussionComment
                                comment={comment}
                                i18n={i18n}
                                onCommentActions={this._onCommentActions}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    }

    _renderDiscussionDetails() {
        const {
            discussion,
            discussion: { author },
            isLiked,
            i18n,
            loadingComments
        } = this.props;
        const stats = generateDiscussionStats(discussion, i18n);

        return (
            <div className="discussion-details-wrapper">
                <div className="discussion-details">
                    <figure className="avatar">
                        <img src={author.avatarURL} />
                    </figure>
                    <div className="discussion-info">
                        <h5>{author.id}</h5>
                        <p>{discussion.body}</p>
                        <dl className="stats">
                            <dt>Time</dt>
                            <dd>{stats.relativeTime}</dd>
                            <dt>Likes</dt>
                            <dd>{stats.totalLikes}</dd>
                            <dt>Comments</dt>
                            <dd>{stats.totalComments}</dd>
                        </dl>
                        <ul className="social-actions">
                            <li className="social-action">
                                <button
                                    data-action={ACTION_LIKE}
                                    className={classNames("social-action like", { active: isLiked })}
                                    onClick={this._onDiscussionActions}
                                />
                            </li>
                            <li className="social-action">
                                <button
                                    data-action={ACTION_SAVE}
                                    className="social-action save"
                                    onClick={this._onDiscussionActions}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="discussion-comments">
                    {loadingComments ?
                        <LoadingText text={i18n.getString("generic.loading")} /> :
                        this._renderCommentsList()
                    }
                </div>
            </div>
        );
    }

    render() {
        const { discussion } = this.props;
        return (discussion != null) ? this._renderDiscussionDetails() : this._renderEmptyContentMessage();
    }
}

CourseDiscussionDetailsView.propTypes = {
    discussion: PropTypes.object,
    comments: PropTypes.array,
    likedComments: PropTypes.array,
    className: PropTypes.string,
    i18n: PropTypes.object,
    loadingComments: PropTypes.bool,
    isLiked: PropTypes.bool,
    onDiscussionActions: PropTypes.func,
    onCommentActions: PropTypes.func
};

CourseDiscussionDetailsView.defaultProps = {
    onDiscussionActions: noop,
    onCommentActions: noop
};

export default CourseDiscussionDetailsView;
