import Crypto from "./index";


export default class DigestMD5 {

    constructor() {
        this.mechanism = "Digest-MD5";
        this.NS = "urn:ietf:params:xml:ns:xmpp-sasl";
    }

    /**
     * Decodes the specified authentication challenge
     *
     * @param challenge             the Base64-encoded string to decode
     * @return the representation of the decoded Base64 string
     */
    decodeChallenge(challenge) {
        const decodedChallenge = Crypto.Base64.decode(challenge);

        return decodedChallenge.split(",").reduce((result, component) => {
            const parts = component.split("=");
            const value = parts[1];
            result[parts[0]] = value.substring(1, value.length - 1);

            return result;
        }, {});
    }

    /**
     * Constructs the challenge response for the specified authentication string, as described by the DIGEST-MD5
     * documentation
     *
     * @param challenge             the authentication challenge to generate the response for
     */
    handleChallenge(challenge = {}) {
        const { toMD5 } = Crypto;
        const method = challenge.method || "GET";

        const ha1 = toMD5(`${challenge.username}:${challenge.realm}:${challenge.password}`);
        const ha2 = toMD5(`${method.toUpperCase()}:${challenge.digestURI}`);

        return toMD5(`${ha1}:${challenge.nonce}:${challenge.nc}:${challenge.qop}:${ha2}`);
    }
}
