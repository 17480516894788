/** -------------------------------------------------------------------------------------------------------------------
 * LoginForm
 * A component that renders a login form and invokes a callback method on login action
 *
 * @examples
 *
 *  ```jsx
 *    <LoginForm
 *      className="user-login"
 *      onLogin={(username, password) => console.log("Coming in...")}
 *    />
 * ```
 *
 *  @component LoginForm
 *  @import LoginForm
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import InputField from "../../shared/input-field";
import Button from "../../shared/button";

import "./login.scss";


export default class LoginForm extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: ""
        };
    }

    _onInputFieldChange = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    _onLogin = () => {
        const { username, password } = this.state;

        if (this.props.onLogin) {
            this.props.onLogin(username, password);
        }
    };

    _onPasswordForgotten = () => {
        if (this.props.onPasswordForgotten) {
            this.props.onPasswordForgotten();
        }
    };

    render() {
        const {
            className,
            disabled,
            i18n: { getString }
        } = this.props;
        const {
            username,
            password,
        } = this.state;
        const disableButton = disabled || !(username.length || password.length);

        return (
            <form className={classNames("login-form", className)}>
                <ul className="rounded">
                    <li>
                        <InputField
                            className="username"
                            value={username}
                            placeholder={getString("login.usernameHint")}
                            disabled={disabled}
                            validationTypes={["isRequired"]}
                            showClearControl={false}
                            onChange={(value) => this._onInputFieldChange("username", value)}
                            onSubmit={this._onLogin}
                        />
                    </li>
                    <li className="password-field">
                        <InputField
                            type="password"
                            className="password"
                            value={password}
                            placeholder={getString("login.passwordHint")}
                            disabled={disabled}
                            validationTypes={["isRequired"]}
                            showClearControl={false}
                            onChange={(value) => this._onInputFieldChange("password", value)}
                            onSubmit={this._onLogin}
                        />
                    </li>
                    <li className="password">
                        <label className="password-reminder">
                            <a href="javascript:void(0);"
                                className="password-reset-link icon-help"
                                onClick={this._onPasswordForgotten}>{getString("login.forgotPassword")}</a>
                        </label>
                    </li>
                    <li className="actions">
                        <Button
                            title={getString("login.loginText")}
                            className="submit-button"
                            disabled={disableButton}
                            onClick={this._onLogin} />
                    </li>
                </ul>
            </form>
        );
    }
}

LoginForm.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    i18n: PropTypes.object,
    onLogin: PropTypes.func,
    onPasswordForgotten: PropTypes.func
};

LoginForm.defaultProps = {
    onLogin: () => {},
    onPasswordForgotten: () => {}
};
