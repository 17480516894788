import * as types from "./courses.types";

const initialState = {
    isLoading: false,
    isUpdating: false,
    isLoadingDiscussions: false,
    isLoadingComments: false,
    isPostingComment: false,
    isStartingDiscussion: false,
    isProcessingAction: false,
    isLoadingStats: false,
    isLoadingLectures: false,
    courseList: [],
    discussions: [],
    activeDiscussionId: null,
    comments: {},
    likedDiscussions: [],
    likedComments: [],
    lectures: [],
    query: {},
    error: null
};

const processCourses = (courseList) => {
    if (Array.isArray(courseList)) {
        courseList.forEach((course) => {
            if (typeof course.configuration === "string") {
                course.configuration = JSON.parse(course.configuration);
            }
        });
    }

    return courseList;
};


const courses = (state = initialState, action = {}) => {
    switch (action.type) {

        case types.COURSES_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case types.COURSES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                courseList: processCourses(action.payload)
            };

        case types.COURSES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case types.COURSE_DETAILS_UPDATED:
            const { details: { name, value }} = action;

            return {
                ...state,
                query: {
                    ...state.query,
                    [name]: value
                }
            };

        // Fall through
        case types.CREATE_COURSE_REQUEST:
        case types.UPDATE_COURSE_REQUEST:
            return {
                ...state,
                isUpdating: true
            };

        case types.CREATE_COURSE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                courseList: [
                    ...state.courseList,
                    action.payload
                ],
                query: {}
            };

        case types.CREATE_COURSE_FAILURE:
            return {
                ...state,
                isUpdating: false,
                error: action.error.response.data.message,
                query: {}
            };

        case types.UPDATE_COURSE_SUCCESS:
            const courseList = state.courseList.map((course) => {
                return (course.id === action.payload.id) ? action.payload : course;
            });

            return {
                ...state,
                isUpdating: false,
                courseList,
                query: {}
            };

        case types.UPDATE_COURSE_FAILURE:
            return {
                ...state,
                isUpdating: false,
                error: action.error.response.data.message,
                query: {}
            };

        case types.COURSES_RESET_QUERY:
            return {
                ...state,
                query: { }
            };

        case types.COURSES_RESET_ERRORS:
            return {
                ...state,
                error: null
            };

        case types.COURSE_DISCUSSIONS_REQUEST:
            return {
                ...state,
                isLoadingDiscussions: true
            };

        case types.COURSE_DISCUSSIONS_SUCCESS:
            return {
                ...state,
                isLoadingDiscussions: false,
                discussions: action.payload
            };

        case types.COURSE_DISCUSSIONS_FAILURE:
            return {
                ...state,
                isLoadingDiscussions: false,
                error: action.error
            };

        case types.SET_ACTIVE_DISCUSSION:
            return {
                ...state,
                activeDiscussionId: action.discussionId
            };

        case types.DISCUSSION_COMMENTS_REQUEST:
            return {
                ...state,
                isLoadingComments: true
            };

        case types.DISCUSSION_COMMENTS_SUCCESS: {
            const updatedComments = {
                ...state.comments,
                [state.activeDiscussionId]: action.payload
            };

            return {
                ...state,
                isLoadingComments: false,
                comments: updatedComments
            };
        }

        case types.DISCUSSION_COMMENTS_FAILURE:
            return {
                ...state,
                isLoadingComments: false,
                error: action.error
            };

        case types.POST_COMMENT_REQUEST:
            return {
                ...state,
                isPostingComment: true
            };

        case types.POST_COMMENT_SUCCESS: {
            let discussionComments = state.comments[state.activeDiscussionId];

            if (!discussionComments) {
                discussionComments = [];
            }

            discussionComments = [
                action.payload,
                ...discussionComments
            ];

            const updatedComments = {
                ...state.comments,
                [state.activeDiscussionId]: discussionComments
            };

            return {
                ...state,
                isPostingComment: false,
                comments: updatedComments
            };
        }

        case types.POST_COMMENT_FAILURE:
            return {
                ...state,
                isPostingComment: false,
                error: action.error
            };

        case types.START_DISCUSSION_REQUEST:
            return {
                ...state,
                isStartingDiscussion: true
            };

        case types.START_DISCUSSION_SUCCESS: {
            return {
                ...state,
                isStartingDiscussion: false,
                discussions: [
                    ...state.discussions,
                    action.payload
                ]
            };
        }

        case types.START_DISCUSSION_FAILURE:
            return {
                ...state,
                isStartingDiscussion: false,
                error: action.error
            };

        case types.ADD_DISCUSSION_LIKE:
            return {
                ...state,
                likedDiscussions: [
                    ...state.likedDiscussions,
                    action.discussionId
                ]
            };

        case types.LIKE_DISCUSSION_REQUEST:
            return {
                ...state,
                isProcessingAction: true
            };

        case types.LIKE_DISCUSSION_SUCCESS: {
            return {
                ...state,
                isProcessingAction: false
            };
        }

        case types.LIKE_DISCUSSION_FAILURE:
            return {
                ...state,
                isProcessingAction: false,
                error: action.error
            };

        case types.ADD_COMMENT_LIKE:
            return {
                ...state,
                likedComments: [
                    ...state.likedComments,
                    action.commentId
                ]
            };

        case types.LIKE_COMMENT_REQUEST:
            return {
                ...state,
                isProcessingAction: true
            };

        case types.LIKE_COMMENT_SUCCESS: {
            return {
                ...state,
                isProcessingAction: false
            };
        }

        case types.LIKE_COMMENT_FAILURE:
            return {
                ...state,
                isProcessingAction: false,
                error: action.error
            };

        case types.LIKED_DISCUSSIONS_REQUEST:
        case types.LIKED_COMMENTS_REQUEST:
            return {
                ...state,
                isLoadingStats: true
            };

        case types.LIKED_DISCUSSIONS_SUCCESS:
            return {
                ...state,
                isLoadingStats: false,
                likedDiscussions: action.payload
            };

        case types.LIKED_COMMENTS_SUCCESS:
            return {
                ...state,
                isLoadingStats: false,
                likedComments: action.payload
            };

        case types.LIKED_DISCUSSIONS_FAILURE:
        case types.LIKED_COMMENTS_FAILURE:
            return {
                ...state,
                isLoadingStats: false,
                error: action.error
            };

        case types.COURSE_LECTURES_REQUEST:
            return {
                ...state,
                isLoadingLectures: true
            };

        case types.COURSE_LECTURES_SUCCESS:
            return {
                ...state,
                isLoadingLectures: false,
                lectures: action.payload
            };

        case types.COURSE_LECTURES_FAILURE:
            return {
                ...state,
                isLoadingLectures: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default courses;
