import * as types from "./dashboard.types";

const initialState = {
    isLoading: false,
    notifications: [],
    recentActivity: [],
    error: null
};

const dashboard = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.RECENT_ACTIVITY_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case types.RECENT_ACTIVITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                recentActivity: action.payload
            };

        case types.RECENT_ACTIVITY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default dashboard;
