/** -------------------------------------------------------------------------------------------------------------------
 * TimezoneSelector
 * A component that renders a map view of timezones and allows the user to select her current one
 *
 * @examples
 *
 *  ```jsx
 *    <TimezoneSelector
 *      i18n={i18n}
 *      timezone="gmt"
 *      onTimezoneSelected={(timezone) => console.log("Timezone changed: ", timezone)}
 *    />
 * ```
 *
 *  @component TimezoneSelector
 *  @import TimezoneSelector
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Raphael from "raphael";

import timezones from "./timezones";
import { noop } from "../../../utils/functions";
import { getLocalTime } from "../../../utils/dates";

import "./timezone.scss";

const CANVAS_WIDTH = 585;
const CANVAS_HEIGHT = 250;


export default class TimezoneSelector extends PureComponent {

    constructor(props) {
        super(props);
        this.canvasContainer = React.createRef();

        const { timezone } = this.props;
        const timezoneList = this._adjustTimezoneOffsets();
        let currentTimezone = timezoneList.find((zone) => zone.timezoneId === timezone);

        /** If no timezone found, default to "Pacific" */
        if (!currentTimezone) {
            currentTimezone = timezoneList[3]; // eslint-disable-line prefer-destructuring
        }

        const updateInterval = (/ja|ko/.test(this.props.locale)) ? 1 : 5;

        this.state = {
            timezoneList,
            userTimezone: currentTimezone,
            currentTimezone,
            updateInterval,
            localTime: null,
            isInitialRendering: true
        };
    }

    _adjustTimezoneOffsets() {
        const { timezoneOffsets } = this.props;

        return Object.values(timezones).map((timezone) => {
            const instance = timezoneOffsets.find((item) => item.id === timezone.id);

            if (instance) {
                timezone.name = instance.name;
                timezone.offset = instance.offset;
            }

            return timezone;
        });
    }

    _renderMap = () => {
        const {
            timezoneList,
            userTimezone: { timezoneId }
        } = this.state;

        this.surface = Raphael(this.canvasContainer.current, CANVAS_WIDTH, CANVAS_HEIGHT);

        let currentTimezone;
        const onTimezoneSelected = this._onTimezoneSelected;

        function onMouseOver() {
            const isSelected = this.data("selected") === true;

            if (!isSelected) {
                this.stop().animate({
                    opacity: 0.45
                }, 150);
            }
        }

        function onMouseOut() {
            const isSelected = this.data("selected") === true;

            if (!isSelected) {
                this.stop().animate({
                    opacity: 0
                }, 150);
            }
        }

        function selectTimezone(timezone) {
            if (timezone) {
                timezone.stop().animate({
                    opacity: 0.8
                }).data("selected", true);

                currentTimezone = timezone;
                onTimezoneSelected(currentTimezone.data("timezone"));
            }
        }

        function onNewTimezoneSelected() {
            if (currentTimezone) {
                currentTimezone.animate({
                    opacity: 0
                }).data("selected", false);
            }

            selectTimezone(this);
        }

        timezoneList.forEach((timezone) => {
            this.surface.setStart();
            this.surface.path(timezone.shapes)
                .attr({
                    stroke: "#0096d6",
                    fill: "#0096d6",
                    "stroke-opacity": 1,
                    "fill-opacity": 0.5,
                    opacity: 0,
                    cursor: "pointer"
                })
                .data("timezone", {
                    id: timezone.id,
                    timezoneId: timezone.timezoneId,
                    name: timezone.name,
                    offset: timezone.offset
                })
                .click(onNewTimezoneSelected);

            const element = this.surface.setFinish();
            element.hover(onMouseOver, onMouseOut);

            const isCurrentTimezone = timezone.timezoneId === timezoneId;

            if (isCurrentTimezone) {
                currentTimezone = element[0]; // eslint-disable-line prefer-destructuring
            }
        });

        if (currentTimezone) {
            selectTimezone(currentTimezone);
        }
    };

    _onTimezoneSelected = (currentTimezone) => {

        this.setState({
            currentTimezone
        });

        this._displayTimeAtTimezone(currentTimezone);

        if (this.state.isInitialRendering) {
            this.setState({
                isInitialRendering: false
            });
        } else {
            if (this.props.onTimezoneSelected) {
                this.props.onTimezoneSelected(currentTimezone.timezoneId);
            }
        }
    };

    _displayTimeAtTimezone(timezone) {
        const localTime = getLocalTime(timezone.offset);

        this.setState({ localTime }, () => {
            this._updateDateAndTime();
        });

        if (this.timer) {
            clearInterval(this.timer);
        }

        this.timer = setInterval(() => this._updateDateAndTime(), this.state.updateInterval * 1000);
    }

    _updateDateAndTime = () => {
        const { localTime, updateInterval } = this.state;
        const instant = new Date(localTime.getTime() + updateInterval * 1000);

        this.setState({
            localTime: instant
        });
    };

    componentDidMount() {
        this._renderMap();
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    render() {
        const {
            i18n: {
                getString,
                formatTime,
                languagePack
            },
            className
        } = this.props;

        const {
            currentTimezone,
            localTime,
        } = this.state;

        const time = localTime ? formatTime(localTime, languagePack.timeFormat.MEDIUM) : null;

        return (
            <div className={classNames("timezone-wrapper", className)}>
                <div className="map-container">
                    <div className="map" ref={this.canvasContainer}>
                        <div className="timezones canvas"></div>
                    </div>
                    <dl>
                        <dt>{getString("profile.currentTimezone")}:</dt>
                        <dd className="timezone-name">{currentTimezone.name}</dd>
                        <dt>{getString("profile.localTime")}:</dt>
                        <dd className="local-time">{localTime ? time : "--:--"}</dd>
                    </dl>
                </div>
            </div>
        );
    }
}

TimezoneSelector.propTypes = {
    timezone: PropTypes.string,
    timezoneOffsets: PropTypes.array,
    i18n: PropTypes.object.isRequired,
    date: PropTypes.object,
    locale: PropTypes.string,
    className: PropTypes.string,
    onTimezoneSelected: PropTypes.func
};

TimezoneSelector.defaultProps = {
    date: new Date(),
    className: "",
    timezoneOffsets: [],
    onTimezoneSelected: noop
};
