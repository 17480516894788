/** -------------------------------------------------------------------------------------------------------------------
 * ContactDetailsView
 * A component that renders the details of a contact
 *
 *  @examples
 *
 *  ```jsx
 *    <ContactDetailsView
 *      contact={contact}
 *      i18n={i18n}
 *      showsFollowButton={true}
 *      showsFollowingLabel={true}
 *      onContactActions={(contact, action) => { console.log("onContactAction"); }}
 *    />
 * ```
 *
 *  @component ContactDetailsView
 *  @import ContactDetailsView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
    USER,
    STUDENT,
    CLASSMATE,
    SUGGESTION,
    ACTION_FOLLOW,
    ACTION_FOLLOW_FROM_SUGGESTIONS,
    ACTION_UNFOLLOW
} from "../../../constants/contacts";

import Button from "../../shared/button";
import { noop } from "../../../utils/functions";

import "../contacts.scss";


class ContactDetailsView extends PureComponent {

    _onContactActions = (action) => {
        const {
            type,
            contact,
            onContactActions
        } = this.props;

        const contactAction = (type === SUGGESTION && action === ACTION_FOLLOW) ?
            ACTION_FOLLOW_FROM_SUGGESTIONS : action;

        onContactActions(contact, contactAction);
    };

    _renderEmptyContentMessage() {
        const {
            type,
            i18n: { getString }
        } = this.props;
        const message = getString((type === STUDENT) ?
            "contacts.noStudentSelected" :
            "contacts.noContactSelected"
        );

        return (
            <p className="info-message">
                <span>{message}</span>
            </p>
        );
    }

    _renderContactDetails() {
        const {
            contact,
            contact: { accountSummary },
            i18n: { getString },
            type
        } = this.props;

        const subtitle = (type === USER && contact.universityName) ?
            <p className="bio">
                {contact.universityName}
            </p> :
            contact.bio ?
                <p className="bio">
                    {contact.bio}
                </p> :
                <p className="info-message">
                    {getString("profile.noBio")}
                </p>;

        return (
            <div className="contact-details">
                <header>
                    <h4>
                        <span>{contact.firstName}</span>
                        <strong>{contact.lastName}</strong>
                    </h4>
                </header>
                <div className="row content">
                    <div className="profile-info">
                        <figure className="avatar">
                            <img src={contact.avatarURL} />
                        </figure>
                        <div className="account-summary">
                            <ul className="summary-items">
                                <li>
                                    <span className="label">{accountSummary.followers}</span>
                                    <p className="info">{getString("profile.followers")}</p>
                                </li>
                                <li>
                                    <span className="label">{accountSummary.following}</span>
                                    <p className="info">{getString("profile.following")}</p>
                                </li>
                            </ul>
                            <div className="actions">
                                {this._generateContextualActions()}
                            </div>
                        </div>
                    </div>
                    {subtitle}
                </div>
            </div>
        );
    }

    _generateContextualActions() {
        const {
            contact,
            showsFollowButton,
            showsUnfollowButton,
            showsFollowingLabel,
            i18n: { getString },
            type
        } = this.props;
        let actions = null;
        const isUserType = (type === USER);

        if (showsFollowButton) {
            actions = (
                <Button
                    title={getString("contacts.followLabel")}
                    onClick={() => this._onContactActions(ACTION_FOLLOW)}
                />
            );
        } else if (showsUnfollowButton) {
            actions = (
                <Button
                    title={getString("contacts.unfollowLabel")}
                    onClick={() => this._onContactActions(ACTION_UNFOLLOW)}
                />
            );
        } else if (showsFollowingLabel) {
            actions = (
                <p className="violator">{getString("contacts.followingLabel")}</p>
            );
        } else if (isUserType) {
            const isInactive = (contact.accountStatus.toLowerCase() === "inactive");
            const label = isInactive ? "Activate" : "Deactivate";
            const action = isInactive ? "activate" : "deactivate";

            actions = (
                <Button
                    title={label}
                    onClick={() => this._onContactActions(action)}
                />
            );
        } else {
            actions = (
                <Button
                    title={getString("generic.chat")}
                    disabled={true}
                    onClick={() => this._onContactActions("chat")}
                />
            );
        }

        return actions;
    }

    render() {
        const {
            contact,
            type
        } = this.props;

        return (
            <div className={classNames("contact-details-wrapper", type)}>
                {(contact != null) ? this._renderContactDetails() : this._renderEmptyContentMessage()}
            </div>
        );
    }
}

ContactDetailsView.propTypes = {
    contact: PropTypes.object,
    type: PropTypes.string,
    className: PropTypes.string,
    i18n: PropTypes.object,
    onContactActions: PropTypes.func,
    showsFollowButton: PropTypes.bool,
    showsUnfollowButton: PropTypes.bool,
    showsFollowingLabel: PropTypes.bool
};

ContactDetailsView.defaultProps = {
    type: CLASSMATE,
    onContactActions: noop
};

export default ContactDetailsView;
