/** -------------------------------------------------------------------------------------------------------------------
 * DestinationView
 * A component that renders the view for choosing a destination (student or instructor)
 *
 * @examples
 *
 *  ```jsx
 *    <DestinationView
 *      className="destination-selector"
 *      onDestinationSelected={(destination) => console.log("Destination set!")}
 *    />
 * ```
 *
 *  @component DestinationView
 *  @import DestinationView
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/
/* eslint-disable max-len */
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { SiteInfo } from "./site-info";
import LanguageSelector from "../../shared/language-selector";

import "./destination-view.scss";


export default class DestinationView extends Component {

    constructor(props) {
        super(props);

        this.viewContainer = React.createRef();
        this.introVideo = React.createRef();

        this.state = {
            destination: null,
            videoPlaying: false
        };
    }

    _onDestinationSelected = (event) => {
        const destination = event.currentTarget.getAttribute("data-destination");

        if (this.props.onDestinationSelected) {
            this.props.onDestinationSelected(destination);
        }
    };

    _onLanguageSelected = (language) => {
        if (this.props.onLanguageSelected) {
            this.props.onLanguageSelected(language);
        }
    };

    _onVideoPlayback = () => {
        if (this.introVideo && this.introVideo.current) {
            this.introVideo.current.play();

            this.setState({
                videoPlaying: true
            });
        }
    };

    _onDocumentScroll = () => {
        if (this.introVideo && this.introVideo.current && this.state.videoPlaying) {
            this.introVideo.current.pause();

            this.setState({
                videoPlaying: false
            });
        }
    };

    componentDidMount() {
        this.viewContainer.current.addEventListener("scroll", this._onDocumentScroll);
    }

    componentWillUnmount() {
        this.viewContainer.current.removeEventListener("scroll", this._onDocumentScroll);
    }

    render() {
        const {
            title,
            locale,
            country,
            languages,
            i18n,
            i18n: { format, getString }
        } = this.props;
        const { videoPlaying } = this.state;

        return (
            <section ref={this.viewContainer} className="view-wrapper home">
                <section className="branding">
                    <header>
                        <h1>
                            <a href="/" title={title}>
                                <img alt={title}
                                    src="https://akwaba.s3-us-west-1.amazonaws.com/assets/university-avenue-logo.png" />
                            </a>
                        </h1>
                    </header>
                    <div className="locale-selector">
                        <LanguageSelector
                            locale={locale}
                            iconClassName="icon-chevron-down-white"
                            country={country}
                            languages={languages}
                            onLanguageSelected={this._onLanguageSelected}
                        />
                    </div>
                </section>
                <section className="container destination-selector" data-hires="true">
                    <section className="module section-destination" data-label="Destinations">
                        <h2 className={classNames("offset-text", locale)}>{getString("application.title")}</h2>
                        <ul className="block destinations">
                            <li>
                                <a href="javascript:void(0);"
                                    className="student"
                                    title={getString("login.studentLogin")}
                                    data-destination="student"
                                    onClick={this._onDestinationSelected}>
                                    <img src={"/images/graduates.png"}
                                        alt={getString("login.studentLogin")} />
                                    <strong className={classNames("offset-text", locale)}>
                                        {getString("login.studentIntro")}
                                    </strong>
                                    <em className={classNames("offset-text", locale)}>
                                        {getString("login.studentTagline")}
                                    </em>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);"
                                    className="instructor"
                                    title={getString("login.instructorLogin")}
                                    data-destination="instructor"
                                    onClick={this._onDestinationSelected}>
                                    <img src={"/images/instructor.png"}
                                        alt={getString("login.instructorLogin")} />
                                    <strong className={classNames("offset-text", locale)}>
                                        {getString("login.instructorIntro")}
                                    </strong>
                                    <em className={classNames("offset-text", locale)}>
                                        {getString("login.instructorTagline")}
                                    </em>
                                </a>
                            </li>
                        </ul>
                    </section>
                    <div className={classNames("app-store-download", locale)}>
                        <a id="appStoreDownload" className="app-store-badge" title={getString("application.appStore")}
                            href="https://itunes.apple.com/us/app/1-university-avenue/id919768724?ls=1&mt=8">
                            {getString("application.appStore")}
                        </a>
                    </div>
                    <div className="caret-container">
                        <a href="javascript:void(0)" className="caret">View more</a>
                    </div>
                </section>
                <section className={classNames("section-video intro", locale)} data-label="1UA intro">
                    <div className={classNames("content", { "is-playing": videoPlaying })}>
                        <video id="1ua-promo-video" ref={this.introVideo}>
                            <source type="video/mp4"
                                src="https://s3-us-west-1.amazonaws.com/rendezvous7.net/media/akwaba/1ua-promo-graphic-design.mp4"
                                poster="https://s3-us-west-1.amazonaws.com/rendezvous7.net/media/akwaba/1ua-intro-video-poster.jpg"></source>
                            <p>
                                Your browser doesn&apos;t support HTML5 video.
                            </p>
                        </video>
                        <div className="video-mask"></div>
                        <a href="javascript:void(0);" className="overlay" onClick={this._onVideoPlayback}></a>
                    </div>
                </section>

                <section className="site-info">
                    <footer className="block">
                        <p className="copyright">
                            <span>{format("application.copyright", [new Date().getFullYear()])}</span>
                            <SiteInfo i18n={i18n} />
                        </p>
                    </footer>
                </section>

            </section>
        );
    }
}

DestinationView.propTypes = {
    title: PropTypes.string,
    languages: PropTypes.array,
    locale: PropTypes.string,
    country: PropTypes.string,
    contextPath: PropTypes.string,
    i18n: PropTypes.object,
    destination: PropTypes.string,
    onDestinationSelected: PropTypes.func,
    onLanguageSelected: PropTypes.func
};

DestinationView.defaultProps = {
    languages: [],
    locale: "en",
    onDestinationSelected: () => {},
    onLanguageSelected: () => {}
};
