/** ----------------------------------------------------------------------------------------------------------------
 * PageTemplate
 * A component that renders the main template of inline pages, which includes the global navigation, the footer,
 * and other such common components
 *
 * @examples
 *
 *  ```jsx
 *    <PageTemplate
 *      className="dashboard-wrapper">
 *          <LocalNav />
 *          <div className="module-content-section" />
 *    />
 * ```
 *
 *  @component PageTemplate
 *  @import PageTemplate
 *  @returns {object} React object
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import GlobalNav from "../global-nav";
import LocalTime from "../local-time";


class PageTemplate extends Component {

    constructor(props) {
        super(props);
        this.pageContainer = React.createRef();

        this.state = {
            panelExpanded: false
        };
    }

    _onPanelToggle = () => {
        const { panelExpanded } = this.state;
        this.setState({
            panelExpanded: !panelExpanded
        });

        setTimeout(() => {
            if (this.pageContainer && this.pageContainer.current) {
                this.pageContainer.current.classList[panelExpanded ? "remove" : "add"]("panel-expanded");
            }
        }, 30);
    }

    render() {
        const {
            title,
            children,
            className,
            auth,
            history,
            profile,
            showLocalTime
        } = this.props;

        /**
         * The timezone is initially loaded with the user's info. The user can then change it on her
         * profile page. So we first check if a recent value exists in the settings before falling back
         * to the initial one
         */
        let { timezone, localTime } = auth.userInfo;
        const shouldUseSettings = (
            profile &&
            profile.settings &&
            profile.settings.timezone &&
            profile.settings.localTime
        );

        if (shouldUseSettings) {
            timezone = profile.settings.timezone; // eslint-disable-line prefer-destructuring
            localTime = profile.settings.localTime; // eslint-disable-line prefer-destructuring
        }

        return (
            <React.Fragment>
                <GlobalNav
                    i18n={this.props.i18n}
                    title={title}
                    history={history}
                />
                <section className={classNames("page-wrapper", className)} ref={this.pageContainer}>
                    <div className="background-mask" />
                    <section className="main-content-container">
                        <section className="main-content">
                            {children}
                        </section>
                        <section className="footer">
                            <div className="toolbar">
                                {showLocalTime && <LocalTime
                                    locale={this.props.context.locale}
                                    i18n={this.props.i18n}
                                    timezone={timezone}
                                    date={localTime}
                                />}
                            </div>
                        </section>
                    </section>
                    <aside className="contextual-panel-container" style={{"display": "none"}}>
                        <div className="panel-wrapper">
                            <header>
                                <a href="javascript:void(0);" onClick={this._onPanelToggle}>
                                    <i className="icon icon-close" />
                                </a>
                                <h5>Chat</h5>
                            </header>
                        </div>
                        <div className="panel-content"></div>
                        <div className="controls">
                            <button onClick={this._onPanelToggle}>
                                <i className="icon icon-chevronleft" />
                            </button>
                        </div>
                    </aside>
                </section>
            </React.Fragment>
        );
    }
}

PageTemplate.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    showLocalTime: PropTypes.bool,
    i18n: PropTypes.object,
    context: PropTypes.object,
    auth: PropTypes.object,
    history: PropTypes.object,
    profile: PropTypes.object
};

PageTemplate.defaultProps = {
    title: "1UA",
    showLocalTime: true
};

export default PageTemplate;
