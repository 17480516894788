import * as types from "./lectures.types";
import {
    removeQuotes,
    removeTags,
    toUnicode
} from "../../../utils/strings";


export const fetchUserLectures = (username) => (dispatch, getState) => {
    const { accountType } = getState().auth.userInfo;
    const endpoint = `/users/${username}/lectures?accountType=${accountType}`;

    return dispatch({
        types: [
            types.LECTURES_REQUEST,
            types.LECTURES_SUCCESS,
            types.LECTURES_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const fetchUserLecturesForDateRange = (username, minDate, maxDate) => (dispatch, getState) => {
    const { accountType } = getState().auth.userInfo;
    const endpoint = `/users/${username}/lectures?accountType=${accountType}&minDate=${minDate}&maxDate=${maxDate}`;

    return dispatch({
        types: [
            types.UPCOMING_LECTURES_REQUEST,
            types.UPCOMING_LECTURES_SUCCESS,
            types.UPCOMING_LECTURES_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const fetchSharedSpaces = (username) => (dispatch) => {
    const endpoint = `/users/${username}/shared-spaces`;

    return dispatch({
        types: [
            types.SHARED_SPACES_REQUEST,
            types.SHARED_SPACES_SUCCESS,
            types.SHARED_SPACES_FAILURE
        ],
        request: {
            method: "GET",
            endpoint
        }
    });
};


export const addSharedSpace = (userId, name, accessLevel = "public") => (dispatch) => {
    const endpoint = `/users/${userId}/shared-spaces`;

    const normalizedName = removeTags(removeQuotes(name));
    const body = {
        name: toUnicode(normalizedName),
        accessLevel
    };

    return dispatch({
        types: [
            types.ADD_SHARED_SPACE_REQUEST,
            types.ADD_SHARED_SPACE_SUCCESS,
            types.ADD_SHARED_SPACE_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};


export const deleteSharedSpace = (userId, sharedSpaceId) => (dispatch) => {
    const endpoint = `/users/${userId}/shared-spaces/${sharedSpaceId}/delete`;

    return dispatch({
        types: [
            types.DELETE_SHARED_SPACE_REQUEST,
            types.DELETE_SHARED_SPACE_SUCCESS,
            types.DELETE_SHARED_SPACE_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body: {}
        }
    });
};


export const onLectureDetailUpdated = (name, value) => (dispatch) => {
    return dispatch({
        type: types.LECTURE_DETAILS_UPDATED,
        details: {
            name,
            value
        }
    });
};


export const resetQuery = () => (dispatch) => {
    return dispatch({
        type: types.LECTURES_RESET_QUERY
    });
};


export const resetErrors = () => (dispatch) => {
    return dispatch({
        type: types.LECTURES_RESET_ERRORS
    });
};


export const scheduleLecture = (username) => (dispatch, getState) => {
    const endpoint = `/users/${username}/lectures`;
    const { query } = getState().lectures;
    const {
        title,
        newRoom,
        ...rest
    } = query;

    const normalizedTitle = removeTags(removeQuotes(title));
    const body = {
        title: toUnicode(normalizedTitle),
        ...rest
    };

    if (newRoom) {
        const normalizedRoomName = removeTags(removeQuotes(newRoom));
        body.newRoom = toUnicode(normalizedRoomName);
    }

    return dispatch({
        types: [
            types.SCHEDULE_LECTURE_REQUEST,
            types.SCHEDULE_LECTURE_SUCCESS,
            types.SCHEDULE_LECTURE_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};


export const rescheduleLecture = (username, lectureId) => (dispatch, getState) => {
    const { query } = getState().lectures;
    const endpoint = `/users/${username}/lectures/${lectureId}`;
    const {
        title,
        ...rest
    } = query;

    const normalizedTitle = removeTags(removeQuotes(title));
    const body = {
        title: toUnicode(normalizedTitle),
        ...rest
    };

    return dispatch({
        types: [
            types.UPDATE_LECTURE_REQUEST,
            types.UPDATE_LECTURE_SUCCESS,
            types.UPDATE_LECTURE_FAILURE
        ],
        request: {
            method: "POST",
            endpoint,
            body
        }
    });
};
