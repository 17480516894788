/** -------------------------------------------------------------------------------------------------------------------
 * API Middleware
 *
 * A middleware that makes API requests to the server and dispatches actions at each stage of the request (started,
 * succeeded, or failed). It is configured as part of the store creation, and it follows the action dispatching flow.
 * This means that no other configuration is needed in order to use it.
 *
 * Whereas normal (synchronous) actions can still be dispatched in the application, asynchronous actions (those
 * that involve making API requests and waiting for the outcome) are dispatched by following these rules:
 * 1. The action object should contain a "types" key, which is an array of the 3 action types that represent the
 *    lifecycle of a request: REQUEST (sending the request), SUCCESS (on success), and FAILURE (on failure)
 * 2. The action object should contain a "request" object with the details of the request to make.
 *
 * @examples
 *
 *   Making a request to the "/universities" URL to retrieve all universities (from the "universities.action.js" file)
 *
 * ```jsx
 *
 *  export const fetchUniversities = () => (dispatch) => {
 *      return dispatch({
 *          types: [
 *              types.UNIVERSITIES_REQUEST,
 *              types.UNIVERSITIES_SUCCESS,
 *              types.UNIVERSITIES_FAILURE
 *          ],
 *          request: {
 *              method: "GET",
 *              endpoint: "/universities"
 *          }
 *      });
 *   };
 *
 * ```
 *
 * In the above scenario, the "UNIVERSITIES_REQUEST" action is dispatched when the request is sent. The "universities"
 * reducer then handles it as follows:
 *
 *  ```jsx
 *
 *  const user = (state = initialState, action = {}) => {
 *      switch(action.type) {
 *          case types.UNIVERSITIES_REQUEST:
 *              return {
 *                  ...state,
 *                  isLoading: true
 *              };
 *
 *          // Additional logic goes here...
 *
 *      };
 *  };
 *  ```
 *
 * It also handles the remaining actions in the same fashion.
 *
 *  @returns {object} Redux middleware
 *
 *-----------------------------------------------------------------------------------------------------------------*/
import axios from "axios";

const BASE_URL = "/api/v3";
const headers = {
    "Accept": "application/json, text/plain, */*",
    "Content-Type": "application/json"
};

const invoke = ({ endpoint, method = "GET", body }) => {
    const url = `${BASE_URL}${endpoint}`;
    const params = {
        method,
        url,
        data: body,
        headers
    };

    return axios(params)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

/* eslint-disable no-unused-vars */
const apiMiddleware = (store) => (next) => (action) => {
    const { request } = action;

    /** Proceed to the next middleware in the chain if it is a normal, synchronous action */
    if (!request) {
        return next(action);
    }

    const {
        request: { method, endpoint, body },
        types: [REQUEST, SUCCESS, FAILURE]
    } = action;

    next({
        type: REQUEST
    });

    return invoke({ method, body, endpoint }).then(
        (response) => {
            return (response.status >= 200 && response.status < 300) ?
                next({ type: SUCCESS, payload: response.data }) :
                next({ type: FAILURE, error: response.response.data });
        })
        .catch((error) => next({ type: FAILURE, error }));
};

export default apiMiddleware;
