export const VIDEOS_REQUEST = "VIDEOS_REQUEST";
export const VIDEOS_SUCCESS = "VIDEOS_SUCCESS";
export const VIDEOS_FAILURE = "VIDEOS_FAILURE";

export const VIDEOS_BY_STATUS_REQUEST = "VIDEOS_BY_STATUS_REQUEST";
export const VIDEOS_BY_STATUS_SUCCESS = "VIDEOS_BY_STATUS_SUCCESS";
export const VIDEOS_BY_STATUS_FAILURE = "VIDEOS_BY_STATUS_FAILURE";

export const DELETING_VIDEO_ID = "DELETING_VIDEO_ID";

export const DELETE_VIDEO_REQUEST = "DELETE_VIDEO_REQUEST";
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const DELETE_VIDEO_FAILURE = "DELETE_VIDEO_FAILURE";

export const ASSIGNING_VIDEO_ID = "ASSIGNING_VIDEO_ID";
export const ASSIGN_VIDEO_REQUEST = "ASSIGN_VIDEO_REQUEST";
export const ASSIGN_VIDEO_SUCCESS = "ASSIGN_VIDEO_SUCCESS";
export const ASSIGN_VIDEO_FAILURE = "ASSIGN_VIDEO_FAILURE";

export const UNASSIGN_VIDEO_REQUEST = "UNASSIGN_VIDEO_REQUEST";
export const UNASSIGN_VIDEO_SUCCESS = "UNASSIGN_VIDEO_SUCCESS";
export const UNASSIGN_VIDEO_FAILURE = "UNASSIGN_VIDEO_FAILURE";

export const PREVIEW = "PREVIEW";
export const ASSIGN = "ASSIGN";
export const UNASSIGN = "UNASSIGN";
export const PUBLISH = "PUBLISH";
export const UNPUBLISH = "UNPUBLISH";
export const DELETE = "DELETE";
export const PLAYBACK = "PLAYBACK";
export const ADD_COURSE = "ADD_COURSE";
export const ADD_LECTURE = "ADD_LECTURE";
export const SET_ASSIGNMENT_TYPE = "SET_ASSIGNMENT_TYPE";
export const SET_ASSIGNED_COURSE_ID = "SET_ASSIGNED_COURSE_ID";
export const SET_ASSIGNED_LECTURE_ID = "SET_ASSIGNED_LECTURE_ID";

export const ASSIGNMENT_TYPE_UNIVERSITY_INTRO = "UNIVERSITY_INTRO";
export const ASSIGNMENT_TYPE_COURSE_INTRO = "COURSE_INTRO";
export const ASSIGNMENT_TYPE_COURSE_LECTURE = "COURSE_LECTURE";

export const VIDEOS_RESET_ERRORS = "VIDEOS_RESET_ERRORS";
