import * as types from "./profile.types";

const initialState = {
    isLoading: false,
    isUpdating: false,
    isUpdatingPreferences: false,
    settings: {},
    membership: {},
    timezoneOffsets: [],
    error: null
};

const normalizePreferences = (preferences) => {
    if (typeof preferences.settings === "string") {
        preferences.settings = JSON.parse(preferences.settings);
    }

    return preferences;
};

const profile = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.MEMBERSHIP_REQUEST:
        case types.TIMEZONE_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case types.MEMBERSHIP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                membership: action.payload
            };

        case types.MEMBERSHIP_FAILURE:
        case types.TIMEZONE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case types.TIMEZONE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                timezoneOffsets: action.payload
            };

        case types.PASSWORD_CHANGE_REQUEST:
            return {
                ...state,
                isUpdating: true
            };

        case types.PASSWORD_CHANGE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                error: null
            };

        case types.PASSWORD_CHANGE_FAILURE:
            return {
                ...state,
                isUpdating: false,
                error: action.error
            };

        case types.PROFILE_SETTINGS_REQUEST:
        case types.LANGUAGE_CHANGE_REQUEST:
        case types.PROFILE_SETTINGS_UPDATE_REQUEST:
        case types.TERMS_AND_CONDITIONS_REQUEST:
        case types.NOTIFICATION_UPDATE_REQUEST:
            return {
                ...state,
                isUpdatingPreferences: true
            };

        case types.PROFILE_SETTINGS_SUCCESS:
        case types.LANGUAGE_CHANGE_SUCCESS:
        case types.PROFILE_SETTINGS_UPDATE_SUCCESS:
        case types.TERMS_AND_CONDITIONS_SUCCESS:
        case types.NOTIFICATION_UPDATE_SUCCESS:
            const normalizedPreferences = normalizePreferences(action.payload);

            return {
                ...state,
                settings: {
                    ...normalizedPreferences.settings
                },
                isUpdatingPreferences: false,
                error: null
            };

        case types.PROFILE_SETTINGS_FAILURE:
        case types.LANGUAGE_CHANGE_FAILURE:
        case types.PROFILE_SETTINGS_UPDATE_FAILURE:
        case types.TERMS_AND_CONDITIONS_FAILURE:
        case types.NOTIFICATION_UPDATE_FAILURE:
            return {
                ...state,
                isUpdatingPreferences: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default profile;
