/* eslint-disable no-throw-literal, no-unused-vars */
/** -----------------------------------------------------------------------------------------------------------------
                                       FILE UPLOADER

A class that allows users to upload files of different types to the server: images, videos, documents, etc
It requires a delegate and notifies it of the different stages of the upload process.

--------------------------------------------------------------------------------------------------------------------*/

import Extensions           from "@akwaba/object-extensions";
import HTML                 from "@akwaba/html";
import DOM                  from "@akwaba/dom";
import Event                from "@akwaba/events";

import { formatFileSize }   from "../utils/numbers";
import { format }           from "../utils/strings";


export default class FileUploader {

    static requiredMethods = [
        "onUploadStart",
        "onUploadProgress",
        "onUploadComplete",
        "onUploadError",
        "onMaxSizeExceeded"
    ];

    constructor(options) {
        this.options = Object.assign(FileUploader.defaultOptions, options);
        this.element = Extensions.isString(this.options.element) ? HTML.getElement(this.options.element) :
            this.options.element;
        const isValidContainer = this.element && this.element.nodeType === HTML.nodeType.ELEMENT_NODE;

        if (!isValidContainer) {
            throw new Error("INVALID_MEDIA_CONTAINER");
        }

        this.locale = this.options.locale;
        this.type = this.options.type;
        this.imageURL = this.options.imageURL;
        this.serverURL = this.options.serverURL;
        this.delegate = this.options.delegate;

        this.render();
    }


    /**
     * Renders the view of this file upload component. It also ensures that the provided delegate conforms to
     * the FileUploader interface.
     */
    render() {
        const proceed = !!(this.delegate && Extensions.implementsInterface(this.delegate, FileUploader));

        if (!proceed) {
            const message = FileUploader.requiredMethods.join(", ");
            throw {
                error: "DELEGATE_METHODS_NOT_IMPLEMENTED",
                message
            };
        }

        const { getString } = this.options.i18n;
        const htmlContent = format(this.options.templates.MARKUP, {
            browse: getString("videos.browse")
        });

        this.container = HTML.createElement("div", {
            className: `file-upload-container ${this.options.style}`
        });
        HTML.setContent(this.container, htmlContent);
        this.element.appendChild(this.container);

        if (this.options.type) {
            HTML.addClassName(this.container, this.options.type);
        }

        const mediaType = this.options.mediaType.toUpperCase();
        const isSupported = (mediaType in this.options.fileTypes);
        this.fileTypes = isSupported ? this.options.fileTypes[mediaType] : this.options.fileTypes.IMAGE;

        this.thumbnailImage = DOM.find(".thumbnail", this.container);
        this.fileSection = DOM.find(".file-section", this.container);
        this.dropzone = DOM.find(".dropzone", this.container);

        this.fileInput = DOM.find('input[type="file"]', this.container);
        const supportedExtensions = this.fileTypes.map((type) => `.${type}`).join(", ");
        this.fileInput.setAttribute("accept", supportedExtensions);

        this.browseButton = DOM.find(".browse", this.container);
        this.progressSection = DOM.find(".progress-section", this.container);
        this.completedStatus = DOM.find(".completed", this.container);
        this.progressBar = DOM.find(".filler", this.container);

        if (this.isCompactMode()) {
            HTML.removeClassName(this.container, "normal");
            HTML.addClassName(this.container, "compact");
        }

        if (this.isImageMode()) {
            HTML.show(this.thumbnailImage);
            this.thumbnailImage.src = this.imageURL;
        }

        return this.registerEvents();
    }


    /**
     * Registers event handlers for this file upload component
     */
    registerEvents() {
        Event.add(this.container, "dragover", this.onDragOver);
        Event.add(this.container, "drop", this.onDrop);
        Event.add(this.browseButton, "click", this.browseFiles);
        Event.add(this.fileInput, "change", this.onFileSelected);

        return this;
    }


    /**
     * Opens the dialog that allows the user to browse for files on her computer
     *
     * @param {Event} event     the event that triggered this event
     */
    browseFiles = (event) => {
        Event.stop(event);

        if (this.fileInput) {
            this.fileInput.click();
        }
    };


    /**
     * A callback method invoked when the user selects a file.
     *
     * @param {Event} event     the file select event
     */
    onFileSelected = () => {
        [this.currentFile] = this.fileInput.files;

        if (!this.currentFile) {
            return this;
        }

        this.handleFile(this.currentFile);
    };


    /**
     * Returns the extension of the given file
     *
     * @param {String} file     the file for which to return the extension
     * @return {String} the extension for the given file
     */
    getFileExtension(file) {
        return file.substring(file.lastIndexOf(".") + 1, file.length);
    }


    /**
     * A callback method invoked when the user moves a file over the dropzone
     *
     * @param {Event} event     the mouse move event over the dropzone
     */
    onDragOver = (event) => {
        Event.stop(event);
    };


    /**
     * A callback method invoked when the user drops a file over the dropzone
     *
     * @param {Event} event     the drop event over the dropzone
     */
    onDrop = (event) => {
        Event.stop(event);

        if (event.dataTransfer.files.length > 0) {
            [this.currentFile] = event.dataTransfer.files;
            this.handleFile(this.currentFile);
        }
    };


    /**
     * Uploads the specified file. If its size exceeds the maximum allowed size, it displays an error message to
     * the user.
     *
     * @param {Object} file         the file to upload
     */
    handleFile(file) {
        const { getString, format } = this.options.i18n;
        const extension = this.getFileExtension(file.name);
        const isSupported = !!(extension && this.fileTypes.includes(extension.toLowerCase()));

        if (!isSupported) {
            const title = getString(this.isVideoMode() ? "videos.notVideoFile" : "videos.fileNotSupported");
            const message = getString(this.isVideoMode() ? "videos.notVideoFileMessage" :
                "images.fileNotSupportedMessage");
            return this.delegate.onUploadError(title, message);
        }

        const maximumSize = this.options.maximumSizes[this.type.toUpperCase()];
        const exceedsMaxSize = this.currentFile.size > maximumSize;

        if (exceedsMaxSize) {
            const errorMessage = format("videos.fileTooLargeMessage", [formatFileSize(maximumSize, true)]);
            return this.delegate.onUploadError(getString("videos.fileTooLarge"), errorMessage);
        }

        HTML.addClassName(this.fileSection, "translucent");
        HTML.show(this.progressSection);
        const fileNameContainer = DOM.find(".file-name", this.progressSection);
        HTML.setContent(fileNameContainer, this.currentFile.name);

        if (!this.fileReader) {
            this.initializeFileReader();
        }

        this.ajaxRequest.open("POST", this.serverURL);
        const formData = new FormData();
        formData.append("Filedata", this.currentFile);
        this.ajaxRequest.send(formData);

        this.delegate.onUploadStart(this.currentFile);
    }


    /**
     * Initializes the instance of the FileReader and Ajax request objects used to read and
     * upload files. It also attaches the handlers for the different states of the upload process.
     */
    initializeFileReader() {
        this.fileReader = new FileReader();

        this.ajaxRequest = new XMLHttpRequest();
        this.ajaxRequest.overrideMimeType("text/plain; charset=x-user-defined-binary");
        this.ajaxRequest.upload.addEventListener("progress", this.onUploadProgress, false);
        this.ajaxRequest.upload.addEventListener("load", this.onUploadComplete, true);
        this.ajaxRequest.addEventListener("readystatechange", this.onUploadProgress, false);

        return this;
    }


    /**
     * A callback method invoked when the progress of the file upload is updated.
     *
     * @param {Event} event     the progress event fired when the file upload state changes
     */
    onUploadProgress = (event) => {
        const isComplete = (event.target && event.target.readyState === 4);
        const isError = (isComplete && /400|404|409|500/.test(event.target.status));

        if (isError) {
            return this.onUploadError({
                status: event.target.status
            });
        }

        if (event.lengthComputable) {
            const percentage = Math.round(event.loaded * 100 / event.total);
            HTML.setContent(this.completedStatus, `${percentage}%`);
            HTML.setStyle(this.progressBar, {
                width: `${percentage}%`
            });

            this.delegate.onUploadProgress(percentage);
        }
    };


    /**
     * A callback method invoked when the file is uploaded successfully.
     */
    onUploadComplete = (event) => {
        HTML.setContent(this.completedStatus, "100%");
        HTML.addClassName(this.progressBar, "full");
        HTML.setStyle(this.progressBar, {
            width: "100%"
        });

        setTimeout(() => {
            HTML.removeClassName(this.fileSection, "translucent");
            HTML.hide(this.progressSection);
            HTML.setContent(this.completedStatus, "0%");
            HTML.setStyle(this.progressBar, {
                width: "0%"
            });
        }, 500);

        this.delegate.onUploadComplete(this.currentFile);

        if (this.isImageMode()) {
            const reader = new FileReader();
            const thumbnail = this.thumbnailImage;

            reader.onload = (e) => {
                const imageDataURI = e.target.result;
                thumbnail.src = imageDataURI;
            };
            reader.readAsDataURL(this.currentFile);
        }

        setTimeout(() => {
            this.fileInput.value = "";
        }, 10);

        return this;
    };


    /**
     * A method invoked when the file upload fails. It notifies the delegate accordingly.
     *
     * @param {Number} response       the response of the file upload operation
     */
    onUploadError = (response) => {
        this.resetUploadStatus();
        HTML.hide(this.progressSection);

        this.delegate.onUploadError(this.currentFile, response);
    };


    /**
     * Resets the state of the file upload UI.
     */
    resetUploadStatus() {
        HTML.removeClassName(this.fileSection, "translucent");
        HTML.setContent(this.completedStatus, "0%");
        HTML.removeClassName(this.progressBar, "full");
        HTML.setStyle(this.progressBar, {
            width: "0%"
        });

        return this;
    }


    /**
     * Returns true if this file upload is in "Video" mode; otherwise, returns false
     *
     * @return {Boolean} true if this file upload is in "Video" mode; otherwise, returns false
     */
    isVideoMode() {
        return this.type.toUpperCase() === "VIDEO";
    }


    /**
     * Returns true if this file upload is in "Image" mode; otherwise, returns false
     *
     * @return {Boolean} true if this file upload is in "Image" mode; otherwise, returns false
     */
    isImageMode() {
        return this.type.toUpperCase() === "IMAGE";
    }


    /**
     * Returns true if this file upload is in "Document" mode; otherwise, returns false
     *
     * @return {Boolean} true if this file upload is in "Document" mode; otherwise, returns false
     */
    isDocumentMode() {
        return this.type.toUpperCase() === "DOCUMENT";
    }


    /**
     * Returns true if this file upload is in compact mode; otherwise, returns false
     *
     * @return {Boolean} true if this file upload is in compact mode; otherwise, returns false
     */
    isCompactMode() {
        return this.options.mode.toLowerCase() === "compact";
    }


    /**
     * Sets the URL of the server to which to upload the file
     *
     * @param {String} serverURL        the URL of the server to which to upload the file
     */
    setServerURL(serverURL) {
        this.serverURL = serverURL;
    }


    /**
     * Sets the source of the image file when in "Image" mode. The image is updated to reflect
     * the new URL.
     *
     * @param {String} imageURL         the new source to set for the image
     */
    setImageURL(imageURL) {
        this.imageURL = imageURL;

        if (this.isImageMode()) {
            this.thumbnailImage.src = `${this.imageURL}?${new Date().getTime()}`;
        }

        return this;
    }

}


FileUploader.defaultOptions = {
    element: document.body,
    width: 300,
    height: 200,
    serverURL: "/",
    mode: "compact",
    style: "normal",
    mediaType: "VIDEO",
    imageURL: "",
    fileTypes: {
        IMAGE: ["jpg", "jpeg", "png"],
        VIDEO: ["flv", "f4v", "mpeg", "mp4", "mov", "m4v", "m4a", "ogg", "3gp", "avi"],
        DOCUMENT: ["pdf", "txt", "rtf", "doc", "docx", "xls", "xlsx", "ppt"]
    },
    maximumSizes: {
        IMAGE: 2097152,
        VIDEO: 524288000,
        DOCUMENT: 2097152
    },
    i18n: {
        getString: () => {},
        format: (string) => string
    },
    templates: {
        MARKUP: `
            <div class="file-section">
                <div class="dropzone" style="display:none;"></div>
                <div class="block thumbnail-section">
                    <img class="thumbnail" style="display:none;" alt="Thumbnail" />
                </div>
                <div class="file-container">
                    <input type="file" accept="*" style="display:none;" />
                    <button class="browse">{browse}</button>
                </div>
            </div>
            <div class="progress-section" style="display:none;">
                <p class="completed">0%</p>
                <p class="progress-bar"><span class="filler">&nbsp;</span></p>
                <p class="file-name">&nbsp;</p>
            </div>
        `
    }
};
