/** -------------------------------------------------------------------------------------------------------------------
 * LectureNotificationsView
 * A component that renders the view for managing lecture notifications
 *
 * @examples
 *
 *  ```jsx
 *
 *    <LectureNotificationsView
 *      userInfo={userInfo}
 *      i18n={i18n}
 *      notifications={notifications}
 *      onNotificationChange={(notification, value) => console.log("Notification changed")}
 *    />
 * );
 * ```
 *
 *  @component LectureNotificationsView
 *  @import LectureNotificationsView
 *  @returns {object} React component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React, { Component } from "react";
import PropTypes from "prop-types";

import Toggle from "../../shared/toggle";
import Selector from "../../shared/selector";
import { noop } from "../../../utils/functions";

const intervals = [
    {value: "900000", localizedString: "date.15Minutes"},
    {value: "1800000", localizedString: "date.30Minutes"},
    {value: "2700000", localizedString: "date.45Minutes"},
    {value: "3600000", localizedString: "date.1Hour"}
];


export class LectureNotificationsView extends Component {

    constructor(props) {
        super(props);

        const {
            newLectures,
            rescheduledLectures,
            cancelledLectures,
            lectureReminders
        } = this.props.notifications;

        this.state = {
            newLectures: newLectures === "true",
            rescheduledLectures: rescheduledLectures === "true",
            cancelledLectures: cancelledLectures === "true",
            lectureReminders
        };
    }

    _generateOptions() {
        const {
            i18n: { getString }
        } = this.props;
        const { lectureReminders } = this.state;
        const optionsList = intervals.map((option) => ({
            id: option.value,
            title: getString(option.localizedString)
        }));

        optionsList.forEach((option) => {
            if (option.id === lectureReminders) {
                option.selected = true;
            }
        });

        return optionsList;
    }

    _onChange = (notification, value) => {
        this.setState({
            [notification]: value
        }, () => {
            this.props.onNotificationChange(notification, `${value}`);
        });
    };

    render() {
        const {
            i18n: { getString },
            disabled
        } = this.props;
        const {
            newLectures,
            rescheduledLectures,
            cancelledLectures
        } = this.state;

        return (
            <div className="profile-section lecture-notifications-wrapper">
                <h3 className="profile-section-header">{getString("notifications.lectures")}</h3>
                <div className="profile-section-wrapper settings">
                    <div className="row reminder-selector">
                        <div className="column medium-6 small-12 label">
                            <label>{getString("notifications.lectureReminders")}</label>
                        </div>
                        <div className="column medium-6 small-12">
                            <Selector
                                options={this._generateOptions()}
                                disabled={disabled}
                                onChange={(event) => this._onChange("lectureReminders", event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <Toggle
                            label={getString("notifications.newLectures")}
                            checked={disabled ? false : newLectures}
                            disabled={disabled}
                            onChange={(id, checked) => this._onChange("newLectures", checked)}
                        />
                    </div>
                    <div className="row">
                        <Toggle
                            label={getString("notifications.rescheduledLectures")}
                            checked={disabled ? false : rescheduledLectures}
                            disabled={disabled}
                            onChange={(id, checked) => this._onChange("rescheduledLectures", checked)}
                        />
                    </div>
                    <div className="row">
                        <Toggle
                            label={getString("notifications.cancelledLectures")}
                            checked={disabled ? false : cancelledLectures}
                            disabled={disabled}
                            onChange={(id, checked) => this._onChange("cancelledLectures", checked)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

LectureNotificationsView.propTypes = {
    userInfo: PropTypes.object,
    i18n: PropTypes.object,
    notifications: PropTypes.object,
    onNotificationChange: PropTypes.func,
    disabled: PropTypes.bool
};

LectureNotificationsView.defaultProps = {
    onNotificationChange: noop
};

export default LectureNotificationsView;
