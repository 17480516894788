export const COURSES_REQUEST = "COURSES_REQUEST";
export const COURSES_SUCCESS = "COURSES_SUCCESS";
export const COURSES_FAILURE = "COURSES_FAILURE";

export const COURSE_DETAILS_UPDATED = "COURSE_DETAILS_UPDATED";
export const COURSES_RESET_QUERY = "COURSES_RESET_QUERY";
export const COURSES_RESET_ERRORS = "COURSES_RESET_ERRORS";

export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const CREATE_COURSE_FAILURE = "CREATE_COURSE_FAILURE";

export const UPDATE_COURSE_REQUEST = "UPDATE_COURSE_REQUEST";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_FAILURE = "UPDATE_COURSE_FAILURE";

export const COURSE_DISCUSSIONS_REQUEST = "COURSE_DISCUSSIONS_REQUEST";
export const COURSE_DISCUSSIONS_SUCCESS = "COURSE_DISCUSSIONS_SUCCESS";
export const COURSE_DISCUSSIONS_FAILURE = "COURSE_DISCUSSIONS_FAILURE";

export const START_DISCUSSION_REQUEST = "START_DISCUSSION_REQUEST";
export const START_DISCUSSION_SUCCESS = "START_DISCUSSION_SUCCESS";
export const START_DISCUSSION_FAILURE = "START_DISCUSSION_FAILURE";

export const SET_ACTIVE_DISCUSSION = "SET_ACTIVE_DISCUSSION";
export const DISCUSSION_COMMENTS_REQUEST = "DISCUSSION_COMMENTS_REQUEST";
export const DISCUSSION_COMMENTS_SUCCESS = "DISCUSSION_COMMENTS_SUCCESS";
export const DISCUSSION_COMMENTS_FAILURE = "DISCUSSION_COMMENTS_FAILURE";

export const POST_COMMENT_REQUEST = "POST_COMMENT_REQUEST";
export const POST_COMMENT_SUCCESS = "POST_COMMENT_SUCCESS";
export const POST_COMMENT_FAILURE = "POST_COMMENT_FAILURE";

export const ADD_DISCUSSION_LIKE = "ADD_DISCUSSION_LIKE";
export const LIKE_DISCUSSION_REQUEST = "LIKE_DISCUSSION_REQUEST";
export const LIKE_DISCUSSION_SUCCESS = "LIKE_DISCUSSION_SUCCESS";
export const LIKE_DISCUSSION_FAILURE = "LIKE_DISCUSSION_FAILURE";

export const ADD_COMMENT_LIKE = "ADD_COMMENT_LIKE";
export const LIKE_COMMENT_REQUEST = "LIKE_COMMENT_REQUEST";
export const LIKE_COMMENT_SUCCESS = "LIKE_COMMENT_SUCCESS";
export const LIKE_COMMENT_FAILURE = "LIKE_COMMENT_FAILURE";

export const LIKED_DISCUSSIONS_REQUEST = "LIKED_DISCUSSIONS_REQUEST";
export const LIKED_DISCUSSIONS_SUCCESS = "LIKED_DISCUSSIONS_SUCCESS";
export const LIKED_DISCUSSIONS_FAILURE = "LIKED_DISCUSSIONS_FAILURE";

export const LIKED_COMMENTS_REQUEST = "LIKED_COMMENTS_REQUEST";
export const LIKED_COMMENTS_SUCCESS = "LIKED_COMMENTS_SUCCESS";
export const LIKED_COMMENTS_FAILURE = "LIKED_COMMENTS_FAILURE";

export const COURSE_LECTURES_REQUEST = "COURSE_LECTURES_REQUEST";
export const COURSE_LECTURES_SUCCESS = "COURSE_LECTURES_SUCCESS";
export const COURSE_LECTURES_FAILURE = "COURSE_LECTURES_FAILURE";
