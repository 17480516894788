/** ----------------------------------------------------------------------------------------------------------------
 * Promo
 * A component that renders the promo page for the "1 University Avenue" app
 *
 * @examples
 *
 *  ```jsx
 *    <Promo
 *      i18n={i18n} />
 *    />
 * ```
 *
 *  @component Promo
 *  @import Promo
 *  @returns {object} React functional component
 *
 *--------------------------------------------------------------------------------------------------------------------*/

import React from "react";
import PropTypes from "prop-types";

import { SiteInfo } from "../../home/views/site-info";

import "../../home/home.scss";


const Promo = ({ i18n }) => (
    <section className="view-wrapper home promo-wrapper">
        <section className="branding">
            <header>
                <h1>
                    <a href="/" title="1 University Avenue">
                        <img alt="1 University Avenue"
                            src="https://akwaba.s3-us-west-1.amazonaws.com/assets/university-avenue-logo.png" />
                    </a>
                </h1>
            </header>
        </section>
        <section className="container promo-container" data-hires="true">
            <header className="module container section-destination" data-label="App title">
                <h2 className="offset-text en">{i18n.getString("application.title")}</h2>
            </header>
            <div className="app-store-download">
                <p>{i18n.getString("application.appStore")}</p>
                <p>
                    <a id="appStoreDownload" className="app-store-badge" title="1 University Avenue"
                        href="https://apps.apple.com/us/app/1-university-avenue/id919768724?ls=1&mt=8&platform=iphone">
                        {i18n.getString("application.appStore")}
                    </a>
                </p>
            </div>
        </section>
        <section className="site-info">
            <footer className="block">
                <p className="copyright">
                    <span>{i18n.format("application.copyright", [new Date().getFullYear()])}</span>
                    <SiteInfo i18n={i18n} />
                </p>
            </footer>
        </section>
    </section>
);


Promo.propTypes = {
    i18n: PropTypes.object
};

export default Promo;
